import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-ach-credit-card-dialog',
    templateUrl: './ach-credit-card-dialog.component.html',
    styleUrls: ['./ach-credit-card-dialog.component.scss']
})

export class AchCreditCardDialogComponent {
    paymentType: string = '';
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AchCreditCardDialogComponent>) {
        console.log(data);
        this.paymentType = data.paymentType;
    }

    onChangeEvent(selectedItem: any) {
        this.paymentType = selectedItem.value;
    }

    close() {
        this.dialogRef.close();
    }
    
}