
export class RolesEntry {
    roleId: number;
    roleTypeId: number;
    name: string;
    description: string;
    persisted ? = false;
    selected ? = false;
}

