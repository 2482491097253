/**
 * Models an API key.
 */

import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class ApiKey extends BaseForm {
    apiKeyId = 0;
     /**
     * The apiKey of the apiKey.
     */
    @FormField('apiKey', ['', Validators.required])
    apiKey = '';
    userId = 0;

     /**
     * The apiKey of the apiKey.
     */
    @FormField('description', ['', Validators.required])
    description = '';
}
