import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Services
import { AuthGuardService } from 'src/app/services/auth-guard.service';

// Component
import { CartStep1Component } from '../cart-step1/cart-step1.component';


const cartRoutes: Routes = [{
  path: 'cart',
  children: [
    {
      path: '',
      component: CartStep1Component,
      data: { title: 'Cart' },
      canActivate: [AuthGuardService]
    },

  ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(cartRoutes)
  ],
  declarations: []
})
export class CartRoutingModule { }
