import { Component, OnInit } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { LoginService } from '../services/login.service';


@Component({
  selector: 'app-clear-cache-menu',
  templateUrl: './clear-cache-menu.component.html',
  styleUrls: ['./clear-cache-menu.component.css']
})
export class ClearCacheMenuComponent implements OnInit {
  public loading: boolean;
  constructor(private menuService: MenuService,
    private loginService: LoginService) { }

  ngOnInit() {
  }

  clearCache() {
    this.loading = true;
      this.menuService.clearCache().subscribe(res => {
        this.menuService.getMenusByAppName('GEWEB').subscribe(menu => {
            LocalStorageHelper.setUserMenu(menu[0]);
            this.menuService.reloadMenu.next(true);
            this.loading = false;
          });
       }, error => this.loading = false);
  }
}
