import { Component, Inject } from '@angular/core';
import { EmailAddressTypeService } from 'src/app/services/email-address-type.service';
import { Email } from 'src/app/model/email';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmailAddressType } from 'src/app/model/email-address-type';

@Component({
    selector: 'add-email',
    templateUrl: './add-email-dialog.component.html',
    styleUrls: ['./add-email-dialog.component.scss']
})
export class AddEmailDialogComponent {
    private peopleId: number;
    private emailFormGroup: FormGroup;
    private emails: Email[] = [];
    private model: Email;
    private emailAddressTypes: EmailAddressType[] = [];
    private editMode: boolean = false;
    private lastEmailAddress: string = '';

    constructor(private emailAddressTypeService: EmailAddressTypeService,
        public dialogRef: MatDialogRef<AddEmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private snackbar: MatSnackBar) {
            this.peopleId = data.peopleId;
            this.model = new Email(this.fb);
            this.emailFormGroup = this.model.buildFormGroup();
    }

    ngOnInit() {
        this.emailAddressTypeService.getAll().subscribe(result => {
            this.emailAddressTypes = result;
            if(this.data.model !== null) {
                this.editMode = true;
                this.lastEmailAddress = this.data.model.address;
                this.model.fromObject(this.data.model);
            }
        }, error => {
            this.snackbar.open('Error:', error, {
                duration: 4000
            });
        });
    }

    private onSave() {
        this.model = this.model.toDto();
        this.dialogRef.close({
            data: this.model,
            isEdit: this.editMode,
            lastEmailAddress: this.lastEmailAddress
        });
    }

    private onCancel() {
        this.dialogRef.close();
    }
    
}