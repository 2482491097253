import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Community } from '../model/community';
import { Observable } from 'rxjs';
import { CommunityTree } from '../model/community-tree';
import { LevelFilter } from '../model/breadcrumb';
import { CountResultModel } from '../model/count-result-model';
import { map } from '../../../node_modules/rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommunityService extends AbstractDataProviderService<Community> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/community', http, appConfig, LevelFilter.Community);
  }

  getCommunityWithAddressByCommunityId(id: number): Observable<Community> {
    const queryString = '/communityAndAddress/' + id.toString();
    return this.http.get<Community>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getAllTreePaginated(pageIndex: number, pageSize: number, communityId: number,
    propertyManagementCompanyId: number): Observable<CommunityTree[]> {
    const queryString = '/allPagedByParams/' + pageIndex.toString() + '/' + pageSize.toString() +
       '/' + propertyManagementCompanyId +  '/' + communityId;
    return this.http.get<CommunityTree[]>(this.baseUrl + queryString ,  { headers: this.buildGetHeaders() });
  }

  getAllPaginatedByPropertyManagementComp(pageIndex: number, pageSize: number, filter?: string): Observable<Community[]> {
    this.setBreadcrumbParams();
    const route = this.filterLevel ? '/allPageByBreadcrumb/' : '/allPaged/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString()  + (filter ? '/' + filter : '');

    if (this.filterLevel && this.filterLevel >= LevelFilter.PropertyManagementCompany) {
      queryString = queryString + '?propertyManagementCompanyId=' +  this.searchParamsBreadcrumb.propertyManagementCompanyId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Community) {
      queryString = queryString + '&communityId=0';
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Area) {
      queryString = queryString + '&areaId=' +  this.searchParamsBreadcrumb.areaId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Unit) {
      queryString = queryString + '&unitId=' +  this.searchParamsBreadcrumb.unitId;
    }
    return this.http.get<Community[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

   getCountByBreadcumd(filter?: string): Observable<number> {
    const endpoint = filter ? '/getCountByParamsFiltered/' : '/getCountByParams/';
      const queryString = endpoint + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/'
       + 0 + '/' + this.searchParamsBreadcrumb.areaId
      + '/' + this.searchParamsBreadcrumb.unitId + (filter ? '/' + filter : '');
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  getAllAllowForAreaList(): Observable<Community[]> {
    return this.http.get<Community[]>(this.baseUrl + '/allAllowForAreaList/' ,  { headers: this.buildGetHeaders() });
  }

  getAllAllowForUnitList(): Observable<Community[]> {
    return this.http.get<Community[]>(this.baseUrl + '/allAllowForUnitList/'  ,  { headers: this.buildGetHeaders() });
  }

  getBySubDomain(domain: string): Observable<Community> {
    return this.http.get<Community>(this.baseUrl + '/byDomain/' + domain, { headers: this.buildGetHeaders() });
  }

  getallByName(name?: string): Observable<Community[]> {
    let queryString = '';
    queryString = '/allByName/' + name ;
    return this.http.get<Community[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

}
