import { Component, Inject } from '@angular/core';
import * as _ from 'lodash';
import { UploadService } from '../../services/upload.service';
import { Upload } from '../../model/upload';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';


@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent {



   // Progress monitoring
   percentage: Observable<number>;

   snapshot: Observable<any>;

   // Download URL
   downloadURL: Observable<string>;

   // State for dropzone CSS toggling
   isHovering: boolean;

   public fileList: any = [];

  constructor(public dialogRef: MatDialogRef<UploadImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private upSvc: UploadService,
    private dialog: MatDialog) { }

    toggleHover(event: boolean) {
      this.isHovering = event;
    }

    startUpload(event: FileList) {
      this.fileList = event;

      // The File object
      const file = event.item(0);

      // Client-side validation example
      if (file.type.split('/')[0] !== 'image') {
        this.openDialog('Error', 'The File must be an Image');
        this.fileList = [];
        return;
      }

      if (file.size  > 2097152 ) {
        this.openDialog('Error', 'The size of the image must be less than 2 mb');
        this.fileList = [];
        return;
      }

    }

    openDialog(title: string, Message: string): void {
      let data;
      data = {
        Title: title,
        Message: Message
      };
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '300px',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    ngSelect(): void {
      this.dialogRef.close({
        valid: true,
        data: {
          files: this.fileList
        }
      });
    }

}
