import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { RoleRing } from '../model/role-ring';

@Injectable({
  providedIn: 'root'
})
export class RoleRingService extends AbstractDataProviderService<RoleRing> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.SecurityAdminService, '/roleRing', http, appConfig);
  }
}
