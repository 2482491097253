import { Component, OnInit } from '@angular/core';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { Router } from '@angular/router';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material';
import { SecurityHelper } from 'src/app/helpers/security-helper';
import { CommunitySettingsService } from '../../services/community-settings.service';

@Component({
  selector: 'app-actions-package',
  templateUrl: './actions-package.component.html',
  styleUrls: ['./actions-package.component.css']
})
export class ActionsPackageComponent implements OnInit {

  constructor(private dialog: MatDialog,
    private router: Router,
    private communitySettingService: CommunitySettingsService) { }

  ngOnInit() {
  }
  receive() {
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if (comunityLocalStorage === 0) {
      SiteHelper.openDialogAlert('Warning', 'Select a community on breadcrumb. ', this.dialog);
    } else {
      this.communitySettingService.getByCommunityId(comunityLocalStorage).subscribe(c => {
        if (c != null && c.packagesEnabled) {
          this.router.navigateByUrl('/app/packages/receive');
        } else {
          SiteHelper.openDialogAlert('Warning',
            'you can not receive a package for this community, please configure the reception of packages for this community',
            this.dialog);
        }
      });
    }
  }
  deliver() {
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if (comunityLocalStorage === 0) {
      SiteHelper.openDialogAlert('Warning', 'Select a community on breadcrumb. ', this.dialog);
    } else {
      this.communitySettingService.getByCommunityId(comunityLocalStorage).subscribe(c => {
        if (c != null && c.packagesEnabled) {
          this.router.navigateByUrl('/app/packages/deliver');
        } else {
          SiteHelper.openDialogAlert('Warning',
            'you can not deliver a package for this community, please configure the reception of packages for this community',
            this.dialog);
        }
      });
    }
  }
  settings() {
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if (comunityLocalStorage === 0) {
      SiteHelper.openDialogAlert('Warning', 'Select a community on breadcrumb. ', this.dialog);
    } else {
      this.router.navigateByUrl('/app/packages/' + comunityLocalStorage + '/settings');
    }
  }
}
