
import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class CreditCardForm extends BaseForm {


    /**
     * The cardName of the CreditCard.
     */
    @FormField('cardName', ['', Validators.required])
    cardName = '';

    /**
     * The cardNumber of the CreditCard.
     */
    @FormField('cardNumber', ['', Validators.required])
    cardNumber = '';

     /**
     * The cardCVV of the CreditCard.
     */
    @FormField('cardCVV', ['', Validators.required])
    cardCVV = '';

     /**
     * The month of the CreditCard.
     */
    @FormField('month', ['', Validators.required])
    month = '';

    /**
     * The year of the CreditCard.
     */
    @FormField('year', ['', Validators.required])
    year = '';

    /**
     * The year of the CreditCard.
     */
    @FormField('zip', ['', Validators.required])
    zip = '';
}
