import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { ServiceInActionListComponent } from './service-in-action-list/service-in-action-list.component';

const routes: Routes = [ {
  path: '',
  children: [
    {
      path: '',
      component: ServiceInActionListComponent,
      canActivate: [AuthGuardService]
    }
  ]
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServicesInActionRoutingModule {
}
