import { AfterViewInit, Component, Inject, OnInit, ViewChild, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSelect,
  MatSnackBar,
} from '@angular/material';


import { ConfirmDialogComponent } from '../../app-dialogs/confirm-dialog/confirm-dialog.component';
import { EntityService } from '../../services/entity.service';
import { RingTypeService } from '../../services/ring-type.service';

import { Ring } from '../../model/ring';
import { Entity } from '../../model/entity';
import { RingType } from '../../model/ring-type';
import { forkJoin } from 'rxjs';
import { PermitionTypeService } from 'src/app/services/permition-type.service';
import { PermitionType } from 'src/app/model/permition-type';

@Component({
  selector: 'app-ring-add-edit-dialog',
  templateUrl: './ring-add-edit-dialog.component.html',
  styleUrls: ['./ring-add-edit-dialog.component.css']
})
export class RingAddEditDialogComponent implements  OnInit {

  public model: Ring;
  public ringFormGroup: FormGroup;
  public ringsTypes: RingType[];
  public permitionTypes: PermitionType[] = [];
  public entities: Entity[];
  @ViewChild('ringTypeSelect') ringTypeSelect: MatSelect;
  @ViewChild('entitySelect') entitySelect: MatSelect;

  constructor(public dialogRef: MatDialogRef<RingAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ringTypeService: RingTypeService,
    private permitionTypeService: PermitionTypeService,
    private entityService: EntityService) {

      this.model = new Ring(this.fb);
      this.ringFormGroup = this.model.buildFormGroup();
  }

  ngOnInit() {

    if (this.data.update === true) {
      this.model.fromObject(this.data.model.entity);
    }

    const ringTypesObservable = this.ringTypeService.getAll();
    const entitiesObservable = this.entityService.getAll();
    const permitionTypeObservable = this.permitionTypeService.getAll();

    forkJoin(
      ringTypesObservable,
      entitiesObservable,
      permitionTypeObservable)
    .subscribe(results => {
      this.ringsTypes = results[0];
      this.entities = results[1];
      this.permitionTypes = results[2];
    });
  }


  onCancel(): void {
    if (this.ringFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else {
      this.dialogRef.close({ valid: false });
    }
  }

  onSubmit(): void {
    this.dialogRef.close({
      valid: true,
      entity: this.model.toDto()
    });
  }
}
