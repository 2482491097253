
import { Validators, FormArray } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { AddressTableEntry } from './address-table-entry';
import { Email } from './email';
import { PhoneNumber } from './phone-number';

export class People extends BaseForm {
    peopleId = 0;

    @FormField('titleId', [null, Validators.required])
    titleId = null;

    @FormField('firstName', ['', Validators.required])
    firstName = '';

    @FormField('middleName', [''])
    middleName = '';

    @FormField('lastName', ['', Validators.required])
    lastName = '';

    @FormField('displayName', [''])
    displayName = '';

    @FormField('aboutMe', [''])
    aboutMe = '';

    @FormField('showProfileInCommunity', [false])
    showProfileInCommunity =  false;


     @FormField('address', [], AddressTableEntry,  true)
     address: FormArray;

     @FormField('emails', [], Email,  true)
     emails: FormArray;

     @FormField('phones', [], PhoneNumber,  true)
     phones: FormArray;

    userId = null;

    isDeleted = false;

    originId = null;

    /**
     * The creation date of this record.
     */
    creationDate: Date = null;

    /**
     * The ID of the user that created this record.
     */
    creationUserId = 0;

    /**
     * The modification date of this record.
     */
    modificationDate: Date = null;

    /**
     * The ID of the user that last modified this record.
     */
    modificationUserId = 0;

    relationshipId ? = null;

    modificationOriginId = 2;

    email: string;

    line1: string;
    line2: string;
    name: string;
    zipCode: number;
}
