import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatSnackBar,
} from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';

/* Components */
import { CompanyAddComponent } from '../../companies/company-add/company-add.component';

/* Services */
import { CommunityService } from '../../services/community.service';
import { PropertyManagmentCompanyService } from '../../services/property-managment-company.service';

/* Models */
import { Community } from '../../model/community';
import { PropertyManagementCompany } from '../../model/property-management-company';

/* Dialogs */
import { ManagmentCompanyDialogsComponent } from '../../app-dialogs/managment-company-dialogs/managment-company-dialogs.component';
import { SiteHelper } from '../../helpers/site-helper';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';


@Component({
  selector: 'app-community-add-edit',
  templateUrl: './community-add-edit.component.html',
  styleUrls: ['./community-add-edit.component.css']
})
export class CommunityAddEditComponent implements OnInit {
  public formCommunity: FormGroup;
  public model: Community;
  public propertyManagmentCompanies: PropertyManagementCompany[];
  public parentLoaded = false;
  @ViewChild('companyComponent') companyComponent: CompanyAddComponent;

  constructor(private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private communityService: CommunityService,
    private breadcrumbService: BreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private propertyManagmentCompaniesService: PropertyManagmentCompanyService,
    private router: Router,
    private dialog: MatDialog
) {
 }

  ngOnInit() {
    this.parentLoaded = false;
    this.model = new Community();
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = params['id'] as number;
      const type = params['type'] as number;
      if (id && type) {
        if (+type === 1) {
          this.model.propertyManagementCompanyId = id;
          this.initCompany();
        } else {
          this.communityService.getById(id).subscribe(co => {
            this.model.parent = co.communityId;
            this.model.propertyManagementCompanyId = co.propertyManagementCompanyId;
            this.initCompany();
          });
        }
      } else if (id && !type) {
        this.communityService.getById(id).subscribe(c => {
          this.model = c;
          this.companyComponent.companyId = this.model.companyId;
          this.initCompany();
        });
      } else {
        this.initCompany();
      }
    });
    this.formCommunity = this.fb.group({
    });
  }

  initCompany() {
    this.companyComponent.watchInit().subscribe(r => {
      if (r) {
      this.companyComponent.initCompany();
      }
  });
  }
  openDialog(): void {
    let data;
    data = {
      managenetComanies: this.propertyManagmentCompanies,
      propertyManagementCompanyId: this.model.propertyManagementCompanyId
    };

    const dialogRef = this.dialog.open(ManagmentCompanyDialogsComponent, {
      width: '300px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined' && result.valid === true) {
        this.model.propertyManagementCompanyId = result.data.propertyManagementCompanyId;
      }
    });
  }

  Save(): void {
    if (!this.model.propertyManagementCompanyId) {
      this.companyComponent.openDialog('Error', 'Must select a Management Company');
      return;
    }
    if (this.formCommunity.status !== 'VALID' || this.companyComponent.formCompany.status !== 'VALID') {
      SiteHelper.markFormGroupTouched(this.companyComponent.formCompany);
      return;
    }

    this.companyComponent.model.community = this.model;
    this.companyComponent.saveCompany('Community').subscribe( Company => {
      this.goToList();
  });
  }

  goToList(): void {
    this.breadcrumbService.watchUpdated(true).subscribe();
    this.router.navigate(['app/community/list']);
  }

  cancel(): void {
    this.goToList();
  }

}
