import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { UnitCategory } from '../model/unit-category';

@Injectable({
  providedIn: 'root'
})
export class UnitCategoryService extends AbstractDataProviderService<UnitCategory> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/unitCategory', http, appConfig);
   }
}
