import { Component, OnInit, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CommunityConfiguration } from '../../model/community-configuration';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommunityConfigurationService } from '../../services/community-configuration.service';
import { CompanyService } from '../../services/company.service';
import { CommunityService } from '../../services/community.service';
import { Community } from '../../model/community';
import { UploadImageComponent } from '../../app-dialogs/upload-image/upload-image.component';
import { Picture } from '../../model/picture';
import { PictureService } from '../../services/picture.service';
import { stringHelper } from '../../helpers/stringHelper';
import { PictureEntry } from '../../model/picture-entry';
import { PictureCommunityService } from '../../services/picture-community.service';
import { PictureCommunity } from '../../model/picture-community';
import { CommunityMapping } from '../../model/community-mapping';
import { CommunityMappingService } from '../../services/community-mapping.service';
import { MatDialog, MatDialogRef, MatInput, MatSelect } from '@angular/material';
import { CommunityImportationFromC3poComponent } from '../community-importation-from-c3po/community-importation-from-c3po.component';
import { SourceService } from '../../services/source.service';
import { Source } from '../../model/source';
import { SmaugService } from '../../services/smaug.service';
import { PayPoint } from '../../model/smaug';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { ConfigDetailComponent } from './community-configuration-detail/config-detail.component';
import { AddressService } from 'src/app/services/address.service';
import { AddressTableEntry } from 'src/app/model/address-table-entry';
import { Address } from 'src/app/model/address';
import { ZipCodeService } from 'src/app/services/zip-code.service';
import { CityService } from 'src/app/services/city.service';
import { StateService } from 'src/app/services/state.service';
import { State } from 'src/app/model/state';
import { City } from 'src/app/model/city';
import { AlertDialogComponent } from 'src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { PhoneNumber } from 'src/app/model/phone-number';
import { ValidatorHelper } from 'src/app/helpers/validator-helper';
import { PhoneNumberService } from 'src/app/services/phone-number.service';

const LENGTH_ZIPCODE = 5;

@Component({
  selector: 'app-community-configuration',
  templateUrl: './community-configuration.component.html',
  styleUrls: ['./community-configuration.component.scss'],
})

export class CommunityConfigurationComponent implements OnInit {
  public model: CommunityConfiguration;
  public modelToSave: CommunityConfiguration;
  public modelMapping: CommunityMapping;
  public modelCommunity: Community;
  public CommunityConfigurationFormGroup: FormGroup;
  public loading: boolean;
  public selectedFile: File;
  public imgSrc = '../../../assets/images/noimage.png';
  public changePicture = false;
  public arrayBuffer: any;
  public CommunityName: string;
  public pictures: PictureEntry[];
  public sources: Source[] = [];
  
  public communitySettingsTab: any[] = [
    {
      name: 'profile',
      displayName: 'Profile',
      isActive: false
    },
    {
      name: 'message',
      displayName: 'Messages',
      isActive: false
    },
    {
      name: 'paymentConfig',
      displayName: 'Payment Config',
      isActive: false
    },
    {
      name: 'officeInformation',
      displayName: 'Office Information',
      isActive: false
    },
    // {
    //   name: 'pmTheme',
    //   displayName: 'PM Theme',
    //   isActive: false
    // },
    // {
    //   name: 'communityTheme',
    //   displayName: 'Community Theme',
    //   isActive: false
    // },
    // {
    //   name: 'gallery',
    //   displayName: 'Gallery',
    //   isActive: false
    // }
  ];
  public communityAddress: string;
  public selectedTab: any = null;
  public sourceName: string = '';
  public communityConfigDetailTitle: string = 'General Details';
  public communityConfigDetailEditTitle: string = 'General Details';
  public communityConfigDetailActionIcon: string = 'edit';
  public communityConfigDetailModel: any[] = [];
  public isUpdateProcess: boolean = false;
  public hasURLActiveTab: boolean = false;
  public hasOffices: boolean = false;

  @ViewChild('pageTemplate') pageTemplate: TemplateRef<any>;
  public communityNameCtrl = new FormControl();


  //for address controls
  public states: State[] = [];
  public cities: City[] = [];
  public addressFormGroup: FormGroup;
  public addressModel: AddressTableEntry;
  @ViewChild('stateSelect') stateSelect: MatSelect;

  //for phone controls
  public phoneFormGroup: FormGroup;
  public phoneModel: PhoneNumber;
  public phonemask: (string | RegExp)[] = [];
  sourceId: number;
  communityConfigDetailTitleEdit: string;

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private communityService: CommunityService,
    private companyService: CompanyService,
    private sourceService: SourceService,
    private dialog: MatDialog,
    private pictureService: PictureService,
    private pictureCommunityService: PictureCommunityService,
    private communityConfigurationService: CommunityConfigurationService,
    private communityMappingService: CommunityMappingService,
    private smaugService: SmaugService,
    private zipCodeService: ZipCodeService,
    private cityService: CityService,
    private stateService: StateService,
    private addressService: AddressService,
    private phoneNumberService: PhoneNumberService) {


    LocalStorageHelper.watchBreadcrumb().subscribe(result => {
      this.activatedRoute.params.subscribe((params: Params) => { 
        let id: number;
        if (params['id']) {
          id = params['id'];
        } else {
          id = LocalStorageHelper.getCommunitiesFromBreadcrumb();
        }
        this.model.communityId = id;
        this.loadCommunityConfiguration(id);
      });
    });

    // initialize community configuration form
    this.model = new CommunityConfiguration(this.fb);
    this.modelMapping = new CommunityMapping();
    this.CommunityConfigurationFormGroup = this.model.buildFormGroup();


    //initialize address form
    this.stateService.getAll().subscribe(states => {
      this.states = states;
      this.addressModel = new AddressTableEntry(this.fb);
      this.addressFormGroup = this.addressModel.buildFormGroup();
    }, error => {
      console.log(error.errorMessage);
      this.loading = false;
    });

    //initialize phone form
    this.phonemask = ValidatorHelper.phonemask();
    this.phoneModel = new PhoneNumber(this.fb);
    this.phoneFormGroup = this.phoneModel.buildFormGroup();
  }

  ngOnInit() {
    this.pictures = [];
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params.pageFrom) {
        this.hasURLActiveTab = true;
        let selectedTabFromURL = this.communitySettingsTab.find(tab => tab.name === params.pageFrom)
        this.onChangeTab(selectedTabFromURL, true);
        LocalStorageHelper.setBackToButtonOnLocationBar({
          label: '',
          link: '',
          selectedTabQueryParam: ''
        });
      }

      let id: number;
      if (params['id']) {
        id = params['id'];
      } else {
        id = LocalStorageHelper.getCommunitiesFromBreadcrumb();
      }
      this.model.communityId = id;
      this.loadCommunityConfiguration(id);
    });
  }

  loadCommunityConfiguration(id) {
    this.loading = true
    this.communityService.getCommunityWithAddressByCommunityId(id).subscribe(c => {
      this.modelCommunity = c;

      //bind the address from the API
      if (c.addresses) {
        this.addressModel.fromObject(c.addresses);
        this.cityService.getByStateId(this.addressModel.stateId).subscribe(res => {
          this.cities = res;
          const city = this.cities.find(c => c.cityId === this.addressModel.cityId).name;
          const stateName = this.states.find(st => st.stateId === this.addressModel.stateId).name;       
          this.communityAddress = this.addressModel.line1 + ', ' +  this.addressModel.cityName + ', ' +
          stateName + ' ' + this.addressModel.zipCode;
         });
      }

      // bind the phone from the API
      if (c.phoneNumbers) {
        this.phoneModel.fromObject(c.phoneNumbers);
        this.modelCommunity.phoneNumber = this.phoneModel.number;
      }

      this.CommunityName = c.name;
      this.communityNameCtrl.setValue(c.name);
      this.getLogo(c.companyId);
      this.getPictures(c.communityId);
      this.communityConfigurationService.getByCommunityId(id).subscribe(config => {
        if (config.length > 0) {
          this.model.fromObject(config[0]);
          if (this.model.communityMappingId) {
            this.modelMapping.communityMappingId = this.model.communityMappingId;
            this.modelMapping.externalCode = this.model.externalCode;
            this.modelMapping.sourceId = this.model.sourceId;
            this.modelMapping.communityId = this.model.communityId;

            this.sourceService.getAll().subscribe(s => {
              this.sources = s;
              this.sourceId = this.sources.find(s => s.sourceId === this.model.sourceId).sourceId;
              if (!this.hasURLActiveTab) {
                this.onChangeTab(this.communitySettingsTab[0], true);
              }
            });
          }
        } else {
          this.model.communityId = id;
          this.modelMapping.communityId = this.model.communityId;
          this.onChangeTab(this.communitySettingsTab[0], true);
        }
      });

    });

  }

  openDialogTree(areas: string): void {
    let data;
    data = {};
    const model = this.model.toDto();
    model.communityId = this.model.communityId;
    let dialogRef: MatDialogRef<CommunityImportationFromC3poComponent>;
    if (this.modelMapping.communityMappingId === 0) {
      this.modelMapping.externalCode = model.externalCode;
      this.modelMapping.sourceId = model.sourceId;
      this.modelMapping.communityId = model.communityId;
      this.communityMappingService.add(this.modelMapping).subscribe(cm => {
        this.model.communityMappingId = cm.id;
        model.communityMappingId = cm.id;
        this.modelMapping.communityMappingId = cm.id;
        dialogRef = this.openTree(model, areas);
      });
    } else {
      dialogRef = this.openTree(model, areas);
    }
  }

  openTree(model: any, areas: string): MatDialogRef<CommunityImportationFromC3poComponent> {
    const host = this.sources.filter(s => s.sourceId === model.sourceId)[0].hostId;
    const dialogRef = this.dialog.open(CommunityImportationFromC3poComponent, {
      width: '800px',
      data: { model: model, areas: areas, host: host },
      panelClass: SiteHelper.getDefaultTheme()
    });
    return dialogRef;
  }

  saveCommunityProfile() {
    //const dto = this.model;
    const dto = this.modelToSave;
    dto.communityId = this.model.communityId;
    this.modelMapping.externalCode = dto.externalCode;
    this.modelMapping.sourceId = dto.sourceId;
    const address = this.addressModel.toDto();
    this.addressService.update(address).subscribe(_ => { },
      error => {
        console.log(error.errorMessage);
      });
    
      //update address, email and phone
    const phone = this.phoneModel.toDto();
    if (phone.phoneNumberId == null || phone.phoneNumberId === 0 ) {
      this.phoneNumberService.add(phone).subscribe(result => {
      this.phoneNumberService.linkPhoneNumberToCompany(result.id, this.modelCommunity.companyId).subscribe();
        console.log(result);
      }, error => {
        console.log(error.errorMessage);
      });
    }
    this.phoneNumberService.update(phone).subscribe(_ => { },
      error => {
        console.log(error.errorMessage);
      });

    if (dto.communityConfigurationId) {
      this.communityConfigurationService.update(dto).subscribe();
      this.savePayPointNumber(dto.paypointNumber, this.modelMapping.externalCode, true);
      this.saveGallery(dto.communityId);

      if (this.communityNameCtrl.invalid) {
        const data = {
          Title: 'Error',
          Message: 'Invalid Community Name'
        }
        this.openAlertDialog(data);
        return;
      } else {
        this.modelCommunity.name =  this.communityNameCtrl.value;
        this.communityService.update(this.modelCommunity).subscribe();
      }
    } else {
      this.communityConfigurationService.add(dto).subscribe(result => {
        this.saveGallery(result.id);
      });
      this.savePayPointNumber(dto.paypointNumber, this.modelMapping.externalCode, false);
    }
    if (this.modelMapping.communityMappingId !== 0) {
      this.communityMappingService.update(this.modelMapping).subscribe();
    } else {
      this.modelMapping.communityId = dto.communityId;
      this.communityMappingService.add(this.modelMapping).subscribe();
    }
    //this.savePicture();
    this.goToList();
  }

  savePayPointNumber(payPointNumber: string, sourceCommunityCode: string, isUpdate: boolean) {
    const payPoint = new PayPoint();
    payPoint.paypointNumber = payPointNumber;
    payPoint.sourceCommunityCode = sourceCommunityCode;
    this.smaugService.getApplication().subscribe(app => {
      const appId = app[0].applicationId;
      this.smaugService.getClassificationItemById(appId).subscribe(ci => {
        const classificationItem = ci[0].classificationItemId;
        this.smaugService.getProvider(appId, classificationItem).subscribe(pro => {
          payPoint.providerId = pro[0].providerId;
          if (isUpdate) {
            this.smaugService.getPaypointBybyCommunityCode(sourceCommunityCode).subscribe(p => {
              if (p.length > 0) {
                payPoint.paypointNumberId = p[0].paypointNumberId;
              }
              this.smaugService.updatePaypoint(payPoint).subscribe();
            });
          } else {
            this.smaugService.addPaypoint(payPoint).subscribe();
          }
        });
      });
    });
  }

  savePicture() {
    if (this.selectedFile && this.changePicture) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.arrayBuffer = reader.result;
        this.companyService.setCompanyLogo(this.modelCommunity.companyId, this.arrayBuffer).subscribe();
      };
      reader.readAsArrayBuffer(this.selectedFile);
    }
  }

  saveGallery(communityId: number) {
    this.pictures.forEach(pic => {

      const picture: Picture = {
        pictureId: pic.pictureId, name: pic.name, description: pic.description, path: pic.path,
        bucketId: pic.bucketId, isDeleted: pic.isDeleted,
      };

      const pictureCommunity: PictureCommunity = {
        pictureId: pic.pictureId, picture_CommunityId: pic.picture_CommunityId,
        communityId: communityId, showInCarrusell: true, orderShown: pic.orderShown
      };

      if (!pic.pictureId) {
        this.pictureService.AddPicture(pic).subscribe(result => {
          pictureCommunity.pictureId = result.id;
          this.pictureCommunityService.add(pictureCommunity).subscribe();
        });
      } else if (pic.pictureId && !pic.isDeleted) {
        this.pictureService.update(picture).subscribe();
        this.pictureCommunityService.update(pictureCommunity).subscribe();

      } else {
        this.pictureService.delete(pic.pictureId).subscribe();
      }
    });
  }

  goToList(): void {
    this.router.navigate(['app/community/list']);
  }

  cancel(): void {
    this.goToList();
  }

  public onChangeTab(tab: any, initial: boolean = false) {
    this.communitySettingsTab.map(tab => {
      tab.isActive = false;
    });
    tab.isActive = true;
    if (initial) {
      this.selectedTab = tab;
      this.setConfigModel(this.selectedTab);
    } else {
      this.loading = true;
      this.selectedTab = tab;
      this.setConfigModel(this.selectedTab);
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    }
  }


  public setConfigModel(tab) {
    switch (tab.name) {
      case 'profile': {
        this.disableProfileControls();
        this.communityConfigDetailTitle = 'General Details';
        this.communityConfigDetailTitleEdit = 'Edit Details';
        this.communityConfigDetailEditTitle = 'Edit Details';
        this.communityConfigDetailActionIcon = 'edit';
        this.communityConfigDetailModel = [
          {
            label: 'URL',
            value: this.model.url,
            type: 'input',
            controlName: 'url'
          },
          {
            label: 'Domain',
            value: this.model.domain,
            type: 'input',
            controlName: 'domain'
          },
          {
            label: 'Source',
            value: this.model.sourceId,
            type: 'select',
            controlName: 'sourceId',
            values:  this.sources.map(r => ({ id: r.sourceId, name: r.name}))
          },
          {
            label: 'External Code',
            value: this.model.externalCode,
            type: 'input',
            controlName: 'externalCode'
          }
        ];
        break;
      }
      case 'message': {
        this.communityConfigDetailTitle = 'Messages';
        this.communityConfigDetailTitleEdit = 'Edit Messages';
        this.communityConfigDetailEditTitle = 'Edit Messages';
        this.communityConfigDetailActionIcon = 'edit';
        this.communityConfigDetailModel = [
          {
            label: 'Payment Message',
            value: this.model.paymentMessage,
            type: 'textarea',
            controlName: 'paymentMessage'
          },
          {
            label: 'Legal Message',
            value: this.model.legalMessage,
            type: 'textarea',
            controlName: 'legalMessage'
          },
          {
            label: 'New Resident Message',
            value: this.model.newResidentMessage,
            type: 'textarea',
            controlName: 'newResidentMessage'
          }
        ];
        break;
      }
      case 'paymentConfig': {
        this.communityConfigDetailTitle = 'General Details';
        this.communityConfigDetailEditTitle = 'Edit Details';
        this.communityConfigDetailTitleEdit = 'Edit Details';
        this.communityConfigDetailActionIcon = 'edit';
        this.communityConfigDetailModel = [
          {
            label: 'Pay Point Number',
            value: this.model.paypointNumber,
            type: 'input',
            controlName: 'paypointNumber'
          },
          {
            label: 'Work Order Email',
            value: this.model.workOrderEmail,
            type: 'input',
            controlName: 'workOrderEmail'
          },
          {
            label: 'Allow Payment',
            value: this.model.allowPayment,
            type: 'checkbox',
            controlName: 'allowPayment'
          },
          {
            label: 'Can Legal Status Pay',
            value: this.model.canLegalStatusPay,
            type: 'checkbox',
            controlName: 'canLegalStatusPay'
          },
          {
            label: 'Enable Mobile Access',
            value: this.model.enableMobileAccess,
            type: 'checkbox',
            controlName: 'enableMobileAccess'
          }
        ];
        break;
      }
      case 'officeInformation': {
        this.communityConfigDetailModel = [];
        this.communityConfigDetailTitle = 'Office Information';
        this.communityConfigDetailTitleEdit = 'Office Information';
        this.communityConfigDetailEditTitle = 'Add Location';
        this.communityConfigDetailActionIcon = 'add_circle_outline';
        break;
      }

      default: {
        this.communityConfigDetailTitle = '';
        this.communityConfigDetailEditTitle = '';
        this.communityConfigDetailTitleEdit = '';
        this.communityConfigDetailActionIcon = '';
        this.communityConfigDetailModel = [];
        break;
      }
    }
  }

  public onEditEvent() {
    //this can be change to switch depending on the next page mockups
    switch (this.selectedTab.name) {
      case 'officeInformation': {
        this.setBackToOfficeInformationLink();
        this.router.navigate(['app/community/office-information-add-edit/', 0])
        break;
      }
      default: {
        this.openDialogForProfileEdit();
        break;
      }
    }

  }

  public openDialogForProfileEdit() {
    let dialogRef: any;
    this.isUpdateProcess = true;
    this.enableProfileControls();
    dialogRef = this.dialog.open(ConfigDetailComponent, {
      width: '600px',
      data: {
        communityConfigDetailTitle: this.communityConfigDetailTitle,
        communityConfigDetailTitleEdit: this.communityConfigDetailTitleEdit,
        communityConfigDetailEditTitle: this.communityConfigDetailEditTitle,
        isUpdate: this.isUpdateProcess,
        htmlModelRef: this.communityConfigDetailModel,
        formModel: this.model,
        formGroup: this.CommunityConfigurationFormGroup,
        addressModel: this.addressModel,
        addressFormGroup: this.addressFormGroup,
        phoneModel: this.phoneModel,
        phoneFormGroup: this.phoneFormGroup,
        selectedTab: this.selectedTab,
        modelMapping: this.modelMapping,
        sources: this.sources,
        pageTemplate: this.pageTemplate,
        communityNameCtrl: this.communityNameCtrl
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isUpdateProcess = false;
      this.disableProfileControls();
      if (result && result.data !== undefined) {
        this.modelToSave = result.data.model;

        this.addressFormGroup = result.data.address.addressFormGroup;
        this.addressModel = result.data.address.addressModel;
        
        this.phoneModel = result.data.phoneNumber.phoneModel;
        this.phoneFormGroup = result.data.phoneNumber.phoneFormGroup;

        this.setConfigModel(this.selectedTab);
        this.saveCommunityProfile();
      }
    });
  }

  public onOfficeInformationEdit(locationId: number) {
    this.setBackToOfficeInformationLink();
    this.router.navigate(['app/community/office-information-add-edit/' + locationId])
  }

  public setBackToOfficeInformationLink() {
    LocalStorageHelper.setBackToButtonOnLocationBar({
      label: 'Back to Office Information',
      link: 'community/settings',
      selectedTabQueryParam: 'officeInformation'
    });
  }

  public updateAddress(value: string, i: number, isForm = false): void {
    if (value === undefined || value.length !== LENGTH_ZIPCODE) {
      return;
    }
    this.zipCodeService.getByZipCode(value).subscribe(zipCode => {
      const state = this.states.find(x => x.stateCode === zipCode[0].stateCode);
      this.cityService.getByStateId(state.stateId).subscribe(x => {
        this.cities= x;
        const city = x.find(c => c.name === zipCode[0].city);
        this.addressFormGroup.get('stateId').setValue(state.stateId);
        this.addressFormGroup.get('cityId').setValue(city.cityId);
        this.addressFormGroup.get('zipCode').setValue(value);
        this.addressFormGroup.get('cityId').enable();
        this.addressFormGroup.get('stateId').enable();
        this.addressModel.zipCodeId = zipCode[0].zipCodeId;
      }, error => {
        console.log(error.errorMessage);
      });
    }, error => {
      console.log(error.errorMessage);
    });
  }

  public onStateSelectionChange(e, i: number): void {
    this.addressModel.stateId = e.value;
    this.stateSelect.value = e.value;
    this.addressModel.stateName = this.states.find(x => x.stateId === e.value).name;
    this.cityService.getByStateId(e.value).subscribe(x => {
      this.cities = x;
      this.addressModel.cityId = null;
    });
  }

  public enableProfileControls() {
    this.addressModel.enableControls();
    this.communityNameCtrl.enable();
    this.phoneModel.enableControls();
  }

  public disableProfileControls() {
    this.addressModel.disableControls();
    this.communityNameCtrl.disable();
    this.phoneModel.disableControls();
  }

  public openAlertDialog(dialogContent = null) {
    const data = {
      Title: 'Error',
      Message: 'Unknown Error'
    };

    this.dialog.open(AlertDialogComponent, {
      width: '400px',
      data: dialogContent == null ? data : dialogContent
    });

  }

  //TODO: review this code to see if it can be removed
  //#region picturecode 
  addPictures() {
    this.openDialog();
  }

  openDialog(): void {
    let data;
    data = {};

    const dialogRef = this.dialog.open(UploadImageComponent, {
      width: '600px',
      data: data,
      panelClass: SiteHelper.getDefaultTheme()
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined' && result.valid === true) {
        this.getSrcList(result.data.files);
      }
    });
  }

  getSrcList(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const pic = new PictureEntry();
      const reader = new FileReader();
      reader.onload = (event: any) => {
        pic.src = event.target.result;
        pic.file = fileList[i];
        pic.name = fileList[i].name;
        pic.orderShown = this.pictures.length;
        this.pictures.push(pic);
      };
      reader.readAsDataURL(fileList[i]);
    }
  }

  getLogo(companyId: number) {
    this.companyService.getCompanyLogo(companyId).subscribe(pic => {
      const reader = new FileReader();
      const blob = new Blob([new Uint8Array(pic)], { type: 'image/jpeg' });
      if (blob.size > 0) {
        const file = new File([blob], 'Logo', { type: 'image/jpeg', lastModified: Date.now() });
        this.selectedFile = file;
        reader.onload = (event: any) => {
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    });
  }
  getPictures(communityId: number) {
    this.loading = true;
    this.pictureService.GetCommunityPictureGallery(communityId).subscribe(pic => {
      if (pic) {
        this.pictures = pic;
        this.pictures.forEach((p, index) => {
          let src: string;
          const reader = new FileReader();
          const blob = new Blob([stringHelper.base64ToArrayBuffer(p.pictureData)], { type: 'image/jpeg' });
          if (blob.size > 0) {
            const file = new File([blob], 'pic', { type: 'image/jpeg', lastModified: Date.now() });
            reader.onload = (event: any) => {
              src = event.target.result;
              p.src = src;
            };
            reader.readAsDataURL(file);
          }
          this.loading = false;

        });
      } else {
        this.loading = false;
      }
    });
  }
  onFileChanged(file: FileList) {
    this.selectedFile = file.item(0);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgSrc = event.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
    this.changePicture = true;
  }

  @HostListener('dragover', ['$event'])
  allowDrop(ev) {
    ev.preventDefault();
  }

  @HostListener('dragstart', ['$event'])
  drag(ev) {
    ev.dataTransfer.setData('id', ev.target.id);
  }
  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    const from: number = $event.dataTransfer.getData('id');
    const pictureFrom = this.pictures[from];
    const to: number = $event.target.id;
    const pictureTo = this.pictures[to];
    pictureTo.orderShown = Number(from);
    pictureFrom.orderShown = Number(to);
    this.pictures[from] = pictureTo;
    this.pictures[to] = pictureFrom;
  }
  remove(i: number) {
    if (this.pictures[i].picture_CommunityId) {
      this.pictures[i].isDeleted = true;
    } else {
      this.pictures.splice(i, 1);
    }
  }
  //#endregion
}

