
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable, empty, forkJoin } from 'rxjs';
import {
  MatSelect, MatInput, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar,
  MatTableDataSource, MatPaginator, MatSort
} from '@angular/material';

// models
import { TemplateRole } from 'src/app/model/template-role';
import { RoleType } from 'src/app/model/role-type';

// services
import { RingService } from 'src/app/services/ring.service';
import { RingTypeService } from 'src/app/services/ring-type.service';
import { EntityService } from 'src/app/services/entity.service';
import { RoleTypeService } from 'src/app/services/role-type.service';
import { TemplateService } from 'src/app/services/template.service';

import { RingTableEntry } from 'src/app/model/rings-table-entry';
import { Ring } from 'src/app/model/ring';
import { RingType } from 'src/app/model/ring-type';
import { Entity } from 'src/app/model/entity';
import { RoleRingService } from 'src/app/services/role-ring.service';
import { RoleRing } from 'src/app/model/role-ring';
import { TemplateRingAddDialogComponent } from '../template-ring-add-dialog/template-ring-add-dialog.component';
import { RingEntityTable } from 'src/app/model/ring-entity-table';
import { EntityType } from 'src/app/model/entity-type';
import { RingEntityService } from 'src/app/services/ring-entity.service';
import { EntityTypeService } from 'src/app/services/entity-type.service';
import { AlertDialogComponent } from 'src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { ServiceError } from 'src/app/model/service-error';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { TemplateRoleRingTable } from 'src/app/model/template-role-ring-table';

@Component({
  selector: 'app-template-add-edit',
  templateUrl: './template-add-edit.component.html',
  styleUrls: ['./template-add-edit.component.css']
})
export class TemplateAddEditComponent implements OnInit {
  public model: TemplateRole;
  public modelToSave: TemplateRoleRingTable[];
  public roleTypes: RoleType[] = [];
  public templateForm: FormGroup;
  public loading: boolean;
  public valid: boolean;
  private sub: Subscription;
  public roleRings: RoleRing[];
  public TITLE_WARNING = 'Warning';
  public TITLE_INFO = 'Info';
  public RING_EXIST = 'Ring already exist';
  public DELETE_MESSAGE = 'Are you sure you want to delete?';
  public SAVED_MESSAGE = 'Template Role was successfully saved';
  public RING_DELETED = 'Ring was successfully deleted';
  public saveFailed: boolean;
  public onNew: boolean;
  public saveErrorMessage: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // rings
  public rings: RingEntityTable[];
  public ringsAll: RingEntityTable[];
  public newRing: RingEntityTable;
  public newRingsAdded: RingEntityTable[];
  public ringTableColumns: string[] = ['entity', 'entityType', 'name', 'ringType', 'actions'];
  public ringDataSource = new MatTableDataSource<RingEntityTable>();
  public ringsTypes: RingType[];
  public entityTypes: EntityType[];

  // entities
  public entities: Entity[];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ringService: RingService,
    private ringEntityService: RingEntityService,
    private ringTypeService: RingTypeService,
    private entityService: EntityService,
    private entityTypeService: EntityTypeService,
    private roleTypeService: RoleTypeService,
    private roleRingService: RoleRingService,
    private templateService: TemplateService) {
    this.model = new TemplateRole(this.fb);
    this.templateForm = this.model.buildFormGroup();
  }

  ngOnInit() {
    this.loading = true;
    this.onNew = false;
    this.rings = [];
    this.modelToSave = [];
    this.newRingsAdded = [];

    this.sub = this.route.paramMap.subscribe(
      params => {
        this.model.templateRoleId = +params.get('id');

        const roleTypesObservable = this.roleTypeService.getAll();
        const ringstypeObservable = this.ringTypeService.getAll();
        const entityObservable = this.entityService.getAll();
        const filteredRoleRings = this.model.templateRoleId ?
          this.templateService.getByTemplateRoleId(this.model.templateRoleId) :
          this.templateService.getAll();
        const entityTypeObservable = this.entityTypeService.getAll();

        forkJoin(roleTypesObservable, ringstypeObservable, entityObservable, filteredRoleRings, entityTypeObservable)
          .subscribe(results => {
            this.roleTypes = results[0];
            this.ringsTypes = results[1];
            this.entities = results[2];
            this.roleRings = results[3];
            this.entityTypes = results[4];
            this.initModel();
            this.loading = false;
          });
      });
  }

  initModel() {
    if (this.model.templateRoleId) {
      this.templateService.getByTemplateId(this.model.templateRoleId).subscribe(t => {
        this.model.fromObject(t[0]);
      });
      this.updateDataSource();
    } else {
      this.onNew = true;
    }
  }

  updateDataSource() {
    this.ringEntityService.getAllRingEntities().subscribe(x => {
      this.ringsAll = x;
      this.ringsAll.forEach(r => {
        this.setToRing(r);
      });
    });
  }

  addRingToTemplate(): void {
    this.setDialog();
  }

  setDialog() {
    const dialogRef = this.dialog.open(TemplateRingAddDialogComponent, {
      width: '750px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.valid = result.valid;
        this.newRing = result.model;
        if (result.valid) {
          this.templateForm.markAsDirty();
          this.setToRing(this.newRing);
        }

      }
    });
  }

  setToRing(newRing: RingEntityTable) {
    const ring = new RingEntityTable();
    const idx = this.roleRings.findIndex(c => c.ringId === newRing.ringId);
    let newRingIdx = -1;
    if (this.valid && this.newRingsAdded.length > 0) {
      newRingIdx = this.newRingsAdded.findIndex(r => r.ringId === newRing.ringId);
    }
    if (this.valid && ( (idx === -1  && newRingIdx === -1) || (this.onNew && newRingIdx === -1) ) || (idx > -1 && !this.valid)) {
      ring.ringId = newRing.ringId;
      ring.ringTypeId = newRing.ringTypeId;
      ring.name = newRing.name;
      ring.ringTypeName = this.ringsTypes.find(t => t.ringTypeId === newRing.ringTypeId).name;
      ring.entityId = newRing.entityId;
      ring.entityName = this.entities.find(e => e.entityId === newRing.entityId).name;
      ring.entityTypeId = newRing.entityTypeId;
      ring.entityTypeName = this.entityTypes.find(e => e.entityTypeId === newRing.entityTypeId).name;
      if (this.valid) {
        this.newRingsAdded.push(newRing);
        this.rings.unshift(ring);
      } else {
        this.rings.push(ring);
      }
      this.updateSourceData();
    } else {
      if (this.valid) {
        this.openDialog(this.TITLE_WARNING, this.RING_EXIST);
      }
    }
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '500px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteItem(id: number): void {
    if (confirm(this.DELETE_MESSAGE)) {
      this.loading = true;
      this.onNew = false;
      this.deleteFromNewRings(id);
      if (!this.onNew) {
      this.templateService.deleteRingFromTemplate(this.model.templateRoleId, id).subscribe(x => {
        this.deleteOriginalRings(id);
        this.loading = false;
        this.openDialog(this.TITLE_INFO, this.RING_DELETED);
      });
    }
    }
  }

  deleteFromNewRings(id: number) {
    if (this.newRingsAdded.length > 0) {
      const idx = this.newRingsAdded.findIndex(x => x.ringId === id);
      if (idx > -1) {
        this.onNew = true;
        this.newRingsAdded.splice(idx, 1);
        this.rings.splice(idx, 1);
        this.updateSourceData();
        this.loading = false;
        this.openDialog(this.TITLE_INFO, this.RING_DELETED);
      }
    }
  }

  deleteOriginalRings(id: number) {
      const idx = this.roleRings.findIndex(x => x.ringId === id);
      if (idx > -1) {
        this.roleRings.splice(idx, 1);
        this.rings.splice(idx, 1);
        this.updateSourceData();
      }
  }

  updateSourceData() {
    this.ringDataSource = new MatTableDataSource(this.rings);
    this.ringDataSource.paginator = this.paginator;
    this.ringDataSource.sort = this.sort;
  }

  onSave() {
    if (this.templateForm.dirty && this.templateForm.valid) {
      this.model = this.model.toDto();
      this.loading = true;
      this.setRoleRing(this.model);
      if (!this.model.templateRoleId) {
        this.addTemplateRole();
      } else {
        this.editTemplateRole();
      }
    }
  }

  addTemplateRole() {
    this.templateService.addTemplateRole(this.modelToSave).subscribe(
      result => {
        this.onSaveComplete();
      },
      (error: ServiceError) => {
        this.onServerError(error);
      });
  }

  editTemplateRole() {
    this.templateService.updateTemplateRole(this.modelToSave).subscribe(result => {
      this.onSaveComplete();

    },
      (error: ServiceError) => {
        this.onServerError(error);
      });
  }

  onServerError(data) {
    this.loading = false;
    this.saveFailed = true;
    this.saveErrorMessage = 'Sorry, there was an error while saving the record.';
  }

  onSaveComplete(): void {
    this.loading = false;
    this.openDialog(this.TITLE_INFO, this.SAVED_MESSAGE);
    this.onCancel();
  }

  onCancel(): void {
    this.templateForm.reset();
    this.router.navigateByUrl('/app/security/templates');
  }

  setRoleRing(entity: any) {
    const tempRoleRing = new TemplateRoleRingTable();
    tempRoleRing.template = this.model;
    tempRoleRing.ring = [];
    tempRoleRing.template.name = this.model.name;
    tempRoleRing.template.description = this.model.description;
    tempRoleRing.template.roleTypeId = this.model.roleTypeId;
    tempRoleRing.ring = this.newRingsAdded;
    tempRoleRing.templateRoleId = this.model.templateRoleId;
    this.modelToSave.push(tempRoleRing);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.ringDataSource.filter = filterValue;
  }

}
