import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/model/user';
import { Role } from 'src/app/model/role';
import { RolesEntry } from 'src/app/model/roles-entry';
import { CompanyEntry, TypeCompany } from 'src/app/model/company';
import { UserRoleCompany } from 'src/app/model/user-role-company';
import { People } from 'src/app/model/people';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { RoleService } from 'src/app/services/role.service';
import { PeopleService } from 'src/app/services/people.service';
import { CompanyService } from 'src/app/services/company.service';
import { forkJoin, Subject, ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar, MatSelect, MatDialog, MatOption } from '@angular/material';
import { RoleTypes } from 'src/app/model/role-type';
import { takeUntil } from 'rxjs/operators';
import { SearchPeopleDialogComponent } from 'src/app/app-dialogs/search-people-dialog/search-people-dialog.component';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { NewUser } from 'src/app/model/new-user';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';


export enum TypeProfile {
    GridStaff = 1,
    CommunityStaff = 2,
    ManagementCompanyStaff = 3,
    TenantOwner = 4,
}

export enum PageTitle {
    Add = 1,
    Edit = 2
}

@Component({
    selector: 'user-add-edit',
    templateUrl: './user-add-edit.component.html',
    styleUrls: ['./user-add-edit.component.scss']
})

export class UserAddEditComponent implements OnInit {
    public model: User;
    public peopleSelect: People;
    public roles: RolesEntry[] = [];
    public roleAll: RolesEntry[] = [];
    public userFormRole: FormGroup;
    public rolesToWipe: Role[] = [];
    public rolesToAdd: Role[] = [];
    public companies: CompanyEntry[] = [];
    public companiesFilter: CompanyEntry[] = [];
    public userRoleCompany: UserRoleCompany[] = [];
    public queriedRoles: any[];
    public loading: boolean;
    public isUpdate: boolean;
    public people: string;
    public roleSelected: RolesEntry;
    @ViewChild('staffType') staffType: MatSelect;
    @ViewChild('allSelected') private allSelected: MatOption;

    protected _onDestroy = new Subject<void>();
    /** control for the MatSelect filter keyword multi-selection */
    private rolesMultiFilterCtrl: FormControl = new FormControl();
    private communitiesMultiFilterCtrl: FormControl = new FormControl();
    private commMultiCtrl: FormControl = new FormControl();
    private roleMultiCtrl: FormControl = new FormControl();


    /** list of banks filtered by search keyword */
    private filteredRolesMulti: ReplaySubject<RolesEntry[]> = new ReplaySubject<RolesEntry[]>(1);
    private filteredCommunityMulti: ReplaySubject<CompanyEntry[]> = new ReplaySubject<CompanyEntry[]>(1);
    private pageTitle: PageTitle;
    private currentCommunity = LocalStorageHelper.getManagementCompanyFromBreadcrumb();

    constructor(private fb: FormBuilder,
        private roleService: RoleService,
        private peopleService: PeopleService,
        private companyService: CompanyService,
        private userService: UserService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router) {
        this.model = new User(this.fb);
        this.userFormRole = this.model.buildFormGroup();

    }

    ngOnInit() {
        this.loading = true;
        this.peopleSelect = new People(null);
        forkJoin(this.roleService.getAll(),
            this.companyService.getByType(0)).subscribe(result => {
                this.roleAll = result[0];
                this.companies = result[1];
                this.activatedRoute.params.subscribe(params => {
                    if (params.id && params.id > 0) {
                        this.pageTitle = PageTitle.Edit;
                        forkJoin(this.roleService.getByUserId(params.id),
                            this.userService.getById(params.id)).subscribe(result => {
                                this.model.fromObject(result[1]);
                                if (result[0].length > 0) {
                                    this.initSelectedRoles(result[0]);
                                    this.queriedRoles = result[0];
                                    this.staffType.value = this.getProfileByRoleType(this.queriedRoles[0].roleType);
                                    this.bindRoles(this.staffType.value);
                                   
                                    this.roles.forEach(item => {
                                        this.selectRole(item);
                                    });
                                    let initialSelectedRoles: any[] = [];
                                    this.roleAll.map(item => {
                                        this.queriedRoles.forEach(role => {
                                            if (role.roleId == item.roleId) {
                                                const existingItems = initialSelectedRoles.filter(item => item.roleId !== item.roleId);
                                                if (existingItems.length == 0) {
                                                    initialSelectedRoles.push(item);
                                                    //this.onChangeRole(true, item);
                                                }
                                            }
                                        })
                                    });
                                    this.roleMultiCtrl.patchValue(initialSelectedRoles);
                                    this.commMultiCtrl.patchValue([...this.companiesFilter.map(item => {
                                        if (item.selected == true) {
                                            return item.companyId;
                                        }})]
                                    );
                                }
                                this.peopleService.GetForUser(params.id).subscribe(result => {
                                    this.peopleSelect = result;
                                    this.people = this.displayFn(this.peopleSelect);
                                    this.model.people = this.people;
                                    this.model.peopleId = this.peopleSelect.peopleId;
                                    this.userFormRole.controls['people'].setValue(this.displayFn(this.peopleSelect));
                                    this.loading = false;
                                }, error => {
                                    this.loading = false;
                                    this.snackbar.open('Error:', error, {
                                        duration: 2000
                                    });
                                });
                            }, error => {
                                this.loading = false;
                                this.snackbar.open('Error:', error, {
                                    duration: 2000
                                });
                            });
                    } else {
                        this.loading = false;
                        this.pageTitle = PageTitle.Add;
                    }
                });
            }, error => {
                this.loading = false;
                this.snackbar.open('Error:', error, {
                    duration: 2000
                });
            });

    }

    private initSelectedRoles(roles: any[]) {
        roles.forEach(ro => {
            this.setSelectedCompany(ro.companyId, true);
            this.userRoleCompany.push({ roleId: ro.roleId, companyId: ro.companyId });
        });
    }

    private bindRoles(typeProfile) {
        this.roles = [];
        this.roles = this.getRoles(typeProfile);
        
        this.filteredRolesMulti.next(this.roles.slice());
        this.rolesMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => { this.filterRoles(); });
    }


    private getRoles(typeProfile: number) {
        switch (typeProfile) {
            case TypeProfile.GridStaff:
                return this.roleAll.filter(ro => ro.roleTypeId === RoleTypes.GridAdmin || ro.roleTypeId === RoleTypes.GridOperator
                    || ro.roleTypeId === RoleTypes.Custom);
            case TypeProfile.CommunityStaff:
                return this.roleAll.filter(ro => ro.roleTypeId === RoleTypes.CommunityOperator || ro.roleTypeId === RoleTypes.CommunityAdmin
                    || ro.roleTypeId === RoleTypes.Custom);
            case TypeProfile.ManagementCompanyStaff:
                return this.roleAll.filter(ro => ro.roleTypeId === RoleTypes.ManagementCompanyAdmin
                    || ro.roleTypeId === RoleTypes.ManagementCompanyOperator || ro.roleTypeId === RoleTypes.Custom);
            case TypeProfile.TenantOwner:
                return this.roleAll.filter(ro => ro.roleTypeId === RoleTypes.Tenant
                    || ro.roleTypeId === RoleTypes.Owner || ro.roleTypeId === RoleTypes.Custom);
            default: return this.roleAll;
        }
    }

    private getProfileByRoleType(roleType: number): number {
        switch (roleType) {
            case RoleTypes.Tenant:
            case RoleTypes.Owner:
                return TypeProfile.TenantOwner;
            case RoleTypes.CommunityAdmin:
            case RoleTypes.CommunityOperator:
                return TypeProfile.CommunityStaff;
            case RoleTypes.ManagementCompanyAdmin:
            case RoleTypes.ManagementCompanyOperator:
                return TypeProfile.ManagementCompanyStaff;
            case RoleTypes.GridAdmin:
            case RoleTypes.GridOperator:
                return TypeProfile.GridStaff;
        }
    }

    private displayFn(people?: People): string {
        return people && people.peopleId ? people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
            people.lastName : '';
    }

    private openDialogPeople(): void {
        const dialogRef = this.dialog.open(SearchPeopleDialogComponent, {
            width: '1050px'
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                if (result.data.people.userId && (result.data.people.userId !== this.model.userId)) {
                    SiteHelper.openDialogAlert('Warning', 'This entry already has a user associated to it.', this.dialog);
                } else {
                    this.peopleSelect = result.data.people;
                    this.model.peopleId = result.data.people.peopleId;
                    this.userFormRole.controls['people'].setValue(this.displayFn(this.peopleSelect));
                }
            }
        });
    }

    private onChange(e) {
        this.bindRoles(e.value);
    }

    private onChangeRole(e, role): void {
        role.selected = e;
        if (!role.selected) {
            this.removeRole(role);
        } else {
            this.addRole(role);
            this.selectRole(role);
            this.addRoleCurrentCommunity(role);
        }
    }

    private addRole(role: RolesEntry): void {
        const idx = this.rolesToWipe.findIndex(x => x.roleId === role.roleId);
        if (idx > -1) {
            this.rolesToWipe.splice(idx, 1);
        }

        if (this.pageTitle === 2 ||
            (this.pageTitle === 2 && this.queriedRoles.findIndex(r => r.roleId === role.roleId) === -1)) {
            this.rolesToAdd.push(role as Role);
        }
    }

    private selectRole(row) {
        this.roleSelected = row;
        const initCompanies = JSON.parse(JSON.stringify(this.companies));
        this.companiesFilter = [];
        if (row.roleTypeId === RoleTypes.Owner || row.roleTypeId === RoleTypes.Tenant || row.roleTypeId === RoleTypes.Custom
            || row.roleTypeId === RoleTypes.CommunityAdmin || row.roleTypeId === RoleTypes.CommunityOperator) {
            this.companiesFilter = initCompanies.filter(c => c.typeCompany === TypeCompany.Community);
        } else if (row.roleTypeId === RoleTypes.ManagementCompanyAdmin || row.roleTypeId === RoleTypes.Custom
            || row.roleTypeId === RoleTypes.ManagementCompanyOperator) {
            this.companiesFilter = initCompanies.filter(c => c.typeCompany === TypeCompany.PropertyMAnagementCompany);
        }
        if (this.userRoleCompany.length > 0 && this.userRoleCompany.filter(urc => urc.roleId === row.roleId).length > 0) {
            this.companiesFilter.forEach(cp => {
                if (this.userRoleCompany.filter(ro => ro.companyId === cp.companyId && ro.roleId === row.roleId).length > 0) {
                    cp.selected = true;
                }
            });
        }
        if (this.companiesFilter.length === 0) {
            // This happens when the role does not have a company realted or the role can see all the companies in the system
            this.userRoleCompany.push({ roleId: this.roleSelected.roleId, companyId: 0 });
        }
        this.filteredCommunityMulti.next(this.companiesFilter.slice());
        this.communitiesMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => { this.filterCommunities(); });
    }

    private removeRole(role: RolesEntry): void {
        const idx = this.rolesToAdd.findIndex(x => x.roleId === role.roleId);
        if (idx > -1) {
            this.rolesToAdd.splice(idx, 1);
        }
        if (this.pageTitle === 2) {
            this.rolesToWipe.push(role as Role);
        }
        if (this.userRoleCompany.length > 0) {
            this.userRoleCompany = this.userRoleCompany.filter(rol => rol.roleId !== role.roleId);
        }
        if (this.rolesToAdd.length === 0) {
            this.companiesFilter = [];
            this.filteredCommunityMulti.next(this.companiesFilter.slice());
        } else {
            this.companiesFilter.forEach(c => c.selected = false);
        }
    }

    private filterRoles() {
        if (!this.roles) {
            return;
        }
        let search = this.rolesMultiFilterCtrl.value;
        if (!search) {
            this.filteredRolesMulti.next(this.roles.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredRolesMulti.next(
            this.roles.filter(role => role.name.toLowerCase().indexOf(search) > -1)
        );
    }

    protected filterCommunities() {
        if (!this.companiesFilter) {
            return;
        }
        let search = this.communitiesMultiFilterCtrl.value;
        if (!search) {
            this.filteredCommunityMulti.next(this.companiesFilter.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredCommunityMulti.next(
            this.companiesFilter.filter(company => company.legalName.toLowerCase().indexOf(search) > -1)
        );
    }

    private selectDeselectAll() {
        if (this.allSelected.selected) {
            this.commMultiCtrl.patchValue([...this.companiesFilter.map(item => item.companyId), 0]);
        } else {
            this.commMultiCtrl.patchValue([]);
        }

        this.companies.forEach(e => {
            this.selectCompany(e);
        });

    }

    private selectCompany(company: CompanyEntry, $event = null) {
        if ($event) {
            this.setSelectedCompany(company.companyId, $event.source.selected);
        }
        
        if (this.userRoleCompany.filter(ro => ro.companyId === company.companyId
            && ro.roleId === this.roleSelected.roleId).length > 0) {
            this.userRoleCompany.splice(this.userRoleCompany.findIndex(ro => ro.companyId === company.companyId
                && ro.roleId === this.roleSelected.roleId), 1);
        } else {
            this.userRoleCompany.push({ roleId: this.roleSelected.roleId, companyId: company.companyId });
        }
        this.checkRole(this.roleSelected);
    }

    private checkRole(role: RolesEntry) {
        const count = this.userRoleCompany.filter(r => r.roleId === role.roleId).length;
        role.selected = count > 0;
    }

    private addRoleCurrentCommunity(role: RolesEntry) {
        this.userRoleCompany.push({ roleId: role.roleId, companyId: this.currentCommunity });
    }
    
    private setSelectedCompany(companyId: number, selected: boolean) {
        let selectedCompany = this.companies.find(c => c.companyId == companyId);
        if (selectedCompany) {
            selectedCompany.selected = selected;
        }
    }

    private onSubmit(): void {
        this.loading = true;
        if (!this.userFormRole.valid) {
            return;
        }
        if (this.noValidRole()) {
            SiteHelper.openDialogAlert('Warning',
                'you can not associate the owner or tenant role to this person because it has no relationship.', this.dialog);
            return;
        }
        this.model.firstName = this.peopleSelect.firstName;
        this.model.lastName = this.peopleSelect.lastName;
        this.getMCAndCommunities();


        if (this.model.userId > 0) {
            const data = this.model.toDto();
            const roles: UserRoleCompany[] = this.roleAll;
            const userRoleCompanies: UserRoleCompany[] = this.userRoleCompany;
            const linkObservables = [];


            const rolesToAdd = userRoleCompanies.filter(c =>
                roles.filter(ro => ro.roleId === c.roleId && ro.companyId === c.companyId).length === 0);
          
            const rolesToWipe = roles.filter(c =>
                roles.filter(ro => ro.roleId === c.roleId && ro.companyId === c.companyId).length === 0);
          
              this.userService.updateUser(data).subscribe(updatedUser => {
                rolesToAdd.forEach(x => {
                    linkObservables.push(this.userService.linkRoleToUser(x.roleId, data.userId, x.companyId));
                });
              
                rolesToWipe.forEach(x => {
                    linkObservables.push(this.userService.unlinkRoleFromUser(x.roleId, data.userId, x.companyId));
                });

                forkJoin(linkObservables).subscribe(result => {
                    this.loading = false;
                    this.router.navigate(['app/security/users']);
                  }, error => {
                    // this.loading = false;
                    // this.snackbar.open('Error on linking roles: ', error.message, {
                    //     duration: 2000
                    // });
                    console.log(error.message);
                    this.router.navigate(['app/security/users']);
                  });
              }, error => {
                this.loading = false;
                this.snackbar.open('Error on update: ', error.message, {
                    duration: 2000
                });
              })
              
             
              
        }else {

            /*this.model.communities = [];
            this.userRoleCompany.forEach(r => {
                if(!this.model.communities.includes(r.companyId.toString()))
                this.model.communities.push(r.companyId.toString());
            });*/
            
            this.userService.addNewUser(new NewUser(this.model.toDto())).subscribe(insertResult => {
                this.userRoleCompany.forEach(r => {
                    this.userService.linkRoleToUser(r.roleId, insertResult.id, r.companyId).subscribe(result => {
                        this.loading = false;
                        this.router.navigate(['app/security/users']);
                    }, error => {
                        this.loading = false;
                        this.snackbar.open('Error on linking roles :', error, {
                            duration: 2000
                        });
                    });
    
                });
            }, error => {
                this.loading = false;
                this.snackbar.open('Error on add: ', error.message, {
                    duration: 2000
                });
            });
        }
    }


    getMCAndCommunities() {
        if (this.staffType.value === TypeProfile.TenantOwner) {
            return;
        }
        this.model.managementCompany = null;
        this.model.communities = [];
        let mc: CompanyEntry = null;
        if ( this.staffType.value === TypeProfile.ManagementCompanyStaff && this.userRoleCompany.length > 0 ) {
         mc = this.companies.filter(c => c.companyId === this.userRoleCompany[0].companyId)[0];
         this.model.communities = this.companies.filter(com => com.parent !== null && com.parent === mc.companyId && com.selected == true).map(c => c.nickName);
        }
        if (this.staffType.value === TypeProfile.CommunityStaff && this.userRoleCompany.length > 0 ) {
          const comm = this.companies.filter(com => com.typeCompany === 1 && com.selected == true &&  this.userRoleCompany.map(c => c.companyId)
          .includes(com.companyId));
          mc =  this.companies.filter(com => com.companyId === comm[0].parent)[0];
          this.model.communities = comm.map(c => c.nickName);
        }
        if (this.staffType.value === TypeProfile.GridStaff) {
            this.model.communities =   this.companies.filter(com => com.parent !== null && com.selected == true).map(c => c.nickName);
        }
        this.model.managementCompany = mc  && mc !== null ? mc.nickName : 'Grid Enterprise';
      }

    private noValidRole() {
        return !this.peopleSelect.relationshipId
            && this.rolesToAdd.filter(r => r.roleTypeId === RoleTypes.Owner || r.roleTypeId === RoleTypes.Tenant).length > 0;
    }

    private onCancel() {
        this.router.navigate(['app/security/users']);
    }

}