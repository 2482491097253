import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Services
import { AuthGuardService } from 'src/app/services/auth-guard.service';

// Components
import { DeliveryOptionListComponent } from '../delivery-option-list/delivery-option-list.component';
import { DeliveryOptionAddEditComponent } from '../delivery-option-add-edit/delivery-option-add-edit.component';


const deliveryOptionRoutes: Routes = [{
  path: 'delivery',
  children: [
    {
      path: '',
      component: DeliveryOptionListComponent,
      data: { title: 'Delivery Options' },
      canActivate: [AuthGuardService]
    },
    {
      path: ':id/edit',
      component: DeliveryOptionAddEditComponent,
      data: { title: 'Edit Delivery Options' },
      canActivate: [AuthGuardService]
    },

  ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(deliveryOptionRoutes)
  ],
  declarations: []
})
export class DeliveryOptionRoutingModule { }
