import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { VisitorType } from '../model/Visitor-type';

@Injectable({
  providedIn: 'root'
})
export class VisitorTypeService extends AbstractDataProviderService<VisitorType> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.TardisService, '/visitorTypes', http, appConfig);

  }
}
