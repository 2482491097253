import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../../../../../src/app/app-dialogs/confirm-dialog/confirm-dialog.component';

// Models
import { Document } from '../../model/document';

@Component({
  selector: 'app-bundle-add-document-dialog',
  templateUrl: './bundle-add-document-dialog.component.html',
  styleUrls: ['./bundle-add-document-dialog.component.css']
})
export class BundleAddDocumentDialogComponent implements OnInit {
  public documents: Document[];
  public docsInBundle: Document[];
  public initialDocsInBundle: Document[];
  public update: boolean;
  public bundleId: number;
  public addEditDialogForm: FormGroup;
  public loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<BundleAddDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog) {
    this.loading = true;
    // this.update = this.data.update;
  }



  ngOnInit() {
    this.documents = this.data.documents;
    this.docsInBundle = [];
    this.initialDocsInBundle = [];
    this.docsInBundle = this.data.docsInBundle;
    if (this.data.update === true) {
      this.initialDocsInBundle = this.data.docsInBundle.slice();
      this.bundleId = this.data.bundleId;
      this.setSelected();
    }
  }

  setSelected(): void {
    this.documents.forEach(d => {
      d.selected = false;
      this.docsInBundle.forEach(b => {
        if (b.documentId === d.documentId) {
          d.selected = true;
        }
      });
    });
  }

  onAddEditDoc(item): void {
    const doc = this.documents.find(r => r.documentId === item.documentId);
    doc.selected = !item.selected;
    if (!doc.selected) {
      this.removeDoc(item);
    } else {
      this.addDoc(item);
    }
  }

  addDoc(docInBundle: Document): void {
    const idx = this.docsInBundle.findIndex(x => x.documentId === docInBundle.documentId);
    if (idx > -1) {
      this.docsInBundle.splice(idx, 1);
    }
    if (this.data.update === false ||
      (this.data.update === true && this.data.docsInBundle.findIndex(r => r.documentId === docInBundle.documentId) === -1)) {
      this.docsInBundle.push(docInBundle);
    }
  }

  removeDoc(docInBundle: Document): void {
    const idx = this.docsInBundle.findIndex(x => x.documentId === docInBundle.documentId);
    if (idx > -1) {
      this.docsInBundle.splice(idx, 1);
    }
  }
  onSubmitAddingDocs(): void {
    this.dialogRef.close({
      dirty: true,
      update: true,
      docsInBundle: this.docsInBundle
    });
  }

  applyFilter(filterValue: string) {
    this.documents = this.data.documents;
    this.documents = this.documents.filter(it => {
      return it.name.toLowerCase().includes(filterValue.trim().toLowerCase());
    });
  }

  onCancelAddingDocs(): void {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '750px'
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.data.docsInBundle = [];
        this.docsInBundle = [];
        this.dialogRef.close({
          dirty: false,
          // update: false,
          docsInBundle: this.initialDocsInBundle
        }
        );
      }
    });
  }

  onClose(): void {
    this.dialogRef.close({
      dirty: false,
          // update: false,
          docsInBundle: this.initialDocsInBundle
    });
  }

}
