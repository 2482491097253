import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG} from '../../../../src/app/app.config';
import { AbstractDataProviderService } from '../../../../src/app/services/abstract-data-provider.service';
import * as AppConstants from '../../../../src/app/app-constants';

import { DocumentType } from '../model/document-type';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService extends AbstractDataProviderService<DocumentType> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.Thorin, '/documentType', http, appConfig);
  }
}
