import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { CreditCard } from '../model/creditCard';
import { NewCreditCard, PaymentAnonymousWithCard } from '../model/newCreditCard';
import { Payment } from '../model/payment';
import { Provider, ClassificationItem, Application, PayPoint } from '../model/smaug';

import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { UserDataPayment } from '../model/user-data-payment';
import { catchError } from 'rxjs/operators';
import { BankAccountType } from '../model/bank-account-type';
import { NewBankAccount, PaymentAnonymousWithBankAccount } from '../model/new-bank-account';
import { environment } from 'src/environments/environment';
import { PaymentMethodType } from '../model/payment-method-type';

@Injectable({
  providedIn: 'root'
})
export class SmaugService {

  public baseUrl: string;
  public authApiKey = false;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) appConfig: AppConfig
  ) {
    if (AppConstants.EndpointNames.SmaugService in appConfig.endpoints) {
      const endpoint = appConfig.endpoints[AppConstants.EndpointNames.SmaugService];
      this.baseUrl = endpoint;
    } else {
      console.error('Invalid endpoint name passed to data provider.');
    }
  }

  /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  private buildGetHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();

    let getHeaders = new HttpHeaders();
    if (this.authApiKey) {
      getHeaders = getHeaders.set('Authorization', 'api-key ' + environment.apiKeys.Smaug);
    } else {
      getHeaders = getHeaders.set('Authorization', 'bearer ' + token);
    }

    return getHeaders;
  }

  addPaypoint(payPoint: PayPoint): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + '/paypoint', payPoint, { headers: this.buildGetHeaders() });
  }

  updatePaypoint(payPoint: PayPoint): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl + '/paypoint', payPoint, { headers: this.buildGetHeaders() });
  }
  getPaypointBybyCommunityCode(communityCode: string): Observable<PayPoint[]> {
    return this.http.get<PayPoint[]>(this.baseUrl + '/paypoint/byCommunityCode/' + communityCode, { headers: this.buildGetHeaders() });
  }

  getClassificationItemById(applicationId: number): Observable<ClassificationItem[]> {
      return this.http.get<ClassificationItem[]>(this.baseUrl + '/classificationItem/for/' +
      applicationId, { headers: this.buildGetHeaders() } );
  }

  getApplication(): Observable<Application[]> {
    return this.http.get<Application[]>(this.baseUrl + '/application', { headers: this.buildGetHeaders() } );
  }

  getProvider(applicationId: number, classificationItemId: number): Observable<Provider[]> {
    return this.http.get<Provider[]>(this.baseUrl + '/provider/for/' + applicationId + '/' + classificationItemId,
    { headers: this.buildGetHeaders() } );
  }

  makePayment(payment: Payment): Observable<any> {
    const requestUrl = this.baseUrl + '/payment';
    return this.http.post<any>(requestUrl, payment, { headers: this.buildGetHeaders() } )
    .pipe(
      catchError(this.handleError)
    );
  }

  makePaymentAnonymousWithCard(creditCard: PaymentAnonymousWithCard): Observable<any> {
    const requestUrl = this.baseUrl + '/payment';
    return this.http.post<any>(requestUrl, creditCard, { headers: this.buildGetHeaders() } )
    .pipe(
      catchError(this.handleError)
    );
  }
  makePaymentAnonymousWithBankAccount(bankAccount: PaymentAnonymousWithBankAccount): Observable<any> {
    const requestUrl = this.baseUrl + '/payment';
    return this.http.post<any>(requestUrl, bankAccount, { headers: this.buildGetHeaders() } )
    .pipe(
      catchError(this.handleError)
    );
  }

  getCreditCards(paypointId: number): Observable<CreditCard[]> {
    const requestUrl = this.baseUrl + '/creditCard/for/' + paypointId;
    return this.http.get<CreditCard[]>(requestUrl, {headers: this.buildGetHeaders()} )
    .pipe(
      catchError(this.handleError)
    );

  }

  getBanksAccount(paypointId: number): Observable<any[]> {
    const requestUrl = this.baseUrl + '/bankAccount/for/' + paypointId;
    return this.http.get<any[]>(requestUrl, {headers: this.buildGetHeaders()} )
    .pipe(
      catchError(this.handleError)
    );

  }

  getFee(ammount: number, applicationId: number, providerId: number): Observable<PaymentMethodType[]> {
    const requestUrl = this.baseUrl + '/fee?amount=' + ammount + '&&applicationId=' + applicationId + '&&providerId=' + providerId;
    return this.http.get<PaymentMethodType[]>(requestUrl, {headers: this.buildGetHeaders()} )
    .pipe(
      catchError(this.handleError)
    );

  }

  addCreditCard(creditCard: NewCreditCard): Observable<any> {
    const requestUrl = this.baseUrl + '/creditCard/';
    return this.http.post<any>(requestUrl, creditCard , { headers: this.buildGetHeaders()} )
    .pipe(
      catchError(this.handleError)
    );

  }

  addBankAccount(creditCard: NewBankAccount): Observable<any> {
    const requestUrl = this.baseUrl + '/bankAccount/';
    return this.http.post<any>(requestUrl, creditCard , { headers: this.buildGetHeaders()} )
    .pipe(
      catchError(this.handleError)
    );

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}`);
    }

    return throwError(
      `Connection error with the service. Code ${error.status}. ${error.error.errorMessage}`);
  }

  getUserDataForProviderId(): Observable<UserDataPayment[]> {
    const requestUrl = this.baseUrl + '/userData/forProvider/1';
    return this.http.get<UserDataPayment[]>(requestUrl, {headers: this.buildGetHeaders()} )
    .pipe(
      catchError(this.handleError)
    );
  }

  getBankAccountType(AuthApiKey?: boolean): Observable<BankAccountType[]> {
    const requestUrl = this.baseUrl + '/bankAccountType';
    return this.http.get<BankAccountType[]>(requestUrl, {headers: this.buildGetHeaders()} )
    .pipe(
      catchError(this.handleError)
    );
  }

}
