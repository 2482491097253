import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';

import { CommunityAddEditComponent } from '../../community/community-add-edit/community-add-edit.component';
import { CompanyAddComponent } from '../company-add/company-add.component';
import { CompanyAddressComponent } from '../company-address/company-address.component';
import { TextMaskModule } from '../../../../node_modules/angular2-text-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    TextMaskModule,
  ],
  declarations: [
    CommunityAddEditComponent,
    CompanyAddComponent,
    CompanyAddressComponent,
  ],
  exports: [
    CommunityAddEditComponent,
    CompanyAddComponent,
    CompanyAddressComponent,
    FormsModule,
    MaterialModule,
    TextMaskModule,
  ],
  bootstrap: [
    CommunityAddEditComponent,
    CompanyAddComponent,
    CompanyAddressComponent,
  ],
  entryComponents: [
    CommunityAddEditComponent,
    CompanyAddComponent,
    CompanyAddressComponent,
  ],
})
export class SharedCompanyModule { }

