import { Document } from './document';

export class Bundle {
    bundleId: number;
    name: string;
    description: string;
    isDeleted: boolean;
    documentsCount: number;
    userName: string;
    documents: Document[];
    isActive: boolean;
    selected: boolean;
    communityId: number;
    managementCompanyId: number;
}
