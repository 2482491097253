import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cross-search',
    templateUrl: './cross-search.component.html',
    styleUrls: ['./cross-search.component.css']
})

export class CrossSearchComponent {
    private searchText: string;
    @Input() showSearchField: boolean = true;
    @Output() search = new EventEmitter<any>();

    doSearch() {
        if (this.searchText !== null && this.searchText.trim() !== '') {
            this.search.emit({searchText: this.searchText});
        }
    }
}