import { Component, ViewChild, HostListener, Output, EventEmitter } from '@angular/core';
import { PhotoCameraComponent } from '../photo-camera/photo-camera.component';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'app-photo-image-upload',
    templateUrl: './photo-camera-image-upload.component.html',
    styleUrls: ['./photo-camera-image-upload.component.scss']
})

export class PhotoCameraImageUpload {
    public cameraInit = new Subject<boolean>();
    public cameraComponent: PhotoCameraComponent;
    private isHovering: boolean = false;

    @ViewChild('camera') set content(content: PhotoCameraComponent) {
        this.cameraComponent = content;
        if (this.cameraComponent) {
            this.cameraComponent.startup();
            this.cameraInit.next(true);
        }
    }
    @Output() photoTaken: EventEmitter<boolean> = new EventEmitter<boolean>();

    setImage(pic: any, type: string) {
        const reader = new FileReader();
        const blob = new Blob([new Uint8Array(pic)], { type: 'image/png' });
        if (blob.size > 0) {
            const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
            reader.onload = (event: any) => {

                if (this.cameraComponent !== undefined) {
                    this.setPhotoCamera(event.target.result);
                }
                this.onCameraInit().subscribe(_ => this.setPhotoCamera(event.target.result));
            };
            reader.readAsDataURL(file);
        }
    }

    onCameraInit(): Observable<boolean> {
        return this.cameraInit.asObservable();
    }

    setPhotoCamera(src: any) {
        this.cameraComponent.setPicture(src);
    }

    startUpload(event: FileList) {
        const file = event.item(0);
        if (file.type.split('/')[0] === 'image') {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.cameraComponent.src = event.target.result;
                this.photoTaken.emit(true);
            };
            reader.readAsDataURL(event.item(0));
        }
    }

    @HostListener('drop', ['$event'])
    onDrop($event) {
        $event.preventDefault();
        this.startUpload($event.dataTransfer.files);
        this.isHovering = false;
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event) {
        $event.preventDefault();
        this.isHovering = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event) {
        $event.preventDefault();
        this.isHovering = false;
    }

}