
export enum AccessType {
  None,
  List,
  Add,
  Delete,
  Update,
  Execute,
}



export class RingAccessType {
    ringId: number;
    allow ? = false;
    accessType: AccessType;
}


export class EntityRing {
   rings: RingAccessType[] = [];

   get allowAdd(): boolean {
       return this.rings.find(ring => ring.accessType === AccessType.Add && ring.allow) !== undefined;
   }
   get allowEdit(): boolean {
    return this.rings.find(ring => ring.accessType === AccessType.Update && ring.allow) !== undefined;
   }
   get allowDelete(): boolean {
    return this.rings.find(ring => ring.accessType === AccessType.Delete && ring.allow) !== undefined;
   }
   get allowList(): boolean {
    return this.rings.find(ring => ring.accessType === AccessType.List && ring.allow) !== undefined;
   }
   get allowExecute(): boolean {
    return this.rings.find(ring => ring.accessType === AccessType.Execute && ring.allow) !== undefined;
   }
}
