import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSelect,
  MatSnackBar,
} from '@angular/material';

import { ConfirmDialogComponent } from '../../app-dialogs/confirm-dialog/confirm-dialog.component';
import { EntityTypeService } from '../../services/entity-type.service';

import { Entity } from '../../model/entity';
import { EntityType } from '../../model/entity-type';

@Component({
  selector: 'app-entity-add-edit-dialog',
  templateUrl: './entity-add-edit-dialog.component.html',
  styleUrls: ['./entity-add-edit-dialog.component.css']
})
export class EntityAddEditDialogComponent implements OnInit {

  public model: Entity;
  public entityFormGroup: FormGroup;
  public entityTypes: EntityType[];
  public entities: Entity[];
  @ViewChild('entityTypeSelect') addressTypeSelect: MatSelect;

  constructor(public dialogRef: MatDialogRef<EntityAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private entityTypeService: EntityTypeService) {

      this.model = new Entity(this.fb);
      this.entityFormGroup = this.model.buildFormGroup();
  }

  ngOnInit() {
    // Instantiate the domain model object.
    if (this.data.update === true) {
      this.model.fromObject(this.data.model.entity);
    }

    this.entityTypeService.getAll().subscribe(x => {
      this.entityTypes = x;
    });
  }

  onCancel(): void {
    if (this.entityFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else { this.dialogRef.close({ valid: false }); }
  }

  onSubmit(): void {
    this.dialogRef.close({
      valid: true,
      entity: this.model.toDto()
    });
  }
}
