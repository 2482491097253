
import { BaseForm } from '../building-blocks/base-form';
import { TemplateRole } from './template-role';
import { RingEntityTable } from './ring-entity-table';

export class TemplateRoleRingTable {
    template: TemplateRole;

    templateRoleRingId: number;

    templateRoleId: number;

    ring: RingEntityTable[];
}
