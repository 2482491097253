import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { map } from 'rxjs/operators';

import { CountResultModel } from '../model/count-result-model';

import { Staff } from '../model/Staff';
import {StaffContact} from '../model/staff-contact';
import { AbstractDataProviderService } from './abstract-data-provider.service';

import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class StaffService extends AbstractDataProviderService<Staff> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/staff', http, appConfig, LevelFilter.Community);
  }
/*
  getAllPaginatedFiltered(pageIndex: number, pageSize: number, filter: string): Observable<Staff[]> {
    const communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    const queryString =
      `/community/${ communityId }/allPagedFiltered/${ pageIndex.toString() }/${ pageSize.toString() }/${ filter }`;
    return this.http.get<Staff[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getAllPaginated(pageIndex: number, pageSize: number): Observable<Staff[]> {
    const communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    const queryString = `/community/${ communityId }/allPaged/${ pageIndex.toString() }/${ pageSize.toString() }`;
    return this.http.get<Staff[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
  */

  getCount(): Observable<number> {
    const communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    return this.http.get<CountResultModel>(this.baseUrl + `/community/${ communityId }/count`, { headers: this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  getCountFiltered(filter: string): Observable<number> {
    const communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    return this.http.get<CountResultModel>(this.baseUrl + `/community/${ communityId }/` + filter + `/count`,
    { headers: this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  getcommunityStaffByStaffId(id: number): Observable<Staff> {
    const queryString = '/community/byStaffId/' + id.toString();
    return this.http.get<Staff>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getStaffContacts(): Observable<StaffContact[]> {
    const communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    const queryString = `/community/${ communityId }/GetContacts`;
    return this.http.get<StaffContact[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
}
