import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';

import { ConfirmDialogComponent } from '../../app-dialogs/confirm-dialog/confirm-dialog.component';

import { UnitCategory } from '../../model/unit-category';
import { Unit } from '../../model/unit';
import { UnitCategoryService } from '../../services/unit-category';
import { UnitService } from '../../services/unit.service';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { AccessType } from 'src/app/model/access-type-ring';
import { AreaService } from '../../services/area.service';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { Area } from '../../model/area';

@Component({
  selector: 'app-unit-add-edit',
  templateUrl: './unit-add-edit.component.html',
  styleUrls: ['./unit-add-edit.component.css']
})
export class UnitAddEditComponent extends BaseSecurity implements OnInit, AfterViewInit {

  public model: Unit;
  public unitForm: FormGroup;
  public categories: UnitCategory[];
  areas: Area[];

  constructor(public dialogRef: MatDialogRef<UnitAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private unitCategoryService: UnitCategoryService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public areaService: AreaService) {
    super();
      this.loadSecurityRings();
      this.model = new Unit(this.fb);
      this.unitForm = this.model.buildFormGroup();
     }

     protected loadSecurityRings() {
      this.securityEntity.rings.push({ringId : 577, accessType: AccessType.Add });
      this.securityEntity.rings.push({ringId : 777, accessType: AccessType.Update });
      this.entityRings.push(this.securityEntity);
    }
  ngOnInit() {
    super.ngOnInit();
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.areaService.getAllByCommunityId(comunityLocalStorage).subscribe(results => this.areas = results);
    this.unitCategoryService.getAll().subscribe(c => {
      this.categories = c;
    });
    if (this.data.isUpdate === true) {
      this.model.fromObject(this.data.model);
    } else {
      this.model.areaId = this.data.areaId;
    }
  }

  ngAfterViewInit() {
  }

  onCancel(): void {
    if (this.unitForm.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else  {
      this.dialogRef.close({ valid: false });
    }
  }

  onSubmit(): void {
    this.dialogRef.close({
       valid: true,
       entity: this.model.toDto()
     });
   }


}
