import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { FinancialsAccount, BalanceAccount, AccountInfo } from '../model/account';
import { Observable, pipe } from 'rxjs';
import { Transaction } from '../model/transaction';
import { Payment } from '../model/payment';
import { CreditCard } from '../model/creditCard';


@Injectable({
  providedIn: 'root'
})
export class AccountService extends AbstractDataProviderService<FinancialsAccount> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/account', http, appConfig);
  }

  makePayment(payment: any): Observable<any> {
    const requestUrl = this.baseUrl + '/payment';
    return this.http.post<any>(requestUrl, payment, { headers: this.buildGetHeaders() } );
  }

  getByCurrentUser(): Observable<FinancialsAccount[]> {
    return this.http.get<FinancialsAccount[]>(this.baseUrl + '/byCurrentUser', { headers: this.buildGetHeaders() });
  }
  getBalances(): Observable<BalanceAccount[]> {
    return this.http.get<BalanceAccount[]>(this.baseUrl + '/getBalance', { headers: this.buildGetHeaders() });
  }
  getBalancesByUnitId(unitId: number): Observable<BalanceAccount[]> {
    return this.http.get<BalanceAccount[]>(this.baseUrl + '/getBalanceByUnit/' + unitId, { headers: this.buildGetHeaders() });
  }
  findAccountByParams(communityId: number, lastName: string, accountNumber: string): Observable<AccountInfo[]> {
    return this.http.get<AccountInfo[]>(this.baseUrl + '/findByParams/'
     + lastName + '/' + accountNumber + '/' + communityId , { headers: this.buildGetHeaders() });
  }
  getAllTransactions(): Observable<Transaction[]> {
    return this.http.get<Transaction[]>(this.baseUrl + '/transactions', { headers: this.buildGetHeaders() });
  }
  getAllTransactionsByUnit(unitId: number): Observable<Transaction[]> {
    return this.http.get<Transaction[]>(this.baseUrl + '/transactions/' + unitId, { headers: this.buildGetHeaders() });
  }

  getTooltipForPending(row: any): string {
    return 'Your payment in the amount of ' + row.amount.toFixed(2) +
    ' was scheduled. Please note that it may take 3 to 5 business days for the payment to be processed and reflected on your account.';
  }

  addBankCreditCardAccount(bankAccountInfo: any, accountId: number, paymentType: string): Observable<any[]> {
    const requestUrl = this.baseUrl + '/' + accountId + '/' + paymentType;
    return this.http.post<any>(requestUrl, bankAccountInfo, { headers: this.buildGetHeaders() } );
  }

  getBankAndCreditCardAccounts(accountId: number, paymentType: string): Observable<any[]> {
    const requestUrl = this.baseUrl + '/' + accountId + '/' + paymentType;
    return this.http.get<any>(requestUrl, { headers: this.buildGetHeaders() } );
  }

}
