import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageLog } from 'src/app/model/packageLog';
import { PackageLogService } from 'src/app/services/package-log.service';
import { PeopleService } from '../../../services/people.service';
import { TardisFileService } from '../../../services/pensieve.service';


@Component({
  selector: 'app-package-log-detail',
  templateUrl: './package-log-detail.component.html',
  styleUrls: ['./package-log-detail.component.css']
})
export class PackageLogDetailComponent implements OnInit {
  public packagesLog: PackageLog[] = [];
  public currentPackage: PackageLog;
  public loading: boolean;
  public id: string;
  public ishot: string;
  public residentInfo: string;
  public signature = '';
  constructor(private packageService: PackageLogService, 
  private route: ActivatedRoute, 
  private tardisFileService: TardisFileService,
  private peopleService: PeopleService,
  private router: Router) {
    this.currentPackage = new PackageLog();
   }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.ishot = this.route.snapshot.paramMap.get('ishot');
    this.loading = true;
    const hot =this.ishot === 'true';
    let relationshipArray : number[] = [];
    this.packageService.getPackageLogDetail(this.id, this.ishot).subscribe(x => {
      this.currentPackage = x.filter(p => p.isHot === hot)[0];
      relationshipArray.push(x[0].relationshipId);
      this.peopleService.getByRelationshipIdArray(relationshipArray).subscribe(resident => {
          this.residentInfo = 'Resident: ' + resident[0].firstName + ' ' + resident[0].lastName + ' - ' + resident[0].areaName + ' ' + resident[0].unitName + '';
      });
      if (this.currentPackage.fileId !== null) {
        this.getSignature(this.currentPackage.fileId)
      }
      this.packagesLog = x;
      this.loading = false;
    });
  }

  cancel() {
    this.router.navigateByUrl('/app/packages/log');
  }
  getSignature(fileId: number) {
    this.tardisFileService.getFile(fileId).subscribe(pic => {
      const reader = new FileReader();
      const blob = new Blob([new Uint8Array(pic)], { type: 'image/png' });
      if (blob.size > 0) {
        const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
        reader.onload = (event: any) => {
          this.signature = event.target.result;
          this.loading = false;
        };
        reader.readAsDataURL(file);
      }
    });
  }

}
