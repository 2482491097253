import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagRoutingModule } from './tag-routing.module';
import { TagListComponent } from '../tag-list/tag-list.component';

import { MaterialModule } from '../material.module';

@NgModule({
  imports: [
    CommonModule,
    TagRoutingModule,
    MaterialModule
  ],
  declarations: [TagListComponent]
})
export class TagModule { }
