import { Component, Input, TemplateRef, Injector, ChangeDetectorRef, Output, EventEmitter, ViewChild, ContentChildren, QueryList } from '@angular/core';
import { TableDataSource } from 'src/app/building-blocks/table-data-source';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatSort, MatTable, MatColumnDef } from '@angular/material';
import { Subject } from 'rxjs';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
@Component({
    selector: 'grid-table',
    templateUrl: './grid-table.component.html',
    styleUrls: ['./grid-table.component.scss'],
    animations: [
        trigger('searchOpenClose', [
            state('open', style({
                'width': '245px',
                'border': '1px solid lightgray',
                'padding-left': '5px'
            })),
            state('closed', style({
                'width': '0px',
                'border': 'none'
            })),
            transition('open => closed', [
                animate('0.1s')
            ]),
            transition('closed => open', [
                animate('0.1s')
            ])
        ]),
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})

export class GridTableComponent {
    //#region TODO: Remove this if verified not needed
    // variable that will show/hide add action  to root
    @Input() rootAdd: boolean = true;

    // variable that will show/hide edit action  to root
    @Input() rootEdit: boolean = true;

    // variable that will show/hide delete action  to root
    @Input() rootDelete: boolean = true;

    // variable that will show/hide delete action  to root
    @Input() rootConfigure: boolean = true;

    @Input() showActionColumnToChildren: boolean = false;
    //#endregion

    // columns that will be displayed on the table
    @Input() cols: any[];

    // title of the table
    @Input() title: string;

    // data that will be coming from the API
    @Input() dataSource: TableDataSource<any>;

    // indicate that the table is a group table
    @Input() multipleDataRows: boolean = false;

    // this will define the template for advance filter section of the table
    @Input() advanceFilterTemplate: TemplateRef<any> = null;

    // value that will be displayed as a child row when the table is set to display multiple row
    @Input() dataTree: any = [];

    // specify table specific column filter interface
    @Input() filterTemplate: TemplateRef<any> = null;

    // specify table search, import, add action
    @Input() actionHeaderTemplate: TemplateRef<any> = null;

    // variable that allows to show/hide add action header button
    @Input() showAddHeaderActionButton: boolean = true;

    // variable will override showAddHeaderActionButton property value
    @Input() overrideShowAddHeaderActionButton: boolean = false;

    // variable that will allow hide/show search box
    @Input() showSearchHeaderAction: boolean = true;

    // variable that allows to show/hide export action header button
    @Input() showExportHeaderActionButton: boolean = true;

    //variable that allows to show/hide pay button
    @Input() showPaymentButton: boolean = false;

    // variable that will decide to show or hide advance filter template
    @Input() showFilter

    // loading indicator
    @Input() loading: boolean;

    // actions that can be perform on each row
    @Input() rowActions: string[];

    // variable that will show/hide action column
    @Input() showActionColumn: boolean = true;

    // determines the security that a user should have in order to perform certain actions in a row
    @Input() securityEntity: any;

    // this will allow the rows to hide/show items based on the state from the parent component
    @Input() subItemsCollapsed: boolean = false;

    // variable that will hold search parameter
    @Input() searchString: string = '';

    // event that will trigger csv download. implementation on the parent component
    @Output() downloadCSVEvent: EventEmitter<any> = new EventEmitter();

    // event that will trigger pdf download. implementation on the parent component
    @Output() downloadPDFEvent: EventEmitter<any> = new EventEmitter<any>();

    // event that will trigger payment option
    @Output() openPaymentEvent: EventEmitter<any> = new EventEmitter<any>();

    // event that will trigger once the column is clicked for sorting
    @Output() applySort: EventEmitter<any> = new EventEmitter<any>();

    // event that will trigger once the user click the add icon on table header
    @Output() addHeaderActionEvent: EventEmitter<any> = new EventEmitter<any>();

    // performs add event
    @Output() addEvent: EventEmitter<any> = new EventEmitter<any>();

    // performs edit event
    @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();

    // performs delete event
    @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();

    // performs configure event
    @Output() configureEvent: EventEmitter<any> = new EventEmitter<any>();

    // performs reset password for user list
    @Output() resetPasswordEvent: EventEmitter<any> = new EventEmitter<any>();

    // performs search event
    @Output() searchEvent: EventEmitter<string> = new EventEmitter<string>();

    /** A reference to the table paginator component. */
    @ViewChild(MatSort) protected sort: MatSort;

    // variable that will hold the data from the parent component
    public _dataSource: TableDataSource<any>;

    // variable that will map column object to specific column name that will be displayed on the table
    private _displayedColumns: string[];

    // variable that will hold the data from the API that will be filtered to display sub items
    private _dataTree: any;

    // variable that will show/hide advance filter
    private showSearch: boolean = true;

    // variable that will hold the selected element on group table
    private selectedElement: any = null;

    private noData$: Subject<boolean> = new Subject<any>();

    // this variable will hold the mat table object from the HTML
    @ViewChild(MatTable) private table;

    // this variable will hold the defined column template from the parent component
    @ContentChildren(MatColumnDef) private columnDefs: QueryList<MatColumnDef>;

    //this variable is used on html template to render column templates from the parent component
    private isParentHasDefinedColumns: boolean = false;

    // variable that will list indexes of columns that needs to be hidden on export
    private exportCSVHiddenCols: number[] = [];

    @ViewChild('exportDirective') exportDirective: any;


    constructor(public injector: Injector,
        private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this._displayedColumns = this.cols.map(col => col.columnDef);
        if (this.showActionColumn || this.showActionColumnToChildren) {
            this._displayedColumns.push('actions');
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.searchEvent.emit(this.searchString);
        }
    }

    searchItem() {
        this.showSearch = !this.showSearch;
    }

    addItem() {
        this.addHeaderActionEvent.emit();
    }

    downloadPDF() {
        const doc = new jsPDF();
        const elem = document.getElementById("grid-table")
        const autoTableCol = doc.autoTableHtmlToJson(elem);
        autoTableCol.columns = autoTableCol.columns.filter(autoTableCol => autoTableCol.content !== 'Action')
        autoTableCol.data.map(d => d.filter(d => d.content !== 'more_vert'))
        doc.autoTable(autoTableCol.columns, autoTableCol.data);
        this.downloadPDFEvent.emit({ doc: doc });
    }

    downloadCSV(options?: any) {
        this.downloadCSVEvent.emit({exportDirective: this.exportDirective, options: options});
    }

    renderSubItems(parentData: any) {
        this._dataTree = [];
        this._dataTree = this.dataTree.filter((d: any) => d.parent === parentData.id)
        return this.selectedElement = this.selectedElement === parentData ? null : parentData;
    }

    hasAction(value: string) {
        return this.rowActions.find(v => v == value);
    }

    performRowEventAction(row: any, actionName: string) {
        switch (actionName) {
            case 'add':
                this.addEvent.emit({ row: row });
                break;
            case 'edit':
                this.editEvent.emit({ row: row });
                break;
            case 'delete':
                this.deleteEvent.emit({ row: row });
                break;
            case 'configure':
                this.configureEvent.emit({ row: row });
                break;
            case 'resetPassword':
                this.resetPasswordEvent.emit({ row: row });
                break;
            default:
                console.log(`Unable to perform action ${actionName}`);
                break;
        }

    }
    hideSubItem(row: any, index: number) {
        if (row.parent === null) {
            return false;
        }
        return this.subItemsCollapsed ? row.state === true : this._dataTree[index].state === true
    }


    loadUserPermisson() {
        let impersonatedFlag = LocalStorageHelper.getImpersonatedFlag();
        let loggedUser = LocalStorageHelper.getLoggedUserInfo(impersonatedFlag);
        // overrideShowAddHeaderActionButton will set the addActionHeader button depending on the set value from the parent component
        // it will not determine based on the loggedUser
        if (this.overrideShowAddHeaderActionButton == false && loggedUser.isResident) {
            return this.showAddHeaderActionButton = false;
        }
    }

    ngOnChanges() {
        this.loadUserPermisson();
        this._dataSource = this.dataSource;
        this._dataTree = this.dataTree;

        /** for group table */
        // this will reset the state of collapsed row when using the group table
        this.selectedElement = null;
        this.noData$ = this.dataSource.noData;
        this.cd.detectChanges();
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(s => {
            const sortItem = `${s.active}|${s.direction}`;
            this.applySort.emit({ sortData: sortItem });
        });

        // this code will remove 'actions' column when exporting to PDF
        for (let i = 0; i < this._displayedColumns.length; i++) {
            if (this._displayedColumns[i] === 'actions') {
                this.exportCSVHiddenCols.push(i);
            }
        }
    }

    ngAfterContentInit() {
        if (this.columnDefs.length > 0) {
            this.isParentHasDefinedColumns = true;
            this.columnDefs.forEach(columnDef => {
                if (columnDef.name === 'actions') {
                    this.showActionColumn = false;
                }
                this.table.addColumnDef(columnDef);

            });
        }


    }

    showChildrenActions(row: any) {
        if (row.parent !== undefined && row.parent !== null) {
            if (this.showActionColumnToChildren === false && row.parent !== 0) {
                return false;
            }
        }
        return true;
    }

    openPayment() {
        this.openPaymentEvent.emit();
    }
}

