import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '../../../../src/app/app.config';
import { DeliveryOption } from '../model/delivery-option';
import { AbstractDataProviderService } from '../../../../src/app/services/abstract-data-provider.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CrudResponseModel } from '../../../../src/app/model/crud-response-model';
import * as AppConstants from '../../../../src/app/app-constants';
import { LocalStorageHelper } from '../../../../src/app/helpers/local-storage-helper';
import { DeliveryPricePlan } from '../model/deliveryPricePlan';
import { LevelFilter } from 'src/app/model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class DeliveryOptionService extends AbstractDataProviderService<DeliveryOption> {

  private userInfo = new BehaviorSubject(LocalStorageHelper.getUserInfo());
    currentUser = this.userInfo.asObservable();

    constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
      super(AppConstants.EndpointNames.Thorin, '/deliveryoption', http, appConfig, LevelFilter.Community);
    }

    getAllPaged(pageIndex: number, pageSize: number): Observable<DeliveryOption[]> {
      this.setBreadcrumbParams();
       const queryString = '/all/' + pageIndex.toString() + '/' + pageSize.toString() +
       '?mcId=' + this.searchParamsBreadcrumb.propertyManagementCompanyId + '&communityId=' + this.searchParamsBreadcrumb.communityId;
      return this.http.get<DeliveryOption[]>(this.baseUrl + queryString ,  { headers: this.buildGetHeaders() });
    }

    getDeliveryById(id: number): Observable<DeliveryPricePlan> {
      const queryString = '/getById/' + id.toString();
      return this.http.get<DeliveryPricePlan>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
    }

    addDeliveryOption(entity: DeliveryPricePlan): Observable<CrudResponseModel> {
      return this.http.post<CrudResponseModel>(this.baseUrl + '/add', entity, { headers: this.buildPostHeaders() });
    }

    updateDeliveryOption(entity: DeliveryPricePlan): Observable<null> {
      return this.http.put<null>(this.baseUrl + '/update', entity, { headers: this.buildPostHeaders() });
    }

    // getDeliveriesByMCAndCommunityId(propertyManagementCompanyId: number, communityId: number): Observable<DeliveryOption[]> {
    //   return this.http.get<DeliveryOption[]>(this.baseUrl + '/getByCompanyAndCommunity/' +
    //   '?mcId=' + propertyManagementCompanyId + '&communityId=' + communityId, { headers: this.buildGetHeaders() });
    // }
}
