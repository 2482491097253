import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { CrudResponseModel } from '../model/crud-response-model';
import { Observable } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Ring } from '../model/ring';
import { RingEntityTable } from '../model/ring-entity-table';

@Injectable({
  providedIn: 'root'
})
export class RingService extends AbstractDataProviderService<Ring> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.SecurityAdminService, '/rings', http, appConfig);
  }
  linkRingToRole(ringId: number, roleId: number): Observable<CrudResponseModel> {
    const body = {
      'ringId': ringId,
      'roleId': roleId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link', body, { headers: this.buildPostHeaders() });
  }

  unlinkRingFromRole(ringId: number, roleId: number): Observable<CrudResponseModel> {
    const body = {
      'ringId': ringId,
      'roleId': roleId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink', body, { headers: this.buildPostHeaders() });
  }

  getByRoleId(roleId: number): Observable<Ring[]> {
    return this.http.get<Ring[]>(this.baseUrl + '/byRoleId/' + roleId.toString(), { headers: this.buildGetHeaders() });
  }

  getRingByIds(ringTypeid: number, entityId: number): Observable<RingEntityTable> {
    const sortingHatEndpoint = this.appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/sortingHat/templates';
    const queryString = '/byIds/' + ringTypeid.toString() + '/' + entityId.toString();
    return this.http.get<RingEntityTable>(sortingHatEndpoint + queryString, { headers: this.buildGetHeaders() });
  }

}
