import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ResidentSearch } from 'src/app/model/resident-search';
import { CommunitySettings } from 'src/app/model/community-settings';
import { MatAutocomplete, MatDialog } from '@angular/material';
import { Package } from 'src/app/model/package';
import { SearchParamsBreadcrumb } from 'src/app/model/search-params-breadcrumb';
import { forkJoin, fromEvent, Observable, Subscription, Subject } from 'rxjs';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { distinctUntilChanged, debounceTime, tap, switchMap } from 'rxjs/operators';
import { PeopleService } from 'src/app/services/people.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PackagesService } from 'src/app/services/packages.service';
import { HotPackagesService } from 'src/app/services/hot-packages.service';
import { CommunitySettingsService } from 'src/app/services/community-settings.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { SignaturePad, CallbackPayload } from 'src/app/model/signature-pad';
import { SignaturePadService } from 'src/app/services/signature-pad.service';
import { TardisFileService } from 'src/app/services/pensieve.service';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { validateConfig } from '@angular/router/src/config';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-deliver-package',
  templateUrl: './deliver-package.component.html',
  styleUrls: ['./deliver-package.component.css']
})

export class DeliverPackageComponent implements AfterViewInit, OnInit, OnDestroy {

  public infoFormGroup: FormGroup;
  public selectTedResident = '';
  private resident: ResidentSearch;
  public currentPackage: Package[] = [];
  public loading = false;
  private isLoading = false;
  private isLoadingPackage = false;
  private relationshipId: number;
  public temporaryFileId = 0;
  public communitySettings: CommunitySettings = new CommunitySettings(null);
  private nameResident = '';
  private searchParamsBreadcrumb: SearchParamsBreadcrumb = new SearchParamsBreadcrumb();
  public signature = '';
  public textSave = 'Deliver';
  @ViewChild('filter') input: ElementRef;
  @ViewChild('residentSelect') residentSelect: MatAutocomplete;
  packagelogId: number;
  showResidentSearch = true;


  @ViewChild('filterPackage') set content(inputPackage: ElementRef) {
    this.debouncePackage(inputPackage);
  }

  private residentSearch: ResidentSearch[];
  private packageSearch: Package[] = [];
  breadcrumbChanges: Subscription;

  constructor(private peopleService: PeopleService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private packageService: PackagesService,
    private hotPackageService: HotPackagesService,
    private communitySettingService: CommunitySettingsService,
    private signaturePAdService: SignaturePadService,
    private pensiveService: TardisFileService,
    private firebaseMessagingService: FirebaseMessagingService,
    private searchService: SearchService,
    private route: ActivatedRoute) {
    this.resident = new ResidentSearch();
    this.infoFormGroup = this.fb.group({
      addressUnit: ['', Validators.required],
      recipientName: ['', Validators.required],
      email: ['', Validators.email],
      phone: [''],
    });

    this.infoFormGroup.controls['recipientName'].disable();
    this.infoFormGroup.controls['addressUnit'].disable();
    this.infoFormGroup.controls['email'].disable();
    this.infoFormGroup.controls['phone'].disable();
  }

  ngOnInit() {
    this.checkParamns();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      this.checkParamns();
    });
    this.route.paramMap.subscribe(
      params => {
        this.packagelogId = +params.get('id');
        if (this.packagelogId && this.packagelogId > 0) {
          this.showResidentSearch = false;
          this.loading = true;
          this.hotPackageService.getById(this.packagelogId).subscribe(p => {
            p.checked = true;
            this.currentPackage.push(p);
            this.peopleService.getByRelationshipId(p.relationshipId).subscribe(r => {
              const resident = r.filter(res => res.relationshipId == p.relationshipId)[0];
              this.onResidentSelectionChange(resident);
            })
          });
        }
      });
  }
  checkParamns(): any {
    this.searchParamsBreadcrumb.propertyManagementCompanyId = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    this.searchParamsBreadcrumb.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.searchParamsBreadcrumb.areaId = LocalStorageHelper.getBuildingFromBreadcrumb();
    this.searchParamsBreadcrumb.unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
  }
  ngOnDestroy() {
    this.breadcrumbChanges.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(200),
          tap(() => this.isLoading = true),
          distinctUntilChanged(),
          switchMap((e: any) => {
            let val = '';
            if (e) {
              val = e.target.value;
            }
            if (val && val.length > 2) {
              return this.applyFilter(val || '');
            } else {
              this.isLoading = false;
              this.residentSearch = [];
              return new Observable<ResidentSearch[]>();
            }
          })
        ).subscribe(result => {
          this.residentSearch = result;
          this.isLoading = false;
        });
    }
  }

  debouncePackage(inputPackage: ElementRef) {
    if (inputPackage) {
      fromEvent(inputPackage.nativeElement, 'keyup')
        .pipe(
          debounceTime(8000),
          tap(() => this.isLoadingPackage = true),
          distinctUntilChanged(),
          switchMap((e: any) => {
            let val = '';
            if (e) {
              val = e.target.value;
            }
            if (val && val.length > 2) {
              return this.applyFilterPackage(val || '');
            } else {
              this.isLoadingPackage = false;
              this.packageSearch = [];
              return new Observable<Package[]>();
            }
          })
        ).subscribe(result => {
          this.packageSearch = result;
          this.isLoadingPackage = false;
        });
    }
  }
  applyFilter(filterValue: string): Observable<ResidentSearch[]> {

    let propertyManagementCompanyId: number = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    let communityId: number = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    let areaId: number = LocalStorageHelper.getBuildingFromBreadcrumb();
     // this.loading = true;
    
     /* // API
      this.searchService.crossSearch(propertyManagementCompanyId, communityId, filterValue).subscribe(d => {
        this.residentSearch = d;
        this.loading = false;
      }, (error) => {
        console.log(error);
      });*/

    return this.peopleService.getAllByFilter(filterValue);
  }
  displayFn(people?: ResidentSearch): string {
    return people && people.peopleId ? people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
      people.lastName + (people.address ? ' - ' + people.address : '') + (' - ' + people.unit) : '';
  }

  onResidentSelectionChange(people: ResidentSearch) {
    this.loading = true;
    this.getCommunitySetting(people.communityId).subscribe(result => {
      if (!result) {
        SiteHelper.openDialogAlert('Warning',
          'you can not receive a package for this community, please configure the reception of packages for this community',
          this.dialog);
        this.loading = false;
        this.residentSearch = [];
        return;

      }
      if (!this.communitySettings.packagesEnabled) {
        SiteHelper.openDialogAlert('Warning',
          'This community is not enabled to receive packages',
          this.dialog);
        this.residentSearch = [];
        this.loading = false;
        return;
      }
      this.showResidentSearch = false;
      this.selectTedResident = this.displayFn(people);
      //this.input.nativeElement.value = '';
      this.resident = people;
      this.nameResident = people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
        people.lastName;
      this.relationshipId = people.relationshipId;

      this.searchParamsBreadcrumb.unitId = people.unitId;

      this.hotPackageService.getAllPaged(1, 100, null, people.unitId).subscribe(p => {
        this.packageSearch = p;
        if (this.currentPackage.length > 0) {
          this.packageSearch.filter(p => p.hotPackageId == this.currentPackage[0].hotPackageId)[0].checked = true;
        }
        this.loading = false;
      });

      if (this.communitySettings.requireSignature) {
          this.textSave = 'Request Signature';
      }
    });
  }
  getCommunitySetting(communityId: number): Observable<boolean> {
    const flag = new Subject<boolean>();
    forkJoin(this.communitySettingService.getByCommunityId(communityId))
      .subscribe(sett => {
        if (sett.length > 0 && sett[0]) {
          this.communitySettings = sett[0];
          flag.next(true);
        } else {
          flag.next(false);
        }
      });
    return flag.asObservable();
  }

  selectPackage(pack: Package, e) {
    pack.checked = e.checked;
    if (e.checked) {      
      this.currentPackage.push(pack);
    } else {
      this.currentPackage.splice(this.currentPackage.findIndex(p => p === pack), 1);
    }
  }

  selectAll(e) {
    this.packageSearch.forEach(p => {
      p.checked = e.checked;  
    });
  }

  /*onPackageSelectionChange(packages: Package) {
    this.currentPackage = packages;
  }*/
  applyFilterPackage(filterValue: string): Observable<Package[]> {
    return this.hotPackageService.getAllPaged(1, 100, filterValue);
  }
  /**
  * Event handler for the Cancel button.
  */
  cancel() {
    this.router.navigateByUrl('/app/packages');
  }
  save () {
    if (this.communitySettings.requireSignature) {
      this.requestSignature();
    } else {
      this.deliver();
    }
  }
  requestSignature() {
    SiteHelper.openDialogAlert('Info', 'please wait while the signature is captured', this.dialog);
    this.loading = true;
    const signaturePad = new SignaturePad();
    signaturePad.clientToken = LocalStorageHelper.getClientToken();
    signaturePad.title = 'your has received a request signature for delivery package';
    signaturePad.callback = 'sendNotificationPackage';
    signaturePad.callbackPayload = { Message: 'new request signature' };
    this.signaturePAdService.signaturePad(signaturePad).subscribe(data => {
    },
      err => {
        this.dialog.closeAll();
        const error = err.status === 404 ? err.error.errorMessage : '';
        SiteHelper.openDialogAlert('Warning', 'An error ocurred on request signature. ' + error, this.dialog);
        this.loading = false;
      });
    this.firebaseMessagingService.onPackageDeliverySuccess().subscribe(data => {
      this.dialog.closeAll();
      if (data && data.TemporaryFileId !== undefined) {
        this.temporaryFileId = data.TemporaryFileId;
        this.getSignature(this.temporaryFileId);
      }
    });

  }

  deliver() {
   this.loading = true;
   this.packageService.deliver(this.getPayload()).subscribe( x => {
    this.router.navigateByUrl('/app/packages');
   });
  }

  getPayload(): CallbackPayload {
    const callbackPayload = new CallbackPayload();
    callbackPayload.communityId = this.resident.communityId;
    callbackPayload.communityName = this.resident.communityName;
    callbackPayload.residentId = this.resident.relationshipId;
    let hotPackages: number[] = [];
    hotPackages = this.currentPackage.map(p => p.hotPackageId);
    callbackPayload.hotPackages = hotPackages;
    callbackPayload.notes = this.currentPackage[0].notes;
    callbackPayload.recipientName = this.nameResident;
    callbackPayload.sameAsDestinatary = 1;
    callbackPayload.residentName = this.nameResident;
    callbackPayload.temporaryFileId = this.temporaryFileId;
    return callbackPayload;
  }

  getSignature(fileId: number) {
    this.pensiveService.getFile(fileId).subscribe(pic => {
      const reader = new FileReader();
      const blob = new Blob([new Uint8Array(pic)], { type: 'image/png' });
      if (blob.size > 0) {
        const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
        reader.onload = (event: any) => {
          this.signature = event.target.result;
          this.loading = false;
        };
        reader.readAsDataURL(file);
      }
    });
  }
  done() {
    this.deliver();
  }
}

