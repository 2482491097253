import { PricePlanRelationshipFilter } from '../model/price-plan-relationship-filter';
import { PricePlanRelationshipTypeFilter } from './price-plan-relationshipType-filter';
import { PricePlanCommunityFilter } from './price-plan-community-filter';

export class PricePlan {
    pricePlanId: number;
    name: string;
    isActive: boolean;
    isDeleted: boolean;
    communities: PricePlanCommunityFilter[];
    relationShipTypes: PricePlanRelationshipTypeFilter[];
    relationShips: PricePlanRelationshipFilter[];
    before: number;
    after: number;
}
