import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

// Services
import { TitleService } from '../../services/title.service';
import { PeopleService } from '../../services/people.service';
import { EmailService } from '../../services/email.service';
import { EmailAddressTypeService } from '../../services/email-address-type.service';
import { AddressService } from '../../services/address.service';
import { AddressTypeService } from '../../services/address-type.service';
import { StateService } from '../../services/state.service';
import { CityService } from '../../services/city.service';
import { ZipCodeService } from '../../services/zip-code.service';
import { UserService } from '../../services/user.service';

// Models
import { People } from '../../model/people';
import { Title } from '../../model/title';
import { AddressTableEntry } from '../../model/address-table-entry';
import { AddressType } from '../../model/address-type';
import { EmailAddressType } from '../../model/email-address-type';
import { Email } from '../../model/email';
import { PhoneNumberService } from '../../services/phone-number.service';
import { PhoneTypeService } from '../../services/phone-type.service';
import { PhoneNumber } from '../../model/phone-number';
import { PhoneType } from '../../model/phone-type';
import { State } from '../../model/state';
import { City } from '../../model/city';
import { ZipCode } from '../../model/zip-code';
import { AlertDialogComponent } from '../../app-dialogs/alert-dialog/alert-dialog.component';
import { ValidatorHelper } from 'src/app/helpers/validator-helper';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { AddAddressDialogComponent } from 'src/app/app-dialogs/add-address-dialog/add-address-dialog.component';
import { AddEmailDialogComponent } from 'src/app/app-dialogs/add-email-dialog/add-email-dialog.component';
import { AddPhoneDialogComponent } from 'src/app/app-dialogs/add-phone-dialog/add-phone-dialog.component';

const enum DataType {
  Email = 1,
  Phone = 2,
  Address = 3
}


@Component({
  selector: 'app-people-edit',
  templateUrl: './people-edit.component.html',
  styleUrls: ['./people-edit.component.scss']
})
export class PeopleEditComponent implements OnInit {

  public model: People;
  public titles: Title[] = [];
  public emailRelationship: Email;
  public phoneRelationship: PhoneNumber;
  public addressRelationship: AddressTableEntry;

  public emails: Email[] = [];
  public emailAddressTypes: EmailAddressType[] = [];
  public phones: PhoneNumber[] = [];
  public phoneTypes: PhoneType[];
  public addresses: AddressTableEntry[] = [];
  public addressTypes: AddressType[];
  public addressModel: AddressTableEntry;
  public states: State[];
  public cities: City[];
  public zipCodes: Observable<ZipCode[]>;
  public allZips: ZipCode[];
  private peopleId: number;
  public loading: boolean;
  public unitAddress = -1;
  peopleForm: FormGroup;
  private sub: Subscription;
  private entity: string;

  public MESSAGE_ERROR_ZIPCODE = 'Unable to add address, city or state does not belong to that zip code';
  public TITLE_WARNING = 'Warning';
  public MESSAGE_ZIPCODE_NOT_EXISTS = 'zip code does not exist';
  public LENGTH_ZIPCODE = 5;

  public selected = -1;
  public selectedPhone = -1;
  private isDialog = false;

  private emailClone: any;
  private emailClones: Email[] = [];
  private phoneClone: any;
  private phoneClones: PhoneNumber[] = [];
  private addressClone: any;
  private addressClones: AddressTableEntry[] = [];

  phonemask: (string | RegExp)[];
  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private peopleService: PeopleService,
    private titleService: TitleService,
    private emailService: EmailService,
    private emailAddressTypeService: EmailAddressTypeService,
    private phoneNumberService: PhoneNumberService,
    private phoneTypeService: PhoneTypeService,
    private addressService: AddressService,
    private addressTypeService: AddressTypeService,
    private stateService: StateService,
    private cityService: CityService,
    private zipCodeService: ZipCodeService,
    private userService: UserService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PeopleEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: MatSnackBar
  ) {
    this.loading = true;
    this.model = new People(this.fb);
    this.peopleForm = this.model.buildFormGroup();
    this.peopleForm.removeControl('address');
    this.peopleForm.removeControl('emails');
    this.peopleForm.removeControl('phones');
    this.phonemask = ValidatorHelper.phonemask();
  }

  ngOnInit() {
    Validators.required = ValidatorHelper.required;
    this.sub = this.route
      .data
      .subscribe(v => this.entity = v.origin);

    this.sub = this.route.paramMap.subscribe(
      params => {
        this.peopleId = +params.get('id');
        this.isDialog = this.peopleId === 0 && this.data && this.data.isDialog;
        const phoneTypesObservable = this.phoneTypeService.getAll();
        const emailAddressTypesObservable = this.emailAddressTypeService.getAll();
        const addressTypesObservable = this.addressTypeService.getAll();
        const citiesObservable = this.cityService.getByPeopleId(this.peopleId);
        const titlesObservable = this.titleService.getAll();
        const statesObservable = this.stateService.getAll();
        forkJoin(
          phoneTypesObservable,
          emailAddressTypesObservable,
          addressTypesObservable,
          citiesObservable,
          titlesObservable,
          statesObservable)
          .subscribe(results => {
            this.phoneTypes = results[0];
            this.emailAddressTypes = results[1];
            this.addressTypes = results[2];
            this.cities = results[3];
            this.titles = results[4];
            this.states = results[5];
            this.initPeople();
          });
      });
  }


  initPeople(): void {
    if (this.peopleId) {
      this.model.peopleId = this.peopleId;
      this.peopleService.getById(this.peopleId).subscribe(p => {
        this.model.fromObject(p);
      });

      const a = this.addressService.getAddressesRelatedToPeopleId(this.model.peopleId);
      const c = this.phoneNumberService.getFullByPeopleId(this.model.peopleId);
      const e = this.emailService.getFullByPeopleId(this.model.peopleId);

      forkJoin(a, c, e).subscribe(results => {
        // address
        if (results[0].length > 0) {
          results[0] = this.reorderArrayIsPreferedFirst(results[0]);
          results[0].forEach(add => {
            this.unitAddress = add.cannotChange ? add.addressId : this.unitAddress;
            this.addAddressDetail(add);
          });
        }

        // phone
        if (results[1].length > 0) {
          results[1].forEach((ph, index) => {
            this.addPhoneDetails(ph);
          });
        }

        //email
        if (results[2].length > 0) {
          results[2].forEach((em, index) => {
            this.addEmailDetails(em);
          });
        }
        this.loading = false;
      });
    }
    if (this.peopleId === 0) {
      this.loading = false;
    }
  }

  reorderArrayIsPreferedFirst(array: AddressTableEntry[]): AddressTableEntry[] {
    const returnArray: AddressTableEntry[] = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].isPrefered) {
        returnArray.unshift(array[i]);
      } else {
        returnArray.push(array[i]);
      }
    }
    return returnArray;
  }

  onSave(): void {
    if (this.peopleForm.valid) {
      this.model = this.model.toDto();
      if (this.addresses.length === 0) {
        this.snackbar.open('Error:', 'Address is required', {
          duration: 3000
        });
        return;
      }

      if (this.emails.length === 0) {
        this.snackbar.open('Error:', 'Email is required', {
          duration: 3000
        });
        return;
      }
      if (!this.peopleId) {
        this.addPeople();
      } else {
        this.editPeople();
      }
    }
  }

  addPeople(): void {
    this.peopleService.add(this.model).subscribe(peopleResult => {
      this.phones.forEach((pho) => {
        this.phoneNumberService.addPhoneNumberToPeople(pho, peopleResult.id);
      });
      this.emails.forEach((email) => {
        this.emailService.addEmailToPeople(email, peopleResult.id);
      });
      this.addressService.addBulk(this.addresses).subscribe(addrResult => {
        addrResult.idList.forEach(id => {
          this.addressService.linkAddressToPeople(id, peopleResult.id).subscribe();
        });
      });
      this.onSaveComplete();
    });
  }

  updatePhones(): void {
    this.phones.forEach((pho, index) => {
      if (!pho.phoneNumberId) {
        this.phoneNumberService.addPhoneNumberToPeople(pho, this.model.peopleId);
      } else if (pho.isDeleted) {
        this.phoneNumberService.deletePhoneNumberFromPeople(pho, this.model.peopleId);
      } else {
          this.phoneNumberService.update(pho).subscribe();
      }
    });
  }

  updateEmails(): void {
    this.emails.forEach((email, index) => {
      if (!email.emailId) {
        this.emailService.addEmailToPeople(email, this.model.peopleId);
      } else if (email.isDeleted) {
        this.emailService.deleteEmailFromPeople(email, this.model.peopleId);
      } else {
          this.emailService.update(email).subscribe();
      }
    });
  }

  addNewAddress() {
    this.addresses.forEach((address, index) => {
      if (!address.addressId) {
        this.addressService.add(address).subscribe(result => {
          this.addressService.linkAddressToPeople(result.id, this.model.peopleId).subscribe(() => {
            if (address.isPrefered && this.model.relationshipId) {
              this.addressService.setPreferedAddress(result.id, this.model.peopleId).subscribe();
            }
          });
        });
      } else {
        if (address.addressId === this.unitAddress) {
          if (address.isPrefered && this.model.relationshipId) {
            this.addressService.setPreferedAddress(address.addressId, this.model.peopleId).subscribe();
          }
        } else {
          this.addressService.update(address).subscribe(upd => {
            if (address.isPrefered && this.model.relationshipId) {
              this.addressService.setPreferedAddress(address.addressId, this.model.peopleId).subscribe();
            }
          });
        }
      }
    });
  }

  editPeople(): void {
    if (SiteHelper.isDirty(this.peopleForm)) {
      this.peopleService.update(this.model).subscribe();
    }
    this.updatePhones();
    this.updateEmails();
    this.addNewAddress();
    this.onSaveComplete();
  }

  onSaveComplete(): void {
    if (!this.isDialog) {
      this.peopleForm.reset();
      this.goToList();
    }
  }

  onCancel(): void {
    if (this.isDialog) {
      this.dialogRef.close({
        valid: true,
        peopleId: 0
      });
    } else {
      this.peopleForm.reset();
      this.goToList();
    }
  }

  goToList() {
    if (this.entity === 'residents') {
      this.router.navigateByUrl('/app/community/residents');
    } else {
      this.router.navigateByUrl('/app/people');
    }
  }

  private openAddAddressDialog(addressData: any = null): void {
    const dialogRef = this.dialog.open(AddAddressDialogComponent, {
      data: {
        peopleId: this.peopleId,
        model: addressData
      },
      width: '750px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        // remove the last email based on the email address
        if (result.isEdit) {
          this.addresses = this.addresses.filter(item => item.line1 != result.lastLine1Address);
          this.addressClones = this.addressClones.filter(item => item.line1 != result.lastLine1Address);
        }
        // set all preferred email to false
        if (result.data.isPrefered) {
          this.resetIsPreferredValue(DataType.Address);
        }
        this.addAddressDetail(result.data);
      }
    });
  }

  private addAddressDetail(data: any){
    //object that will be saved on the database
    this.addresses.push(data);
    // object that will be displayed on the html
    this.addressClone = data;
    this.addressClone.addressTypeName = this.determineTypeName(3, data.addressTypeId);
    this.addressClone.addressCityName = this.determineCityName(data.cityId, data.stateId);
    this.addressClone.addressStateName = this.determineStateName(data.stateId);
    this.addressClones.push(this.addressClone);
  }

  private openEmailDialog(emailData: any = null): void {
    const dialogRef = this.dialog.open(AddEmailDialogComponent, {
      data: {
        peopleId: this.peopleId,
        model: emailData
      },
      width: '750px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        // remove the last email based on the email address
        if (result.isEdit) {
          this.emails = this.emails.filter(item => item.address != result.lastEmailAddress);
          this.emailClones = this.emailClones.filter(item => item.address != result.lastEmailAddress);
        }
        // set all preferred email to false
        if (result.data.isPreferred) {
          this.resetIsPreferredValue(DataType.Email);
        }
        this.addEmailDetails(result.data);
      }
    });
  }

  private addEmailDetails(data: any) {
    //object that will be saved on the database
    this.emails.push(data);
    // object that will be displayed on the html
    this.emailClone = data;
    this.emailClone.emailAddressTypeName = this.determineTypeName(1, data.emailAddressTypeId);
    this.emailClones.push(this.emailClone);
  }

  private openPhoneDialog(phoneData: any = null): void {
    const dialogRef = this.dialog.open(AddPhoneDialogComponent, {
      data: {
        peopleId: this.peopleId,
        model: phoneData
      },
      width: '750px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        // remove the last email based on the email address
        if (result.isEdit) {
          this.phones = this.phones.filter(item => item.number != result.lastPhoneNumber);
          this.phoneClones = this.phoneClones.filter(item => item.number != result.lastPhoneNumber);
        }
        // set all preferred email to false
        if (result.data.isPreferred) {
          this.resetIsPreferredValue(DataType.Phone);
        }
        this.addPhoneDetails(result.data);
      }
    });
  }

  private addPhoneDetails(data: any) {
    //object that will be saved on the database
    this.phones.push(data);
    // object that will be displayed on the html
    this.phoneClone = data;
    this.phoneClone.phoneNumberTypeName = this.determineTypeName(2, data.phoneTypeId);
    this.phoneClones.push(this.phoneClone);

  }

  private determineCityName(cityId: number, stateId: number): string {
    let cityName: string = '';
    this.cities.map(item => {
      if (item && item.cityId == cityId && item.stateId == stateId) {
        cityName = item.name;
      }
    });
    return cityName;
  }

  private determineStateName(stateId: number): string {
    let stateName: string = '';
    this.states.map(item => {
      if (item && item.stateId == stateId) {
        stateName = item.name;
      }
    });
    return stateName;
  }

  private determineTypeName(dataType: DataType, typeData: number): string {
    let typeName: string = '';
    switch (dataType) {
      case 1:
        this.emailAddressTypes.map(item => {
          if (item && item.emailAddressTypeId == typeData) {
            typeName = item.name + ' Email';
          }
        });
        break;
      case 2:
        this.phoneTypes.map(item => {
          if (item && item.phoneTypeId == typeData) {
            typeName = item.name + ' Phone Number';
          }
        })
        break;
      case 3:
        this.addressTypes.map(item => {
          if (item && item.addressTypeId == typeData) {
            typeName = item.name + ' Address';
          }
        })
        break;
    }
    return typeName;
  }

  private edit(dataType: DataType, data: any) {
    switch (dataType) {
      case 1:
        this.openEmailDialog(data);
        break;
      case 2:
        this.openPhoneDialog(data);
        break;
      case 3:
        this.openAddAddressDialog(data);
        break;
      default:
        this.openEmailDialog(data);
        break;
    }
  }

  private setAsPreferred(dataType: DataType, data: any) {
    switch (dataType) {
      case 1:
        this.resetIsPreferredValue(dataType);
        this.emails.map(item => {
          if (item.address == data.address) {
            item.isPreferred = true;
          }
        });
        this.emailClones.map(item => {
          if (item.address == data.address) {
            item.isPreferred = true;
          }
        });
        break;
      case 2:
        this.resetIsPreferredValue(dataType);
        this.phones.map(item => {
          if (item.number == data.number) {
            item.isPreferred = true;
          }
        });
        this.phoneClones.map(item => {
          if (item.number == data.number) {
            item.isPreferred = true;
          }
        });
        break;
      case 3:
        this.resetIsPreferredValue(dataType);
        this.addresses.map(item => {
          if (item.line1 == data.line1) {
            item.isPrefered = true;
          }
        });
        this.addressClones.map(item => {
          if (item.line1 == data.line1) {
            item.isPrefered = true;
          }
        });
        break;
    }
  }

  private delete(dataType: DataType, data: any) {
    switch (dataType) {
      case 1:
        if (data.emailId) {
          this.emails.map(item => {
            if (item.emailId == data.emailId) {
              item.isDeleted = true;
            }
          });
          this.emailClones = this.emailClones.filter(item => item.address !== data.address);
        }else {
          this.emails = this.emails.filter(item => item.address !== data.address);
          this.emailClones = this.emailClones.filter(item => item.address !== data.address);  
        }
        break;
      case 2:
        if (data.phoneNumberId) {
          this.phones.map(item => {
            if (item.phoneNumberId == data.phoneNumberId) {
              item.isDeleted = true;
            }
          });
          this.phoneClones = this.phoneClones.filter(item => item.number !== data.number);
        }else {
          this.phones = this.phones.filter(item => item.number !== data.number);
          this.phoneClones = this.phoneClones.filter(item => item.number !== data.number);
        }
        break;
      case 3:
        if (data.addressId) {
          this.addresses.map(item => {
            if (item && item.addressId == data.addressId) {
              if (item.addressId !== this.unitAddress) {
                item.isDeleted = true;
                this.addressService.softDelete(item.addressId).subscribe(a => {
                  this.addressService.unlinkAddressFromPeople(item.addressId, this.model.peopleId).subscribe();
                });
              }else {
                confirm('Address from Unit cannot be change or deleted.');
              }
            }
          });
          this.addressClones = this.addressClones.filter(item => item.line1 !== data.line1);
        }else {
          this.addresses = this.addresses.filter(item => item.line1 !== data.line1);
          this.addressClones = this.addressClones.filter(item => item.line1 !== data.line1);
        }
        break;
    }
  }

  private resetIsPreferredValue(dataType: DataType) {
    switch (dataType) {
      case 1:
        this.emails.map(item => item.isPreferred = false);
        this.emailClones.map(item => item.isPreferred = false);
        break;
      case 2:
        this.phones.map(item => item.isPreferred = false);
        this.phoneClones.map(item => item.isPreferred = false);
        break;
      case 3:
        this.addresses.map(item => item.isPrefered = false);
        this.addressClones.map(item => item.isPrefered = false);
        break;
    }
  }
}
