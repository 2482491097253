import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class Tag extends BaseForm {

    /**
     * The ID of the tag in the database.
     */
    tagId = 0;

    /**
     * The name of the tag.
     */
    @FormField('name', ['', Validators.required])
    name = '';

    /**
     * True, if the tag is active within the system.
     */
    @FormField('isActive', [ true ])
    isActive = true;

    /**
     * The creation date of this record.
     */
    creationDate: Date = null;

    /**
     * The ID of the user that created this record.
     */
    creationUserId = 0;

    /**
     * The modification date of this record.
     */
    lastModification: Date = null;

    /**
     * The ID of the user that last modified this record.
     */
    modificationUserId = 0;
}
