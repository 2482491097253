import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { LocationOffice } from '../model/locationOffice';


@Injectable({
  providedIn: 'root'
})
export class LocationService extends AbstractDataProviderService<LocationOffice> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/locations', http, appConfig);
  }

  getLocationByCompany(companyId): Observable<LocationOffice[]> {
    return this.http.get<LocationOffice[]>(this.baseUrl + '/?companyId=' + companyId, { headers: this.buildGetHeaders() });
  }

  getLocationByLocationId(locationId: number): Observable<LocationOffice>  {
    return this.http.get<LocationOffice>(this.baseUrl + '/' + locationId, { headers: this.buildGetHeaders() });
  }

  softDelete(locationId: number) : Observable<any> {
    let body = {
      modificationOriginId: 2
    }
    return this.http.post<LocationOffice>(this.baseUrl + '/' + locationId + '/softDelete', body, { headers: this.buildGetHeaders() });
  }
  addIcon(entity: FormData): Observable<boolean> {
    entity['originId'] = 2;
    return this.http.post<boolean>(this.baseUrl + '/linkFile' , entity, { headers: this.buildGetHeaders() });
  }
}
