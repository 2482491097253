import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { ErrorResponse } from '../model/error-response';
import { LoginObject } from '../model/login-object';
import { ApiResponse } from '../model/api-response';
import { ServiceError } from '../model/service-error';
import { UserData } from '../model/user-data';

import { FirebaseMessagingService } from '../services/firebase-messaging.service';

import { default as decode } from 'jwt-decode';
import { Claim } from '../model/claim';
import { LocalStorageHelper } from '../helpers/local-storage-helper';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public token: string;
  private sortingHatEndpoint: string;
  private loginSuccessfulEvent: EventEmitter<any>;

  constructor(
    private http: HttpClient,
     @Inject(APP_CONFIG) protected _appConfig: AppConfig,
     private firebaseMessaging: FirebaseMessagingService
     ) {
    // const currentUser = ...
    this.token = '';
    this.loginSuccessfulEvent = new EventEmitter<any>();
    this.sortingHatEndpoint = this._appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/sortingHat';
  }

  private userDataFromLoginObject(loginObject: LoginObject): UserData {
    this.token = loginObject.token;
    const decodedToken = decode(this.token);

    const userData = new UserData();
    userData.token = loginObject.token;
    userData.refreshToken = loginObject.refreshToken;
    userData.username = decodedToken.username;
    userData.firstName = loginObject.firstName;
    userData.lastName = loginObject.lastName;

    return userData;
  }

  login(username: string, password: string): Observable<boolean | ServiceError> {
    return this.http.post(this.sortingHatEndpoint + '/login/', {
      'Username': username,
      'Password': password
    }).pipe(map((response: LoginObject) => {
      const result = response !== null && response.hasOwnProperty('token') && response.token !== null;

      if (result) {
        const userData = this.userDataFromLoginObject(response);
        localStorage.setItem('GridSystemsUserData', JSON.stringify(userData));
      }

      return result;
    }), catchError(this.handleError));
  }

  getClaims(): Observable<Claim[]> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'bearer ' + this.token);

    return this.http.get<Claim[]>(this.sortingHatEndpoint + '/user/claims', { headers: headers });
  }

  refreshToken(token: string): Observable<boolean | ServiceError> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'bearer ' + token);

    return this.http.get(this.sortingHatEndpoint + '/refreshToken', {
      headers: headers
    }).pipe(map((response: LoginObject) => {
      const result = response !== null && response.hasOwnProperty('token') && response.token !== null;

      if (result) {
        const userData = this.userDataFromLoginObject(response);
        localStorage.setItem('GridSystemsUserData', JSON.stringify(userData));
      }

      return result;
    }), catchError(this.handleError));
  }

  resetPasswordByAdmin(username: string): Observable<ApiResponse | ServiceError> {
    return this.http.post(`${this.sortingHatEndpoint}/reset-password`,
      {
        'Username': username
      }
    ).pipe(map((response: ApiResponse) => {
      return response;
    }), catchError(this.handleError));
  }

  resetPassword(username: string, vCode: string, newPassword: string, oldPassword: string): Observable<ApiResponse | ServiceError> {
    return this.http.post(`${this.sortingHatEndpoint}/confirm-forgot-password`, {
      'username': username,
      'vCode': vCode,
      'newPassword': newPassword,
      'oldPassword': oldPassword
    }).pipe(map((response: ApiResponse) => {
      return response;
    }), catchError(this.handleError));
  }

  forgotPassword(username: string): Observable<ApiResponse | ServiceError> {
    return this.http.post(`${this.sortingHatEndpoint}/forgot-password`, {
      'Username': username
    }).pipe(map((response: ApiResponse) => {
      return response;
    }), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<ServiceError> {
    const serviceError = new ServiceError();
    serviceError.errorInfo = new ErrorResponse();
    this.token = error.headers.get('Authorization');
    if (error instanceof ErrorEvent) {

    } else {
      if (error.error !== null) {
        serviceError.errorInfo.code = error.error.code;
        serviceError.errorInfo.description = error.error.description;
      } else {
        serviceError.errorInfo.description = 'Description unavailable.';
      }
      serviceError.errorInfo.statusCode = error.status;
      serviceError.token = this.token;
    }

    return throwError(serviceError);
  }

  logout(): void {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'bearer ' + this.token);
    const finish = x => {
      this.token = null;
      localStorage.removeItem('GridSystemsUserData');
      localStorage.removeItem('PaypointInfo');     
      localStorage.removeItem('ImpersonatedFlag');
      LocalStorageHelper.setLoggedUserInfo(null, false);
      LocalStorageHelper.setLoggedUserInfo(null, true); 
      this.http.post(this.sortingHatEndpoint + '/logout/', null, {headers: headers}),catchError(this.handleError);
    };
    this.firebaseMessaging.unregisterDeviceToUser().then(finish).catch(finish);
  }

  loginSuccessful() {
    this.loginSuccessfulEvent.emit(null);
  }

  subscribeToLoginSuccessful(callback: any) {
    this.loginSuccessfulEvent.subscribe(callback);
  }
}
