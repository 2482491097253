import {Component, OnInit, ViewChild, OnDestroy, Input} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, FormGroup} from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Area } from 'src/app/model/area';
import { Community } from 'src/app/model/community';
import { CommunityService } from 'src/app/services/community.service';
import { AreaService } from 'src/app/services/area.service';
import { UnitService } from 'src/app/services/unit.service';
import { SearchParamsBreadcrumb } from 'src/app/model/search-params-breadcrumb';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { Unit } from 'src/app/model/unit';
import { PaymentInfo } from 'src/app/model/paymentInfo';
import { AccountService } from 'src/app/services/account.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { TransactionsService } from 'src/app/services/transactions.service';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { Transaction } from 'src/app/model/transaction';
import { BalanceAccount } from 'src/app/model/account';
import { AccessType } from 'src/app/model/access-type-ring';
import { Router } from '@angular/router';
import { PaymentDialogComponent } from 'src/app/app-dialogs/payment-dialog/payment-dialog.component';
import { PaymentConfirmationComponent } from 'src/app/app-dialogs/payment-confirmation/payment-confirmation.component';

@Component({
  selector: 'app-transactions2',
  templateUrl: './transactions2.component.html',
  styleUrls: ['./transactions2.component.css']
})
export class Transactions2Component extends BaseSecurity implements OnInit, OnDestroy {

  /* TODO: replace the comun array with the columns definitions */
  @Input() displayedColumns: string[] = [ 'date', 'description','transType',
  'amount'];

  @Input() dataSource: MatTableDataSource<any>;
  @Input() items: any[] = [];
  @Input() itemsOrigin: any[] = [];
  /*TODO: replace the title*/
  @Input() title = 'Units';
  
  
  filterComboName: string = '';
  toppings = new FormControl();
  viewFilter: boolean = false;

  /** control for the MatSelect filter keyword multi-selection */
  public typeMultiFilterCtrl: FormControl = new FormControl();
  public descMultiFilterCtrl: FormControl = new FormControl();

  //toppingList: string[] = [];
  //toppingListOrigin: string[] = [];
  selectedNames: string[] = [];

  private loadComplete$: BehaviorSubject<boolean>;

  public loading: boolean;

  

   /** list of banks filtered by search keyword */
   public filteredTypesMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
   public filteredDescsMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
   
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  /** Subject that emits when the component has been destroyed. */

  //@ViewChild('selectActive') selectActive: MatSelect;

  protected _onDestroy = new Subject<void>();
  
  /* start - specific attributes/properties */

  private counterArray: number[] = [];

  private paymentInfo: PaymentInfo[];
  public accounts: number[] = [];
  public accountsExte: string[] = [];
  public balances: number[] = [];
  types: string[] = [];
  descriptions: string[] = [];
  typesOrigin: string[] = [];
  descriptionsOrigin: string[] = [];
  public filterFormGroup: FormGroup;
  /**** */
  
  public areas: Area[] = [];
  public communities: Community[] = [];
  protected areaSelected = 0;
  public communityIdSelected = 0;
  protected managementCompanyIdSelected = 0;
  protected communityId = 0;
  public unitSelected = 0;
  public searchParamsBreadcrumb: SearchParamsBreadcrumb = new SearchParamsBreadcrumb();
  unitId: number;
/* end - specific attributes/properties */

  constructor(private accountService: AccountService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private transactionService: TransactionsService,
    private route: Router) {
      super();

  }


  async fillDataSource() {
    
    let result = await this.loadItems();
    this.setBreadcrumbParams(null, null, null);
   
    
    this.items = result.filter(p => p.isDeleted !== true);
    this.itemsOrigin = result;
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.items);
    
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    
    /* TODO: replace the areaName by the column name to show in filter combo */
   
    // listen for search field value changes
    this.typeMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterTypesMulti();
    });

    this.descMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterDescsMulti();
    });
    

    //this.selectActive.value = '2';
    this.paginator._intl.itemsPerPageLabel = 'Show:';
    this.paginator.showFirstLastButtons = true;

   
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  ngOnInit() {
    this.filterFormGroup = new FormGroup({
      filterDate: new FormControl(''),
      filterDescription: new FormControl(''),
      filterType: new FormControl('')
    });
    this.fillDataSource();
    
    
  }

  toggleFilters(){
    this.viewFilter = !this.viewFilter;
  }

  setBreadcrumbParams(pmcId?: number, communityId?: number, areaId?: number ) {
    this.searchParamsBreadcrumb.propertyManagementCompanyId = pmcId ? pmcId : LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    this.searchParamsBreadcrumb.communityId = communityId ? communityId : LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.searchParamsBreadcrumb.areaId = areaId ? areaId : LocalStorageHelper.getBuildingFromBreadcrumb();
    this.searchParamsBreadcrumb.unitId =  LocalStorageHelper.getUnitIdFromBreadcrumb();
    this.searchParamsBreadcrumb.AccountId = LocalStorageHelper.getAccountIdFromBreadcrumb();
  }
  
  filterTypesMulti() {
    if (!this.types) {
      return;
    }
    // get the search keyword
    let search = this.typeMultiFilterCtrl.value;
    if (!search) {
      this.types = this.typesOrigin;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    let filterr = this.typesOrigin.filter(p => p.toLowerCase().indexOf(search) > -1);
    this.types = filterr;
  }

  filterDescsMulti() {
    if (!this.descriptions) {
      return;
    }
    // get the search keyword
    let search = this.descMultiFilterCtrl.value;
    if (!search) {
      this.descriptions = this.descriptionsOrigin;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    let filterr = this.descriptionsOrigin.filter(p => p.toLowerCase().indexOf(search) > -1);
    this.descriptions = filterr;
  }
  /*
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
*/
  filter(data) {
    
      let dataf: any[] = [];
      this.items = this.itemsOrigin;
      if(data) {
      if(data && data.value.length > 0) {

        for (let i = 0; i <= data.value.length ; i++) {
          /* TODO: replace the areaName by the column name to filter */
          let resf = this.items.filter(q=>q.areaName === data.value[i]);
          if(resf && resf.length > 0){
            resf.forEach(element => {
              dataf.push(element);
            });
            
          }
          
        }
        
        this.items = dataf;
      
      }
      this.dataSource = new MatTableDataSource(this.items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      /*
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }

      console.log(data);
      */
    }
  }
/*
  changedActive(e: any){
    this.selectedNames = [];
    this.items = this.itemsOrigin;
    if(this.selectActive.value === '2') {
      this.items = this.itemsOrigin.filter(p=>p.isDeleted !== true);
    } else if ( this.selectActive.value === '3') {
      this.items = this.itemsOrigin.filter(p=>p.isDeleted === true);
    }
    this.dataSource = new MatTableDataSource(this.items);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.dataSource.paginator) {
     this.dataSource.paginator.firstPage();
   }

  }
  */
  clear(){
    
     this.selectedNames = [];
     this.items = this.itemsOrigin;
     this.dataSource = new MatTableDataSource(this.items);
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
     if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
     /*
    for(let i = 0; i < 10; i++) {
    let user1 = new UserData();
    user1.checked=true;
    user1.name='Luis' + i;
    this.users.push(user1);
    }
   this.selectedUsers = []
   */
  }

  deleteFilter(name: string) {
    this.selectedNames = this.selectedNames.filter(p=>p !== name);
    
    let dataf: any[] = [];
    this.items = this.itemsOrigin;
      
    if(this.selectedNames && this.selectedNames.length > 0) {

        for (let i = 0; i <= this.selectedNames.length ; i++) {

          /* TODO: replace the areaName by the column name to filter */
          let resf = this.items.filter(q=>q.areaName === this.selectedNames[i]);
          if(resf && resf.length>0){
            resf.forEach(element => {
              dataf.push(element);
            });
            
          }
          
        }
        
        this.items = dataf;
      
      }
    this.dataSource = new MatTableDataSource(this.items);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      
  }
}

/* TODO: add behavior to these funtions */
addItem() {

}

editItem() {

}

exportItem(){

}

searchItem(){


}


/* TODO: rewrite this method to load the items to fill the datasource */
async loadItems(date?: Date, type?: string, description?: string): Promise<any[]> {

  let result: Transaction[] = [];
  this.loadComplete$ = new BehaviorSubject(false);
    this.loading = true;

    this.unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
    this.paymentInfo = LocalStorageHelper.getPaymentInfo().filter(x => (x.accountExternalCode));
    if (this.paymentInfo.length > 0 || this.unitId > 0) {
      const  account = LocalStorageHelper.getAccountIdFromBreadcrumb();
      const userInfo = LocalStorageHelper.getLoggedUserInfo(false);
      this.accounts = Array.from(new Set(userInfo.residents.map(accountNo => accountNo.accountId)));
      this.accountsExte = Array.from(new Set(userInfo.residents.map(accountNo => accountNo.externalAccountNumber)));
      
      //this.accounts.forEach(async (acc, index) => {
        for(let i = 0; i < this.accounts.length; i++) {
        if (account === 0 || account === this.accounts[i]) {
        this.loading = true;

          let trans = await this.transactionService.getAllFilteredPaged(1, 1000000, this.accounts[i], date, type, description)
          .toPromise().then();
          
          if (trans && trans.length > 0) {
            
            trans.forEach(t => {
              result.push(t);
              const det = t as any;
              if (det && det.transType && det.transType !== '') {
                const typeFiltered = this.types.filter(ty => ty === det.transType);
                  if (!(typeFiltered && typeFiltered.length  > 0)) {
                    this.types.push(det.transType);
                    this.typesOrigin.push(det.transType);
                  }
              }

              if (det && det.description && det.description !== '') {
                const descFiltered = this.descriptions.filter(ty => ty === det.description);
                  if (!(descFiltered && descFiltered.length  > 0)) {
                    this.descriptions.push(det.description);
                    this.descriptionsOrigin.push(det.description);
                  }
              }
            });
          }
          let balncs = await this.accountService.getBalances().toPromise().then();
          this.balances = balncs.map(balances => this.balance(balances));
          this.loadComplete();

          
        }
        } //account if
      //});
    } else {
      this.loadComplete();
    }

    return result;

}

private loadComplete() {
  this.loadComplete$.complete();
  this.loading = false;
}

private balance(balance: BalanceAccount) {
  if (balance) {
    return balance.amount;
  } else { return 0; }
}

protected loadSecurityRings() {
  this.securityEntity.rings.push({ringId : 7012, accessType: AccessType.Add });
  this.securityEntity.rings.push({ringId : 7013, accessType: AccessType.Execute });
  this.entityRings.push(this.securityEntity);
}

async applyFilter() {
let dateF = this.filterFormGroup.controls['filterDate'].value;
  let descF = this.filterFormGroup.controls['filterDescription'].value;
  let typeF = this.filterFormGroup.controls['filterType'].value;
  let dateff = new Date(dateF);
  let pDate = null;
  //let pType = null;
  //let pDesc = null:
  if(dateff && dateff !== undefined && dateff !== null && dateff.getUTCFullYear() > 1970) {
    pDate = dateff;
  }
  console.log(pDate + '-' + descF + '-' + typeF);

  //this.loadItems(pDate, typeF, descF);

  let result = await this.loadItems(pDate, typeF, descF);
  this.setBreadcrumbParams(null, null, null);
  
  
  this.items = result.filter(p => p.isDeleted !== true);
  this.itemsOrigin = result;
  // Assign the data to the data source for the table to render
  this.dataSource = new MatTableDataSource(this.items);
  
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
  this.selectedNames = [];
  if(dateff && dateff.getUTCFullYear() > 1970) {
    this.selectedNames.push((dateff.getUTCMonth() + 1).toString().padStart(2,'0') + '/' 
    + dateff.getUTCDate().toString().padStart(2, '0') + '/' + dateff.getUTCFullYear().toString());     
  }
  if(descF && descF.length > 0) {
    descF.forEach(x => {
      
      this.selectedNames.push(x);
    });
  }

  if(typeF && typeF.length > 0) {
    typeF.forEach(x => {
      this.selectedNames.push(x);
    });
  }

  this.toggleFilters();
}

resetFilters() {
  this.filterFormGroup.controls['filterDate'].setValue(null);
  this.filterFormGroup.controls['filterDescription'].setValue(null);
  this.filterFormGroup.controls['filterType'].setValue(null);
  this.selectedNames = [];
  this.applyFilter();
}

  openDetail(row: any) {
    var roww = row;
    this.route.navigate(['/app/profile/transaction', row.code]);
  }

  openPayment(account: string) {
    let data;
    data = {
      accounts: [
        account
      ]
    };
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const dialogConfirmation = this.dialog.open(PaymentConfirmationComponent, {
          data: result
        });
        dialogConfirmation.afterClosed().subscribe(results => {

        });
      }
    });
  }
}


