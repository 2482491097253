import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { People } from '../model/people';
import { AbstractDataProviderService } from './abstract-data-provider.service';

import * as AppConstants from '../app-constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { PictureService } from './picture.service';
import { PaymentInfo } from '../model/paymentInfo';
import { ResidentSearch, ResidentInfoPackage } from '../model/resident-search';
import { LevelFilter } from '../model/breadcrumb';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PeopleService extends AbstractDataProviderService<People> {

  protected baseUrlPicture = '/picture';
  private userInfo = new BehaviorSubject(LocalStorageHelper.getUserDisplayName());
  currentUser = this.userInfo.asObservable();
  private userPicture = new BehaviorSubject('');
  currentUserPicture = this.userPicture.asObservable();

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig, private pictureService: PictureService) {
    super(AppConstants.EndpointNames.GridVendor, '/people', http, appConfig, LevelFilter.Unit);
  }

  getByRelationshipIdArray(relationshipArray: number[]) : Observable<ResidentInfoPackage[]>{
    let relationshipIdArray = 'relationshipId=';

    relationshipArray.forEach(a => {
            relationshipIdArray += a.toString() + ",";            
    });
    const queryString = '/byRelationshipIdArray/?' + relationshipIdArray;
    return this.http.get<ResidentInfoPackage[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
  getAllPaginatedFiltered(pageIndex: number, pageSize: number, filter: string): Observable<People[]> {
    const queryString = '/allPagedFiltered/' + pageIndex.toString() + '/' + pageSize.toString() + '/' + filter;
    return this.http.get<People[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getAllPaginated(pageIndex: number, pageSize: number, filter?: string, sortData?: string, firstName?: string, lastName?: string, displayName?: string, email?: string): Observable<People[]> {
    const route = this.filterLevel ? '/allPageByBreadcrumb/' : '/allPaged/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString() 
     + (filter ? '/' + filter : '');

    if (this.filterLevel && this.filterLevel >= LevelFilter.PropertyManagementCompany) {
      queryString = queryString + '?propertyManagementCompanyId=' +  this.searchParamsBreadcrumb.propertyManagementCompanyId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Community) {
      queryString = queryString + '&communityId=' +  this.searchParamsBreadcrumb.communityId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Area) {
      queryString = queryString + '&areaId=' +  this.searchParamsBreadcrumb.areaId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Unit) {
      queryString = queryString + '&unitId=' +  this.searchParamsBreadcrumb.unitId;
    }

    if (sortData) {
      const sortCol = sortData.split('|');
      if (sortCol[1] !== '') {
        queryString = queryString + '&sort=' + sortCol[0];
        queryString = (queryString + '&dir=') + (sortCol[1] !== '' ? sortCol[1] : 'asc');
      }
    }

    if (firstName) {
        queryString = queryString + '&firstName=' + firstName;      
    }

    if (lastName) {
      queryString = queryString + '&lastName=' + lastName;      
    }

    if (displayName) {
      queryString = queryString + '&displayName=' + displayName;      
    }

    if (email) {
      queryString = queryString + '&email=' + encodeURIComponent(email);      
    }
    return this.http.get<People[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCount(filter?: string, firstName?: string, lastName?: string, displayName?: string, email?: string): Observable<number> {
    if (this.filterLevel === LevelFilter.None || (!lastName && !firstName && displayName && email)) {
    return this.http.get<CountResultModel>(this.baseUrl + '/count' , { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
    } else {
        return this.getCountByBreadcumd(filter, firstName, lastName, displayName, email);
    }
  }

  getCountByBreadcumd(filter?: string, firstName?: string, lastName?: string, displayName?: string, email?: string): Observable<number> {
    const endpoint = (filter || firstName || lastName || displayName || email)  ? '/getCountByParamsFiltered/' : '/getCountByParams/';
      let queryString = endpoint + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/'
       + this.searchParamsBreadcrumb.communityId + '/' + this.searchParamsBreadcrumb.areaId
      + '/' + this.searchParamsBreadcrumb.unitId + (filter ? '/' + filter : '');
    let first = true;
    if (firstName) {
        queryString = queryString + (first ? '?' : '&') + 'firstName=' + firstName;   
        first = false;
    }

    if (lastName) {
      queryString = queryString + (first ? '?' : '&') + 'lastName=' + lastName;
      first = false; 
    }

    if (displayName) {
      queryString = queryString + (first ? '?' : '&') + 'displayName=' + displayName;
      first = false;
    }

    if (email) {
      queryString = queryString + (first ? '?' : '&') + 'email=' + encodeURIComponent(email);
    }

    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  GetForCurrentUser(): Observable<People> {
    return this.http.get<People>(this.baseUrl + '/forUser', { headers: this.buildGetHeaders() });
  }
  GetForUser(userId: number): Observable<People> {
    return this.http.get<People>(this.baseUrl + '/forUser/' + userId, { headers: this.buildGetHeaders() });
  }
  GetProfilePicture(impersonated?: boolean): Observable<any> {
    if (impersonated) {
      return this.pictureService.GetProfilePictureImpersonated();
    }
    return this.pictureService.GetProfilePicture();
  }
  SetProfilePicture(picture: Uint8Array): Observable<null> {
    return this.pictureService.SetProfilePicture(picture);
  }

 setUserPicture() {
    this.GetProfilePicture().subscribe( pic => {
      const reader =  new FileReader();
      const blob = new Blob( [ new Uint8Array(pic) ], { type: 'image/jpeg' } );
      if (blob.size > 0) {
      const file = new File([blob], 'xxx', {type: 'image/jpeg', lastModified: Date.now()});
      reader.onload = (event: any) => {
        this._setUserPicture(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      this._setUserPicture(null);
    }
  });
  }


  setUserInfo(people: People): void {
    const displayName = people.firstName + ' ' + people.lastName;
    LocalStorageHelper.setUserDisplayName(displayName);
    this.userInfo.next(displayName);
  }
  getUserInfo(): Observable<string> {
    if (this.userInfo.value == null) {
      this.userInfo.next(LocalStorageHelper.getUserDisplayName());
    }
    return this.userInfo;
  }
  _setUserPicture(src: string): void {
    this.userPicture.next(src);
  }

  getPaymentInfo(username: string): Observable<PaymentInfo[]> {
    const queryString = '/paymentInfo/' + username;
    return this.http.get<PaymentInfo[]>(this.baseUrl + queryString, { headers: this.buildPostHeaders() });
  }


  getResidentsAllByParamsPaginated(pageIndex: number, pageSize: number,
    propertyManagementCompanyId: number, communityId: number, areaId: number, unitId: number,
    filter: string): Observable<People[]> {
      let  queryString = '/allResidentsByParamsPaged/' +  pageIndex + '/' + pageSize + '/' +
      propertyManagementCompanyId + '/' + communityId + '/' + areaId  + '/' + unitId;

      if (filter) {
        queryString += '/' + filter;
      }

      return this.http.get<People[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getAllByFilter( filter: string): Observable<ResidentSearch[]> {
    this.setBreadcrumbParams();
        const queryString = '/searchResidents/'  + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' +
        this.searchParamsBreadcrumb.communityId + '/' + this.searchParamsBreadcrumb.areaId
        + '/' + this.searchParamsBreadcrumb.unitId +  '/' + filter  ;
    return this.http.get<ResidentSearch[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getByRelationshipId(relationshipId: number): Observable<ResidentSearch[]> {
    this.setBreadcrumbParams();
    const filter = '';
        const queryString = '/searchResidentByRelationshipId/'  + relationshipId;
    return this.http.get<ResidentSearch[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getResidentOrUnitByFilter( filter: string): Observable<ResidentSearch[]> {
    this.setBreadcrumbParams();
        const queryString = '/searchResidentsAndUnits/'  + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' +
        this.searchParamsBreadcrumb.communityId + '/' + this.searchParamsBreadcrumb.areaId
        + '/' + this.searchParamsBreadcrumb.unitId +  '/' + filter  ;
    return this.http.get<ResidentSearch[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getResidentAndStaffByFilter( filter: string): Observable<ResidentSearch[]> {
    this.setBreadcrumbParams();
        const queryString = '/searchResidentsAndStaff/'  + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' +
        this.searchParamsBreadcrumb.communityId + '/' + this.searchParamsBreadcrumb.areaId
        + '/' + this.searchParamsBreadcrumb.unitId +  '/' + filter  ;
    return this.http.get<ResidentSearch[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getforNeewsFeed(communityId: number): Observable<People[]> {
    this.setBreadcrumbParams();
    const filter = '';
        const queryString = '/UsersForNewsFeed/'  + communityId;
    return this.http.get<People[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
}
