import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Breadcrumb } from '../model/breadcrumb';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import * as AppConstants from '../app-constants';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService extends AbstractDataProviderService<Breadcrumb> {
  public updateBreadcrumb = new Subject<boolean>();

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/breadcrumb', http, appConfig);
  }

  get(): Observable<Breadcrumb> {
    return this.http.get<Breadcrumb>(this.baseUrl, { headers: this.buildGetHeaders() });
  }

  watchUpdated(manually?: boolean): Observable<any> {
    if (manually) {
      this.updateBreadcrumb.next(true);
    }
    return this.updateBreadcrumb.asObservable();
}
}
