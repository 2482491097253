import { AfterViewInit, Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatAutocompleteSelectedEvent,
} from '@angular/material';
import { Tag } from 'src/app/model/tag';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { TagService } from 'src/app/services/tag.service';
import { ENTER, COMMA } from 'material2/src/cdk/keycodes';
import { Observable } from 'rxjs';
import { TagTableEntry } from 'src/app/model/tag-table-entry';
import { startWith, map } from 'rxjs/operators';
import { MatChipInputEvent } from 'material2/src/lib/chips';
import { Area } from 'src/app/model/area';

/**
 * A component used for adding/editing tags.
 */
@Component({
  selector: 'app-taglist-add-edit',
  templateUrl: './taglist-add-edit.component.html',
  styleUrls: ['./taglist-add-edit.component.css']
})
export class TaglistAddEditComponent implements AfterViewInit, OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl ();
  filteredTags: Observable<TagTableEntry[]>;
  tags: TagTableEntry[] = [];
  allTags: TagTableEntry[] = [];
  initialTags: TagTableEntry[] = [];
  newTags: TagTableEntry[] = [];
  deletedTags: TagTableEntry[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  public loading: boolean;

  public tagFormGroup: FormGroup;

  public _entity: string;
  /**
   * Initializes a new instance of TagAddEditComponent.
   *
   * @param dialogRef A reference to an instance of MatDialogRef that allows
   * add/update dialogs to be opened.
   * @param data If a tag has been selected for update, the data of the tag
   * to update.
   * @param fb A reference to an instance of FormBuilder.
   * @param snackBar A reference to an instance of MatSnackBar.
   * @param dialog .
   */
  constructor(public dialogRef: MatDialogRef<TaglistAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private tagService: TagService) {
      this.loading = true;
      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith (null),
        map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));

      this._entity = this.data.model.__EntityName__;

    }

  ngOnInit() {
    this.tagFormGroup = this.fb.group({
      tagInput: ['', Validators.nullValidator],
    });

    if (this.data.model.id) {
      switch (this._entity) {
        case 'Unanimated.Area':
          this.tagService.getByAreaId(this.data.model.id).subscribe(list => {
            this.tags = Array.from(list);
            this.initialTags = Array.from(list);
          });
          break;
      }
    }
    this.tagService.getAll().subscribe(x => {
      x.forEach(element => {
        this.allTags.push({ id: element.tagId, name: element.name, isActive: true });
      });
    });
    this.relist();

    this.loading = false;
  }

  ngAfterViewInit() {

  }

  /**
   * Event handler for the Cancel button.
   */
  onCancel(): void {
    if (this.tagFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else { this.dialogRef.close({ valid: false }); }
  }

  /**
   * Event handler for the Ok/Submit button.
   */
  onSubmit(): void {
    this.saveTags();
    this.dialogRef.close({
      valid: true
    });
  }

  saveTags(): void {
    this.diffTags();
    switch (this._entity) {
      case 'Unanimated.Area':
        if (this.newTags.length > 0) {
          this.tagService.bulk(this.newTags).subscribe(tagResult => {
            tagResult.idList.forEach((id, index) => {
              this.tagService.linkTagToArea(id, this.data.model.id).subscribe(linkResult => {

              });
            });
          });
        }
        if (this.deletedTags.length > 0) {
          this.deletedTags.forEach((el) => {
            this.tagService.unlinkTagFromArea(el).subscribe(linkResult => {

            });
          });
        }
        break;
    }
  }

  add(event: MatChipInputEvent): void {
    if (event.value !== '') {
      const input = event.input;
      const value = event.value;
      const inputTag: TagTableEntry = { id: 0, name: value, isActive: true };
      let exist = false;

      this.tags.forEach(el => {
        if (el.name === inputTag.name) { exist = true; }
      });

      this.allTags.forEach((el, index) => {
        if (el.name === inputTag.name) {
          this.allTags.splice(index, 1);
        }
      });

      if (!exist) {
        this.tags.push({ id: 0, name: value.trim(), isActive: true});
      }

      this.relist();
      this.diffTags();

      if (input) {
        input.value = '';
      }

      this.tagCtrl.setValue(null);
    }
  }

  remove(tag: TagTableEntry): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.allTags.push(tag);
      this.relist();
      this.diffTags();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    event.option.value = '';
    // this.tagInput.nativeElement.value = '';

    this.allTags.forEach((el, index) => {
      if (el.name === event.option.viewValue) { this.allTags.splice(index, 1); }
    });

    this.tags.push({ id: 0, name: event.option.viewValue, isActive: true });

    this.relist();
    this.diffTags();
    this.tagCtrl.setValue(null);
  }

  private relist() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith (null),
      map((tag: TagTableEntry | null) => tag ? this._filter(tag.name) : this.allTags.slice()));
  }

  private _filter(value: string): TagTableEntry[] {
    let filterValue = value; // .toLowerCase();
    if (filterValue) { filterValue = filterValue.toLowerCase(); }
    return this.allTags.filter(tag => tag.name.toLowerCase().indexOf(filterValue) === 0);
  }

  diffTags() {
    this.newTags = this.tags.filter(el => !this.initialTags.includes(el));

    this.deletedTags = this.initialTags.filter(el => !this.tags.includes(el));
  }

}

