import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { User } from '../model/user';
import { Observable, BehaviorSubject } from 'rxjs';
import { CrudResponseModel } from '../model/crud-response-model';
import { NewUser } from '../model/new-user';
import { UserImpersonated } from '../model/user-impersonated';
import { LevelFilter } from '../model/breadcrumb';
import { LoggedUser } from '../model/logged-user';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractDataProviderService<User> {
  baseUrlGringotts: string;
  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.SecurityAdminService, '/users', http, appConfig, LevelFilter.Unit);
  this.baseUrlGringotts = appConfig.endpoints[AppConstants.EndpointNames.GridVendor];
  }

  public isStaff = new BehaviorSubject(false);
  public isTenantOwner = new BehaviorSubject(false);

  emitTenantOwner(): Observable<boolean> {
    return this.isTenantOwner.asObservable();
  }

  emitStaff(): Observable<boolean> {
    return this.isStaff.asObservable();
  }

  setIsStaff(isStaff) {
    this.isStaff.next(isStaff);
  }

  addNewUser(user: NewUser): Observable<any> {
    return this.http.post<any>(this.baseUrl, user);
  }

  getLoggedUserInfo(): Observable<LoggedUser> {
    return this.http.get<LoggedUser>(this.baseUrlGringotts + '/user/loggedUserInfo/', { headers: this.buildGetHeaders() });
  }

  getByStaff(filter: string): Observable<User[]> {
    this.setBreadcrumbParams();
    const queryString = '/user/byStaff/' + filter + '?communityId=' + this.searchParamsBreadcrumb.communityId;
    return this.http.get<User[]>(this.baseUrlGringotts + queryString, { headers: this.buildGetHeaders() });
  }

  impersonate(user: User): Observable<UserImpersonated> {
    const sortingHatEndpoint = this.appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/sortingHat';
    return this.http.post<UserImpersonated>(sortingHatEndpoint + '/impersonate/', user, { headers: this.buildGetHeaders() });
  }

  clearImpersonate(): Observable<boolean> {
    const sortingHatEndpoint = this.appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/sortingHat';
    return this.http.post<boolean>(sortingHatEndpoint + '/clearImpersonate/', null, { headers: this.buildGetHeaders() });
  }

  registerUser(user: any): Observable<any> {
    const sortingHatEndpoint = this.appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/sortingHat';
    return this.http.post<any>(sortingHatEndpoint + '/registerUser', user, { headers: this.buildGetHeaders() });
  }

  updateUser(user: User): Observable<boolean> {
    const sortingHatEndpoint = this.appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/sortingHat';
    return this.http.put<boolean>(sortingHatEndpoint + '/updateUser', user, { headers: this.buildGetHeaders() });
  }

  getAllByParamsPaginated(pageIndex: number, pageSize: number, filter?: string, firstname?: string, lastname?: string, email?: string, sortData?: string): Observable<User[]> {
    this.setBreadcrumbParams();
     let queryString = '/allPageByBreadcrumb/' + pageIndex + '/' + pageSize + '/' +
     this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' + this.searchParamsBreadcrumb.communityId + '/'
     +  this.searchParamsBreadcrumb.areaId + '/' +  this.searchParamsBreadcrumb.unitId;

    let hasAdvanceFilter = false

    if (filter || email) {
      queryString = queryString + '?email=' + ((filter !== undefined && filter !== '') ? encodeURIComponent(filter) : encodeURIComponent(email));
      hasAdvanceFilter = true
    }

    if (firstname) {
      if (hasAdvanceFilter) {
        queryString = queryString + '&firstname=' + firstname;
      }else {
        queryString = queryString + '?firstname=' + firstname;
      }
      hasAdvanceFilter = true
    }

    if (lastname) {
      if (hasAdvanceFilter) {
        queryString = queryString + '&lastname=' + lastname;
      }else {
        queryString = queryString + '?lastname=' + lastname;
      }
      hasAdvanceFilter = true
    }

     if (sortData) {
      const sortCol = sortData.split('|');
      if (sortCol[1] !== '') {
        queryString = queryString +  (hasAdvanceFilter === true ?  '&sort=' : '?sort=') + sortCol[0];
        queryString = (queryString + '&dir=') + (sortCol[1] !== '' ? sortCol[1] : 'asc');
      }
    }
    return this.http.get<User[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCountByParams(filter?: string, firstname?: string, lastname?: string, email?: string): Observable<number> {
    const endpoint = '/getCountByParams/';
      let queryString = endpoint + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/'
       + this.searchParamsBreadcrumb.communityId + '/' + this.searchParamsBreadcrumb.areaId
      + '/' + this.searchParamsBreadcrumb.unitId;

    let hasAdvanceFilter = false

    if (filter || email) {
      queryString = queryString + '?email=' + ((filter !== undefined && filter !== '') ? encodeURIComponent(filter) : encodeURIComponent(email));
      hasAdvanceFilter = true
    }

    if (firstname) {
      if (hasAdvanceFilter) {
        queryString = queryString + '&firstname=' + firstname;
      }else {
        queryString = queryString + '?firstname=' + firstname;
      }
      hasAdvanceFilter = true
    }

    if (lastname) {
      if (hasAdvanceFilter) {
        queryString = queryString + '&lastname=' + lastname;
      }else {
        queryString = queryString + '?lastname=' + lastname;
      }
      hasAdvanceFilter = true
    }
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  linkRoleToUser(roleId: number, userId: number, companyId): Observable<CrudResponseModel> {
    const body = {
      'roleId': roleId,
      'userId': userId,
      'companyId': companyId
    };
    return this.http.post<CrudResponseModel>(this.baseUrl + '/addRole', body, { headers: this.buildPostHeaders() });
  }

  unlinkRoleFromUser(roleId: number, userId: number, companyId: number): Observable<CrudResponseModel> {
    return this.http.delete<CrudResponseModel>(this.baseUrl + '/role/' + roleId.toString() + '/' + userId.toString() +  '/' + companyId,
     { headers: this.buildPostHeaders() });

  }

  isUserAvailable(email): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/isUserAvailable?email=' + email, { headers: this.buildGetHeaders() });
  }
}
