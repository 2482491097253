import { PricePlan } from './price-plan';
import { DeliveryOption } from './delivery-option';


export class DeliveryPricePlan {
    delivery: DeliveryOption;
    pricePlan: PricePlan;
    communityId: number;
    managementCompanyId: number;
}
