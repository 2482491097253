import { Component, HostListener, ElementRef } from '@angular/core';
import { NewsfeedService } from '../services/newsfeed.service';
import { Newsfeed } from '../model/newsfeed';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, Subject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageHelper } from '../helpers/local-storage-helper';

const timestampFactor = 621355968000000000;

@Component({
    selector: 'notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss'],
    animations: [
        trigger('notificationOpenClose', [
            state('open', style({
                'height': '0px',
                'display': 'none'

            })),
            state('closed', style({
                'height': '350px',
                'border': '1px solid #e2e2e2'
            })),
            transition('open => closed', [
                animate('300ms ease-in-out')
            ])
        ]),
    ]
})

export class NotificationPanelComponent {
    private showNotifPanel: boolean = false;
    private newsFeeds: any[] = [];
    private showActiveNotifIcon: boolean = false;

    constructor(private newsfeedService: NewsfeedService,
        private router: Router,
        private snackbar: MatSnackBar,
        private eRef: ElementRef) {
        this.newsfeedService.getNewsfeedByUserId().subscribe(data => {
            this.newsFeeds = data.map(x => {
                
                /** TODO: isSeen should exist on the database to mark the item unread */
                // if (x.isSeen) {
                //    this.showActiveNotifIcon = true;
                // }

                return {
                    'title': x.title,
                    'body': x.body,
                    'message':x.message,
                    'articleType' :x.articleType,                    
                    'notifTime': this.convert(parseInt(x.timestamp, 10)),
                    'timestamp': this.convertToDays(parseInt(x.timestamp, 10)),
                    'isSeen': true
                }
            }).sort((a, b) => {
                return (parseInt(a.timestamp, 10) - parseInt(b.timestamp, 10))
            });
        }, error => {
            this.snackbar.open(error, '', { duration: 5000 });
        });
    }

    markAllAsRead() {
        /** update to api that will set isSeen property to true */
        this.showActiveNotifIcon = false;
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.showNotifPanel = false;
        }
    }

    viewMore(feed: Newsfeed) {
        this.showNotifPanel = false;
         /** update to api that will set isSeen property to true */
        LocalStorageHelper.setPostDetail(feed);
        this.router.navigateByUrl('/app/news-feed/view-details');
    }

    deleteNotification(feed: Newsfeed) {
        /** TODO: Implement method using API */
    }

    convert(timestamp: number) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'];
        let convertedDate = (timestamp - timestampFactor) / 10000;
        let newDate = new Date(convertedDate);
        let hours = newDate.getHours();
        hours = hours % 12;
        hours = hours === 0 ? 12 : hours;
        let mins = newDate.getMinutes();
        let newMins = mins.toString().length < 2 ? '0' + mins.toString() : mins.toString();
        let formatedHourOfDay = newDate.getHours() < 12 ?
            `${hours}:${newMins} AM` :
            `${hours}:${newMins} PM`;
        return `${months[newDate.getUTCMonth()]} ${newDate.getUTCDate()}, ${newDate.getUTCFullYear()} - ${formatedHourOfDay}`
    }

    convertToDays(timestamp: number) {
        const secondsO = (timestamp - timestampFactor) / 10000;
        const msecPerMinute = 1000 * 60;
        const msecPerHour = msecPerMinute * 60;
        const msecPerDay = msecPerHour * 24;
        let interval = Date.now() - secondsO;
        const days = Math.floor(interval / msecPerDay);
        return days.toString();
    }
}
