import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { AddressTypeService } from 'src/app/services/address-type.service';
import { CityService } from 'src/app/services/city.service';
import { StateService } from 'src/app/services/state.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { AddressTableEntry } from 'src/app/model/address-table-entry';
import { forkJoin } from 'rxjs';
import { AddressType } from 'src/app/model/address-type';
import { State } from 'src/app/model/state';
import { City } from 'src/app/model/city';
import { ZipCodeService } from 'src/app/services/zip-code.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';


@Component({
    selector: 'add-address',
    templateUrl: './add-address-dialog.component.html',
    styleUrls: ['./add-address-dialog.component.scss']
})

export class AddAddressDialogComponent {
    private peopleId: number;
    private addressFormGroup: FormGroup;
    private model: AddressTableEntry;
    private addressTypes: AddressType[];
    private states: State[];
    private cities: City[];
    private LENGTH_ZIPCODE = 5;
    private editMode: boolean = false;
    private lastLine1Address: string;

    constructor(public dialogRef: MatDialogRef<AddAddressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private addressTypeService: AddressTypeService,
        private cityService: CityService,
        private stateService: StateService,
        private zipCodeService: ZipCodeService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,) {
            
        this.peopleId = data.peopleId;
        this.model = new AddressTableEntry(this.fb);
        this.addressFormGroup = this.model.buildFormGroup();
    }

    ngOnInit() {
        const addressTypesObservable = this.addressTypeService.getAll();
        const statesObservable =  this.stateService.getAll();
        const citiesObservable = this.data.model !== null ? this.cityService.getByStateId(this.data.model.stateId) : this.cityService.getByPeopleId(this.peopleId);

        forkJoin(addressTypesObservable, citiesObservable, statesObservable).subscribe(result => {
            this.addressTypes = result[0];
            this.cities = result[1];
            this.states = result[2];
            if(this.data.model !== null) {
                this.initZipCode(this.data.model.zipCode);
                this.editMode = true;
                this.lastLine1Address = this.data.model.line1;
                this.model.fromObject(this.data.model);
            }
            
        }, error => {
            this.snackbar.open('Error:', error.statusText, {
                duration: 4000
            });
        });
    }

    private onZipCodeChange(): void {
        const value = this.addressFormGroup.get('zipCode').value;
        if (value === undefined || value.length !== this.LENGTH_ZIPCODE) {
            return;
        }
        this.initZipCode(value);
    }

    private initZipCode(value: string) {
        this.zipCodeService.getByZipCode(value).subscribe(zipCode => {
            if (zipCode.length === 0) {
                this.clearZipCode();
                this.openDialog('Warning', 'zip code does not exist');
                return;
            }
            this.model.zipCodeId =  zipCode[0].zipCodeId;
            const state = this.states.find(x => x.stateCode === zipCode[0].stateCode);
            this.addressFormGroup.get('stateId').setValue(state.stateId);
            this.cityService.getByStateId(state.stateId).subscribe(x => {
                if (this.cities !== undefined) {
                    this.cities = this.cities.concat(x);
                } else {
                    this.cities = x;
                }
                const city = this.cities.find(c => c.name.trim() === zipCode[0].city.trim());
                this.addressFormGroup.get('cityId').setValue(city !== undefined ? city.cityId : 0);
            });
        });
    }

    private openDialog(title: string, Message: string): void {
        let data;
        data = {
            Title: title,
            Message: Message
        };
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '300px',
            data: data
        });
        dialogRef.afterClosed().subscribe(result => {
            this.clearZipCode();
        });
    }

    private clearZipCode(): void {
        this.model.cityId = null;
        this.model.cityName = null;
        this.model.stateId = null;
        this.model.stateName = null;
        this.model.zipCode = null;
        this.model.zipCodeId = null;
    }

    private onSave() {
        this.model = this.model.toDto();
        this.dialogRef.close({
            data: this.model,
            isEdit: this.editMode,
            lastLine1Address: this.lastLine1Address
        });
    }

    private onCancel() {
        this.dialogRef.close();
    }
}   