import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { FirebaseMessagingService } from './services/firebase-messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'GridEnterprise';

  constructor(private messaging: FirebaseMessagingService, private snackbar: MatSnackBar) {
    this.messaging.setNotificationCallback(text => {
      snackbar.open(text, '', { duration: 2000 });
    });
  }

  ngOnInit()  {
  }
}
