import { EntityRing} from '../model/access-type-ring';
import { OnInit } from '@angular/core';
import { SecurityHelper } from '../helpers/security-helper';

export abstract class BaseSecurity implements OnInit {

    public entityRings: EntityRing[] = [];

    public securityEntity: EntityRing = new EntityRing();
    ngOnInit(): void {
      this.entityRings.forEach(ent => {
            ent.rings.forEach(ring => {
              ring.allow = SecurityHelper.ValidatePermition(ring.ringId);
            });
      });
    }
  }
