export class LocationBarNode {
    id: number;
    text: string;
    type: number;
    children?: LocationBarNode[];
    expandable: boolean;
    level: number;
    parentId: number;
}


/** Flat node with expandable and level information */
export interface LocationBarFlatNode {
    expandable: boolean;
    text: string;
    level: number;
    type: number;
    id : number;
    hasChild: boolean;
  }

  export enum TypeLevel {
    ManagementCompany = 'ManagementCompany',
    Community = 'Community',
    Unit = 'Unit',
    Account = 'Account',
  }



  export enum TypeNode {
    ManagementCompany = 1,
    Community = 2,
    SubCommunity = 3,
    Unit = 4,
    Account = 5,
  }