import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Services
import { AuthGuardService } from 'src/app/services/auth-guard.service';

// Component
import { SearchComponent } from '../search/search.component';


const searchRoutes: Routes = [{
  path: 'search',
  children: [
    {
      path: '',
      component: SearchComponent,
      data: { title: 'Search' },
      canActivate: [AuthGuardService]
    },

  ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(searchRoutes)
  ],
  declarations: []
})
export class SearchRoutingModule { }
