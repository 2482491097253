export class Document {
    documentId: number;
    name: string;
    description: string;
    documentTypeId: number;
    mandatoryPayUpFront: boolean;
    isActive: boolean;
    isDeleted: boolean;
    selected: boolean;
}
