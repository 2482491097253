import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Observable, of, Subject } from 'rxjs';
import { ServiceInfo } from '../model/service-info';

@Injectable({
  providedIn: 'root'
})
export class SystemInfoService extends AbstractDataProviderService<ServiceInfo> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/tardisInfo', http, appConfig);
  }

  public getServiceInfo(): Observable<ServiceInfo[]> {
    return this.http.get<ServiceInfo[]>(this.baseUrl + '/getVersion', { headers: this.buildGetHeaders() });
  }
}
