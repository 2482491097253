import { Exporter, Options} from 'mat-table-exporter';
import * as XLSX from 'xlsx';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class CustomExporterService implements Exporter<Options>{
    export(rows: Array<any>, options?: Options) {
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows, <XLSX.Table2SheetOpts>{ sheet: 'Sheet 1' });
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();
        // Adjust column width
        const wscols = [
            { wch: 50 }
        ];
        workSheet["!cols"] = wscols;

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
        XLSX.writeFile(workBook, `${options.fileName}.xlsx`);
    }
}