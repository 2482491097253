import { Component, Injector, AfterViewInit } from '@angular/core';
import { BaseList } from 'src/app/building-blocks/base-list';
import { Observable } from 'rxjs';
import { VisitorsTimeLogService } from 'src/app/services/visitors-time-log.service';
import { VisitorsTimeLog } from 'src/app/model/visitors-time-log';
import { People } from 'src/app/model/people';
import { VisitorType } from 'src/app/model/Visitor-type';
import { VisitorTypeService } from 'src/app/services/visitor-type.service';
import { FormGroup, FormControl } from '@angular/forms';


const visitorsColumns = [
  'visitor',
  'pointInTime',
  'visitorTypeName',
  'company',
  'vehicle',
  'resident',
  'unit',
  'detail'
];

@Component({
  selector: 'app-visitors-log-list',
  templateUrl: './visitors-log-list.component.html',
  styleUrls: ['./visitors-log-list.component.scss']
})
export class VisitorsLogListComponent extends BaseList<VisitorsTimeLog> implements AfterViewInit {
  private cols = [
    {
      headerName: 'Visitor Name',
      columnDef: 'visitor',
      cell: (visitorsTimeLog) => this.displayFullName(visitorsTimeLog),
      sortable: true
    },
    {
      headerName: 'Visitor Type',
      columnDef: 'visitorTypeName',
      cell: (visitorsTimeLog) => visitorsTimeLog.visitorTypeName,
      sortable: true
    },
    {
      headerName: 'Date',
      columnDef: 'pointInTime',
      cell: (visitorsTimeLog) => this.formatDate(visitorsTimeLog),
      sortable: true
    },
    {
      headerName: 'Access',
      columnDef: 'accessDenied',
      cell: (visitorsTimeLog) => this.formatAccess(visitorsTimeLog),
      sortable: true
    }
  ];
  private showFilter = false;
  private rowActions = ['edit'];
  private filterFormGroup: FormGroup;
  private selectedAccessFilter: string = '-1';
  private selectedVisitorType: number = -1;
  private filterParams: any = {
    filter: '',
    useCustomFilter: 0,
    visitorName: '',
    visitorType: 0,
    residentName: '',
    companyName: '',
    utcf: '',
    utcu: '',
    vehicleName: '',
    accessType: 0
  };

  public people: People;
  protected noData = 0;
  protected showNoRecords = false;
  public visitorType = 0;
  public filterRaised = false;
  public countRaised = false;
  public visitorTypes: VisitorType[] = [];
  sortCol = 'pointInTime';
  sortDir = 2;
  constructor(public injector: Injector,
    private visitorTimeLogService: VisitorsTimeLogService,
    private visitorTypeService: VisitorTypeService, ) {
    super(visitorsColumns, '/visitorlog', visitorTimeLogService, 'visitorsTimeLog', injector);
    this.filters = '';
    this.visitorTypeService.getAll().subscribe(s => {
      this.visitorTypes = s;
    });

    this.filterFormGroup = new FormGroup({
      visitorTypeFormControl: new FormControl(''),
      pointInTimeFormControl: new FormControl(''),
      accessFormControl: new FormControl('')
    });
    this.filterFormGroup.controls['visitorTypeFormControl'].setValue(-1);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<VisitorsTimeLog[]> {
    if (this.filterParams.useCustomFilter == 1) {
      if (this.filterFormGroup.controls['pointInTimeFormControl'].value && this.filterFormGroup.controls['pointInTimeFormControl'].value !== null) {
        let newDate = new Date(this.filterFormGroup.controls['pointInTimeFormControl'].value);
        this.filterParams.utcf = `${newDate.getUTCFullYear().toString().padStart(4, '0')}${(newDate.getUTCMonth() + 1).toString().padStart(2, '0')}${(newDate.getUTCDate()).toString().padStart(2, '0')}`
        this.filterParams.utcu = `${newDate.getUTCFullYear().toString().padStart(4, '0')}${(newDate.getUTCMonth() + 1).toString().padStart(2, '0')}${(newDate.getUTCDate() + 1).toString().padStart(2, '0')}`;
      }
    } 
    return this.visitorTimeLogService.getAllPaginatedSorted(pageIndex, pageSize, this.sortCol, this.sortDir, filter, 
      this.filterParams.useCustomFilter, '', this.selectedVisitorType, '', '', this.filterParams.utcf, this.filterParams.utcu, '', this.selectedAccessFilter);
  }

  protected getCount(): Observable<number> {
    if (this.filterParams.useCustomFilter == 1) {
      if (this.filterFormGroup.controls['pointInTimeFormControl'].value && this.filterFormGroup.controls['pointInTimeFormControl'].value !== null) {
        let newDate = new Date(this.filterFormGroup.controls['pointInTimeFormControl'].value);
        this.filterParams.utcf = `${newDate.getUTCFullYear().toString().padStart(4, '0')}${(newDate.getUTCMonth() + 1).toString().padStart(2, '0')}${(newDate.getUTCDate()).toString().padStart(2, '0')}`
        this.filterParams.utcu = `${newDate.getUTCFullYear().toString().padStart(4, '0')}${(newDate.getUTCMonth() + 1).toString().padStart(2, '0')}${(newDate.getUTCDate() + 1).toString().padStart(2, '0')}`;
      }
    }
    return this.visitorTimeLogService.getCountByBreadcumd(this.dataSource.filter, this.filterParams.useCustomFilter, '', this.selectedVisitorType, '', '', this.filterParams.utcf, this.filterParams.utcu, '', this.selectedAccessFilter);
  }

  protected getById(id: number): Observable<VisitorsTimeLog> {
    return this.visitorTimeLogService.getById(id);
  }

  protected detail(id: number) {
    this.router.navigateByUrl('/app/visitors/detail/' + id);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any { }

  displayFullName(entity: VisitorsTimeLog) {
    return `${entity.firstName} ${entity.lastName}`; 
  }

  formatDate(entity: VisitorsTimeLog) {
    let newDate = new Date(entity.pointInTime);
    return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
  }

  formatAccess(entity: VisitorsTimeLog) {
    return entity.accessDenied === false ? 'Granted' : 'Denied';
  }

  search(searchParameter: string) {
    if (searchParameter !== '') {
      this.filterParams.useCustomFilter = 1;
    }
    this.applyFilter(searchParameter);
  }

  applySort(event) {
    const sortCol = event.sortData.split('|');
    this.sortDir = 1;

    if (sortCol[0] !== '') {
      this.sortCol =  sortCol[0];
    }

    if (sortCol[1] !== '') {
      if (sortCol[1] !== '') {
        this.sortDir = sortCol[1] === 'asc' ? 1 : 2;
      }
    }
    
    this.dataSource.reload();
  }

  onChangeFilter(e: any) {
    this.selectedAccessFilter = e.value;
    this.dataSource.reload();
  }

  onChangeVisitorTypeFilter(e: any) {
    this.filterParams.visitorType = this.selectedVisitorType;
  }

  resetAdvanceFilters() {
    this.filterFormGroup.controls['visitorTypeFormControl'].setValue('');
    this.filterFormGroup.controls['pointInTimeFormControl'].setValue('');
    this.filterFormGroup.controls['accessFormControl'].setValue('');
    this.filterParams.utcf = '';
    this.filterParams.utcu = '';
    this.filterParams.useCustomFilter = 0;
    this.selectedAccessFilter = '-1';
    this.reload();
  }

  applyAdvanceFilter() {
    this.filterParams.useCustomFilter = 1;
    this.reload();
  }

  processSelectedAccessType(type: string) : string {
    switch (type) {
      case '0':
        return 'Granted'
      case '1':
        return 'Denied'
      case '-1': 
        return 'All Visitors'
      default: 
        return "Granted"
    }
  }

  downloadPDFEvent(doc: any) {
    doc.save('grid-system-export.pdf');
  }

  downloadCSVEvent(directive: any, options: any) {
    directive.exportTable('csv', options);
  }



}

