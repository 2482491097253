import { BaseForm } from '../building-blocks/base-form';
import { ParameterMassComm } from './mass-comm-parameter';
import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { Validators, FormArray } from '@angular/forms';

export class EmailMassComm extends BaseForm {
    idEmailMassComm = 0;

    @FormField('massCommListsSelect', ['', Validators.required])
    idMassCommList = 0;

    @FormField('parameters', [], ParameterMassComm,  true)
    parameters: FormArray;

    @FormField('subject', ['', Validators.required])
    subject = '';

    @FormField('body', ['', Validators.required])
    body = '';
}
