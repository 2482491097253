import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../src/app/material.module';
import { DocumentRoutingModule } from './document-routing/document-routing.module';
import { FormsModule } from '@angular/forms';

import { DocumentAddEditComponent } from './document-add-edit/document-add-edit.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { SharedModule } from '../thorin-shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    DocumentRoutingModule,
    SharedModule
  ],
  declarations: [
    DocumentAddEditComponent,
    DocumentListComponent,
  ],
  exports: [],
  entryComponents: []

})
export class DocumentModule { }
