import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatTableDataSource,
  MatPaginator,
  MatSelect,
  MatSnackBar,
  MatSort,
  MatInput
} from '@angular/material';


import { forkJoin } from 'rxjs';

/* Services */
import { AddressService } from '../../services/address.service';
import { AddressTypeService } from '../../services/address-type.service';
import { CityService } from '../../services/city.service';
import { CompanyTypeService } from '../../services/company-type.service';
import { StateService } from '../../services/state.service';
import { TaxClassificationService } from '../../services/tax-classification.service';
import { ZipCodeService } from '../../services/zip-code.service';

/* Models */
import { Address } from '../../model/address';
import { AddressTableEntry } from '../../model/address-table-entry';
import { AddressType } from '../../model/address-type';
import { Company } from '../../model/company';
import { CompanyType } from '../../model/company-type';
import { State } from '../../model/state';
import { City } from '../../model/city';
import { TaxClassification } from '../../model/tax-classification';
import { ZipCode } from '../../model/zip-code';

/* Components */
import { AlertDialogComponent } from '../../app-dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '../../app-dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-company-add-edit-dialog',
  templateUrl: './company-add-edit-dialog.component.html',
  styleUrls: ['./company-add-edit-dialog.component.css']
})
export class CompanyAddEditDialogComponent implements AfterViewInit, OnInit {
  public MESSAGE_ERROR_ZIPCODE = 'Unable to add address, city or state does not belong to that zip code';
  public TITLE_WARNING = 'Warning';
  public MESSAGE_ZIPCODE_NOT_EXISTS = 'zip code does not exist';
  public LENGTH_ZIPCODE = 5;

  public stepOneGroup: FormGroup;
  public stepTwoGroup: FormGroup;
  public stepThreeGroup: FormGroup;

  public addresses: AddressTableEntry[];
  public addressTypes: AddressType[];
  public companyTypes: CompanyType[];
  public states: State[];
  public cities: City[];
  public taxClassifications: TaxClassification[];
  public zipCodes: Observable<ZipCode[]>;
  public allZips: ZipCode[];

  public model: Company;
  public addressModel: AddressTableEntry;
  public undoAddressModel: AddressTableEntry;
  public addressesToWipe: Address[];

  public isEditingAddress: boolean;

  public addressTableColumns: string[] = [ 'line1', 'line2', 'number', 'apt', 'zipCode', 'state', 'city',  'addressType', 'actions' ];

  public addressDataSource = new MatTableDataSource<AddressTableEntry>();
  public disableState = true;

  @ViewChild('addressTypeSelect') addressTypeSelect: MatSelect;
  @ViewChild('companyTypeSelect') companyTypeSelect: MatSelect;
  @ViewChild('stateSelect') stateSelect: MatSelect;
  @ViewChild('citySelect') citySelect: MatSelect;
  @ViewChild('taxClassificationSelect') taxClassificationSelect: MatSelect;
  @ViewChild('zipCodeInput') zipCodeInput: MatInput;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  zipCodeCtrl = new FormControl();
  filteredOptionsZipCodes: Observable<any[]>;

  constructor(
    public dialogRef: MatDialogRef<CompanyAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private addressService: AddressService,
    private addressTypeService: AddressTypeService,
    private companyTypeService: CompanyTypeService,
    private stateService: StateService,
    private cityService: CityService,
    private taxClassificationService: TaxClassificationService,
    private zipCodeService: ZipCodeService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {
  }


  ngOnInit() {
    // Instantiate the domain model object.
    this.model = new Company(null);
    this.addressModel = new AddressTableEntry(null);
    this.addresses = [];
    this.addressesToWipe = [];
    this.isEditingAddress = false;
    this.undoAddressModel = null;

    if (this.data.update === true) {
      this.model = this.data.model.company;
    }

    this.stepOneGroup = this.fb.group({
      legalName: [ '', Validators.required ],
      nickName: [ '', Validators.required ],
      description: [ '', Validators.required ],
      webSite: [ '', Validators.required ],
      companyType: [ '', Validators.required ]
    });

    this.stepTwoGroup = this.fb.group({
      eINNumber: [ '', [Validators.required, Validators.pattern('[0-9]*') ]],
      taxClassification: [ '', Validators.required ],
    });

    this.stepThreeGroup = this.fb.group({
      apt: [ ''],
      number: [ '', [ Validators.required, Validators.pattern('[0-9]*') ]],
      zipCode: [ '', [ Validators.required, Validators.pattern('[0-9]*')]],
      state: [ '', Validators.required ],
      city: [ '', Validators.required ],
      line1: [ '', Validators.required ],
      line2: [ '' ],
      addressType: [ '', Validators.required ]
    });

    // Retrieve all data necessary to populate select controls in this form.
    forkJoin(
      this.addressTypeService.getAll(),
      this.stateService.getAll(),
      this.companyTypeService.getAll(),
      this.taxClassificationService.getAll())
    .subscribe(results => {
      this.addressTypes = results[0];
      this.states = results[1];
      this.companyTypes = results[2];
      this.taxClassifications = results[3];
      this.updateAddressDataSource();
    });
  }

  ngAfterViewInit() {
    if (this.data.update === true) {
      this.model = this.data.model.company;
      for (const x of this.data.model.addresses) {
        const addr = new AddressTableEntry(null);
        addr.addressId = x.addressId;
        addr.addressTypeId = x.addressTypeId;
        addr.apt = x.apt;
        addr.number = x.number;
        addr.line1 = x.line1;
        addr.line2 = x.line2;
        addr.zipCodeId = x.zipCodeId;
        addr.zipCode = x.zipCode;
        addr.stateId = x.stateId;
        addr.stateName = x.stateName;
        addr.cityId = x.cityId;
        addr.cityName = x.cityName;
        addr.persisted = true;
        this.addresses.push(addr);
      }
    }
  }

  onStateSelectionChange(e): void {
    this.addressModel.stateId = e.value;
    this.stateSelect.value = e.value;
    this.addressModel.stateName = this.states.find(x => x.stateId === e.value).name;
    this.cityService.getByStateId(e.value).subscribe(x => {
      this.cities = x;
      this.addressModel.cityId = null;
    });
  }

  onCitySelectionChange(e): void {
    this.citySelect.value =  e.value;
    this.addressModel.cityId = e.value;
    this.addressModel.cityName = this.cities.find(x => x.cityId === e.value).name;
  }

  onZipCodeChange(value: string): void {
    if (value.length !== this.LENGTH_ZIPCODE) {
      return;
    }

    this.zipCodeService.getByZipCode(value).subscribe(zipCode => {
      if (zipCode.length === 0) {
        this.clearZipCode();
        this.openDialog(this.TITLE_WARNING, this.MESSAGE_ZIPCODE_NOT_EXISTS);
        return;
      }
      this.disableState = false;
      this.addressModel.zipCodeId = zipCode[0].zipCodeId;

      const state = this.states.find(x => x.stateCode === zipCode[0].stateCode);
      this.addressModel.stateId = state.stateId;
      this.addressModel.stateName = state.name;

      this.cityService.getByStateId(this.addressModel.stateId).subscribe(x => {
        this.cities = x;
        const city = this.cities.find(c => c.name === zipCode[0].city);
        this.addressModel.cityId = city.cityId;
        this.addressModel.cityName = city.name;
      });
    });
  }

  onTaxClassificationSelectionChange(e): void {
    this.model.taxClassificationId = e.value;
  }

  updateAddressDataSource(): void {
    this.addresses.forEach(x => {
      x.addressTypeName = this.addressTypes.find(y => y.addressTypeId === x.addressTypeId).name;
    });
    this.setValuesByDefault();
    this.addressDataSource = new MatTableDataSource(this.addresses);
    this.addressDataSource.paginator = this.paginator;
    this.addressDataSource.sort = this.sort;
  }

  setValuesByDefault(): void {
    // By default set the first registry
    if (this.addressTypes.length > 0) {
      this.addressModel.addressTypeId = this.addressTypes[0].addressTypeId;
      this.addressModel.addressTypeName = this.addressTypes[0].name;
      this.addressTypeSelect.value = this.addressTypes[0].addressTypeId;
    }
  }

  addAddress(): void {
    if (this.stepThreeGroup.status !== 'VALID') {
      return;
    }

    this.validateAddress().subscribe(result => {
      if (!result) {
        this.openDialog(this.TITLE_WARNING, this.MESSAGE_ERROR_ZIPCODE);
      } else {
          this.addressModel.addressTypeId = this.addressTypeSelect.value;
          this.addressModel.addressTypeName = this.addressTypes.find(x => x.addressTypeId === this.addressTypeSelect.value).name;
          this.addressModel.persisted = false;
          this.addresses.push(this.addressModel);
          this.addressModel = new AddressTableEntry(null);
          this.updateAddressDataSource();
      }
    });
  }

  private validateAddress(): Observable<boolean> {
    return new Observable((obs: any) => {
      this.zipCodeService.getById(this.addressModel.zipCodeId).subscribe(z => {
        if (z.city !== this.addressModel.cityName) {
          obs.next(false);
          obs.complete();
        } else {
          obs.next(true);
          obs.complete();
        }
      });
    });
  }

  openDialog(title: string, Message: string): void {
    let data;

    data = {
      Title: title,
      Message: Message
    };

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '300px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      this.clearZipCode();
    });
  }

  clearZipCode(): void {
    this.addressModel.cityId = null;
      this.addressModel.cityName = null;
      this.addressModel.stateId = null;
      this.addressModel.stateName = null;
      this.addressModel.zipCode = null;
      this.addressModel.zipCodeId = null;
      this.disableState = true;
  }

  getAddressFromTableEntry(entry: AddressTableEntry): Address {
    const addr = new Address();
    addr.addressId = entry.addressId;
    addr.addressTypeId = entry.addressTypeId;
    addr.apt = entry.apt;
    addr.number = entry.number;
    addr.line1 = entry.line1;
    addr.line2 = entry.line2;
    addr.zipCodeId = entry.zipCodeId;
    return addr;
  }

  editAddress(address: AddressTableEntry): void {
    if (!this.isEditingAddress) {
      this.isEditingAddress = true;
      const idx = this.addresses.findIndex(x => x.addressId === address.addressId);
      this.undoAddressModel = this.addresses.splice(idx, 1)[0];
      this.addressModel = address;
      this.cityService.getByStateId(address.stateId).subscribe(c => {
        this.cities = c;
      });
      this.disableState = false;
    }
  }

  saveAddress(): void {
    this.validateAddress().subscribe(result => {
      if (!result) {
        this.openDialog(this.TITLE_WARNING, this.MESSAGE_ERROR_ZIPCODE);
      } else {
        const address = this.getAddressFromTableEntry(this.addressModel);
        if (this.isEditingAddress) {
          this.addressService.update(address).subscribe(x => {
            this.pushAddress();
          });
        } else {
          this.addressService.add(address).subscribe(x => {
            this.pushAddress();
          });
        }
      }
    });
  }

  pushAddress(): void {
    this.isEditingAddress = false;
    this.addresses.push(this.addressModel);
    this.addressModel = new AddressTableEntry(null);
    this.addressModel.persisted = false;
    this.disableState = true;
  }

  cancelEditing(): void {
    this.addresses.push(this.undoAddressModel);
    this.isEditingAddress = false;
    this.undoAddressModel = null;
    this.addressModel = new AddressTableEntry(null);
    this.addressModel.persisted = false;
    this.disableState = true;
  }

    removeAddress(address: AddressTableEntry): void {
      const idx = this.addresses.findIndex(x => x.addressId === address.addressId);
      const item = this.addresses.splice(idx, 1);

      // If this is an update, build a list of addresses to remove.
      // Otherwise, just wipe them from the addresses list.
      if (this.data.update === true && address.persisted === true) {

        const addr = this.getAddressFromTableEntry(address);
        this.addressesToWipe.push(addr);

      }
      this.updateAddressDataSource();
    }

    getAddressesFromTable(): Address[] {
      return this.addresses
        .filter(x => x.persisted === false)
        .map(x => {
          const address = new Address();
          address.addressTypeId = x.addressTypeId;
          address.apt = x.apt;
          address.number = x.number;
          address.line1 = x.line1;
          address.line2 = x.line2;
          address.zipCodeId = x.zipCodeId;
          return address;
        });
    }

    onCancel(): void {
      if (this.stepOneGroup.dirty || this.stepTwoGroup.dirty || this.stepThreeGroup.dirty) {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
          width: '750px'
        });
        confirmDialog.afterClosed().subscribe(result => {
          if (result === true) { this.dialogRef.close({ valid: false }); }
        });
      } else { this.dialogRef.close({ valid: false }); }
    }

    onSubmit(): void {
      if (typeof this.companyTypeSelect.value !== 'undefined') {
        this.model.companyType = this.companyTypeSelect.value;
      }

      this.dialogRef.close({
        valid: true,
        addresses: this.getAddressesFromTable(),
        addressesToWipe: this.addressesToWipe,
        entity: this.model
      });
    }
}
