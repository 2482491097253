import { Injectable } from '@angular/core';
import { MatSnackBar } from 'material2/src/lib';
import { CustomSnackbarComponent, snackType } from '../shared/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class CustomSnackbarService {

  constructor(private snackBar: MatSnackBar) { 
  }

  public openSnackBar(message: string,title: string, snackTypeEnum?: snackType) {
    const _snackType: snackType =
    snackTypeEnum !== undefined ? snackTypeEnum : snackType.Success;

    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: { title: title,  message: message, snackType: _snackType}
    });
  }
  
}
