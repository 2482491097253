
import { Component, OnInit } from '@angular/core';
import { CommunitySettings } from 'src/app/model/community-settings';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { CommunityService } from 'src/app/services/community.service';
import { CommunitySettingsService } from 'src/app/services/community-settings.service';
import { forkJoin } from 'rxjs';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';


@Component({
  selector: 'app-setting-visitor',
  templateUrl: './setting-visitor.component.html',
  styleUrls: ['./setting-visitor.component.css']
})
export class SettingVisitorComponent implements OnInit {
  public model: CommunitySettings;
  public visitorSettingsFormGroup: FormGroup;
  public loading: boolean;
  public CommunityName: string;
  public vehicleEnabled = true;
  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private communityService: CommunityService,
    private communitySettingService: CommunitySettingsService
    ) {
      this.model = new CommunitySettings(this.fb);
      this.visitorSettingsFormGroup = this.model.buildFormGroup();
    }

  ngOnInit() {
    this.loading = true;
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.model.communityId = comunityLocalStorage;   
      forkJoin(this.communitySettingService.getByCommunityId(this.model.communityId),
      this.communityService.getById(this.model.communityId)).subscribe(result => {
        if (result[0] ) {
          this.model.fromObject(result[0]);
        } else {
          this.model.communityId = result[1].communityId;
        }
        this.loading = false;
    });
  }
  onChangeVP(e) {
    if (e.checked ===  false) {
      this.visitorSettingsFormGroup.controls['requireVisitorsPicture'].setValue(false);
    }
  }
  onChangeVS(e) {
    if (e.checked ===  false) {
      this.visitorSettingsFormGroup.controls['requireVisitorsSignature'].setValue(false);
    }
  }
  onChangeCN(e) {
    if (e.checked ===  false) {
      this.visitorSettingsFormGroup.controls['requireCompanyName'].setValue(false);
    }
  }
  onChangeVT(e) {
    if (e.checked ===  false) {
      this.visitorSettingsFormGroup.controls['requireVisitorsType'].setValue(false);
    }
  }
  onChange(value) {
    this.vehicleEnabled = value.checked;
    if (value.checked ===  false) {
      this.visitorSettingsFormGroup.controls['vehicleMaker'].setValue(false);
      this.visitorSettingsFormGroup.controls['requireVehicleMaker'].setValue(false);

      this.visitorSettingsFormGroup.controls['vehicleYear'].setValue(false);
      this.visitorSettingsFormGroup.controls['requireVehicleYear'].setValue(false);

      this.visitorSettingsFormGroup.controls['vehicleLicensePlate'].setValue(false);
      this.visitorSettingsFormGroup.controls['requireVehicleLicensePlate'].setValue(false);
      
      this.visitorSettingsFormGroup.controls['vehicleColor'].setValue(false);
      this.visitorSettingsFormGroup.controls['requireVehicleColor'].setValue(false);
      
      this.visitorSettingsFormGroup.controls['vehicleParkingTicket'].setValue(false);
      this.visitorSettingsFormGroup.controls['requireVehicleParkingTicket'].setValue(false);
           
      this.visitorSettingsFormGroup.controls['vehicleModel'].setValue(false);
      this.visitorSettingsFormGroup.controls['requireVehicleModel'].setValue(false);

    }
}
  onSubmit() {
    const dto = this.model.toDto();
    dto.communityId = this.model.communityId;
    if (this.model.communitySettingsId > 0) {
      this.communitySettingService.update(dto).subscribe();
    } else {
        this.communitySettingService.add(dto).subscribe();
    }
    this.goToPackageList();

  }
 
  cancel() {
    this.goToPackageList();
  }
  goToPackageList() {
    this.router.navigateByUrl('/app/visitors');
  }

}
