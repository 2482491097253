import { BaseForm } from '../building-blocks/base-form';
import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class CompanyGuests extends BaseForm {


    companyId = 0;

    @FormField('name', [])
    name = '';

    isDeleted = false;

}
