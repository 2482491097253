import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { EmailService } from '../services/email.service';
import { ApiResponse } from '../model/api-response';
import { ServiceError } from '../model/service-error';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmEmailForm } from '../model/confirm-email-form';
@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {
  public model: ConfirmEmailForm;
  @Input() username: string;
  @Input() token: string;
  public verifyEmailFormGroup: FormGroup;
  public verifyEmailFailed: boolean;
  public verifyEmailErrorMessage: string;

  constructor(private emailService: EmailService,
    private fb: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute) {

    this.model = new ConfirmEmailForm(this.fb);
      this.verifyEmailFormGroup = this.model.buildFormGroup();

  }

  ngOnInit() {
    this.username = this.route.snapshot.paramMap.get('username');
    this.verifyEmailFormGroup.get('username').setValue(this.username);
    this.token = this.route.snapshot.paramMap.get('token').substring(7);
  }

  verifyEmail() {
    const  entity = this.model.toDto();
    this.emailService.confirmEmail(entity.vcode, this.token)
      .subscribe((result: ApiResponse) => {
        if (result.success) {
          this.snackbar.open('Your email has been verified, redirecting to login page.', '', { duration: 4000 });
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 4000);
        } else {
          this.verifyEmailFailed = true;
          this.verifyEmailErrorMessage = result.description;
        }
      }, (error: HttpErrorResponse) => {
        this.verifyEmailFailed = true;
        this.verifyEmailErrorMessage = error.error.description;
      });
  }
}
