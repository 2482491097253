import { Component, EventEmitter, Injector } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';

import { Observable } from 'rxjs';

import { BaseList } from '../building-blocks/base-list';
import { BaseListDialogData } from '../building-blocks/base-list-dialog-data';

import { Tag } from '../model/tag';
import { CrudResponseModel } from '../model/crud-response-model';
import { TagService } from '../services/tag.service';
import { TagAddEditComponent } from '../tag-add-edit/tag-add-edit.component';
import { AccessType } from '../model/access-type-ring';

const tagColumns = [
  'name',
  'isActive',
  'actions'
];

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.css']
})
export class TagListComponent extends BaseList<Tag>  {

  constructor(
    public injector: Injector,
    private tagService: TagService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar) {
    super(tagColumns, '/tag', tagService, 'tag', injector);
    this.loadSecurityRings();
  }
  protected loadSecurityRings() {
    this.securityEntity.rings.push({ringId : 569, accessType: AccessType.Add });
    this.securityEntity.rings.push({ringId : 769, accessType: AccessType.Update });
    this.securityEntity.rings.push({ringId : 669, accessType: AccessType.Delete });
    this.entityRings.push(this.securityEntity);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Tag[]> {
    return this.tagService.getAllPaginated(pageIndex, pageSize, filter);
  }

  protected getCount(): Observable<number> {
    return this.tagService.getCount();
  }

  deleteItem(id: number): void {
    this.loading = true;
    this.tagService.delete(id).subscribe(x => {
      this.dataSource.reload();
      this.loading = false;
      this.snackbar.open('The tag has been successfully deleted.', '', { duration: 3000 });
    });
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>) {
    const dialogRef = this.dialog.open(TagAddEditComponent, {
      width: '600px',
      height: '350px',
      data: new BaseListDialogData<Tag>(isUpdate, model, emitter)
    });

    return dialogRef;
  }
}


