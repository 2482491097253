import { Component, OnInit, OnDestroy } from '@angular/core';
import { SignaturePadService } from '../services/signature-pad.service';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { SiteHelper } from '../helpers/site-helper';
import { MatDialog } from '@angular/material';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-pair-device',
  templateUrl: './pair-device.component.html',
  styleUrls: ['./pair-device.component.css']
})
export class PairDeviceComponent implements OnInit, OnDestroy {
  public QrCode = '';
  breadcrumbChanges: Subscription;
  private communityId: number;
  public loading: boolean;
  constructor(private signaturePadService: SignaturePadService,
    private dialog: MatDialog) {
      this.getCommunity();
    }
    getCommunity() {
      this.QrCode = '';
      this.communityId  = LocalStorageHelper.getCommunitiesFromBreadcrumb();
      if (this.communityId === 0) {
        SiteHelper.openDialogAlert('info' , 'please select a community on breadcrumb', this.dialog);
        return;
      }
      this.getQrCode();
    }

  ngOnInit() {

    this.breadcrumbChanges  = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      if (this.communityId !== LocalStorageHelper.getCommunitiesFromBreadcrumb()) {
        this.getCommunity();
      }
    });
  }
  ngOnDestroy() {
    if (this.breadcrumbChanges) {
      this.breadcrumbChanges.unsubscribe();
    }
  }
  getQrCode() {
    this.signaturePadService.getQRCode(LocalStorageHelper.getClientToken(), this.communityId).subscribe(qrByteArray => {
      const reader = new FileReader();
      const blob = new Blob([new Uint8Array(qrByteArray)], { type: 'image/png' });
      if (blob.size > 0) {
        const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
        reader.onload = (event: any) => {
          this.QrCode = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    });
  }
}
