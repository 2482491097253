import { Injectable, Inject } from '@angular/core';
import { Package } from '../model/package';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { SearchParamsBreadcrumb } from '../model/search-params-breadcrumb';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class HotPackagesService extends AbstractDataProviderService<Package> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/hotPackage', http, appConfig, LevelFilter.Unit);
  }

  getAllPaged(pageIndex: number, pageSize: number, filter?: string, unitId?: number): Observable<Package[]> {
      this.setBreadcrumbParams();
      const queryString = '/allPaged/' + pageIndex + '/' + pageSize + '/' + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' +
      this.searchParamsBreadcrumb.communityId + '/' +  this.searchParamsBreadcrumb.areaId
      + '/' +  (unitId != null ? unitId : this.searchParamsBreadcrumb.unitId) +  (filter ? '/' + filter : '');
    return this.http.get<Package[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
}
