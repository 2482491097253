import { APP_CONFIG, GLOBAL_CONFIG } from './app.config';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* Components */
import { AccountDetailsComponent } from './account/account-details/account-details.component';
import { AlertDialogComponent } from './app-dialogs/alert-dialog/alert-dialog.component';
import { AchCreditCardDialogComponent }from './app-dialogs/ach-credit-card-dialog/ach-credit-card-dialog.component';
import { ApiKeyAddEditDialogComponent } from './security/api-key-add-edit-dialog/api-key-add-edit-dialog.component';
import { BreadcrumbComponent } from '../app/breadcrumb/breadcrumb.component';
import { CompanyAddEditDialogComponent } from './company/company-add-edit-dialog/company-add-edit-dialog.component';
import { ConfirmDialogComponent } from './app-dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmForgotPasswordComponent } from './confirm-forgot-password/confirm-forgot-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { EntityAddEditDialogComponent } from './security/entity-add-edit-dialog/entity-add-edit-dialog.component';
import { FooterComponent } from './footer/footer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { LandingCommunityComponent } from './landing-community/landing-community.component';
import { LoginComponent } from './login/login.component';
import { ManagmentCompanyDialogsComponent } from './app-dialogs/managment-company-dialogs/managment-company-dialogs.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { NewsFeedComponent } from './news-feed/news-feed.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ResetPasswordDialogComponent } from './app-dialogs/reset-password-dialog/reset-password-dialog.component';
import { RingAddEditDialogComponent } from './security/ring-add-edit-dialog/ring-add-edit-dialog.component';
import { RoleAddEditDialogComponent } from './security/role-add-edit-dialog/role-add-edit-dialog.component';
import { SearchPeopleDialogComponent } from './app-dialogs/search-people-dialog/search-people-dialog.component';
import { AddAddressDialogComponent } from './app-dialogs/add-address-dialog/add-address-dialog.component';
import { SelectUserDialogsComponent } from './app-dialogs/select-user-dialogs/select-user-dialogs.component';
import { TagAddEditComponent } from './tag-add-edit/tag-add-edit.component';
import { TaglistAddEditComponent } from './tag/taglist-add-edit/taglist-add-edit.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UnitAddEditComponent } from './unit/unit-add-edit/unit-add-edit.component';
import { UploadImageComponent } from './app-dialogs/upload-image/upload-image.component';
import { UsersAddEditDialogComponent } from './security/users-add-edit-dialog/users-add-edit-dialog.component';

// import { DocumentAddEditComponent } from '../../ThorinFrontend/src/app/document/document-add-edit/document-add-edit.component';

/* Modules */
import { AngularFireModule } from 'angularfire2';
import { AppRouterModule } from './app-router/app-router.module';
import { CommunityModule } from './community/community.module';
import { CompaniesModule } from './companies/companies.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule  } from 'angular-svg-icon';
import { LedgerModule } from './ledger/ledger.module';
import { MaterialModule } from './material.module';
import { SecurityModule } from './security/security.module';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TagModule } from './tag/tag.module';
import { AppThorinModule } from '../../ThorinFrontend/src/app/app.thorin.module';
import { MassCommunicationModule } from './mass-communication/mass-communication.module';
import { AnonymousPaymentModule } from './anonymous-payment/anonymous-payment.module';
import { PackagesModule } from './community-packages/community-packages.module';
import { VisitorsModule } from './visitors/visitors.module';
import { SharedModule } from './shared/shared.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { ServicesInActionModule } from './services-in-action/services-in-action.module';




/* Pipes */
import { FileSizePipe } from './pipes/file-size.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FirstLetterPipe } from './pipes/first-letter-pipe.component';

/* Directives */
import { DropZoneDirective } from './directives/drop-zone.directive';

/* Configuration */
import { environment } from '../environments/environment';
// tslint:disable-next-line:max-line-length
import { CommunityImportationFromC3poComponent } from './community/community-importation-from-c3po/community-importation-from-c3po.component';
import { RegisterComponent } from './register/register.component';

import { PaymentDialogComponent } from './app-dialogs/payment-dialog/payment-dialog.component';
import { PaymentConfirmationComponent } from './app-dialogs/payment-confirmation/payment-confirmation.component';
import { AddCreditCardDialogComponent } from './app-dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import { RegisterStep1Component } from './register/register-step1/register-step1.component';
// import { ThorinTestComponent } from 'ThorinFrontend/thorin-test/thorin-test.component';

import { AddAchPaymentDialogComponent } from './app-dialogs/add-ach-payment-dialog/add-ach-payment-dialog';
import { DynamicMenuComponent } from './building-blocks/components/dynamic-menu/dynamic-menu.component';
import { ClearCacheMenuComponent } from './clear-cache-menu/clear-cache-menu.component';
import { TemplateRingAddDialogComponent } from './security/template-ring-add-dialog/template-ring-add-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatTreeModule } from '@angular/material';
import { LocationBarComponent } from './location-bar/location-bar.component';
import { NewsFeedModule } from './news-feed/news-feed.module';
import { EnumKeyValueListPipe } from './pipes/enum-list.pipe';
import { TextMaskModule } from 'angular2-text-mask';
import { AddPhoneDialogComponent } from './app-dialogs/add-phone-dialog/add-phone-dialog.component';
import { AddEmailDialogComponent } from './app-dialogs/add-email-dialog/add-email-dialog.component';
import { ConfigDetailComponent } from './community/community-configuration/community-configuration-detail/config-detail.component';



@NgModule({
  declarations: [
    DynamicMenuComponent,
    AppComponent,
    CompanyAddEditDialogComponent,
    RoleAddEditDialogComponent,
    RingAddEditDialogComponent,
    UsersAddEditDialogComponent,
    BreadcrumbComponent,
    ApiKeyAddEditDialogComponent,
    EntityAddEditDialogComponent,
    ConfirmDialogComponent,
    FilterPipe,
    FirstLetterPipe,
    LoginComponent,
    HomeComponent,
    ToolbarComponent,
    PageNotFoundComponent,
    SideNavComponent,
    AlertDialogComponent,
    AccountDetailsComponent,
    ManagmentCompanyDialogsComponent,
    TagAddEditComponent,
    TaglistAddEditComponent,
    FooterComponent,
    SelectUserDialogsComponent,
    MenuListItemComponent,
    LandingCommunityComponent,
    SearchPeopleDialogComponent,
    AddAddressDialogComponent,
    AddPhoneDialogComponent,
    AddEmailDialogComponent,
    UploadImageComponent,
    DropZoneDirective,
    FileSizePipe,
    UnitAddEditComponent,
    CommunityImportationFromC3poComponent,
    ForgotPasswordComponent,
    ConfirmForgotPasswordComponent,
    ResetPasswordDialogComponent,
    RegisterComponent,
    ConfirmEmailComponent,
    NewsFeedComponent,
    PaymentDialogComponent,
    PaymentConfirmationComponent,
    AddCreditCardDialogComponent,
    RegisterStep1Component,
    AddAchPaymentDialogComponent,
    AchCreditCardDialogComponent,
    RegisterStep1Component,
    ClearCacheMenuComponent,
    TemplateRingAddDialogComponent,
    LocationBarComponent
    // DocumentAddEditComponent
  ],
  imports: [
    AppRouterModule, // The router import should go before any component declaration to keep routing order.
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserModule,
    BrowserAnimationsModule,
    CommunityModule,
    HttpClientModule,
    MaterialModule,
    AngularSvgIconModule,
    LedgerModule,
    SecurityModule,
    CompaniesModule,
    ServicesInActionModule,
    ContactUsModule,
    TagModule,
    SharedModule,
    AnonymousPaymentModule,
    PackagesModule,
    VisitorsModule,
    AppThorinModule,
    MassCommunicationModule,
    MatTreeModule,
    NewsFeedModule,
    TextMaskModule
  ],
  providers: [
    { provide: APP_CONFIG, useValue: GLOBAL_CONFIG },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  entryComponents: [
    AccountDetailsComponent,
    CompanyAddEditDialogComponent,
    RoleAddEditDialogComponent,
    RingAddEditDialogComponent,
    UsersAddEditDialogComponent,
    ApiKeyAddEditDialogComponent,
    EntityAddEditDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ManagmentCompanyDialogsComponent,
    TagAddEditComponent,
    TaglistAddEditComponent,
    SelectUserDialogsComponent,
    MenuListItemComponent,
    UploadImageComponent,
    SearchPeopleDialogComponent,
    AddAddressDialogComponent,
    AddPhoneDialogComponent,
    AddEmailDialogComponent,
    UnitAddEditComponent,
    CommunityImportationFromC3poComponent,
    ResetPasswordDialogComponent,
    PaymentDialogComponent,
    PaymentConfirmationComponent,
    AddCreditCardDialogComponent,
    AddAchPaymentDialogComponent,
    AchCreditCardDialogComponent,
    NewsFeedComponent,
    TemplateRingAddDialogComponent,
    ConfigDetailComponent

    // DocumentAddEditComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

