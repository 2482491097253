import { Component, OnInit, Injector, EventEmitter } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BaseList } from 'src/app/building-blocks/base-list';
import { CrudResponseModel } from 'src/app/model/crud-response-model';
import { Observable } from 'rxjs';
import { TemplateRole } from 'src/app/model/template-role';
import { TemplateService } from 'src/app/services/template.service';
import { AlertDialogComponent } from 'src/app/app-dialogs/alert-dialog/alert-dialog.component';

const templateColumns = [
  'name',
  'description',
  'roleTypeName',
  'actions'
];

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})
export class TemplateListComponent extends BaseList<TemplateRole> {

  public TITLE_INFO = 'Info';
  public DELETE_MESSAGE = 'Template Role was successfully deleted';

  constructor(
    public injector: Injector,
    private templateService: TemplateService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar) {
    super(templateColumns, '/security/templates', templateService, 'templates', injector);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<TemplateRole[]> {
    return this.templateService.getAllPaginated(pageIndex, pageSize);
  }

  protected getCount(): Observable<number> {
    return this.templateService.getCount();
  }

  protected getById(id: number): Observable<TemplateRole> {
    return this.templateService.getById(id);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>): any {}

  editTemplateRole(templateRoleId: number): void {
    this.router.navigateByUrl('/app/security/templates/' + templateRoleId + '/edit');
  }

  addTemplateRole(): void {
    this.router.navigateByUrl('/app/security/templates/' + 0 + '/edit');
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '300px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteItem(id: number): void {
    this.loading = true;
    this.templateService.deleteTemplateRole(id).subscribe(x => {
      this.dataSource.reload();
      this.loading = false;
      this.openDialog(this.TITLE_INFO, this.DELETE_MESSAGE);
    });
  }

}
