import { NumberLiteralType } from "typescript";
import { AddressTableEntry } from "./address-table-entry";
import { PhoneNumber } from "./phone-number";
import { Email } from "./email";

export class Community {
    communityId: number;
    name: string;
    parent = null;
    propertyManagementCompanyId: number;
    companyId: number;
    isDeleted: boolean;
    originId = 2;
    /**
     * The creation date of this record.
     */
    creationDate: Date = null;

    /**
     * The ID of the user that created this record.
     */
    creationUserId = 0;

    /**
     * The modification date of this record.
     */
    lastModification: Date = null;

    /**
     * The ID of the user that last modified this record.
     */
    modificationUserId = 0;

    modificationOriginId = 2;

    addresses: AddressTableEntry;
    phoneNumbers: PhoneNumber;
    emails: Email; 
    phoneNumber: string;
}

