import { AfterViewInit, Component, OnInit, QueryList, ViewChildren, OnDestroy, Injector, EventEmitter } from '@angular/core';
import { MatPaginator, MatTable, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';

import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';

import { C3POService } from '../../services/c3po.service';
import { PaymentDialogComponent } from 'src/app/app-dialogs/payment-dialog/payment-dialog.component';
import { PaymentConfirmationComponent } from 'src/app/app-dialogs/payment-confirmation/payment-confirmation.component';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AccountService } from 'src/app/services/account.service';
import { FinancialsAccount, BalanceAccount } from 'src/app/model/account';
import { RelationshipService } from 'src/app/services/relationship.service';
import { PaymentInfo } from 'src/app/model/paymentInfo';
import { Unit } from 'src/app/model/unit';
import { UnitService } from 'src/app/services/unit.service';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { AccessType } from 'src/app/model/access-type-ring';
import { Transaction } from 'src/app/model/transaction';
import { TransactionItem } from 'src/app/model/transaction-item';
import { TransactionsService } from 'src/app/services/transactions.service';
import { tap, map } from 'rxjs/operators';
import { BaseList } from 'src/app/building-blocks/base-list';
import { LoggedUser } from 'src/app/model/logged-user';
import { CrudResponseModel } from 'src/app/model/crud-response-model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormGroup, FormControl } from '@angular/forms';
import { TransactionTypes } from 'src/app/model/transaction-type';
import { TransactionTypeService } from 'src/app/services/transaction-type.service';
import { Resident } from '../../model/resident';
import { SiteHelper } from '../../helpers/site-helper';

const displayedColumns = [
  'description',
  'amount',
  'transType',
  'date'
];

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  animations: [
    trigger('searchOpenClose', [
      state('open', style({
        'width': '245px',
        'border': '1px solid lightgray',
        'padding-left': '5px'
      })),
      state('closed', style({
        'width': '0px',
        'border': 'none'
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ])
    ]),
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TransactionsComponent extends BaseList<Transaction> implements AfterViewInit, OnDestroy {

  public accounts: number[] = [];
  public accountsExte: string[] = [];
  public dataSourceArray: MatTableDataSource<Transaction>[] = [];
  public balances: number[] = [];
  public unitId: number;
  public unitName: string;
  private paymentInfo: PaymentInfo[];
  private showFilter: boolean = false;
  private showSearch: boolean = true;
  private localStorageuserInfo: LoggedUser = null;
  public resident: Resident;
  private localStorageAccount: number;
  private filterFormGroup: FormGroup;
  private searchString: string = '';
  private transactionType: TransactionTypes[]
  private selectedTransactionType: string;
  private filterParams = {
    transDate: null,
    transType: '',
    transTypeCode: '',
    transDesc: ''
  };
  private cols = [
    {
      columnDef: 'date',
      headerName: 'Date',
      cell: (transaction) => this.formatTransDate(transaction.date),
      sortable: true
    },
    {
      columnDef: 'description',
      headerName: 'Description',
      cell: (transaction) => transaction.description,
      sortable: true
    },
    {
      columnDef: 'transType',
      headerName: 'Type',
      cell: (transaction) => transaction.transType,
      sortable: true
    },
    {
      columnDef: 'amount',
      headerName: 'Amount',
      cell: (transaction) => '$' + parseFloat(transaction.amount).toFixed(2),
      sortable: true
    },
  ];
  

  constructor(
    private c3poService: C3POService,
    private accountService: AccountService,
    private unitService: UnitService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private relationshipService: RelationshipService,
    private transactionService: TransactionsService,
    private transactionTypeService: TransactionTypeService,
    private injector: Injector
  ) {
    super(displayedColumns, '/transactions', transactionService, 'transactions', injector);
    this.filterFormGroup = new FormGroup({
      transDateFormControl: new FormControl(''),
      transDescFormControl: new FormControl(''),
      transTypeFormControl: new FormControl('')
    });

    this.unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
    this.paymentInfo = LocalStorageHelper.getPaymentInfo().filter(x => (x.accountExternalCode));
    if (this.paymentInfo.length > 0 || this.unitId > 0) {
      this.localStorageAccount = LocalStorageHelper.getAccountIdFromBreadcrumb();
      this.localStorageuserInfo = LocalStorageHelper.getLoggedUserInfo(false);
      if (this.localStorageuserInfo.isResident) {
        this.resident = this.localStorageuserInfo.residents.filter(u => u.userId = this.localStorageuserInfo.userId)[0];
      }
      this.accounts = Array.from(new Set(this.localStorageuserInfo.residents.map(accountNo => accountNo.accountId)));
      this.accountsExte = Array.from(new Set(this.localStorageuserInfo.residents.map(accountNo => accountNo.externalAccountNumber)));
    }

    this.accountService.getBalances().subscribe(d => {
      if (d) {
        this.balances.push(d[0].amount);
      }
    });
    
    this.transactionTypeService.getAll().subscribe(data => {
      if (data && data.length > 0) {
        this.transactionType = data;
      }
    });

    this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.securityEntity.rings.push({ ringId: 7012, accessType: AccessType.Add });
    this.securityEntity.rings.push({ ringId: 7013, accessType: AccessType.Execute });
    this.entityRings.push(this.securityEntity);
  }

  protected getList(pi: number, ps: number, fi: string): Observable<Transaction[]> {
    if (this.paymentInfo.length > 0 || this.accounts.length > 0) {
      return this.transactionService.getAllFilteredPaged(pi, ps, this.accounts[0], this.filterParams.transDate,
        this.filterParams.transType, this.filterParams.transTypeCode,  this.filterParams.transDesc, this.dataSource.sortData);
    }
    return this.transactionService.getAllFilteredPaged(pi, ps, 0, this.filterParams.transDate, this.filterParams.transType,
      this.filterParams.transTypeCode, this.filterParams.transDesc, this.dataSource.sortData);
  }

  protected getCount(): Observable<number> {
    if (this.accounts.length > 0) {
      return this.transactionService.getCountFiltered(this.accounts[0].toString(), this.filterParams.transDate,
      this.filterParams.transType, this.filterParams.transTypeCode,  this.filterParams.transDesc);
    }
    return this.transactionService.getCount('0');
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>) {
    throw new Error("Method not implemented.");
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  applySort($event) {
    this.dataSource.sortData = $event.sortData;
    this.dataSource.reload();
  }

  search(searchParameter: string) {
    this.filterParams.transDesc = searchParameter;
    this.dataSource.reload();
  }

  applyAdvanceFilter() {
    this.assignFilterValues();
    this.dataSource.reload();
  }

  resetAdvanceFilters() {
    this.onChangeTransactionType(null);
    this.filterFormGroup.controls['transDescFormControl'].setValue('');
    this.filterFormGroup.controls['transDateFormControl'].setValue(null);
    this.assignFilterValues();
    this.dataSource.reload();
  }

  assignFilterValues() {
    this.filterParams.transDesc = this.filterFormGroup.controls['transDescFormControl'].value;
    this.filterParams.transDate = this.filterFormGroup.controls['transDateFormControl'].value;
  }
  onChangeTransactionType(value) {
    if (value) {
      this.filterParams.transType = value;
    this.filterParams.transTypeCode = this.transactionType.filter(t => t.transactionTypeId === value)[0].name;
    } else {
      this.filterFormGroup.controls['transTypeFormControl'].setValue('');
      this.filterParams.transTypeCode = null;
      this.filterParams.transType = '';
    }
  }

  formatTransDate(value): string {
    let newDate = new Date(value);
    return `${newDate.getUTCFullYear()}/${newDate.getUTCMonth() + 1}/${newDate.getUTCDate()}`;
  }

  openPayment(account: string) {
    let data;
    data = {
      accounts: [
        account
      ]
    };
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: data,
      panelClass:  SiteHelper.getDefaultTheme()
    });
    dialogRef.componentInstance.registerPaymentEvent.subscribe(result => {
      if (result) {
        this.balances = [];
        this.accountService.getBalances().subscribe(d => {
          if (d) {
            this.balances.push(d[0].amount);
          }
        });
        this.dataSource.reload();
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const dialogConfirmation = this.dialog.open(PaymentConfirmationComponent, {
          data: result,
          panelClass:  SiteHelper.getDefaultTheme()
        });
        dialogConfirmation.afterClosed().subscribe(results => {

        });
      }
    });
  }

  downloadPDFEvent(doc: any) {
    doc.save('grid-system-export.pdf');
  }

  downloadCSVEvent(directive: any, options: any) {
    directive.exportTable('csv', options);
  }

}