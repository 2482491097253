import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';

import { Observable, forkJoin } from 'rxjs';

import { PackageLog } from 'src/app/model/packageLog';
import { BaseList } from 'src/app/building-blocks/base-list';
import { PackageLogService } from 'src/app/services/package-log.service';
import { PackageStatusService } from 'src/app/services/package-status.service';
import { PackagesStatus } from 'src/app/model/packages-status';
import { Carrier } from 'src/app/model/carrier';
import { CarrierService } from 'src/app/services/carrier.service';
import { MatSelect } from '@angular/material/select';
import { MatIconRegistry, MatSort } from 'material2/src/lib';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { Router } from '../../../../node_modules/@angular/router';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { AccessType, EntityRing } from '../../model/access-type-ring';


const packageColumns = [
  'receivedDate',
  'trackNumber',
  'unit',
  'recipient',
  'carrierName',
  'actions'
];

@Component({
  selector: 'app-package-log',
  templateUrl: './package-log.component.html',
  styleUrls: ['./package-log.component.css']
})

export class PackageLogComponent extends BaseList<PackageLog> implements OnInit {

  @ViewChild('fromDateInput', {
    read: MatInput
  }) fromDateInput: MatInput;

  @ViewChild('toDateInput', {
    read: MatInput
  }) toDateInput: MatInput;

  @ViewChild('trackingNumberInput', {
    read: MatInput
  }) trackingNumberInput: MatInput;


  @ViewChild('recipientInput', {
    read: MatInput
  }) recipientInput: MatInput;

  @ViewChild('statusSelect', {
    read: MatSelect
  }) statusSelect: MatSelect;

  
  @ViewChild('statusSelectFilter', {
    read: MatSelect
  }) statusSelectFilter: MatSelect;


  @ViewChild('carrierSelect', {
    read: MatSelect
  }) carrierSelect: MatSelect;

  protected packageStatus = 1;
  protected carrier = 0;
  protected filter: string = null;
  protected packagesStatus: PackagesStatus[] = [];
  protected carriers: Carrier[] = [];
  protected noData = 0;
  protected showNoRecords = false;
  protected usingParametersFlag = true;
  showFilter = false;
  sortByDesc = true;
  public isResident: boolean;

  @ViewChild(MatSort, {read: true}) sort: MatSort;
  selectedAccessType: number;
  securityEntityDeliver = new EntityRing();
  
  constructor(
    public injector: Injector,
    private packageService: PackageLogService,
    private packageStatusService: PackageStatusService,
    private carrierService: CarrierService,
    private _route: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer, ) {
    super(packageColumns, '/packageLog', packageService, 'packageLog', injector);
    this.filters = '';
    this.loading = true;
    
    this.matIconRegistry.addSvgIcon(
      "deliver",
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/deliver.svg')
    );
    this.matIconRegistry.addSvgIcon(
      "package",
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/packages.svg')
    );
    this.matIconRegistry.addSvgIcon(
      "search",
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/search.svg')
    );
    this.isResident = LocalStorageHelper.getLoggedUserInfo(false).isResident;
    this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.securityEntity.rings.push({ ringId: 7110, accessType: AccessType.Add });
    this.entityRings.push(this.securityEntity);
    this.securityEntityDeliver.rings.push({ ringId: 7038, accessType: AccessType.Add });
    this.entityRings.push(this.securityEntityDeliver);
  }
  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    forkJoin(this.packageStatusService.getAll(),
      this.carrierService.getAll()).subscribe(results => {

        this.packagesStatus = results[0];
        this.carriers = results[1];

        const c = new Carrier();
        c.carrierId = 0;
        c.name = 'All';
        c.isDeleted = false;
        this.carriers.push(c);
        this.carriers.reverse();
      });
  }

  sortData(event) {
    this.loading = true;
    this.sortByDesc = event.direction == 'desc';    
    this.dataSource.reload();
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<PackageLog[]> {

    let trackNumber = '';
    let recipient = '';
    let fromDate = '';
    let toDate = '';
    let packageStatus = 0;
    let carrier = 0;

    if (this.showFilter) {
      trackNumber = this.trackingNumberInput.value ? this.trackingNumberInput.value : '';
      recipient = this.recipientInput.value ? this.recipientInput.value : '';
      fromDate = this.fromDateInput.value ? new Date(this.fromDateInput.value).toISOString() : '';
      toDate = this.toDateInput.value ? new Date(this.toDateInput.value).toISOString() : '';
    }
    packageStatus = this.packageStatus ? this.packageStatus : 0;
    carrier = this.carrier ? this.carrier : 0;
    this.showNoRecords = false;
    const result = this.packageService.getAllPaged(pageIndex, pageSize, this.usingParametersFlag, trackNumber,
      recipient, fromDate, toDate, packageStatus, carrier, this.sortByDesc);

    /*result.subscribe(c => {
      this.showNoRecords = true;
      this.noData = c.length;
    });*/

    return result;
  }

  protected getCount(): Observable<number> {

    let trackNumber = '';
    let recipient = '';
    let fromDate = '';
    let toDate = '';
    let packageStatus = 0;
    let carrier = 0;

    if (this.showFilter) {
      trackNumber = this.trackingNumberInput.value ? this.trackingNumberInput.value : '';
      recipient = this.recipientInput.value ? this.recipientInput.value : '';
      fromDate = this.fromDateInput.value ? new Date(this.fromDateInput.value).toISOString() : '';
      toDate = this.toDateInput.value ? new Date(this.toDateInput.value).toISOString() : '';
    }
    packageStatus = this.packageStatus ? this.packageStatus : 0;
    carrier = this.carrier ? this.carrier : 0;

    const result = this.packageService.getCountForPackageLog(this.usingParametersFlag, trackNumber,
      recipient, fromDate, toDate, packageStatus, carrier);
    return result;
  }

  protected getById(id: number): Observable<PackageLog> {
    return this.packageService.getById(id);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any { }

  public edit(row) {
    this._route.navigateByUrl('/app/packages/' + + row.packageId + '/edit');
  }
  public deliver(row) {
    this._route.navigateByUrl('/app/packages/deliver/' + row.packageId);
  }
  ShortenStringDescription(description: string): string {
    if (description) {
      if (description.length > 15) {
        return description.substring(0, 15) + '..';
      }
      return description;
    }
  }

  viewDetail(pl: PackageLog): void {
    const id = pl.packageId;
    this.router.navigate(['app/packages/log/detail/' + id + '/' + pl.isHot]);
  }

  dateSelected(isFromDate: boolean, value: any) {
    if (value) {
      if (!isFromDate) {
        if (value <= this.fromDateInput.value) {
          alert('To Date must be greater than the From Date');
          this.toDateInput.value = '';
        } else {
          this.toDateInput.value = value;
        }
      } else {
        this.fromDateInput.value = value;
      }
    } else {
      this.toDateInput.value = '';
      this.fromDateInput.value = '';
    }
  }

  trackingNumberInputChange(value: any) {
    this.trackingNumberInput.value = value ? value : '';
  }

  recipientInputChange(value: any) {
    this.recipientInput.value = value ? value : '';
  }

  statusSelectedFilter(value: any) {
    this.loading = true;
    this.statusSelected(value);
    this.dataSource.reload();
  }

  statusSelected(value: any) {
    this.packageStatus = value ? value : 0;
  }

  carrierSelected(value: any) {
    this.carrier = value ? value : 0;
  }

  applyFilters() {
    if (this.showFilter && this.validateFilters()) {
      this.loading = true;
      this.reload();
    }
  }

  validateFilters(): boolean {

    if (this.fromDateInput.value && this.fromDateInput.value !== '' && this.fromDateInput.value !== null) {

      if (!this.toDateInput.value) {
        alert('To date must be completed');
        return false;
      }

      if (this.toDateInput.value === '') {
        alert('To date must be completed');
        return false;
      }

      if (this.toDateInput.value === null) {
        alert('To date must be completed');
        return false;
      }
    }

    if (this.toDateInput.value && this.toDateInput.value !== '' && this.toDateInput.value !== null) {
      if (!this.fromDateInput.value) {
        alert('From date must be completed');
        return false;
      }

      if (this.fromDateInput.value === '') {
        alert('From date must be completed');
        return false;
      }

      if (this.fromDateInput.value === null) {
        alert('From date must be completed');
        return false;
      }
    }

    this.usingParametersFlag = ((this.fromDateInput.value && this.fromDateInput.value !== '' && this.fromDateInput.value !== null) ||
      (this.trackingNumberInput.value && this.trackingNumberInput.value !== '' && this.trackingNumberInput.value !== null) ||
      (this.recipientInput.value && this.recipientInput.value !== '' && this.recipientInput.value !== null) ||
      (this.packageStatus && this.packageStatus !== 0) ||
      (this.carrier && this.carrier !== 0) ||
      (this.toDateInput.value && this.toDateInput.value !== '' && this.toDateInput.value !== null));

    return true;
  }

  protected clearFilter() {
    this.loading = true;
    this.trackingNumberInput.value = '';
    this.recipientInput.value = '';
    this.fromDateInput.value = '';
    this.toDateInput.value = '';
    this.statusSelect.value = 0;
    this.carrierSelect.value = 0;
    this.packageStatus = 1;
    this.carrier = 0;
    this.reload();
  }
}
