import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class CommunitySettings extends BaseForm {

    communitySettingsId = 0;
    communityId= 0;

    @FormField('packagesEnabled', [false, Validators.required])
    packagesEnabled = false;

    @FormField('showSender', [false, Validators.required])
    showSender = false;

    @FormField('requireSender', [false, Validators.required])
    requireSender = false;

    @FormField('showSignature', [false, Validators.required])
    showSignature = false;

    @FormField('requireSignature', [false, Validators.required])
    requireSignature = false;

    @FormField('showPackageType', [false, Validators.required])
    showPackageType = false;

    @FormField('requirePackageType', [false, Validators.required])
    requirePackageType = false;

    @FormField('showStorage', [false, Validators.required])
    showStorage = false;

    @FormField('requireStorage', [false, Validators.required])
    requireStorage = false;

    @FormField('showNote', [false, Validators.required])
    showNote = false;

    @FormField('requireNote', [false, Validators.required])
    requireNote = false;

    @FormField('notificationDelayInMiliseconds', [0, Validators.required])
    notificationDelayInMiliseconds = 0;

    @FormField('notificationTypeId', [null, Validators.required])
    notificationTypeId = null;

    @FormField('visitorsEnabled', [false, Validators.required])
    visitorsEnabled = false;

    @FormField('visitorsPicture', [false, Validators.required])
    visitorsPicture = false;

    @FormField('requireVisitorsPicture', [false, Validators.required])
    requireVisitorsPicture = false;

    @FormField('visitorsSignature', [false, Validators.required])
    visitorsSignature = false;

    @FormField('requireVisitorsSignature', [false, Validators.required])
    requireVisitorsSignature = false;

    @FormField('visitorsCompanyName', [false, Validators.required])
    visitorsCompanyName = false;

    @FormField('requireCompanyName', [false, Validators.required])
    requireCompanyName = false;

    @FormField('visitorsType', [false, Validators.required])
    visitorsType = false;

    @FormField('requireVisitorsType', [false, Validators.required])
    requireVisitorsType = false;

    @FormField('visitorsBadge', [false, Validators.required])
    visitorsBadge = false;

    @FormField('requireVisitorsBadge', [false, Validators.required])
    requireVisitorsBadge = false;

    @FormField('vehiclesEnabled', [false, Validators.required])
    vehiclesEnabled = false;

    @FormField('vehicleMaker', [false, Validators.required])
    vehicleMaker = false;

    @FormField('requireVehicleMaker', [false, Validators.required])
    requireVehicleMaker = false;

    @FormField('vehicleModel', [false, Validators.required])
    vehicleModel = false;

    @FormField('requireVehicleModel', [false, Validators.required])
    requireVehicleModel = false;

    @FormField('vehicleYear', [false, Validators.required])
    vehicleYear = false;

    @FormField('requireVehicleYear', [false, Validators.required])
    requireVehicleYear = false;

    @FormField('vehicleLicensePlate', [false, Validators.required])
    vehicleLicensePlate = false;

    @FormField('requireVehicleLicensePlate', [false, Validators.required])
    requireVehicleLicensePlate = false;

    @FormField('vehicleColor', [false, Validators.required])
    vehicleColor = false;

    @FormField('requireVehicleColor', [false, Validators.required])
    requireVehicleColor = false;

    @FormField('vehicleParkingTicket', [false, Validators.required])
    vehicleParkingTicket = false;

    @FormField('requireVehicleParkingTicket', [false, Validators.required])
    requireVehicleParkingTicket = false;
}
