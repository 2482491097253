import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatSidenav } from '@angular/material';

import { filter } from 'rxjs/operators';

/* Components */
import { AccountDetailsComponent } from '../account/account-details/account-details.component';

/* Services */
import { LoginService } from '../services/login.service';
import { MenuService } from '../services/menu.service';
import { SearchService } from '../services/search.service';

import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { Menu } from '../model/menu';
import { LocationBarComponent } from '../location-bar/location-bar.component';
import { CrossEntitySearchResult } from '../model/cross-entity-search-result';
import { SiteHelper } from '../helpers/site-helper';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {

  public links = [];
  public routerTitle: string;
  public routerFilter: string;
  public loading: boolean;
  public loadingSearchResult: boolean;
  public impersonate = false;
  public readonly MENU_ITEM_ACCOUNT = 130;
  public readonly MENU_ITEM_LOGOUT = 132;
  @ViewChild('side') side: MatSidenav;
  @ViewChild('locationbar') locationbar: LocationBarComponent;
  private showSearch:boolean = true;
  private searchResult: CrossEntitySearchResult[];
  private searchPerformed: boolean = false;
  private errorOnSearch: boolean = false;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private menuService: MenuService,
    private searchService: SearchService,
    private dialog: MatDialog,
    url: ActivatedRoute) {

    this.populateMenu();
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      .forEach(e => {
        this.routerTitle = url.root.firstChild.snapshot.data.title;
      });
  }

  @HostBinding('class.is-open')
  collapse = false;

  public populateMenu() {
    this.links = this.menuService.mapMenuToAngularRoutes(LocalStorageHelper.getUserMenu());
    this.links.forEach(link => {
      link.selected = false;
      if (link.children) {
        link.children.forEach(child => {
          child.selected = false;
        });
      }
    });
    const accountMenu = this.menuService.getItemMenuById(this.links, this.MENU_ITEM_ACCOUNT);
   if (accountMenu !== null) {
      accountMenu.click =  () => this.openAccountDialog(this);
    }

    const logoutMenu = this.menuService.getItemMenuById(this.links, this.MENU_ITEM_LOGOUT);
   if (logoutMenu !== null) {
    logoutMenu.click =  () => this.logout(this);
    }
  }

  ngOnInit() {
    this.routerFilter = '';
    this.menuService.reloadMenu.subscribe(_ => {
      this.populateMenu();
    });
    this.getCrossEntitySearchParameterFromLocalStorage();
  }
  
  toggle() {
    this.collapse = !this.collapse;
    this.resetMenuTreeState(this.links);
   
  }

  resetMenuTreeState(menuItem: any) {
    menuItem.forEach(link => {
      link.selected = false;
      link.opened = false;
      if (link.children.length > 0) {
        this.resetMenuTreeState(link.children);
      }
    });
  }

  openMenu() {
    this.collapse = false;
    this.menuService.setSidePanelState(this.collapse);
  }

  logout(that: SideNavComponent) {
    that.loginService.logout();
    that.router.navigateByUrl('/login');
  }

  openAccountDialog(that: SideNavComponent) {
    this.impersonate  = LocalStorageHelper.getImpersonatedFlag();
    const dialogRef = that.dialog.open(AccountDetailsComponent, {
      width: '800px',
      data: {isImpersonated : this.impersonate},
      panelClass:  SiteHelper.getDefaultTheme()
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  performSearch($event) {
    let propertyManagementCompanyId: number = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    let communityId: number = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    let areaId: number = LocalStorageHelper.getBuildingFromBreadcrumb();
      this.loadingSearchResult = true;
      this.searchPerformed = true;
      
      // API
      this.searchService.crossSearch(propertyManagementCompanyId, communityId, $event.searchText).subscribe(d => {
        this.searchResult = d;
        this.loadingSearchResult = false;
      }, (error) => {
        console.log(error);
        this.errorOnSearch = true
      });
    }
  
      
  closeSearchResult() {
    this.searchPerformed = false;
  }

  getCrossEntitySearchParameterFromLocalStorage() {
    let loggedUser = LocalStorageHelper.getLoggedUserInfo(false)
    this.showSearch = !loggedUser.isResident;
  }

}
