import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskPhoneNumber'
})

export class MaskPhoneNumberPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    if (value.length !== 10) {
      return value;
    }
    return value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6);
  }
}

