import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../src/app/material.module';
import { CartRoutingModule } from './cart-routing/cart-routing.module';
import { FormsModule } from '@angular/forms';

import { CartStep1Component } from './cart-step1/cart-step1.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    CartRoutingModule,
  ],
  declarations: [
    CartStep1Component
  ]
})
export class CartModule { }
