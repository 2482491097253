import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { AbstractDataProviderService } from './abstract-data-provider.service';

import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { PictureEntry } from '../model/picture-entry';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';
import { Picture } from '../model/picture';
import { LevelFilter } from '../model/breadcrumb';
@Injectable({
  providedIn: 'root'
})
export class PictureService  extends AbstractDataProviderService<PictureEntry>  {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/picture', http, appConfig, LevelFilter.Community);
  }

  GetProfilePicture(): Observable<any> {
    return  this.http.get(this.baseUrl + '/profilePicture', { headers: this.buildGetHeaders(), responseType: 'arraybuffer' });
  }

  GetProfilePictureByPeopleIdArray(peopleIdArray: number[]): Observable<any[]> {
    let peopleIds = 'peopleId=';

    peopleIdArray.forEach(u => {
      peopleIds += u.toString() + ',';
    });

    peopleIds = peopleIds.slice(0, -1);

    return  this.http.get<any[]>(this.baseUrl + '/profilePictureByPeopleIdArray/?' + peopleIds.toString(), 
        { headers: this.buildGetHeaders() });
  }
  GetProfilePictureImpersonated(): Observable<any> {
    return  this.http.get(this.baseUrl + '/profilePictureImpersonated', { headers: this.buildGetHeaders(), responseType: 'arraybuffer' });
  }

  GetCommunityPictureGallery(companyId: number): Observable<PictureEntry[]> {
    return  this.http.get<PictureEntry[]>(this.baseUrl + '/communityGallery/' + companyId, { headers: this.buildGetHeaders()});
  }

  GetPicturesPagedByCommunityId(communityId: number, pageIndex: number, pageSize: number): Observable<PictureEntry[]> {
    return  this.http.get<PictureEntry[]>(this.baseUrl + '/communityGallery/' + communityId + '/'
    + pageIndex + '/' + pageSize
    , { headers: this.buildGetHeaders()});
  }

  GetCompanyPictureLogo(companyId: number): Observable<any> {
    return  this.http.get(this.baseUrl + '/companyPictureLogo/' + companyId,
    { headers: this.buildGetHeaders(), responseType: 'arraybuffer' });
  }

  SetProfilePicture(picture: Uint8Array): Observable<null> {
    return this.http.post<null>(this.baseUrl + '/profilePicture', picture, { headers: this.buildGetHeaders() });
  }

  SetCompanyPictureLogo(companyId: number, picture: Uint8Array): Observable<any> {
    return this.http.post<null>(this.baseUrl + '/companyPictureLogo/' + companyId, picture, { headers: this.buildGetHeaders() });
  }

  AddPicture(picture: PictureEntry): Observable<any> {
    return this.http.post<PictureEntry>(this.baseUrl + '/addPicture' , picture, { headers: this.buildGetHeaders() });
  }

  getCountByCommunityId(communityId: number): Observable<number> {
      const queryString = '/getCountByCommunityId/' + communityId;
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  update(entity: Picture): Observable<null> {
    entity['originId'] = 2;
    return this.http.put<null>(this.baseUrl, entity, { headers: this.buildPostHeaders() });
  }
}
