import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../src/app/material.module';
import { DeliveryOptionRoutingModule } from './delivery-option-routing/delivery-option-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../thorin-shared/shared.module';

import { DeliveryOptionListComponent } from './delivery-option-list/delivery-option-list.component';
import { DeliveryOptionAddEditComponent } from './delivery-option-add-edit/delivery-option-add-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    DeliveryOptionRoutingModule,
    SharedModule
  ],
  declarations: [
    DeliveryOptionListComponent,
    DeliveryOptionAddEditComponent
  ]
})
export class DeliveryOptionModule { }
