import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Community_Position,  Position} from '../model/community-position';

import {map} from 'rxjs/operators';
import { LocalStorageHelper } from '../helpers/local-storage-helper';

@Injectable({
  providedIn: 'root'
})
export class CommunityPositionService  extends AbstractDataProviderService<Community_Position> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/CommunityPosition', http, appConfig);
   }

  getAll(): Observable<Community_Position[]> {
    const communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    const queryString = `/byCommunity/${ communityId }`;
    return this.http.get<Community_Position[]>(this.baseUrl + queryString, {headers: this.buildGetHeaders()});
  }

  getPositions(): Observable<Position[]> {
    return this.getAll().pipe(map(x => {
      return x.map(y => new Position(y.community_PositionId, y.positionName));
    }));
  }
}
