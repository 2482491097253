import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material';
import { CommunitySettingsService } from '../../services/community-settings.service';


@Component({
  selector: 'app-actions-visitor',
  templateUrl: './actions-visitor.component.html',
  styleUrls: ['./actions-visitor.component.css']
})

export class ActionsVisitorComponent implements OnInit {
  public isStaff: boolean;
  constructor(private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private communitySettingService: CommunitySettingsService) { }
  ngOnInit() {
    this.userService.isStaff.subscribe(isStaff => this.isStaff = isStaff);
  }
  new() {
    const commmunity = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if (commmunity === 0) {
      SiteHelper.openDialogAlert('Warning',
        'Please select a community on breadcrumb.',
        this.dialog);
    } else {
      this.communitySettingService.getByCommunityId(commmunity).subscribe(c => {
        if (c != null && c.visitorsEnabled) {
          this.router.navigateByUrl('/app/visitors/add');
        } else {
          SiteHelper.openDialogAlert('Warning',
            'you can not add new visitor for this community, please configure enable visitor for this community',
            this.dialog);
        }
      });
    }
  }

  visitorsLog() {
    this.router.navigateByUrl('/app/visitors/visitorlog');
  }

  newGuests() {
    const commmunity = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if (commmunity === 0) {
      SiteHelper.openDialogAlert('Warning',
        'Please select a community on breadcrumb.',
        this.dialog);
    } else {
      this.communitySettingService.getByCommunityId(commmunity).subscribe(c => {
        if (c != null && c.visitorsEnabled) {
          this.router.navigateByUrl('/app/guests/add');
        } else {
          SiteHelper.openDialogAlert('Warning',
            'you can not add new guest for this community, please configure enable visitor for this community',
            this.dialog);
        }
      });
    }
  }

  settings() {
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if (comunityLocalStorage === 0) {
      SiteHelper.openDialogAlert('Warning', 'Select a community on breadcrumb. ', this.dialog);
    } else {
      this.router.navigateByUrl('/app/visitors/' + comunityLocalStorage + '/settings');
    }
  }
}
