import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { SmaugService } from 'src/app/services/smaug.service';
import { CreditCard } from 'src/app/model/creditCard';
import { AccountData } from 'src/app/model/account-data';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AddCreditCardDialogComponent } from 'src/app/app-dialogs/add-credit-card-dialog/add-credit-card-dialog.component';
import { AlertDialogComponent } from 'src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { AddAchPaymentDialogComponent } from 'src/app/app-dialogs/add-ach-payment-dialog/add-ach-payment-dialog';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

interface PaymentItem {
  icon: string;
  name: string;
  lastDigits: number;
  expDate: string;
  sourceCommunityCode: string;
  token: number;
}

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent implements OnInit {
  displayedColumns: string[] = ['icon', 'type', 'last-digits', 'exp-date', 'actions'];
  dataSource: AccountData[] = [];
  public dataSourceArray: any;
  public methods: CreditCard[] = [];
  totalAmount = 0;
  loading = true;
  cardSelected: CreditCard = new CreditCard;

  sourceCommunityCode = '';
  sourceAccountNumber = '';

  paymentMethods: PaymentItem[] = [];
  public enabledAddPaymentMethod = false;
  constructor(
    private smaugService: SmaugService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.userService.emitTenantOwner().subscribe(isTenantOwner => {
      this.enabledAddPaymentMethod = isTenantOwner && LocalStorageHelper.getBreadcrumbInfo() != null;
    });
    this.smaugService.getUserDataForProviderId().subscribe(data => {
      if (data) {
        if (data.length > 0) {
          this.sourceAccountNumber = data[0].sourceAccountNumber;
          this.getPaymentMethod();
        } else { this.loading = false; }
      }
    });
  }

  getFormatDate(value: string): string {
    let newValue = '';
    value.split('').forEach((element, index) => {
      newValue += element;
      if (index === 1) { newValue += '/'; }
    });
    return newValue;
  }

  getPaymentMethod() {
    this.methods = [];
    this.paymentMethods = [];
    this.loading = true;
    const paymentInfo = LocalStorageHelper.getPaymentInfo();
    if (paymentInfo.length > 0) {
      for (let i = 0; i < paymentInfo.length; i++) {
        const element = paymentInfo[i];
        this.smaugService.getPaypointBybyCommunityCode(element.communityExternalCode).subscribe(data => {
          for (let iX = 0; iX < data.length; iX++) {
            const payment = data[iX];
            const creditService = this.smaugService.getCreditCards(payment.paypointId);
            const accountService = this.smaugService.getBanksAccount(payment.paypointId);
            forkJoin(creditService, accountService).subscribe(results => {
              if (results[0]) {
                this.paymentMethods = this.paymentMethods.concat(results[0].map(x => {
                  const newPayment: PaymentItem = {
                    icon: 'credit_card',
                    name: x.name,
                    expDate: this.getFormatDate(x.expDate.toString()),
                    sourceCommunityCode: payment.sourceCommunityCode,
                    lastDigits: x.lastDigits,
                    token: x.token
                  };
                  return newPayment;
                }));
              }
              if (results[1]) {
                this.paymentMethods = this.paymentMethods.concat(results[1].map(x => {
                  const newPayment: PaymentItem = {
                    icon: 'account_balance',
                    name: x.name,
                    expDate: '',
                    sourceCommunityCode: payment.sourceCommunityCode,
                    lastDigits: x.lastDigits,
                    token: x.token
                  };
                  return newPayment;
                }));
              }
              this.dataSourceArray =  new MatTableDataSource(this.paymentMethods);
              this.loading = false;
            });

          }
        }, error => {
          this.snackbar.open(error, '', { duration: 5000 });
          this.loading = false;
        });

      }
    } else {
      this.loading = false;
    }
  }

  openAddCreditCard() {
    let data: any;
    let dialogRef: any;
    const paymentInfo = LocalStorageHelper.getPaymentInfo()[0];
    let paypointSourceAccountNumber = '';
    if (paymentInfo) { paypointSourceAccountNumber = paymentInfo.communityExternalCode; }
    if (paypointSourceAccountNumber === '') {
      data = {
        Title: 'Paypoint Account Number',
        Message: 'Your user is not configured to add payment methods'
      };
      dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: data
      });
    } else {
      data = {
        userSourceAccountNumber: this.sourceAccountNumber,
        paypointSourceAccountNumber: paypointSourceAccountNumber
      };
      dialogRef = this.dialog.open(AddCreditCardDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getPaymentMethod();
        }
      });
    }
  }

  openAddBankAccount() {
    let data: any;
    let dialogRef: any;
    const paymentInfo = LocalStorageHelper.getPaymentInfo()[0];
    let paypointSourceAccountNumber = '';
    if (paymentInfo) { paypointSourceAccountNumber = paymentInfo.communityExternalCode; }
    if (paypointSourceAccountNumber === '') {
      data = {
        Title: 'Paypoint Account Number',
        Message: 'Your user is not configured to add payment methods'
      };
      dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: data
      });
    } else {
      data = {
        userSourceAccountNumber: this.sourceAccountNumber,
        paypointSourceAccountNumber: paypointSourceAccountNumber
      };
      dialogRef = this.dialog.open(AddAchPaymentDialogComponent, {
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getPaymentMethod();
        }
      });
    }
  }

  onChange(value: boolean, index: number) {
    this.dataSource[index].checked = value;
    this.totalAmount = 0;
    this.dataSource.forEach(element => {
      if (element.checked === true) { this.totalAmount += element.amount_due; }
    });
  }

  onCardSelected(value: CreditCard) {
    this.cardSelected = value;
  }
}
