import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { ServiceInActionListComponent } from './service-in-action-list/service-in-action-list.component';
import { ServicesInActionRoutingModule } from './services-in-action-routing.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ServicesInActionRoutingModule,
    SharedModule,
    MaterialModule
  ],
  exports: [
    ServicesInActionRoutingModule
  ],
  declarations: [
    ServiceInActionListComponent
  ]
})
export class ServicesInActionModule { }
