
import { Component, AfterViewInit, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { MatDialog, MatSelect, MatSlideToggleChange } from '@angular/material';
import { AlertDialogComponent } from '../../../../../src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { PricePlan } from '../../model/price-plan';
import { DocumentPricePlan } from '../../model/documentPricePlan';
import { CommunityDialogComponent } from '../../community-dialog/community-dialog.component';
import { CommunityRelationTypeDialogComponent } from '../../community-relation-type-dialog/community-relation-type-dialog.component';

@Component({
  selector: 'app-price-modifiers',
  templateUrl: './price-modifiers.component.html',
  styleUrls: ['./price-modifiers.component.css']
})
export class PriceModifiersComponent implements OnInit {
  @Input() model: DocumentPricePlan;
  @Input() managementCompanyIdSelected: number;
  @Input() formGroup: FormGroup;
  public counterR: number;
  public counterC: number;
  public update: boolean;
  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.counterR = 0;
    this.counterC = 0;
  }

  onChangePrice(value) {
    const before = value[0].before;
    const after = value[0].after;
    value.forEach(v => {
      v.before = before;
      v.after = after;
    });
    this.formGroup.markAsDirty();
  }

  openCommunityDialog(i, details: boolean, id: number) {
    this.counterC ++;
    let data;
    data = {
      comInPriceP: this.model.pricePlan.communities,
      mcIdSelected: this.managementCompanyIdSelected,
      comDetails: i ? i : null,
      // communityIdSelected: this.communityIdSelected,
      update: this.model.pricePlan.pricePlanId > 0 ? true : false,
      counter: this.counterC,
      pricePlanId: this.model.pricePlan.pricePlanId,
      details: details
    };
    const dialogRef = this.dialog.open(CommunityDialogComponent, {
      width: '750px',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.update = result.update;
        this.model.pricePlan.communities = result.comInPriceP;
        if (result.dirty) {
           this.formGroup.markAsDirty();
        }
      }
    });
  }

  openCommRelTypeDialog(i, details: boolean): void {
    this.counterR ++;
  this.setCommRelType(i, details);
}

  setCommRelType(i, details) {
    let data;
    data = {
      relTypeInPriceP: this.model.pricePlan.relationShipTypes,
      relTypeDetails: i ? i : null,
      mcIdSelected: this.managementCompanyIdSelected,
      // communityIdSelected: this.communityIdSelected,
      update: this.model.pricePlan.pricePlanId > 0 ? true : false,
      counter: this.counterR,
      pricePlanId: this.model.pricePlan.pricePlanId,
      details: details
    };
    const dialogRef = this.dialog.open(CommunityRelationTypeDialogComponent, {
      width: '750px',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.update = result.update;
         this.model.pricePlan.relationShipTypes = result.relTypeInPriceP;
        if (result.dirty) {
           this.formGroup.markAsDirty();
        }
      }
    });
  }

  removeCommunityRow(row, idxRow) {
    const domRowToRemove = document.querySelector('#com-list-row-' + idxRow);
    domRowToRemove.closest('.parent-com-to-remove').remove();
    row.forEach(r => {
      const index = this.model.pricePlan.communities.findIndex(c => c.communityId === r.communityId);
      if (index > -1) {
        this.model.pricePlan.communities.splice(index, 1);
        this.formGroup.markAsDirty();
      }
    });
  }

  removeRelationTypeRow(row, idxRow) {
    const domRowToRemove = document.querySelector('#rel-list-row-' + idxRow);
    domRowToRemove.closest('.parent-rel-to-remove').remove();
    row.forEach(r => {
      const index = this.model.pricePlan.relationShipTypes.findIndex(rt => rt.relationshipTypeId === r.relationshipTypeId);
      if (index > -1) {
        this.model.pricePlan.relationShipTypes.splice(index, 1);
        this.formGroup.markAsDirty();
      }
    });
  }

}
