import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Entity } from '../model/entity';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityService extends AbstractDataProviderService<Entity> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.SecurityAdminService, '/entities', http, appConfig);
  }

  getEntitiesByEntityTypeId(id: number): Observable<Entity[]> {
    const sortingHatEndpoint = this.appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/sortingHat/templates';
    const queryString = '/byEntityTypeId/' + id.toString();
    return this.http.get<Entity[]>(sortingHatEndpoint + queryString, { headers: this.buildGetHeaders() });
  }
}
