import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { CommunityConfiguration } from '../model/community-configuration';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunityConfigurationService  extends AbstractDataProviderService<CommunityConfiguration> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/communityConfiguration', http, appConfig);
  }

  getByCommunityId(communityId: number): Observable<CommunityConfiguration[]> {
    return this.http.get<CommunityConfiguration[]>(this.baseUrl + '/byCommunityId/' + communityId, { headers: this.buildGetHeaders() });
  }

}
