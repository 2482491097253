import { Component, OnInit, Injector, EventEmitter} from '@angular/core';
import { ServiceInfo } from 'src/app/model/service-info';
import { SystemInfoService } from 'src/app/services/system-info.service';
import { Observable, Subject} from 'rxjs';
import { BaseList } from 'src/app/building-blocks/base-list';
import { CrudResponseModel } from 'src/app/model/crud-response-model';


const serviceColumns = [
  'name',
  'version',
  'environment'
];

@Component({
  selector: 'app-service-in-action-list',
  templateUrl: './service-in-action-list.component.html',
  styleUrls: ['./service-in-action-list.component.css']
})

export class ServiceInActionListComponent extends BaseList<ServiceInfo> {

  constructor(public systemInfoService: SystemInfoService,
    public injector: Injector) {
    super(serviceColumns, '/tardisInfo', systemInfoService, 'tardisInfo', injector);
   }

   protected getList(pageIndex: number, pageSize: number, filter: string): Observable<ServiceInfo[]> {
    return this.systemInfoService.getServiceInfo();
  }

  protected getCount(): Observable<number> {
    return;
  }

  protected openEditForm(isUpdate: boolean, model: ServiceInfo, emitter: EventEmitter<CrudResponseModel>): any {}
}
