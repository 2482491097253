import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { catchError } from 'rxjs/operators';
import { LocalStorageHelper } from '../helpers/local-storage-helper';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  public baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) appConfig: AppConfig
  ) {
    if (AppConstants.EndpointNames.SortingHat in appConfig.endpoints) {
      const endpoint = appConfig.endpoints[AppConstants.EndpointNames.SortingHat] + '/registration';
      this.baseUrl = endpoint;
    } else {
      console.error('Invalid endpoint name passed to data provider.');
    }
  }

  /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  private buildGetHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();

    let getHeaders = new HttpHeaders();
    getHeaders = getHeaders.set('Authorization', 'bearer ' + token);

    return getHeaders;
  }

  sendCode(emailId: number): Observable<any> {
    const requestUrl = this.baseUrl + `/verificationCode/email/${ emailId }`;
    return this.http.get<any>(requestUrl)
    .pipe(
      catchError(this.handleError)
    );
  }

  verifyCode(emailId: number, code: string): Observable<any> {
    const requestUrl = this.baseUrl + `/verifyCode/${ emailId }/${ code }`;
    return this.http.get<any>(requestUrl)
    .pipe(
      catchError(this.handleError)
    );

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}`);
    }

    return throwError(
      `Connection error with the service. Code ${error.status}`);
  }

}
