import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '../services/auth-guard.service';

import { TagListComponent } from '../tag-list/tag-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
        {
            path: 'list',
            component: TagListComponent,
            data: { title: 'Tag' },
            canActivate: [AuthGuardService]
        },
    ]
}];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
      ],
      exports: [
        RouterModule
      ],
      declarations: []
})
export class TagRoutingModule { }
