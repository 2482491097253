import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-visitor-parking-pass',
  templateUrl: './visitor-parking-pass.component.html',
  styleUrls: ['./visitor-parking-pass.component.css']
})
export class VisitorParkingPassComponent implements OnInit {

  public parkingId = '';
  public expirationDate = '';
  public vehicleName = '';
  public vehicleLicencePlate = '';
  public color = '';
  public building = '';
  public unitNUmber = '';

  constructor(private route: ActivatedRoute, ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(
      params => {
        this.unitNUmber =  params['unit'];
        this.color =  params['color'];
        this.expirationDate = params['expirationDate'];
        this.building = params['area'];
        this.vehicleLicencePlate = params['vehicleLicencePlate'];
        this.vehicleName = `${params['year']} ${params['brand']}  ${params['model']}`;
        this.parkingId = params['decalNumber'];
        window.print();

        window.onafterprint = function() {
          //window.close();
          window.open('', '_self').close();
        };
      });
  }
}
