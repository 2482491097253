import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentModule } from './document/document.module';
import { BundleModule } from './bundle/bundle.module';
import { DeliveryOptionModule } from './delivery-option/delivery-option.module';
import { CartModule } from './cart/cart.module';
import { SearchModule } from './search/search.module';

@NgModule({
  imports: [
    CommonModule,
    DocumentModule,
    BundleModule,
    DeliveryOptionModule,
    CartModule,
    SearchModule
  ],
  declarations: [
  ],
  entryComponents: [
  ]
})
export class AppThorinModule { }
