import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchUnitComponent } from 'src/app/shared/search-unit/search-unit.component';
import { SearchAddress } from 'src/app/model/search-address';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  selectedAddresses: SearchAddress[] = [];
  showSearch = true;
  notFound = false;
  showSelect = false;
  constructor() { }
  @ViewChild('searchUnitComponent') searchUnitComponent: SearchUnitComponent;

  ngOnInit() {
    this.searchUnitComponent.onSelectedAddress().subscribe(r => {
      this.selectedAddresses = this.searchUnitComponent.selectedAddresses;
      this.showSelect = this.selectedAddresses.length > 0;
    });
    this.searchUnitComponent.onSearchAddressCompleted().subscribe(r => {
      this.notFound = this.searchUnitComponent.notFound;
    });
  }

  createOrder() {
    // fix once we have the order page
    // this.router.navigateByUrl('/app/docs/order/');
  }

}
