import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PhoneNumber } from 'src/app/model/phone-number';
import { PhoneType } from 'src/app/model/phone-type';
import { PhoneTypeService } from 'src/app/services/phone-type.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ValidatorHelper } from 'src/app/helpers/validator-helper';

@Component({
    selector: 'add-phone',
    templateUrl: './add-phone-dialog.component.html',
    styleUrls: ['./add-phone-dialog.component.scss']
})
export class AddPhoneDialogComponent {
    private peopleId: number;
    private phoneFormGroup: FormGroup;
    private model: PhoneNumber;
    public phoneTypes: PhoneType[];
    private phonemask: (string | RegExp)[];
    private editMode: boolean = false;
    private lastPhoneNumber: number;



    constructor(public dialogRef: MatDialogRef<AddPhoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private phoneTypeService: PhoneTypeService,
        private snackbar: MatSnackBar) {
            this.peopleId = data.peopleId;
            this.model = new PhoneNumber(this.fb);
            this.phoneFormGroup = this.model.buildFormGroup();
            this.phonemask = ValidatorHelper.phonemask();

    }

    ngOnInit() {
        this.phoneTypeService.getAll().subscribe(result => {
            this.phoneTypes = result;
            if(this.data.model !== null) {
                this.editMode = true;
                this.lastPhoneNumber = this.data.model.number;
                this.model.fromObject(this.data.model);
            }
        }, error => {
            this.snackbar.open('Error:', error, {
                duration: 4000
            });
        });
    }

    private onSave() {
        this.model = this.model.toDto();
        this.dialogRef.close({
            data: this.model,
            isEdit: this.editMode,
            lastPhoneNumber: this.lastPhoneNumber
        });
    }

    private onCancel() {
        this.dialogRef.close();
    }
}