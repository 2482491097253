import { Component, OnInit, Inject } from '@angular/core';
import { C3POService } from '../../services/c3po.service';
import { C3PEOTreeCommunityInfo,
   C3PEOTreeCommunityInfoDto,
   C3peoPeopleSelected,
   C3peoUnit} from '../../model/c3peo-tree-community-info';

import {
  MatDialog, MatTreeFlatDataSource, MatTreeNestedDataSource, MatTreeFlattener, MAT_DIALOG_DATA, MatDialogRef,
} from '@angular/material';

import { FlatTreeControl } from '../../../../material2/src/cdk/tree/control/flat-tree-control';
import { NestedTreeControl } from '../../../../material2/src/cdk/tree/control/nested-tree-control';
import { SelectionModel } from 'material2/src/cdk/collections';
import { stringHelper } from '../../helpers/stringHelper';
import { CommunityConfiguration } from '../../model/community-configuration';
import { RelationshipTypeMapService } from '../../services/relationship-type-map.service';
import { RelationshipTimeStatusMapService } from '../../services/relationship-time-status-map.service';
import { Constans } from '../../constants';
import { SiteHelper } from '../../helpers/site-helper';
import { ImportDataFromC3POService } from '../../services/import-data-from-c3-po.service';
import { FileNode, FileFlatNode, FileDatabase } from 'src/app/model/file';
import { CommunityImport } from 'src/app/model/community-import';
import { CommunityImportService } from 'src/app/services/community-import.service';
@Component({
  selector: 'app-community-importation-from-c3po',
  templateUrl: './community-importation-from-c3po.component.html',
  styleUrls: ['./community-importation-from-c3po.component.css'],
  providers: [FileDatabase]
})
export class CommunityImportationFromC3poComponent implements OnInit {

  public loading: boolean;
  private c3poCommunityInfo: C3PEOTreeCommunityInfo = new C3PEOTreeCommunityInfo();
  public model: CommunityConfiguration;

  // Flat tree control
  treeControl: FlatTreeControl<FileFlatNode>;

  // Nested tree control
  nestedTreeControl: NestedTreeControl<FileNode>;

  nestedNodeMap = new Map<FileNode, FileFlatNode>();
  flatNodeMap = new Map<FileFlatNode, FileNode>();

  treeFlattener: MatTreeFlattener<FileNode, FileFlatNode>;

  // Flat tree data source
  dataSource: MatTreeFlatDataSource<FileNode, FileFlatNode>;

  checklistSelection = new SelectionModel<FileFlatNode>(true /* multiple */);

  peopleSelection: C3peoPeopleSelected[] = [];

  // Nested tree data source
  nestedDataSource: MatTreeNestedDataSource<FileNode>;
  constructor(private c3poService: C3POService,
    private importDataFromC3POService: ImportDataFromC3POService,
    private communityImportService: CommunityImportService,
    private database: FileDatabase,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CommunityImportationFromC3poComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
    this.isExpandable, this.getChildren);

        // For flat tree
        this.treeControl = new FlatTreeControl<FileFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        // For nested tree
        this.nestedTreeControl = new NestedTreeControl<FileNode>(this.getChildren);
        this.nestedDataSource = new MatTreeNestedDataSource();
    }

    transformer = (node: FileNode, level: number) => {
      const existingNode = this.nestedNodeMap.get(node);
      const flatNode = existingNode && existingNode.filename === node.filename
          ? existingNode
          : new FileFlatNode();
      flatNode.filename = node.filename;
      flatNode.type = node.type;
      flatNode.parent = node.parent;
      flatNode.level = level;
      flatNode.expandable = !!node.children;
      this.flatNodeMap.set(flatNode, node);
      this.nestedNodeMap.set(node, flatNode);
      return flatNode;
    }

  getLevel = (node: FileFlatNode) => node.level;

  isExpandable = (node: FileFlatNode) => node.expandable;

  getChildren = (node: FileNode): FileNode[] => node.children;

  hasChild = (_: number, _nodeData: FileFlatNode) => _nodeData.expandable;

  hasNestedChild = (_: number, nodeData: FileNode) => !(nodeData.type);

  ngOnInit() {
    this.model = this.data.model;
    this.import();
  }

  import() {
    const dto = this.model;
    this.loading = true;
    try {
      this.c3poService.getsTreeByCommunityAndAreas(this.data.host, dto.externalCode, this.data.areas).subscribe(tree => {
         this.c3poCommunityInfo = tree;
        const objTree = new C3PEOTreeCommunityInfoDto(this.c3poCommunityInfo);
        if (objTree) {
        const data = this.buildFileTree(objTree, 0, null);
        this.database.dataChange.next(data);
        this.dataSource.data = data;
        }
        this.loading = false;
      }, error => {
        SiteHelper.openDialogAlert('Warning', error.error.errorMessage, this.dialog);
        this.onNoClick();
        this.loading = false;
      });
    } catch (e) {
      this.loading = false;
    }
  }

  buildFileTree(obj: object, level: number, parent: FileNode): FileNode[] {
    return Object.keys(obj).reduce<FileNode[]>((accumulator, key) => {
      let value = obj[key];
      const node = new FileNode();
      node.filename = stringHelper.jsUcfirst(key);
      parent = parent ? node : parent;
      if (value != null) {
        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            value =  JSON.parse(JSON.stringify(value));
            node.children = [];
            value.forEach((arr) => {
              const file: FileNode = new FileNode();
              file.filename = this.getFileNAme(node, arr);
              file.parent = this.getParent(node, arr);
              const childs = this.buildFileTree(arr, level + 1, node);
              file.children = childs;
              parent.children.push(file);
            });
          } else {
            node.filename = this.getFileNAme(node, value);
            node.children = this.buildFileTree(value, level + 1, node);
          }
        } else {
          node.type = value;
        }
      }
      if (typeof value === 'object' && Array.isArray(node.children) && node.filename.toLowerCase().indexOf('community') < 0) {
          return accumulator.concat(node.children);
      } else {
        return accumulator.concat(node);
      }
    }, []);
  }
  getParent(node: FileNode, value: any): string {
    let val = '';
    if (node.filename.toLowerCase() === 'units') {
      val = value.areaCode;
      delete value.areaCode;
    }
    return val;
  }

  getFileNAme(node: FileNode, value: any): string {
    let val = '';
    if (node.filename.toLowerCase() === 'areas') {
      val = value.areaCode + '-' + value.name ;
      delete value.name;
      delete value.areaCode;
    }
    if (node.filename.toLowerCase() === 'peoples') {
      val = value.name;
      delete value.name;
      delete value.areaCode;
    }
    if (node.filename.toLowerCase() === 'community') {
      val = node.filename + '-'  + value.name;
      delete value.name;
    }
    if (node.filename.toLowerCase() === 'units') {
      val = value.unitCode;
      delete value.unitCode;
    }
    return val;
  }
  descendantsAllSelected(node: FileFlatNode): boolean {
    if (this.treeControl.dataNodes !== undefined) {
      if (node.level >= 3 && this.checklistSelection.isSelected(node)) {
        return true;
      }
      const descendants = this.treeControl.getDescendants(node).filter(child => child.expandable && node.level !== 3);
      return descendants.every(child => this.checklistSelection.isSelected(child));
    }

  }
   /** Whether part of the descendants are selected */
   descendantsPartiallySelected(node: FileFlatNode): boolean {
    if (this.treeControl.dataNodes !== undefined) {
    const descendants = this.treeControl.getDescendants(node).filter(n => n.expandable && n.level < 3);
    const result = descendants.some(child => child.expandable && this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
    }
  }
  ItemSelectionToggle(node: FileFlatNode): void {
    if (this.treeControl.dataNodes !== undefined) {
    this.checklistSelection.toggle(node);
    this.addRemovePeople(node);
    if (node.level >= 2) {
      return ;
    }
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    }
  }
  addRemovePeople(node: FileFlatNode) {
    const selected = this.checklistSelection.isSelected(node);
    if (node.level === 0 ) {
      if (selected) {
      const descendants = this.treeControl.getDescendants(node).filter(n => n.level === 1 && n.expandable === true);
      descendants.forEach( n => this.ItemSelectionToggle(n));
      } else { this.peopleSelection = []; }
    }
    if (node.level === 1) { // this node is an area
      const descendants = this.treeControl.getDescendants(node).filter(n => n.level === 2 && n.expandable === true);
      descendants.forEach( n => this.ItemSelectionToggle(n));
    }
    if (node.level === 2) { // this node is an unit
      const unitCode = node.filename;
      const areaCode = node.parent;
      if (selected) {
        this.c3poCommunityInfo.communities[0].areas.filter(a => a.areaCode === areaCode).forEach(a => {
            a.units.forEach(u => {
              if (u.unitCode === unitCode) {
                this.addPeopleFromUnit(u);
              }
            });
        });
      } else {
        this.peopleSelection.filter(p => p.areaCode === areaCode && p.unitCode === unitCode)
        .forEach((e, index) => this.peopleSelection.splice(index, 1));
      }
    }
  }
  addPeopleFromUnit(unit: C3peoUnit) {
    unit.people.forEach(p => {
      const peope: C3peoPeopleSelected = {areaCode: p.areaCode, unitCode: p.unitCode, personCode: p.personCode, appNo: p.appNo};
       this.peopleSelection.push(peope);
      });
  }

  saveData() {
    if (this.peopleSelection.length === 0) {
        SiteHelper.openDialogAlert('Warning', 'Must be select areas and units', this.dialog);
        return;
    }
    const communityImport = this.getImportCommunity();
    if (communityImport.length > 0) {
      this.communityImportService.addBulk(communityImport).subscribe();
    }
/*
    this.c3poCommunityInfo.communities[0].communityId = this.model.communityId;
    this.c3poCommunityInfo.communities[0].originId = Constans.Origin.source;
    this.c3poCommunityInfo.communities[0].sourceId = this.model.sourceId;
    this.c3poCommunityInfo.communities[0].communityMappingId = this.model.communityMappingId;
    let tempCommunity =  JSON.parse(JSON.stringify(this.c3poCommunityInfo.communities[0]));

          tempCommunity.areas.forEach((a, index) => {
              if (this.peopleSelection.filter(select => select.areaCode === a.areaCode).length === 0) {
                this.c3poCommunityInfo.communities[0].areas.splice(index, 1);
              }
          });
          tempCommunity = JSON.parse(JSON.stringify(this.c3poCommunityInfo.communities[0]));
          let  j = 0;
          tempCommunity.areas.forEach((a, i) => {
            a.units.forEach((u) => {
              if (this.peopleSelection.filter(select => select.unitCode === u.unitCode && select.areaCode === a.areaCode).length <= 0) {
                this.c3poCommunityInfo.communities[0].areas[i].units.splice(j, 1);
                j --;
              }
              j ++;
            });
        });

   this.importDataFromC3POService.importDataFromC3PO(this.c3poCommunityInfo.communities[0]).subscribe(ci => {
     if (ci !== undefined && ci !== null) {
      const communityImport = this.getImportCommunity();
        if (communityImport.length > 0) {
          this.communityImportService.addBulk(communityImport);
        }
      }
   });*/
    SiteHelper.openDialogAlert('Information', 'In a few minutes your community will be imported',
    this.dialog);
    this.dialogRef.close();
  }

  getImportCommunity(): CommunityImport[]  {
    const map = new Map();
    const communityImportData: CommunityImport[] = [];
    for (const item of this.peopleSelection) {
        if (!map.has(item.unitCode)) {
            map.set(item.unitCode, true);    // set any value to Map
            communityImportData.push({
              communityImportId: 0,
              communityExternalCode: this.model.externalCode,
              buildingExternalCode: item.areaCode,
              unitExternalCode: item.unitCode,
              imported: false,
              communityId: this.model.communityId
            });
          }
        }
        return communityImportData;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
