import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { Visitor, VisitorFullModel } from '../model/visitor';
import { LevelFilter } from '../model/breadcrumb';
import { CrudResponseModel } from '../model/crud-response-model';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class VisitorService extends AbstractDataProviderService<Visitor> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.TardisService, '/visitors', http, appConfig, LevelFilter.Unit);
  }

  visit(visitor: Visitor): Observable<CrudResponseModel> {
    return this.http.post<CrudResponseModel>(this.baseUrl , visitor, { headers: this.buildGetHeaders() });
  }

  updatePhoto(data: FormData): Observable<any> {
    return this.http.post<null>(this.baseUrl + '/uploadPhoto', data, { headers: this.buildGetHeaders() });
  }

  getAllPaginatedByAccess(pageIndex: number, pageSize: number, sortCol: string, sortDir: number,
     accessId: number, filter?: string): Observable<Visitor[]> {
    this.setBreadcrumbParams();
    const route = this.filterLevel ? '/allPageByBreadcrumb/' : '/allPaged/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString() + '/' +  sortCol + '/' + sortDir
    + (filter ? '/' + filter : '');

    if (this.filterLevel && this.filterLevel >= LevelFilter.PropertyManagementCompany) {
      queryString = queryString + '?propertyManagementCompanyId=' +  this.searchParamsBreadcrumb.propertyManagementCompanyId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Community) {
      queryString = queryString + '&communityId=' +  this.searchParamsBreadcrumb.communityId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Area) {
      queryString = queryString + '&areaId=' +  this.searchParamsBreadcrumb.areaId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Unit) {
      queryString = queryString + '&unitId=' +  this.searchParamsBreadcrumb.unitId;
    }
    queryString = queryString + '&accessId=' + accessId.toString();

    return this.http.get<Visitor[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCountByAccess(accessId: number, filter?: string): Observable<number> {
    // this.setBreadcrumbParams();
    if (this.filterLevel === LevelFilter.None) {
    return this.http.get<CountResultModel>(this.baseUrl + '/count' , { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
    } else {
        return this.getCountByBreadcumdByAccessId(accessId, filter);
    }
  }

  getCountByBreadcumdByAccessId(accessId: number, filter?: string): Observable<number> {
    const endpoint = filter ? '/getCountByParamsFiltered/' : '/getCountByParams/';
      const queryString = endpoint + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/'
       + this.searchParamsBreadcrumb.communityId + 
       '/' + this.searchParamsBreadcrumb.areaId +
       '/' + this.searchParamsBreadcrumb.unitId +
       '/' + accessId + 
        (filter ? '/' + filter : '');
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  getByCommunityIdFiltered(filter: string): Observable<VisitorFullModel[]> {
    this.setBreadcrumbParams();
    return this.http.get<VisitorFullModel[]>(this.baseUrl +
      '/byCommunityIdFiltered/' + this.searchParamsBreadcrumb.communityId + '/' + filter, { headers: this.buildGetHeaders() });
  }

  getByRelationshipId(relationshipId: number): Observable<VisitorFullModel[]> {
    return this.http.get<VisitorFullModel[]>(this.baseUrl +
      '/byRelationshipId/' + relationshipId, { headers: this.buildGetHeaders() });
  }

  getByPeopleId(peopleId: number): Observable<VisitorFullModel[]> {
    return this.http.get<VisitorFullModel[]>(this.baseUrl +
      '/byPeopleId/' + peopleId, { headers: this.buildGetHeaders() });
  }


  getByRelationshipIdArray(relationshipId: number[]): Observable<VisitorFullModel[]> {
    return this.http.get<VisitorFullModel[]>(this.baseUrl +
      '/byRelationshipIdArray/?relationshipId=' + relationshipId, { headers: this.buildGetHeaders() });
  }


}
