import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { VisitorLogEntryComponent } from './visitor-log-entry/visitor-log-entry.component';
import { VisitorsRoutingModule } from './visitors-routing.module';
import { SettingVisitorComponent } from './setting-visitor/setting-visitor.component';
import { SharedModule } from '../shared/shared.module';
import { VisitorsTimeLogListComponent } from './visitors-time-log-list/visitors-time-log-list.component';
import { CompanyAddComponent } from './company-add/company-add.component';
import { VisitorsLogListComponent } from './visitors-log-list/visitors-log-list.component';
import { VisitorLogDetailComponent } from './visitor-log-detail/visitor-log-detail.component';
import { GuestInfoComponent } from './guest-info/guest-info.component';
import { VisitorTimeLogAddEditComponent } from './visitor-time-log-add-edit/visitor-time-log-add-edit.component';
import { CardsVisitorsComponent } from './cards-visitors/cards-visitors.component';
import { VisitorBadgeComponent } from './visitor-badge/visitor-badge.component';
import { VisitorParkingPassComponent } from './visitor-parking-pass/visitor-parking-pass.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'; //TODO: Remove this when grid component is complete
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    VisitorsRoutingModule,
    SharedModule,
    NgxMatSelectSearchModule
  ],
  declarations: [
    VisitorLogEntryComponent,
    VisitorsLogListComponent,
    SettingVisitorComponent,
    VisitorsTimeLogListComponent,
    CompanyAddComponent,
    VisitorLogDetailComponent,
    GuestInfoComponent,
    VisitorTimeLogAddEditComponent,
    CardsVisitorsComponent,
    VisitorBadgeComponent,
    VisitorParkingPassComponent,
  ],
  entryComponents: [
    CompanyAddComponent,
  ],
  exports:[
    VisitorsLogListComponent,
    VisitorLogDetailComponent
  ],
})
export class VisitorsModule { }
