import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { ApiKey } from '../model/api-key';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService extends AbstractDataProviderService<ApiKey> {
  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.SecurityAdminService, '/APIKeys', http, appConfig);
  }
  generateAPIKey(): Observable<string> {
    return this.http.get<string>(this.baseUrl + '/generateApiKey', { headers: this.buildGetHeaders() });
  }
}

