import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { isString } from 'util';
import { of } from 'rxjs';

export class ValidatorHelper {
    static phonemask(): (string | RegExp)[] {
        return  [/\(?([2-9]{1})/, /\d/, /\d/, '-', /\d/,  /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }

    static required(control: AbstractControl): ValidationErrors | null {
         return (isString(control.value) && control.value.trim() === '') ?
               {'required': true} :
               null;
    }

    // Validates phone numbers
    static phoneNumber(number): any {
        if (number.pristine && number.value === '') {
            return null;
         }
         const PHONE_REGEXP = /^\(?([2-9]{1})?([0-9]{2})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;
         number.markAsTouched();
         if (PHONE_REGEXP.test(number.value) && number.value.replace(/-/g, '').length === 10) {
            return null;
         }
         return {
            invalidNumber: true
         };
    }
}
