import { Injectable, Inject } from '@angular/core';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { ExplicitSecurity } from '../model/explicit-security';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

@Injectable({
  providedIn: 'root'
})
export class ExplicitSecurityService extends AbstractDataProviderService<ExplicitSecurity> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/explicitSecurity', http, appConfig);
    }

    getByRoleId(roleId: number): Observable<ExplicitSecurity[]> {
      return this.http.get<ExplicitSecurity[]>(this.baseUrl + '/byRoleId/' + roleId.toString(), { headers: this.buildGetHeaders() });
    }

    getByRoleIdArray(roles: number[]): Observable<ExplicitSecurity[]> {
      return this.http.post<ExplicitSecurity[]>(this.baseUrl + '/byRoleIdArray',  roles, { headers: this.buildGetHeaders() });
    }
}
