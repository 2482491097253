import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class Package extends BaseForm {
       /**
     * The ID of the tag in the database.
     */

    hotPackageId = 0;
    packageId = 0;

    @FormField('carrierId', [])
    carrierId = 0;

    receivedDate: Date = null;

    @FormField('trackNumber', ['', Validators.required])
    trackNumber = '';

    @FormField('packageTypeId', [])
    packageTypeId = 0;

    @FormField('storageId', [])
    storageId = 0;

    relationshipId = 0;
    peopleId = 0;
    unitId = 0;
    packageStatusId = 0;

    recipientName = '';
    carrier = '';

    packageStatusChanged: Date = null;

    @FormField('sender', [])
    sender = '';

    @FormField('notes', [])
    notes = '';

    isDeleted = false;
     /**
     * The creation date of this record.
     */
    creationDate: Date = null;

    /**
     * The ID of the user that created this record.
     */
    creationUserId = 0;

    /**
     * The modification date of this record.
     */
    lastModification: Date = null;

    /**
     * The ID of the user that last modified this record.
     */
    modificationUserId = 0;


    sameAsDestinatary: number;

    evidenceGuid: string;

    checked? = false;;
}
