import { Injectable, Inject } from '@angular/core';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { CommunitySettings } from '../model/community-settings';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommunitySettingsService extends AbstractDataProviderService<CommunitySettings> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/communitySettings', http, appConfig);
  }

  getByCommunityId(communityId: number): Observable<CommunitySettings> {
    return this.http.get<CommunitySettings>(this.baseUrl + '/byCommunityId/' + communityId, { headers: this.buildGetHeaders() });
  }
}

