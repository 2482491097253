import { Component, Injector, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import { BaseList } from '../../building-blocks/base-list';

import { Observable} from 'rxjs';
import { MassCommunicationService } from '../../services/mass-communication.service';

import { CrudResponseModel } from '../../model/crud-response-model';
import { MassCommunicationList } from '../../model/mass-communication-list';
import { UserService } from 'src/app/services/user.service';


const listColumns = [
 'listName',
  'actions'
];

@Component({
 selector: 'app-mass-comm-list',
 templateUrl: './mass-comm-list.component.html',
 styleUrls: ['./mass-comm-list.component.css']
})
export class MassCommListComponent extends BaseList<MassCommunicationList> {

 protected areaSelected = 0;
 protected communityIdSelected = 0;
 protected managementCompanyIdSelected = 0;
 protected communityId = 0;
 protected unitSelected = 0;
 private filterObservable: Observable<any>;
 private filterStatus: boolean;
 @ViewChild('filter') filterInput: ElementRef;

 constructor(
   public injector: Injector,
   private massCommService: MassCommunicationService,
   private cdr: ChangeDetectorRef,
   private userService: UserService) {
      super(listColumns, '/mass-communication-list', massCommService, 'massCommunicationList', injector);
      this.filters = '';
      this.loading = false;
  }

 protected getList(pageIndex: number, pageSize: number, filter: string): Observable<MassCommunicationList[]> {
   return this.massCommService.getAllPaginated( pageIndex, pageSize, this.dataSource.filter);
 }

 protected getCount(): Observable<number> {
   return this.massCommService.getCount();
 }

 protected getById(id: number): Observable<MassCommunicationList> {
   return this.massCommService.getById(id);
 }

 protected insertEntity(entity: MassCommunicationList): Observable<CrudResponseModel> {
   return this.massCommService.add(entity);
 }

 protected updateEntity(entity: MassCommunicationList): Observable<CrudResponseModel> {
   return this.massCommService.update(entity);
 }

 protected openEditForm(isUpdate: boolean, model: any, emitter: any): any {}

 editList(listId: number): void {
   this.router.navigateByUrl('/app/mass-comm-add-edit/add/' + listId);
 }

 add(): void {
   this.router.navigate(['app/mass-communication/edit/' + 0]);
 }
}
