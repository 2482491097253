import { Injectable, Inject } from '@angular/core';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { Carrier } from '../model/carrier';
import { Observable } from '../../../node_modules/rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarrierService extends AbstractDataProviderService<Carrier> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/carrier', http, appConfig);
  }

  detect(trackNumber: string): Observable<Carrier[]> {
    const body = 
    {
      trackingNumber:trackNumber
    }    
    return this.http.post<Carrier[]>(this.baseUrl + '/detect/', body, { headers: this.buildPostHeaders() });
  }
}

