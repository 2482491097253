import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class AddressTableEntry extends BaseForm {

    @FormField('addressId', [])
    addressId = 0;

    @FormField('addressTypeId', [1, Validators.required])
    addressTypeId = 1;
    addressTypeName = '';

    @FormField('line1',  ['', Validators.required])
    line1 = '';

    @FormField('line2', [''])
    line2 = '';

    @FormField('number', [''])
    number: number;

    @FormField('apt', [''])
    apt = '';

    @FormField('stateId', [0])
    stateId = 0;
    stateName: string;

    @FormField('cityId', [0])
    cityId = 0;
    cityName = '';

    zipCodeId = 0;

    @FormField('zipCode', ['', Validators.required])
    zipCode = '';

    @FormField('isPrefered', [])
    isPrefered = false;

    persisted: boolean;
    cannotChange: boolean;
    isMailing: boolean;
    address_RelationshipId: number;
    address_PeopleId: number;
    isDeleted = false;
    originId = 2;

    /**
     * The creation date of this record.
     */
    creationDate: Date = null;

    /**
     * The ID of the user that created this record.
     */
    creationUserId = 0;

    /**
     * The modification date of this record.
     */
    modificationDate: Date = null;

    /**
     * The ID of the user that last modified this record.
     */
    modificationUserId = 0;

}

