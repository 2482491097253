import { BaseForm } from '../building-blocks/base-form';
import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { Validators } from '@angular/forms';

export class ParameterMassComm extends BaseForm {
    id: number;

    @FormField('name', ['', Validators.required])
    name = '';

    @FormField('replace', ['', Validators.required])
    replace = 0;

    isDeleted = false;
}
