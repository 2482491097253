import { Component, Injector, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { BaseList } from '../../building-blocks/base-list';
import { PropertyManagementCompany } from '../../model/property-management-company';
import { PropertyManagmentCompanyService } from '../../services/property-managment-company.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { map } from 'rxjs/operators';
import { EntityRing, AccessType } from 'src/app/model/access-type-ring';
import { trigger, state, style, transition, animate } from '@angular/animations';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomExporterService } from 'src/app/services/grid-table-custom-exporter-service';

const propertyManagementCompanyColumns = [
  'name',
  'actions',
];

@Component({
  selector: 'app-property-management-company-list',
  templateUrl: './property-management-company-list.component.html',
  styleUrls: ['./property-management-company-list.component.css'],
  animations: [
    trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', 
        animate('225ms ease-in-out')),
    ]),
  ]
})
export class PropertyManagementCompanyListComponent extends BaseList<PropertyManagementCompany> implements OnDestroy {

  protected dataSourceAll: any[] = [];
  protected states: boolean[] = [];
  protected collapseAll = false;
  public pmcPermisions = new EntityRing();

  private cols = [
    {
      columnDef: 'name',
      headerName: 'Name',
      cell: (company) => company.name,
      sortable: true,
      parentRow: true
    }
  ];

  private rowActions = ['add', 'edit', 'delete'];
  private showFilter: boolean = false;



  constructor(
    public injector: Injector,
    private snackbar: MatSnackBar,
    private propertyManagmentCompanyService: PropertyManagmentCompanyService ,
    private breadcrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef,
    private customExporter: CustomExporterService) {

      super(propertyManagementCompanyColumns, '/propertyManagmentCompany', propertyManagmentCompanyService,
       'propertyManagmentCompany', injector); { }
       this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.pmcPermisions.rings.push({ accessType: AccessType.Add, ringId: 511 });
    this.pmcPermisions.rings.push({ accessType: AccessType.Delete, ringId: 611 });
    this.pmcPermisions.rings.push({ accessType: AccessType.Update, ringId: 711 });
    this.entityRings.push(this.pmcPermisions);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<PropertyManagementCompany[]> {
     return this.propertyManagmentCompanyService.getAllTreePaginated(pageIndex, pageSize, filter)
     .pipe(map(result => this.renderTree(result)));
  }
  protected renderTree(array: any[]): any[] {
    this.loading = true;
    array.forEach(item => {
      item.state = true;
    });
    this.dataSourceAll = array;
    this.dataSourceAll.forEach((p, i) => this.states[i] = false);
    this.loading = false;
    return this.dataSourceAll;
  }

  protected getCount(): Observable<number> {
    return this.propertyManagmentCompanyService.getCountByParams(this.dataSource.filter);
  }

  protected openEditForm(isUpdate: boolean, model: PropertyManagementCompany, emitter: any) {
  }


  protected clickOpenCollapse(parentId: number, index: number, isOpen: boolean): void {
    this.collapseAll = !isOpen;
    this.dataSourceAll.forEach((mc, i)  => {
      if (mc.parent ===  parentId) {
        mc.state = isOpen;
      }else {
        mc.state = true;
      }
    });
    this.states[index] = isOpen;
    
  }

  protected opened(index: number,  isOpen: boolean ): boolean {
    return this.collapseAll ? this.dataSourceAll[index].state === isOpen :  !isOpen;
  }

  isOpened(row: any, index: number,  isOpen: boolean) {
    let item = this.dataSourceAll.filter(d => d.parent === row.propertyManagementCompanyId);

    if (item.length > 0) {
      return item[0].state;
    }else {
      return true;
    }
  }

  showSubItems(row: any) {
    let item = this.dataSourceAll.filter(d => d.propertyManagementCompanyId === row.propertyManagementCompanyId);
    if (item.length > 0) {
      return !item[0].state;
    }    
  }

  public add(entity: any) {
    this.router.navigate(['app/management-company/add/' + entity ]);
  }

  public edit(entity: any): void {
    this.router.navigate(['app/management-company/edit/' + entity]);
  }

  public delete(entity: any): void {
    if (confirm('Are you sure you want to delete this Management Company?')) {
      this.loading = true;
      this.propertyManagmentCompanyService.delete(entity).subscribe(x => {
        this.breadcrumbService.watchUpdated(true).subscribe();
        this.dataSource.reload();
            this.loading = false
      });
    }
  }

  public applySort($event) {
    console.log(`Implement sorting on base-list just like the apply filter that will be called on each component using this parameter`, $event.sortData);
  }

  public search(searchStringParameter: string) {
    this.applyFilter(searchStringParameter);
  }

  downloadPDFEvent(doc: any) {
    let tableData = this.dataSource.tableData.map(d => {
      return {'name': d.name}; 
    });
    const docz = new jsPDF();
    docz.autoTable({
      body: tableData,
      columns: [ 
        {header: 'Name', dataKey: 'name'}
      ]
    });
    docz.save('grid-system-export.pdf');

  }

  downloadCSVEvent(directive: any, options: any) {
    let tableData = this.dataSource.tableData.map(d => {
      return {'name': d.name}; 
    });
    this.customExporter.export(tableData, options);
  }
}
