import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class Ring extends BaseForm {
    ringId = 0;

     /**
     * The ringTypeId of the Ring.
     */
    @FormField('ringTypeId', ['', Validators.required])
    ringTypeId = null;


     /**
     * The permitionTypeId of the Ring.
     */
    @FormField('permitionTypeId', ['', Validators.required])
    permitionTypeId = null;
     /**
     * The entityId of the Ring.
     */
    @FormField('entityId', ['', Validators.required])
    entityId = null;

    /**
     * The name of the Ring.
     */
    @FormField('name', ['', Validators.required])
    name = '';

    /**
     * The description of the Ring.
     */
    @FormField('description', ['', Validators.required])
    description = '';
}
