import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { VisitorsTimeLog } from '../model/visitors-time-log';
import { Observable } from 'rxjs';
import { LevelFilter } from '../model/breadcrumb';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';
import { CrudResponseModel } from '../model/crud-response-model';
@Injectable({
  providedIn: 'root'
})
export class VisitorsTimeLogService extends AbstractDataProviderService<VisitorsTimeLog> {
  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/visitorsTimeLog', http, appConfig, LevelFilter.Unit);
  }

  // frontDeskVisit

  visit(visitorTimeLog: FormData): Observable<CrudResponseModel> {
    return this.http.post<CrudResponseModel>(this.baseUrl + '/frontDeskVisit', visitorTimeLog, { headers: this.buildGetHeaders() });
  }

  getAllPaginatedSorted(pageIndex: number, pageSize: number, sortCol: string, sortDir: number, filter?: string, filterRaised?: number,
    visitor?: string, visitorType?: number, resident?: string, company?: string, fromDate?: string,
    toDate?: string, vehicle?: string, accessType?: string): Observable<VisitorsTimeLog[]> {
    this.setBreadcrumbParams();
    let route = this.filterLevel ? '/allPageByBreadcrumb/' : '/allPaged/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString() + '/' + sortCol + '/' + sortDir
      + (filter ? '/' + filter : '');
      
    if (this.filterLevel && this.filterLevel >= LevelFilter.PropertyManagementCompany) {
      queryString = queryString + '?propertyManagementCompanyId=' + this.searchParamsBreadcrumb.propertyManagementCompanyId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Community) {
      queryString = queryString + '&communityId=' + this.searchParamsBreadcrumb.communityId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Area) {
      queryString = queryString + '&areaId=' + this.searchParamsBreadcrumb.areaId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Unit) {
      queryString = queryString + '&unitId=' + this.searchParamsBreadcrumb.unitId;
    }

    if (accessType === undefined || accessType === null) {
      queryString = queryString + '&accessType=-1';
    } else {
      queryString = queryString + '&accessType=' + accessType;
    }

    if (visitorType === undefined || visitorType === null) {
      queryString = queryString + '&visitorType=-1';
    } else {
      queryString = queryString + '&visitorType=' + visitorType;
    }

    if (fromDate === undefined || fromDate === null) {
      queryString = queryString + '&fromDate=0';
    } else {
      queryString = queryString + '&fromDate=' + fromDate;
    }

    if (toDate === undefined || toDate === null) {
      queryString = queryString + '&toDate=0';
    } else {
      queryString = queryString + '&toDate=' + toDate;
    }

    if (filterRaised === 1) {
      queryString = queryString + '&visitor=' + visitor;
      queryString = queryString + '&resident=' + resident;
      queryString = queryString + '&company=' + company;
      queryString = queryString + '&vehicle=' + vehicle;
    }
    return this.http.get<VisitorsTimeLog[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCount(filter?: string, filterRaised?: number,
    visitor?: string, visitorType?: number, resident?: string, company?: string, fromDate?: string,
    untilDate?: string, vehicle?: string): Observable<number> {
    // this.setBreadcrumbParams();
    if (this.filterLevel === LevelFilter.None) {
      return this.http.get<CountResultModel>(this.baseUrl + '/count', { headers: this.buildGetHeaders() })
        .pipe(map(x => x.count));
    } else {
      return this.getCountByBreadcumd(filter, filterRaised, visitor, visitorType,
        resident, company, fromDate, untilDate, vehicle);
    }
  }

  getCountByBreadcumd(filter?: string, filterRaised?: number,
    visitor?: string, visitorType?: number, resident?: string, company?: string, fromDate?: string,
    toDate?: string, vehicle?: string, accessType?: string): Observable<number> {
    let endpoint = filter ? '/getCountByParamsFiltered/' : '/getCountByParams/';
    let queryString = endpoint + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/'
      + this.searchParamsBreadcrumb.communityId + '/' + this.searchParamsBreadcrumb.areaId
      + '/' + this.searchParamsBreadcrumb.unitId + (filter ? '/' + filter : '');


      if (accessType === undefined || accessType === null) {
        queryString = queryString + '?accessType=-1';
      } else {
        queryString = queryString + '?accessType=' + accessType;
      }
      
      if (visitorType === undefined || visitorType === null) {
        queryString = queryString + '&visitorType=-1';
      } else {
        queryString = queryString + '&visitorType=' + visitorType;
      }
  
      if (fromDate === undefined || fromDate === null) {
        queryString = queryString + '&fromDate=';
      } else {
        queryString = queryString + '&fromDate=' + fromDate;
      }
  
      if (toDate === undefined || toDate === null) {
        queryString = queryString + '&toDate=';
      } else {
        queryString = queryString + '&toDate=' + toDate;
      }
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers: this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  updateVisit(visitorTimeLog: FormData): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl + '/', visitorTimeLog, { headers: this.buildGetHeaders() });
  }
}
