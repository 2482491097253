import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ParameterMassComm } from 'src/app/model/mass-comm-parameter';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { EmailMassComm } from 'src/app/model/mass-comm-email';
import { Subject } from 'rxjs';
import { QuillModule } from 'ngx-quill';

@Component({
  selector: 'app-mass-comm-email',
  templateUrl: './mass-comm-email.component.html',
  styleUrls: ['./mass-comm-email.component.css']
})
export class MassCommEmailComponent implements OnInit {

  public massCommLists = [];

  public loading = true;
  private editorInstance: any;
  public massCommEmailFormGroup: FormGroup;
  public parametersItems: FormArray;
  public model: EmailMassComm;

  public parameters: ParameterMassComm[] = [];
  public parameterModel: ParameterMassComm;

  private replaces: {fieldId: number, name: string}[] = [];


  constructor(
    private fb: FormBuilder
  ) {
    this.model = new EmailMassComm(this.fb);
    this.massCommEmailFormGroup = this.model.buildFormGroup();
    this.parametersItems = this.massCommEmailFormGroup.get('parameters') as FormArray;
    this.replaces.push({fieldId: 1, name: 'FirstName'});
    this.replaces.push({fieldId: 2, name: 'SecondName'});
  }

  ngOnInit() {
    this.loading = true;

    this.parameterModel = new ParameterMassComm(this.fb);
    this.parameterModel.buildFormGroup();
    this.parameters.push(this.parameterModel);

    this.loading = false;
  }

  loadParameters(add: ParameterMassComm[]): void {
    add.forEach((ad, index) => {
      this.addParameter(ad);
      // if (index === 0) {
        // this.parameterModel = new ParameterMassComm(this.fb);
        // this.parameterModel.buildFormGroup();
        // this.parameterModel.fromObject(ad);
        // this.parameters.push(this.parameterModel);
      // } else {
        // this.addParameter(ad);
      // }
    });
  }

  get parametersFormGroup() {
    return this.massCommEmailFormGroup.get('parameters') as FormArray;
  }

  public addParameter(entity: ParameterMassComm = null) {
    this.parametersItems.push(this.createParameterForm(entity));
  }

  private createParameterForm(entity: ParameterMassComm = null): FormGroup {
    this.parameterModel = new ParameterMassComm(this.fb);
    const formGroup = this.parameterModel.buildFormGroup();
    if (entity) {
      this.parameterModel.fromObject(entity);
    }
    this.parameters.push(this.parameterModel);
    return formGroup;
  }

  public created(ei: any) {
    this.editorInstance = ei;
  }

  private insertParameter(index: number) {
    const pName = ' {{' + this.parametersItems.controls[index].value.name + '}}';
    const range = this.editorInstance.getSelection(true);
    this.editorInstance.insertText(range.index, pName, 'italic', true);
  }

  private removeParameter(index: number) {
    const delta = this.editorInstance.getContents();
    const pName = ' {{' + this.parametersItems.controls[index].value.name + '}}';

    delta.ops.forEach(item => {
      item.insert = item.insert.replace(pName, '');
    });

    this.editorInstance.setContents(delta.ops);
    if (this.parameters[index].id > 0) {
      this.parameters[index].isDeleted = true;
    } else {
      this.parameters.splice(index, 1);
    }
    this.parametersItems.removeAt(index);
  }

  public onCancel() {
  }

  public onSubmit() {
  }

}
