import {
  Component, AfterViewInit, OnInit, ViewChild, ViewChildren, QueryList, ElementRef,
  ChangeDetectorRef, OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { MatDialog, MatSelect, MatSlideToggleChange } from '@angular/material';
import { AlertDialogComponent } from '../../../../../src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '../../../../../src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { BundleAddDocumentDialogComponent } from '../../bundle/bundle-add-document-dialog/bundle-add-document-dialog.component';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';

// Services
import { DocumentService } from '../../services/document.service';
import { BundleService } from '../../services/bundle.service';
import { ServiceError } from '../../../../../src/app/model/service-error';

// Models
import { Document } from '../../model/document';
import { Bundle } from '../../model/bundle';
import { BundlePricePlan } from '../../model/bundlePricePlan';
import { PricePlan } from '../../model/price-plan';

@Component({
  selector: 'app-bundle-add-edit',
  templateUrl: './bundle-add-edit.component.html',
  styleUrls: ['./bundle-add-edit.component.css']
})
export class BundleAddEditComponent implements AfterViewInit, OnInit, OnDestroy {
  public model: BundlePricePlan;
  public allDocs: Document[];
  public update: boolean;
  public SAVED_MESSAGE = 'Your bundle was suscessfully saved';
  public TITLE_INFO = 'Info';
  @ViewChildren('docsList') docsList: QueryList<ElementRef>;
  public disabledButton = false;
  public saveFailed: boolean;
  public saveErrorMessage: string;


  public formGroup: FormGroup;
  public loading: boolean;
  private sub: Subscription;
  protected communityIdSelected = 0;
  protected managementCompanyIdSelected = 0;
  protected breadcrumbChanges: Subscription;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private documentService: DocumentService,
    private bundleService: BundleService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog) {
    this.loading = true;

    this.checkParams();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      if (this.checkParams()) {
        this.onCancel();
      }
    });


  }
  ngOnInit() {
    // Instantiate the model object.
    this.model = new BundlePricePlan();
    this.model.bundle = new Bundle();
    this.model.bundle.documents = [];
    this.model.pricePlan = new PricePlan();
    this.model.pricePlan.relationShipTypes = [];
    this.model.pricePlan.communities = [];
    this.validateBundleFormGroup();

    this.sub = this.route.paramMap.subscribe(
      params => {
        this.model.bundle.bundleId = +params.get('id');
        this.initBundle();
        this.loading = false;

      });
    if (!this.model.bundle.bundleId) {
      this.loading = false;
      this.model.bundle.isActive = true;
    }

  }

  getDocs() {
    this.sub = this.route.paramMap.subscribe(
      params => {
        const docObservable = this.documentService.getDocumentsByMCAndCommunityId
          (this.managementCompanyIdSelected, this.communityIdSelected);
        forkJoin(docObservable)
          .subscribe(results => {
            this.allDocs = results[0];
            this.loading = false;
            this.setDocumentDialog();
          });
      });
  }

  setDocumentDialog() {
    let data;
    data = {
      documents: this.allDocs,
      docsInBundle: this.model.bundle.documents,
      update: this.model.bundle.bundleId > 0 ? true : false,
      bundleId: this.model.bundle.bundleId
    };
    const dialogRef = this.dialog.open(BundleAddDocumentDialogComponent, {
      width: '750px',
      disableClose: true,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.update = result.update;
        this.model.bundle.documents = result.docsInBundle;
        if (result.dirty) {
          this.formGroup.markAsDirty();
        }
      }
    });
  }

  validateBundleFormGroup(): void {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      isActive: '',
      pricePlan: this.fb.group({
        before: ['', Validators.required],
        after: ['', Validators.required]
      })
    });
  }

  initBundle(): void {
    this.update = false;
    if (this.model.bundle.bundleId) {
      this.update = true;
      this.bundleService.getBundlesById(this.model.bundle.bundleId).subscribe(dc => {
        this.model = dc;
        this.loading = false;
      });
    }
    this.loading = false;

  }

  onSave() {
    if (this.formGroup.dirty && this.formGroup.valid) {
      this.loading = true;
      this.model.managementCompanyId = this.managementCompanyIdSelected;
      this.model.communityId = this.communityIdSelected;
      if (!this.model.bundle.bundleId) {
        this.addBundle();
      } else {
        this.editBundle();
      }
    }
  }

  addBundle() {
    this.model.bundle.isDeleted = false;
    this.bundleService.addBundle(this.model).subscribe(
      result => {
        this.onSaveComplete();
      },
      (error: ServiceError) => {
        this.onServerError(error);
      });
  }

  editBundle() {
    this.model.bundle.isDeleted = false;
    this.bundleService.updateBundle(this.model).subscribe(result => {
      this.onSaveComplete();

    },
      (error: ServiceError) => {
        this.onServerError(error);
      });
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '750px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.formGroup.reset();
      this.router.navigateByUrl('/app/docs/bundle');
    });
  }

  onServerError(data) {
    this.loading = false;
    this.saveFailed = true;
    this.saveErrorMessage = 'Sorry, there was an error while saving the record.';
    console.log(data.error.errorMessage);
  }

  onSaveComplete(): void {
    this.loading = false;
    this.openDialog(this.TITLE_INFO, this.SAVED_MESSAGE);
  }


  ngAfterViewInit() {
    this.disabledButton = this.docsList.length > 0;
    this.changeDetector.detectChanges();
    this.docsList.changes.subscribe((divs) => {
      this.disabledButton = divs.length > 0;
      this.changeDetector.detectChanges();
    });
  }

  onCancel(): void {
    this.formGroup.reset();
    this.router.navigateByUrl('/app/docs/bundle');
  }

  openDialogDocument(): void {
    this.getDocs();
  }

  removeDocument(documentId: number): void {
    const idx = this.model.bundle.documents.findIndex(x => x.documentId === documentId);
    if (idx > -1) {
      this.model.bundle.documents.splice(idx, 1);

    }
    this.formGroup.markAsDirty();
  }

  checkParams(): boolean {
    const mcLocalStorage = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if ((this.managementCompanyIdSelected !== mcLocalStorage || this.communityIdSelected !== comunityLocalStorage)) {
      this.managementCompanyIdSelected = mcLocalStorage;
      this.communityIdSelected = comunityLocalStorage;
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.breadcrumbChanges) {
      this.breadcrumbChanges.unsubscribe();
    }
  }

}

