import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { NewsfeedService } from '../../services/newsfeed.service';
import { Newsfeed } from '../../model/newsfeed';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { TypePost } from '../create-edit-post/create-edit-post.component';

@Component({
  selector: 'app-view-detail-post',
  templateUrl: './view-detail-post.component.html',
  styleUrls: ['./view-detail-post.component.css']
})
export class ViewDetailPostComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    LocalStorageHelper.removePostDetail();
  }
  postId: number;
  public model: Newsfeed;
  _types = TypePost;

  constructor(private route: ActivatedRoute,
    private _router: Router) {
   }

  ngOnInit() {
    let post = LocalStorageHelper.getPostDetail();
    if (post) {
      this.model = post;
      this.model.subtitle = this._types[post.articleType];
      if(post.articleType == 6)
      {
        this.model.subtitle = 'General Communication';
      }

      try
      {
        if(this.model.body.includes('<p>')) {
          let splited = this.model.body.split('<p>')
          if(splited && splited.length>0) {
            this.model.body = splited[splited.length - 1];
            this.model.subtitle = 'General Communication';
          }
        }
      } catch(ee) {

      }
      
    } else {
        this._router.navigateByUrl('/');
    }
  }

}
