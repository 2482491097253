import { Provider, ClassificationItem, Application, PayPoint } from '../model/smaug';

import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TardisFileService {
  public baseUrl: string;
  public authApiKey = false;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) appConfig: AppConfig
  ) {
    if (AppConstants.EndpointNames.TardisService in appConfig.endpoints) {
      const endpoint = appConfig.endpoints[AppConstants.EndpointNames.TardisService];
      this.baseUrl = endpoint + '/file';
    } else {
      console.error('Invalid endpoint name passed to data provider.');
    }
  }

  /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  private buildGetHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();

    let getHeaders = new HttpHeaders();
    if (this.authApiKey) {
      getHeaders = getHeaders.set('Authorization', 'api-key ' + environment.apiKeys.Pensieve);
    } else {
      getHeaders = getHeaders.set('Authorization', 'bearer ' + token);
    }

    return getHeaders;
  }

  getFile(fileId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/byFileId/' + fileId,
    { headers: this.buildGetHeaders() , responseType: 'arraybuffer'} );
  }
}
