import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { ResidentSearch } from 'src/app/model/resident-search';
import { Observable, fromEvent, Subject, forkJoin, Subscription } from 'rxjs';
import { CommunitySettingsService } from 'src/app/services/community-settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PeopleService } from 'src/app/services/people.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { debounceTime, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { People } from 'src/app/model/people';
import { LevelFilter, Level } from 'src/app/model/breadcrumb';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { CommunitySettings } from 'src/app/model/community-settings';
import { VisitorTypeService } from 'src/app/services/visitor-type.service';
import { VisitorType } from 'src/app/model/visitor-type';
import { VehicleBrand } from 'src/app/model/vehicle-brand';
import { Color } from 'src/app/model/color';
import { ColorService } from 'src/app/services/color.service';
import { VisitorsTimeLog, VisitorsTimeLogFull } from 'src/app/model/visitors-time-log';
import { Vehicle } from 'src/app/model/vehicle';
import { VehicleBrandService } from 'src/app/services/vehicle-brand.service';
import { VisitorsTimeLogService } from 'src/app/services/visitors-time-log.service';
import { CompanyGuests } from 'src/app/model/company-guests';
import { CompanyGuestsService } from 'src/app/services/company-guests.service';
import { PhotoCameraComponent } from 'src/app/shared/photo-camera/photo-camera.component';
import { VisitorService } from 'src/app/services/visitor.service';
import { VisitorFullModel, Visitor } from 'src/app/model/visitor';
import { SearchVisitorsComponent } from '../search-visitors/search-visitors.component';
import { SignaturePadService } from 'src/app/services/signature-pad.service';
import { TardisFileService } from 'src/app/services/pensieve.service';
import { SignaturePad } from 'src/app/model/signature-pad';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { SearchResidentComponent } from 'src/app/shared/search-resident/search-resident.component';
import { RelationshipService } from 'src/app/services/relationship.service';
import { stringHelper } from 'src/app/helpers/stringHelper';
import { Relationship } from 'src/app/model/relationship';
import { ResidentInfo } from 'src/app/model/resident';
import { CompanyAddComponent } from '../company-add/company-add.component';
import { CommunityService } from 'src/app/services/community.service';
import { StringLiteral } from 'typescript';


export enum access {
  Granted = 1,
  Denied = 2,
  Contact = 3
}

@Component({
  selector: 'app-visitor-log-detail',
  templateUrl: './visitor-log-detail.component.html',
  styleUrls: ['./visitor-log-detail.component.scss']
})
export class VisitorLogDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  //resident variables
  public areas: Level[] = [];
  public areaId = 0;
  public areaName = '';
  public communitySettings: CommunitySettings = new CommunitySettings(null);
  public communityId: number;
  private communityName: string;
  public residents: ResidentInfo[] = [];
  public relationships: Relationship[] = [];
  public residentName: string = '';
  public unitName = '';
  // visitor variables
  public companies: CompanyGuests[] = [];
  public enableVisitors = true;
  private model: VisitorsTimeLog;
  public relationshipId: number;
  public signature = '';
  public picture = '';
  public visitorTypes: VisitorType[] = [];
  public visitors: VisitorFullModel[] = [];
  public visitorTypeName: string = '';
  public visitorAccess: string = '';
  public visitorInfo = '';
  private visitorsTimeLogId = 0;
  //vehicle variables
  public colors: Color[];
  public years: number[] = [];
  public vehicleMarks: VehicleBrand[] = [];
  public vehicles: Vehicle[] = [];
  private vehicle: Vehicle;
  //form variables
  public visitorFormGroup: FormGroup;
  public vehicleFormGroup: FormGroup;
  //search variables
  public breadcrumbChanges: Subscription;
  public unitSearch: string;
  public searchPeople: SearchVisitorsComponent;
  private searchResident: SearchResidentComponent;
  //indicator variables
  public loading = false;
  public peopleNotFound = false;
  public showArea = false;
  private sub: Subscription;
  public temporaryFileId = 0;
  public selectedVisitor = false;
  public bSignature = false;
  public bPicture = false;
  public bCompany = false;
  showResidentSearch = true;
  staffInfo: string;


  @ViewChild('searchResident') set contentSearch(content: SearchResidentComponent) {
    this.searchResident = content;
    if (this.searchResident) {
      this.searchResident.residentSelectionChangeEmit().subscribe(p => {
        if (this.showResidentSearch) {
            this.showResidentSearch = false;
            if (p.relationshipId != null) {
              this.getRelationships(p.unitId);
        if (this.communitySettings.visitorsType) {
              this.visitorTypes = this.visitorTypes.filter(v => v.isTiedToUnit);
            }
            this.showArea = false;
          } else {
            this.showArea = true;
            this.staffInfo = this.displayFn(p) + ' (Staff - ' + p.positionName + ')';
            if (this.communitySettings.visitorsType) {
              this.visitorTypes = this.visitorTypes.filter(v => !v.isTiedToUnit);
            }
          }
        }
      });
    }
  }
  public cameraInit = new Subject<boolean>();
  private camera: PhotoCameraComponent;
  @ViewChild('camera') set content(content: PhotoCameraComponent) {
    this.camera = content;
  /*
    if (this.camera) {
      this.camera.startup();
      this.cameraInit.next(true);
    }
    */
  }
  onCameraInit(): Observable<boolean> {
    return this.cameraInit.asObservable();
  }

  constructor(private fb: FormBuilder,
    private fbVehicle: FormBuilder,
    private dialog: MatDialog,
    private peopleService: PeopleService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private visitorTypeService: VisitorTypeService,
    private communitySettingService: CommunitySettingsService,
    private colorService: ColorService,
    private vehicleBrandService: VehicleBrandService,
    private visitorTimeLogService: VisitorsTimeLogService,
    private visitorsService: VisitorService,
    private companyGuestService: CompanyGuestsService,
    private signaturePAdService: SignaturePadService,
    private pensiveService: TardisFileService,
    private firebaseMessagingService: FirebaseMessagingService,
    private relationshipService: RelationshipService,
    private communityService: CommunityService,
    private cdRef: ChangeDetectorRef) {
      this.model = new VisitorsTimeLog(this.fb);
      this.visitorFormGroup = this.model.buildFormGroup();
      this.vehicle = new Vehicle(this.fbVehicle);
      this.visitorFormGroup.addControl('vehicleGroup',  this.vehicle.buildFormGroup());

      this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
        const community = LocalStorageHelper.getCommunitiesFromBreadcrumb();
        if (this.communityId !== community) {
          this.communityId = community;
          this.communityName = LocalStorageHelper.getCommunityNameFromBreadcrumb();
        }
      });
      this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.sub = this.route.paramMap.subscribe(
      params => {
        this.visitorsTimeLogId = +params.get('id');
        if (this.visitorsTimeLogId > 0) {
          this.loading = true;
          this.showResidentSearch = false;
          this.visitorTimeLogService.getById(this.visitorsTimeLogId).subscribe(p => {
            console.log(p);
            if (this.communityId === 0) {
              this.communityId = p.communityId;
              this.communityService.getById(this.communityId).subscribe(c => this.communityName = c.name);
            } else {  }
            this.configureVisitorSetting(p);
          });
        }
        });
  }

  ngOnInit() {

  }

  getCommunity() {
    this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.model.communityName = LocalStorageHelper.getCommunityNameFromBreadcrumb();
    this.model.communityId = this.communityId;
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
   }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.breadcrumbChanges.unsubscribe();
  }

  
  
  async configureVisitorSetting(p: any) {
    this.enableVisitors = false;

    this.loading = true;
     this.enableVisitors = true;
      const currentYear = new Date().getFullYear() + 1;
      for (let i = currentYear; i >= currentYear - 20; i--) {
        this.years.push(i);
      }
      const observableArray: Observable<any>[] = [];
      observableArray.push(this.peopleService.GetForCurrentUser());
      this.visitorTypes = await this.visitorTypeService.getAll().toPromise();
      this.colors = await this.colorService.getAll().toPromise();
      this.vehicleMarks = await this.vehicleBrandService.getAll().toPromise();
      this.companies = await this.companyGuestService.getAll().toPromise();
      this.visitorFormGroup.controls['vehicleGroup'].updateValueAndValidity();
      this.visitorFormGroup.updateValueAndValidity();
      this.setModelFromVisitorTimeLog(p);
      this.loading = false;
      this.visitorAccess = this.model.accessDenied === true ? 'Denied' : 'Granted';
      this.visitorTypeName = this.visitorTypes.filter(v => v.visitorTypeId === this.model.visitorTypeId)[0].name;
  }
  onChangeVisitorType(value) {
    const type = this.visitorTypes.filter(v => v.visitorTypeId === value)[0];
    this.showArea = !type.isTiedToUnit;
    this.showResidentSearch = type.isTiedToUnit && !this.unitSearch;
    this.model.visitorTypeId = type.visitorTypeId;
  }
  getRelationships(unitId: number) {
    this.loading = true;
  forkJoin(this.relationshipService.getResidentsByUnit(unitId)).subscribe(result => {
    this.getResidentInfo(result[0]);
    this.showResidentSearch = false;
  });
  }

  getResidentInfo(residents: any[]) {
    this.areaId = residents[0].areaId;
    this.areaName = residents[0].areaName;
    this.unitName = residents[0].unitName;
    if (this.unitName && this.unitName.includes('Unit:') === true) {
      this.unitName = this.unitName.replace('Unit:', '');
    }
    this.unitSearch = this.areaName + ' ' + this.unitName;
    const groups = Array.from(new Set(residents.map((item: any) => item.relationshipId)));
    this.visitorsService.getByRelationshipIdArray(groups).subscribe(r => {
      this.visitors = r;
   groups.forEach(re => {
        const resident = new ResidentInfo();
        const people = residents.filter(p => p.relationshipId === re)[0];
        resident.peopleId = people.peopleId;
        resident.relationshipId = people.relationshipId;
        resident.firstName = people.firstName;
        resident.lastName = people.lastName;
        residents.filter(p => p.relationshipId === re).forEach(c => {
          resident.contacts.push({ phoneNumber: c.phoneNumber, phoneType: c.phoneType, isPreferred: c.isPreferred });
        });
        resident.visitors = r.filter(v => v.relationshipId === re);
        this.residents.push(resident);
        this.loading = false;
      });
    });
  }


  displayFn(visitor: any) {
      return visitor.firstName + ' ' + visitor.lastName;
  }

  displayFnV(visitor: VisitorFullModel) {
    return visitor.firstName + ' ' + visitor.lastName + (visitor.visitorId !== null && visitor.visitorId  > 0 ?
       ' (Guest)' : ' (Previous Visitor)');
  }

  setVisitorInfo(firstName: string, lastName: string) {
    this.visitorInfo = firstName + ' ' + lastName;
    this.visitorFormGroup.controls['firstName'].setValue(firstName);
    this.visitorFormGroup.controls['lastName'].setValue(lastName);
    this.visitorFormGroup.controls['visitorTypeId'].disable();
  }
  newVisitor() {
    this.selectedVisitor = false;
    this.model = new VisitorsTimeLog(this.fb);
    this.visitorFormGroup.controls['firstName'].setValue('');
    this.visitorFormGroup.controls['lastName'].setValue('');
    this.visitorFormGroup.controls['visitorTypeId'].enable();
   }

  setModelFromVisitorTimeLog(p: any) {
    this.selectedVisitor = true;
    if (p.relationshipId) {
    this.relationshipService.getById(p.relationshipId).subscribe(u => {
      this.areaId = u.areaId;
      this.areaName = u.areaName;
      this.unitName = u.unitName;
      this.residentName = u.firstName + ' ' + u.lastName;
      if (this.unitName && this.unitName.includes('Unit:') === true) {
        this.unitName = this.unitName.replace('Unit:', '');
      }
      this.unitSearch = this.areaName + ' ' + this.unitName;
      this.showArea  = false;
    });
    this.model.relationshipIdArray.push(p.relationshipId);
    } else {
      this.showArea  = true;
    }
    this.model.fromObject(p);

    if (p.companyId) {
      this.bCompany = true;
    }
    if (p.vehicleId) {
      this.vehicle.fromObject(p);
    }

    if (p.signature) {
      this.getFile(p.signature, 'signature');
      this.bSignature = true;
    }
    if (p.picture) {
      this.getFile(p.picture, 'picture');
      this.bPicture = true;
    }
    this.setVisitorInfo(this.model.firstName , this.model.lastName);
  }

  onAreaChange(area: Level) {
    this.areaId = area.id;
    this.areaName = area.name;
  }

  getCommunitySetting(communityId: number): Observable<boolean> {
    const flag = new Subject<boolean>();
    forkJoin(this.communitySettingService.getByCommunityId(communityId))
      .subscribe(sett => {
        if (sett.length > 0 && sett[0]) {
          this.communitySettings = sett[0];
          flag.next(true);
        } else {
          flag.next(false);
        }
      });
    return flag.asObservable();
  }

  getData(): FormData {
    const entity: any = this.model.toDto();
    entity.communityId = this.communityId;
    if (this.visitorFormGroup.controls['vehicleGroup'].valid && this.visitorFormGroup.controls['vehicleGroup'].dirty) {
      entity.hasVehicle = true;
      const entityVehicle = this.vehicle.toDto();
      entity.vehicle = entityVehicle;
    }
    const form = this.model.toFormData(entity);
    if ((this.communitySettings.visitorsPicture) &&
      ((this.model.visitorsTimeLogId === null || this.model.visitorsTimeLogId === 0)
        || (this.model.visitorsTimeLogId > 0 && this.model.picture !== this.camera.src))) {
      form.append('picture', this.camera.picture);
    }

    if (this.communitySettings.visitorsSignature) {
      form.append('temporaryFileId', this.temporaryFileId.toString());
    }
    form.append('areaId', this.areaId.toString());
    form.append('areaName', this.areaName);
    form.append('unitName', this.unitName);
    form.append('communityName', this.communityName);
    return form;
  }

  requestSignature() {
    SiteHelper.openDialogAlert('Info', 'please wait while the signature is captured', this.dialog);
    this.loading = true;
    const signaturePad = new SignaturePad();
    signaturePad.clientToken = LocalStorageHelper.getClientToken();
    signaturePad.title = 'new visit';
    signaturePad.callback = 'sendNotification';
    signaturePad.callbackPayload = { Message: 'new request signature' };
    this.signaturePAdService.signaturePad(signaturePad).subscribe(data => {
    },
      err => {
        this.dialog.closeAll();
        const error = err.status === 404 ? err.error.errorMessage : '';
        SiteHelper.openDialogAlert('Warning', 'An error ocurred on request signature. ' + error, this.dialog);
        this.loading = false;
      });
    this.firebaseMessagingService.onNewVisitSignatureSuccess().subscribe(data => {
      this.dialog.closeAll();
      if (data && data.TemporaryFileId !== undefined) {
        this.temporaryFileId = data.TemporaryFileId;
        this.getSignature(this.temporaryFileId);
      }
    });

  }

  getSignature(fileId: number) {
    this.pensiveService.getFile(fileId).subscribe(pic => {
      const reader = new FileReader();
      const blob = new Blob([new Uint8Array(pic)], { type: 'image/png' });
      if (blob.size > 0) {
        const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
        reader.onload = (event: any) => {
          this.signature = event.target.result;
          this.loading = false;
        };
        reader.readAsDataURL(file);
      }
    });
  }

  setImage(pic: any, type: string) {
    const reader = new FileReader();
    const blob = new Blob([new Uint8Array(pic)], { type: 'image/png' });
    if (blob.size > 0) {
      const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
      reader.onload = (event: any) => {
        if (type === 'signature') {
        this.signature = event.target.result;
        this.model.signature = this.signature;
        this.dialog.closeAll();
        } else if (type === 'picture') {
          /*
          if (this.camera !== undefined) {
            this.setPhotoCamera(event.target.result);
          }
          */
          /*this.onCameraInit().subscribe(_ => this.setPhotoCamera(event.target.result));*/
          this.picture = event.target.result;
        this.model.picture = this.picture;
        }
      };
      reader.readAsDataURL(file);
    }
  }
  setPhotoCamera(src: any) {
    this.camera.setPicture(src);
    this.model.picture = this.camera.src;
  }
  getFile(fileId: number, type: string) {
    this.pensiveService.getFile(fileId).subscribe(pic => {
      this.setImage(pic, type);
    });
  }
  onCancel() {
    this.router.navigateByUrl('/app/profile/visitors');
  }

}
