import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceModifiersComponent } from './price-modifiers/price-modifiers.component';
import { CommunityRelationTypeDialogComponent } from '../community-relation-type-dialog/community-relation-type-dialog.component';
import { CommunityDialogComponent } from '../community-dialog/community-dialog.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { GroupByPipe } from '../thorin-helper/group-by-pipe';
import { RemoveDuplicatePipe } from '../thorin-helper/remove-duplicate-pipe';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
  ],
  declarations: [
    PriceModifiersComponent,
    CommunityRelationTypeDialogComponent,
    CommunityDialogComponent,
    GroupByPipe,
    RemoveDuplicatePipe
  ],
  entryComponents: [
    PriceModifiersComponent,
    CommunityRelationTypeDialogComponent,
    CommunityDialogComponent
  ],
  exports: [
    PriceModifiersComponent
  ]
})
export class SharedModule { }
