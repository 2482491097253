import { Visitor, VisitorFullModel } from './visitor';
import { StringLiteral } from 'typescript';

export class Resident {
    userId: number;
    accountId: number;
    externalAccountNumber: string;
    relationshipId: number;
    unitId: number;
    areaId: number;
    communityId: number;
    canPay: boolean;
    canLegalPay: boolean;
    managementCompanyId: number;
    canEditname: boolean;
    email: string;
    peopleId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    areaName: string;
    unitName: string;
}

export class ResidentInfo {
    relationshipId: number;
    peopleId: number;
    firstName: string;
    lastName: string;
    contacts: ContactInfo[] = [];
    emails: EmailInfo[] = [];
    visitors: VisitorFullModel[] = [];
    areaId: number;
    areaName: string;
    unitId: number;
    unitName: string;
}

export class ContactInfo {
    phoneNumber: string;
    phoneType: string;
    isPreferred: boolean;
}

export class EmailInfo {
    email: string;
    emailType: string;
    isPreferred: boolean;
}
