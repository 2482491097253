import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { Ring } from 'src/app/model/ring';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { RingType } from 'src/app/model/ring-type';
import { Entity } from 'src/app/model/entity';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSelect,
  MatSnackBar,
} from '@angular/material';
import { RingTypeService } from 'src/app/services/ring-type.service';
import { EntityService } from 'src/app/services/entity.service';
import { forkJoin } from 'rxjs';
import { RoleTypeService } from 'src/app/services/role-type.service';
import { RoleType } from 'src/app/model/role-type';
import { EntityType } from 'src/app/model/entity-type';
import { EntityTypeService } from 'src/app/services/entity-type.service';
import { TemplateService } from 'src/app/services/template.service';
import { RingEntityTable } from 'src/app/model/ring-entity-table';
import { RingService } from 'src/app/services/ring.service';

@Component({
  selector: 'app-template-ring-add-dialog',
  templateUrl: './template-ring-add-dialog.component.html',
  styleUrls: ['./template-ring-add-dialog.component.css']
})
export class TemplateRingAddDialogComponent implements OnInit {

  public model: Ring;
  public newRing: RingEntityTable;
  public templateRingFormGroup: FormGroup;
  public ringsTypes: RingType[];
  public entities: Entity[];
  public roleTypes: RoleType[];
  public entityTypes: EntityType[];
  public entitySelected = 0;
  public entityTypeSelected = 0;
  public ringTypeSelected = 0;
  @ViewChild('ringTypeSelect') ringTypeSelect: MatSelect;
  @ViewChild('entitySelect') entitySelect: MatSelect;
  @ViewChild('roleTypeSelect') roleTypeSelect: MatSelect;
  @ViewChild('entityTypeSelect') entityTypeSelect: MatSelect;

  constructor(public dialogRef: MatDialogRef<TemplateRingAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ringTypeService: RingTypeService,
    private ringService: RingService,
    private roleTypeService: RoleTypeService,
    private entityTypeService: EntityTypeService,
    private templateService: TemplateService,
    private entityService: EntityService) {

    this.model = new Ring(this.fb);
    this.templateRingFormGroup = this.model.buildFormGroup();
  }

  ngOnInit() {
    this.newRing = new RingEntityTable();
    this.ringsTypes = [];
    this.roleTypes = [];
    this.entities = [];

    const entityTypesObservable = this.entityTypeService.getAll();
    forkJoin(
      entityTypesObservable
    )
      .subscribe(results => {
        this.entityTypes = results[0];
      });
  }

  onEntityChange(entityId: number): void {
    if ( entityId !== 0 ||
      (this.entitySelected !== entityId && this.entitySelected !== 0) ) {
        this.entitySelected = 0;
        this.ringTypeSelected = 0;
    }
    this.ringTypeService.getAll().subscribe(results => {
      this.ringsTypes = results;
      this.entitySelected = entityId;
    });
  }

  onEntityTypeChange(entityTypeId: number): void {
    if ( entityTypeId !== 0 ||
      (this.entityTypeSelected !== entityTypeId && this.entityTypeSelected !== 0) ) {
        this.entityTypeSelected = 0;
        this.entitySelected = 0;
        this.ringTypeSelected = 0;
    }

    this.entityService.getEntitiesByEntityTypeId(entityTypeId).subscribe(results => {
      this.entities = results;
      this.entityTypeSelected = entityTypeId;
    });
  }

  onRingTypeChange(ringTypeId: number): void {
    this.ringService.getRingByIds(ringTypeId, this.entitySelected).subscribe(results => {
       if (results[0]) {
        this.newRing = results[0];
        this.templateRingFormGroup = this.fb.group({
          'name': new FormControl(results[0].name),
          'description': new FormControl(results[0].description)
        });
       } else {
        this.newRing = new RingEntityTable();
        this.templateRingFormGroup = this.fb.group({
          'name': new FormControl('', Validators.required),
          'description': new FormControl('', Validators.required)
        });
       }
      this.ringTypeSelected = ringTypeId;
    });
  }

  onCancel(): void {
    if (this.templateRingFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else {
      this.dialogRef.close({ valid: false });
    }
  }

  onSubmit(): void {
    this.dialogRef.close({
      valid: true,
      model: this.newRing
    });
    console.log(this.newRing);
  }

}
