import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from 'material2/src/lib';
import { Subject } from '../../../../node_modules/rxjs';

export enum snackType {
  Success,
  Error,
  Warn,
  Info
}

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css']
})


export class CustomSnackbarComponent implements OnInit {
 constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
 private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  get getIcon() {
    switch (this.data.snackType) {
      case snackType.Success:
        return 'check_circle_outline';
      case snackType.Error:
        return 'error';
      case snackType.Warn:
        return 'warning';
      case snackType.Info:
        return 'info';
    }
  }
close() {
  this.snackBar.dismiss();
}

}
