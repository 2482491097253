import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PackageAddComponent } from '../com-package-add/package-add.component';
import { ResidentSearch } from 'src/app/model/resident-search';
import { CommunitySettings } from 'src/app/model/community-settings';

@Component({
  selector: 'app-package-add-dialog',
  templateUrl: './package-add-dialog.component.html',
  styleUrls: ['./package-add-dialog.component.css']
})
export class PackageAddDialogComponent implements OnInit {

  public loading: boolean;
  public resident: ResidentSearch;
  public communitySettings: CommunitySettings = new CommunitySettings(null);
  @ViewChild('addPackage') addPackage: PackageAddComponent;

  constructor(public dialogRef: MatDialogRef<PackageAddDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.resident = this.data.resident;
    this.communitySettings = this.data.communitySettings;
    this.addPackage.watchCancel().subscribe(r => {
      if (r) {
        this.onNoClick();
      }
    });
    this.addPackage.watchAddPackage().subscribe(result => {
      if (result) {
      this.dialogRef.close({
        valid: true,
        data: {
          package: result
        }
      });
    }
    });
  }


}
