import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import {
  MatAutocompleteModule,
  MatInput,
  MatCardModule,
  MatButtonModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatBadgeModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatTabsModule,
  MatChipsModule,
  MatDatepickerIntl,
  MatTreeModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatSortModule,
  MatButtonToggleModule
} from '@angular/material';

import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatChipsModule,
    MatTreeModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSortModule,
    MatButtonToggleModule
  ],
  exports: [
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatBadgeModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTabsModule,
    MatChipsModule,
    MatTreeModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSortModule,
    MatButtonToggleModule
  ],
  declarations: []
})
export class MaterialModule { }
