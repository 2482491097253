import { Injectable, Inject } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { TemplateRole } from '../model/template-role';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RoleRing } from '../model/role-ring';
import { Entity } from '../model/entity';
import { RingType } from '../model/ring-type';
import { Ring } from '../model/ring';
import { RingEntityTable } from '../model/ring-entity-table';
import { CrudResponseModel } from '../model/crud-response-model';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends AbstractDataProviderService<TemplateRole> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.SortingHat, '/sortingHat/templates', http, appConfig);
  }

  getByTemplateId(id: number): Observable<TemplateRole> {
    const queryString = '/byTemplateId/' + id.toString();
    return this.http.get<TemplateRole>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getByTemplateRoleId(id: number): Observable<RoleRing[]> {
    const queryString = '/byTemplateRoleId/' + id.toString();
    return this.http.get<RoleRing[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  addTemplateRole(entity): Observable<CrudResponseModel> {
    return this.http.post<CrudResponseModel>(this.baseUrl + '/add', entity, { headers: this.buildPostHeaders() });
  }

  updateTemplateRole(entity): Observable<null> {
    return this.http.put<null>(this.baseUrl + '/update', entity, { headers: this.buildPostHeaders() });
  }

  deleteRingFromTemplate(templateRoleId: number, ringId: number): Observable<null> {
    return this.http.delete<null>(this.baseUrl + '/deleteTemplateRing/' + templateRoleId.toString()
      + '/' + ringId.toString(), { headers: this.buildGetHeaders() });
  }

  deleteTemplateRole(templateRoleId: number): Observable<null> {
    return this.http.delete<null>(this.baseUrl + '/delete/' + templateRoleId.toString(), { headers: this.buildGetHeaders() });
  }


}
