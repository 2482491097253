import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Services
import { AuthGuardService } from 'src/app/services/auth-guard.service';

// Components
import { BundleListComponent } from '../bundle-list/bundle-list.component';
import { BundleAddEditComponent } from '../bundle-add-edit/bundle-add-edit.component';


const bundleRoutes: Routes = [{
  path: 'bundle',
  children: [
    {
      path: '',
      component: BundleListComponent,
      data: { title: 'Bundles' },
      canActivate: [AuthGuardService]
    },
    {
      path: ':id/edit',
      component: BundleAddEditComponent,
      data: { title: 'Add Bundle' },
      canActivate: [AuthGuardService]
    },

  ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(bundleRoutes)
  ],
  declarations: []
})
export class BundleRoutingModule { }

