import { Component, OnInit, Renderer2, ElementRef, ViewContainerRef, AfterViewInit, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { Breadcrumb, UserProfile, Level, Profile } from '../model/breadcrumb';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { UserService } from '../services/user.service';
import { Observable, fromEvent } from 'rxjs';
import { map, debounceTime, switchAll, startWith, distinctUntilChanged, switchMap, tap, finalize } from 'rxjs/operators';
import { UnitService } from '../services/unit.service';
import { Unit } from '../model/unit';
import { MatAutocomplete } from '@angular/material';


enum Title {
  ManagementCompany = 'Management Companies',
  Community = 'Communities',
  Unit = 'Units',
  Account = 'Accounts',
  Area = 'Buildings'
}

const profileDictionary = {
  1: 'GridStaff',
  2: 'PropertyManagementCompanyStaff',
  3: 'CommunityStaff',
  4: 'OwnerTenant'
};

const breadcrumbData = {
  1: new Level(),
  2: new Level(),
  3: new Level(),
  4: new Level(),
};

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})



export class BreadcrumbComponent implements OnInit, AfterViewInit {

  public level1Title = '';
  public level2Title = '';
  public level3Title = '';

  public showBreadcrumb = true;
  public isLevel1Selectable = false;
  public isLblLevel1 = false;
  public isLblLevel2 = false;
  public isLblLevel3 = false;
  public showLevel1 = false;
  public showLevel2 = false;
  public showLevel3 = false;
  public showLevel4 = true;
  public level1: Level = new Level();
  public level2: Level = new Level();
  public level3: Level = new Level();
  public level4: Level = new Level();
  public level1All: Level = new Level();
  public level1AllDisabled: boolean;
  public level2All: Level = new Level();
  public level2AllDisabled: boolean;
  public level3All: Level = new Level();
  public level3AllDisabled: boolean;
  public Breadcrumb: Breadcrumb = new Breadcrumb();
  public levels1: Level[] = [];
  public levels2: Level[] = [];
  public levels3: Level[] = [];
  public userProfile = new UserProfile();
  filteredOptionsUnits: Observable<any[]>;
  public selectTedUnit = '';
  public loading = true;
  public isLoading = false;
  public disabledBreadcrumb = false;
  public selectable = false;
  public  removable = true;
   @ViewChild('filter') input: ElementRef;
   @ViewChild('unitSelect') unitSelect: MatAutocomplete;

  public units: Unit[];

  private filterObservable: Observable<any>;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private unitService: UnitService,
    private render: Renderer2,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private userService: UserService) {
      this.level2All.id = 0;
      this.level3All.id = 0;
      this.level1All.id = 0;
  }


  ngOnInit() {
    this.populateBreadcrumb();
    this.isLoading = false;
    this.breadcrumbService.watchUpdated(false).subscribe(r => {
      this.cdr.detectChanges();
      this.populateBreadcrumb();
    });
  }

  ngAfterViewInit() {
    this.isLoading = false;
    if (this.input) {
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(200),
      tap(() => this.isLoading = true),
      distinctUntilChanged(),
      switchMap((e: any)  => {
        let val = '';
        if (e) {
          val = e.target.value;
        }
        if (val && val.length > 2) {
          this.onExpand();
        return this.applyFilter(val || '');
        } else {
          this.isLoading = false;
          this.units = [];
            return new Observable<Unit[]>();
        }
      })
    ).subscribe( result => {
      this.units = result.filter(u => u.areaTypeId === 1);
      this.isLoading = false;
    });
  }
  }

  onUnitSelectionChange(unit: Unit, setLocalStorage = true) {
    this.SetLevels(this.level1All);
    this.level4 = new Level();
    this.selectTedUnit = unit.displayName;
    this.disabledBreadcrumb = true;
    this.level3 = this.levels3.find( l => l.id === unit.areaId);
    this.level2 =  this.levels2.find( l => l.id === this.level3.fatherId);
    this.level1 =  this.levels1.find( l => l.id === this.level2.fatherId);
    this.level4.fatherId = unit.areaId;
    this.level4.id = unit.unitId;
    this.level4.name = unit.displayName;
    this.level4.type = 'Unit';
    this.input.nativeElement.value = '';
    if (setLocalStorage) {
      this.SetLocalStorageData();
    }
  }

  removeUnit() {
    this.clearFilter();
    // this.selectAll();
    this.SetLocalStorageData();
  }

  clearFilter() {
    this.disabledBreadcrumb = false;
    this.selectTedUnit = '';
    this.level4 = null;
    this.units = [];
  }

  applyFilter(filterValue: string): Observable<Unit[]>  {
    return this.unitService.getAllByPaginatedFiltered(1, 20, filterValue);
  }

  setSelectionsFromLocalStorage() {
    const lev1 = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    const lev2 = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    const lev3 = LocalStorageHelper.getBuildingFromBreadcrumb();
    const lev4 = LocalStorageHelper.getUnitIdFromBreadcrumb();

    if (lev1 > 0) {
      const level1 = this.levels1.find(l1 => l1.id === lev1);
      if (level1) {
        this.SetLevels(level1);
      }
      if (lev2 > 0) {
        const level2 = level1.son.find(l2 => l2.id === lev2);
        if (level2) {
          this.OnLevel2SelectedChange(level2, false);
        }
        if (lev3 > 0) {
          const level3 = level2.son.find(l3 => l3.id === lev3);
          if (level3) {
            this.OnLevel3SelectedChange(level3, false);
          }
          if (lev4 > 0) {
            this.unitService.getById(lev4).subscribe(unit => {
              this.onUnitSelectionChange(unit, false);
            });
          }// If from Level 4
        }// If from Level 3
      }// If from Level 2
    }// If from Level 1
  }

  populateBreadcrumb() {
    this.clearFilter();
    this.breadcrumbService.get().subscribe(x => {
      this.Breadcrumb = x;
      this.Breadcrumb.levels.map(child => this.removeEmptyItems(child));

      if (this.Breadcrumb !== null && this.Breadcrumb !== undefined &&
          this.Breadcrumb.levels !== undefined && this.Breadcrumb.levels.length > 0) {
        //this.userService.setIsStaff(this.Breadcrumb.userProfile.id < Profile.OwnerTenant ||
                                   // LocalStorageHelper.getUserImpersonatedInfo() != null);
        this.userService.isTenantOwner.next(this.Breadcrumb.userProfile.id === Profile.OwnerTenant);
        this.CreateBreadcrumb();
        this.setSelectionsFromLocalStorage();
        this.SetLocalStorageData();
        this.showBreadcrumb = true;
      } else {
        this.showBreadcrumb = false;
      }
    });
  }

  onExpand() {
    let parent = this.elRef.nativeElement.closest('body').querySelector('.breadcrumb-panel');
    if (!parent) {
       parent = this.elRef.nativeElement.closest('body').querySelector('.mat-autocomplete-panel');
    }
    if (parent && parent.parentElement) {
      this.render.addClass(parent.parentElement, 'custom-panel' );
    }
  }

  removeEmptyItems(nodo: Level) {
    nodo.son.forEach((child, index) => {
      if (child.id === 0) {
        nodo.son.splice(index, 1);
      }
      this.removeEmptyItems(child);
    });
  }

  setLevels2() {
    this.levels2 = [];
    this.levels1.map(child => {
      this.levels2 = child.son;
    });
  }

  setLevels3(mc?: number ) {
    this.levels3 = [];
    this.levels1.map(child => {
      if (!mc || child.id === mc) {
      child.son.map (chilLevel2 => {
        chilLevel2.son.map (chilLevel3 => {
          this.levels3.push(chilLevel3);
        });
      });
    }
    });
  }

  SetLevels(level: Level) {
    this.level1 = level;
    if (level.id !== 0) {
        this.levels2 = this.level1.son;

        if (this.levels2.length === 1) {
          this.level2 = this.levels2[0];
        } else {
          this.level2 = this.level2All;
      }

        this.setLevels3(level.id);
        this.level3 = this.level3All;
        if (this.levels2.length === 0) {
          this.setLevel2Empty();
        }
        if (this.levels3.length === 0) {
          this.setLevel3Empty();
        }
    } else {
      this.levels2 = level.son;
      this.level2 = this.level2All;
      this.levels3 = this.level2All.son;
      this.level3 = this.level3All;
    }
    this.isLblLevel2 = this.levels2.length <= 1;
    this.isLblLevel3 = this.levels3.length <= 1;
    this.showLevel2 = this.levels2.length > 0;
    this.level2All.fatherId = this.level1.id;
  }

  OnLevel1SelectedChange(selectedLevel: Level, setLocalStorage = true) {
    this.SetLevels(selectedLevel);
    if (setLocalStorage) {
        this.SetLocalStorageData();
    }
  }

  OnLevel2SelectedChange(selectedLevel: Level, setLocalStorage = true) {
    this.level2 = selectedLevel; // select as level 2 the selected item
    this.levels3 = this.level2.son; // set that levels 3 are the children of the newly selected level 2
    if (selectedLevel.id === 0) {
      this.levels3 = this.level2.son;
      if (this.level1.id === 0) {
        this.setLevels2();
        this.level2 = selectedLevel;
      } else {
        this.setLevels3();
       }

    } else {
    this.level1 = this.levels1.find(lvl1 => lvl1.id === this.level2.fatherId); // set that level 1 is the father of level 2
    }
    //  if the third level contains more than one element, set it to be label or not as appropriate
    if (this.levels3.length === 0) {
      this.setLevel3Empty();
    } else if (this.levels3.length === 1) {
    this.isLblLevel3 = true;
    this.level3  = this.levels3[0];
    } else {
      this.level3All.fatherId = this.level2.id;
      this.isLblLevel3 = false;
      this.level3 = this.level3All;
    }
    if (setLocalStorage) {
      this.SetLocalStorageData();
    }
  }

  setLevel2Empty() {
    this.isLblLevel2 = true;
    this.level2 = new Level();
    this.level2.id = 0;
    this.level2.name = 'No Data';
  }

  setLevel3Empty() {
    this.isLblLevel3 = true;
    this.level3 = new Level();
    this.level3.id = 0;
    this.level3.name = 'No Data';
  }

  OnLevel3SelectedChange(selectedLevel: Level, setLocalStorage = true) {

    this.level3 = selectedLevel;
    if (selectedLevel.id !== 0) {
      this.level2 = this.levels2.find(lvl2 => lvl2.id === this.level3.fatherId);
      this.level1 = this.levels1.find(lvl1 => lvl1.id === this.level2.fatherId); // set that level 1 is the father of level 2
    }

    if (setLocalStorage) {
      this.SetLocalStorageData();
    }
  }

  CreateBreadcrumb() {
    this.levels1 = this.Breadcrumb.levels; // Loading the First Level
    if (this.levels1 !== undefined && this.levels1[0] !== undefined) {
      if (this.Breadcrumb.userProfile.id === Profile.OwnerTenant) {
          this.showLevel4 = false;
      }
      if (this.Breadcrumb.userProfile.id === Profile.GridStaff) {
        this.BuildGridStaffBreadcrumb();
      } else {
        this.BuildTheRestOfStaffBreadcrumb();
      }
    }
  }



  BuildGridStaffBreadcrumb() {
    this.loadLevel2Complete(); // Loading the Second Level with all the sons of items in level 1

    this.level1All.son = this.levels2;
    this.level1 = this.levels1[0];
    // If the first level contains more than one element, set it to label or not as appropriate
     this.isLblLevel1 = this.levels1.length === 1;
    this.level1Title = this.GetControlTitle(this.level1.type); // set the title for level 1
    this.showLevel1 = true; // show the first level
    if (this.level1.son !== undefined && this.level1.son.length > 0) {
      this.level2 = this.levels2[0]; // select the first one on the list as level 2
      // if the second level contains more than one element, set it to be label or not as appropriate
      this.isLblLevel2 = this.levels2.length === 1;
      this.level2Title = this.GetControlTitle(this.level2.type); // set the title for level 2
      this.showLevel2 = true; // show the second level
      this.level2All.fatherId = this.levels1.length === 1 ? this.level1.id : 0;
    } else {
      this.showLevel2 = false; // if the children of level 1 are not defined or does not contain an element, do not show level 2
    }

    if (this.level2.son !== undefined && this.level2.son.length > 0) {
      this.levels3 = this.level2.son; // set that the children of level 2 are levels 3
    if (this.levels1.length === 1) {
      this.level2All.son = this.levels3;
    } else {
      this.level2All.son = [];
      for (const lvl1 of this.levels2) {
        for (const son of lvl1.son) {
          this.level2All.son.push(son);
        }
      }
    }
      this.level3 = this.levels3[0]; // select the first one from the list as level 3
      // If the third level contains more than one element, set it to label or not as appropriate
      this.isLblLevel3 = this.levels3.length === 1;
      this.level3Title = this.GetControlTitle(this.level3.type); // set the title for level 3
      this.showLevel3 = true; // show the third level
      this.level3All.fatherId = this.levels2.length === 1 ? this.level2.id : 0;
    } else {
      this.showLevel3 = false; // if the children of level 2 are not defined or does not contain an element, I do not show level 3
    }

    this.level1AllDisabled = this.selectAllDisabled(this.level1, 1); // determine if the ability to select all is disabled in lebvell 1
    this.level2AllDisabled = this.selectAllDisabled(this.level2, 2); // determine if the ability to select all is disabled in lebvell 2
    this.level3AllDisabled = this.selectAllDisabled(this.level3, 3);
    this.selectAll();
  }

  selectAll() {
        // select the first one from the list
        this.level1 = this.levels1.length > 1 ? this.level1All : this.level1;
        this.level2 = this.levels2.length > 1 ? this.level2All : this.level2;
        this.level3 = this.levels3.length > 1 ? this.level3All : this.level3;
        this.level3.fatherId = this.levels2.length > 1 ? 0 : this.level3.fatherId;
        this.levels3 = this.level2All.son;
        this.showLevel3 = true;
        this.isLblLevel3 = false;
  }

  BuildTheRestOfStaffBreadcrumb() {
    // add all units o level 2
   this.setLevels2();
   this.loadLevel2Complete();
    this.level2 = this.levels2[0]; // select the first one from the list for level 2
    this.levels3 = this.level2.son; // load level 3 with the children of the level 2 accounts associated with the unit
    if (this.levels3.length > 0) {
       this.isLblLevel3 = Boolean(this.level2.son.length === 1);
      this.level3 =  this.levels3[0]; // select the first account in the list
    } else {
      this.setLevel3Empty();
    }
    this.level1 = this.levels1.find(lvl1 => lvl1.id === this.level2.fatherId); // select level one based on the father of level 2

    this.isLblLevel1 = true; // In this case, always it will be a label since it is not selectable

    // If the second level contains more than one element, set it to label or not as appropriate
    this.isLblLevel2 = Boolean(this.levels2.length === 1);


    this.showLevel1 = true; // I'm going to show all the levels
    this.showLevel2 = true; // I'm going to show all the levels
    this.showLevel3 = true; // I'm going to show all the levels
    this.level1Title = this.GetControlTitle(this.level1.type); // set the title for the level
    this.level2Title = this.GetControlTitle(this.level2.type); // set the title for the level
    this.level3Title = this.GetControlTitle(this.level3.type); // set the title for the level
    this.level1AllDisabled = this.selectAllDisabled(this.level1, 1);
    this.level2AllDisabled = this.selectAllDisabled(this.level2, 2);
    this.level3AllDisabled = this.selectAllDisabled(this.level3, 3);

    if (! this.level2AllDisabled)  {
    this.level2All.son = [];
    this.levels1.map(child => {
      this.level2All.son = child.son;
    });
   }
    }

  ShortenStringDescription(description: string): string {
    if (description) {
      if (description.length > 20) {
        return description.substring(0, 17) + '...';
      }
      return description;
    }
  }

  selectAllDisabled(level: Level, levelN: number) {
    if (level.type === 'Unit' && levelN === 2) {
      return true;
    } else if (level.type === 'Community' && levelN === 1) {
      return true;
    } else {return false; }
  }

  GetControlTitle(description: string): string {
    switch (description) {
      case 'ManagementCompany': {
        return Title.ManagementCompany;
      }
      case 'Community': {
        return  Title.Community;
      }
      case 'Unit': {
        return Title.Unit;
      }
      case 'Account': {
        return Title.Account;
      }
      case 'Area': {
        return Title.Area;
      }
      default: {
        return '';
      }
    }
  }

  SetLocalStorageData() {
    breadcrumbData[1] = this.level1;
    breadcrumbData[2] = this.level2;
    breadcrumbData[3] = this.level3;
    breadcrumbData[4] = this.level4;
    LocalStorageHelper.setBreadcrumbInfo(breadcrumbData);
    this.loading = false;
  }

  loadLevel2Complete() {
    this.levels2 = [];
    for (const lvl1 of this.levels1) {
      for (const son of lvl1.son) {
        this.levels2.push(son);
      }
    }
  }
}
