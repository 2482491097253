import { Component, OnInit } from '@angular/core';
import { Newsfeed } from '../../model/newsfeed';
import { FormGroup, FormBuilder } from '../../../../node_modules/@angular/forms';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { NewsfeedService } from '../../services/newsfeed.service';
import { EnumKeyValueListPipe } from '../../pipes/enum-list.pipe';
import { ThorService } from '../../services/thor.service';
import { NotificationGroup } from '../../model/notification-group';
import { ArticleType } from '../../model/article-type';
import { forkJoin } from '../../../../node_modules/rxjs';
import { LevelFilter } from '../../model/breadcrumb';
import { Resident } from '../../model/resident';
import { ResidentService } from '../../services/resident.service';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { SiteHelper } from '../../helpers/site-helper';
import { MatDialog } from 'material2/src/lib';
import { PeopleService } from '../../services/people.service';
import { CustomSnackbarService } from '../../services/custom-snackbar.service';
import { snackType } from '../../shared/custom-snackbar/custom-snackbar.component';

export enum TypePost {
  Emergency = 1,
  Package = 2,
  Alert = 3,
  Visitor = 4,
  Balance = 5
}

@Component({
  selector: 'app-create-edit-post',
  templateUrl: './create-edit-post.component.html',
  styleUrls: ['./create-edit-post.component.css']
})
export class CreateEditPostComponent implements OnInit {
  public model: Newsfeed;
  public postFormGroup: FormGroup;
  public isUpdate = false;
  public postId: number;
  public loading: boolean;
  groups: NotificationGroup[] = [];
  articlesTypes: ArticleType[] = [];
  public users: Resident[] = [];
  breadcrumbChanges: any;
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private _router: Router,
    private peopleService: PeopleService,
    private newsFeedService: ThorService,
    private snackbar: CustomSnackbarService) {
    this.model = new Newsfeed(this.fb);
    this.postFormGroup = this.model.buildFormGroup();
    this.model.communityId =  LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(_ => {
     const community = LocalStorageHelper.getCommunitiesFromBreadcrumb();
     this.model.communityId = community;
     });

  }

  ngOnInit() {
    this.loading = true;
    forkJoin(//this.peopleService.getAllPaginated(0,1000),
      this.newsFeedService.getArticlesTypes()
    ).subscribe(result => {
      //this.users = result[0];
      this.articlesTypes = result[0];
      this.loading = false;
    })

    /*this.loading = true;
    this.route
    .data
    .subscribe(v => this.isUpdate = v.update);

    if (this.isUpdate === true) {
      this.route.paramMap.subscribe(
        params => {
          this.postId = +params.get('id');
          this.newsFeedService.getNewsfeedById(this.postId ).subscribe(r => {
            this.model.fromObject(r);
            this.loading = false;
          });
        });
    } else  { this.loading = false; }*/
  }

  cancel() {
    this._router.navigate(['/']);
  }

  save() {
    const entity: any = this.model.toDto();
    this.loading = true;
    if (!this.isUpdate) {
      this.newsFeedService.addNewsFeed(entity).subscribe(res => {
        if (Array.isArray(res)) {
          const art = this.articlesTypes.filter(at => at.articleTypeId === entity.articleType)[0];
          const mensaje = `The post has been sent successfully.\n Recipient:  Current Owner / Tenants and Staff \n` +
          `Post Type: ${art.name} \n` +
          `Title: ${entity.title} \n` +
          `Message: ${entity.body} \n`;
          this.snackbar.openSnackBar(mensaje, 'Success', snackType.Success);
          this.cancel();
        } else {
          this.snackbar.openSnackBar( 'An error ocurred posting message', 'Failed', snackType.Error);
          this.cancel();
        }
      }, error => {
        this.snackbar.openSnackBar( 'An error ocurred posting message', 'Failed', snackType.Error);
      });
    } else {
      //this.newsFeedService.update(this.model.toDto()).subscribe(_ => this.cancel());
    }

  }
}
