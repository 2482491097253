import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, QueryList, ViewChildren, AfterViewInit, OnDestroy, Injector } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { MassCommmResident } from 'src/app/model/mass-comm-resident';
import { MassCommmCommunityStaff } from 'src/app/model/mass-comm-community-staff';
import { MassCommunicationService } from 'src/app/services/mass-communication.service';
import { forkJoin, Subscription } from 'rxjs';
import { MassCommunicationList, MassCommunicationListType } from 'src/app/model/mass-communication-list';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SearchParamsBreadcrumb } from 'src/app/model/search-params-breadcrumb';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mass-comm-add-edit',
  templateUrl: './mass-comm-add-edit.component.html',
  styleUrls: ['./mass-comm-add-edit.component.css']
})

export class MassCommAddEditComponent implements OnInit, AfterViewInit, OnDestroy {
  public model: MassCommunicationList;
  public massCommListFormGroup: FormGroup;
  private router: Router;
  public loading = false;
  public listTypes: MassCommunicationListType[] = [
    new MassCommunicationListType(1, 'Static'),
    new MassCommunicationListType(2, 'Dynamic')];

  /** a reference field for breadcrumb Subscription */
  public breadcrumbChanges: Subscription;

  /** a reference for breadcrumd filters params to list */
  public searchParamsBreadcrumb: SearchParamsBreadcrumb = new SearchParamsBreadcrumb();

  public residentDisplayedColumns: string[] = ['select', 'peopleName', 'relationshipType',
   'isOcupant', 'emailAddress', 'building', 'unit'];
  public residentsDataSource = new MatTableDataSource<MassCommmResident>();
  private residentSelection = new SelectionModel<MassCommmResident>(true, []);

  public cStaffDisplayedColumns: string[] = ['select', 'peopleName', 'position', 'emailAddress',
   'community'];
  public cStaffDataSource = new MatTableDataSource<MassCommmCommunityStaff>();
  private cStaffSelection = new SelectionModel<MassCommmCommunityStaff>(true, []);

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  constructor(
    private fb: FormBuilder,
    public injector: Injector,
    private massCommService: MassCommunicationService
  ) {
    this.router = injector.get(Router);
    this.model = new MassCommunicationList(this.fb);
    this.massCommListFormGroup = this.model.buildFormGroup();
    this.setBreadcrumbParams();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      if (this.checkBreadcrumbParams()) {
        this.setBreadcrumbParams();
        this.loading = true;
        this.populateTables();
      }
    });
  }

  ngOnInit() {
    this.loading = true;
  }

  ngOnDestroy() {
    this.breadcrumbChanges.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.residentsDataSource.paginator = this.paginator.toArray()[0];
    this.cStaffDataSource.paginator = this.paginator.toArray()[1];
    this.populateTables();
  }

  private populateTables(): void {
    forkJoin(
      this.massCommService.getMassCommResidentList(),
      this.massCommService.getMassCommCStafffList()
    ).subscribe(results => {
      this.residentsDataSource.data = results[0];
      this.cStaffDataSource.data = results[1];
      this.loading = false;
    });
  }

  /** Whether the number of selected elements matches the total number of rows.
   * Residents
  */
  isAllSelectedResidents() {
    const numSelected = this.residentSelection.selected.length;
    const numRows = this.residentsDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.
   * Residents
  */
  masterToggleResidents() {
    this.isAllSelectedResidents() ?
      this.residentSelection.clear() :
      this.residentsDataSource.data.forEach(row => this.residentSelection.select(row));
  }

  /** Whether the number of selected elements matches the total number of rows.
   * Community STAFF
  */
  isAllSelectedCStaff() {
    const numSelected = this.cStaffSelection.selected.length;
    const numRows = this.cStaffDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.
   * Community STAFF
  */
  masterToggleCStaff() {
    this.isAllSelectedCStaff() ?
      this.cStaffSelection.clear() :
      this.cStaffDataSource.data.forEach(row => this.cStaffSelection.select(row));
  }

  ShortenStringDescription(description: string): string {
    if (description) {
      if (description.length > 20) {
        return description.substring(0, 17) + '...';
      }
      return description;
    }
  }
  onCancel() {}

  onSubmit(): void {
    this.residentSelection.selected.forEach(element => {
      this.model.massCommList.push(element.peopleId);
    });
    this.cStaffSelection.selected.forEach(element => {
      this.model.massCommList.push(element.peopleId);
    });
    this.model.listType = this.massCommListFormGroup.value.listType;
    const id = 0; // The id from mass comm list
    this.router.navigate(['app/mass-communication/email/' + id]);
  }

  protected checkBreadcrumbParams(): boolean {
    const filterLevel = this.massCommService.getLevelFilterBreadcrumb();
    return ((this.searchParamsBreadcrumb.propertyManagementCompanyId !== LocalStorageHelper.getManagementCompanyFromBreadcrumb())
      || (this.searchParamsBreadcrumb.communityId !== LocalStorageHelper.getCommunitiesFromBreadcrumb())
      || (this.searchParamsBreadcrumb.areaId !== LocalStorageHelper.getBuildingFromBreadcrumb())
      || (this.searchParamsBreadcrumb.unitId !== LocalStorageHelper.getUnitIdFromBreadcrumb()));
  }

  protected setBreadcrumbParams() {
    this.searchParamsBreadcrumb.propertyManagementCompanyId = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    this.searchParamsBreadcrumb.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.searchParamsBreadcrumb.areaId = LocalStorageHelper.getBuildingFromBreadcrumb();
    this.searchParamsBreadcrumb.unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
  }
}
