import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subject, Observable, fromEvent } from 'rxjs';
import { People } from 'src/app/model/people';
import { PeopleService } from 'src/app/services/people.service';
import { LevelFilter } from 'src/app/model/breadcrumb';
import { PeopleEditComponent } from 'src/app/people/people-edit/people-edit.component';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { VisitorService } from 'src/app/services/visitor.service';
import { VisitorFullModel } from 'src/app/model/visitor';

@Component({
  selector: 'app-search-visitors',
  templateUrl: './search-visitors.component.html',
  styleUrls: ['./search-visitors.component.css']
})
export class SearchVisitorsComponent implements OnInit {

  @Input() disabledInput: boolean;
  @Input() isVisitor: boolean;
  public selectedPeople = new Subject<VisitorFullModel>();
  private isLoading = false;
  private peopleSearch: VisitorFullModel[] = [];
  private loading: boolean;
  @ViewChild('filter') input: ElementRef;
  public selectTedPeople = '';
  private people: VisitorFullModel;

  constructor(private peopleService: PeopleService,
    private visitorService: VisitorService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.isLoading = false;
    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(200),
          tap(() => this.isLoading = true),
          distinctUntilChanged(),
          switchMap((e: any) => {
            let val = '';
            if (e) {
              val = e.target.value;
            }
            if (val && val.length > 2) {
              return this.applyFilter(val || '');
            } else {
              this.isLoading = false;
              this.peopleSearch = [];
              return new Observable<VisitorFullModel[]>();
            }
          })
        ).subscribe(result => {
          this.peopleSearch = result;
          this.isLoading = false;
        });
    }
  }
  protected applyFilter(filterValue: string): Observable<VisitorFullModel[]> {
      return this.visitorService.getByCommunityIdFiltered(filterValue);
  }
  public displayFn(people?: any): string {
    return people && people.peopleId ? people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
      people.lastName : '';
  }

  protected onPeopleSelectionChange(people: VisitorFullModel) {
    this.selectTedPeople = this.displayFn(people);
    this.people = people;
    this.selectedPeople.next(people);
  }
  public peopleSelectionChangeEmit(): Observable<VisitorFullModel> {
    return this.selectedPeople.asObservable();
  }

  protected newVisitor() {
      this.selectedPeople.next(null);
  }
}
