import { Timestamp } from 'rxjs';
import { BaseForm } from '../building-blocks/base-form';
import { Validators } from '../../../node_modules/@angular/forms';
import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class Newsfeed extends BaseForm { 

    @FormField('articleType', ['', Validators.required])
    articleType = null;

    subtitle: string;

    userId: number;

    @FormField('title', ['', Validators.required])
    title = '';

    @FormField('body', ['', Validators.required])
    body = '';
    message: string;

    timestamp: string;

    communityId = 0;
}

