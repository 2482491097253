import { PricePlan } from './price-plan';
import { Bundle } from './bundle';


export class BundlePricePlan {
    bundle: Bundle;
    pricePlan: PricePlan;
    communityId: number;
    managementCompanyId: number;
}
