



export class C3PEOTreeCommunityInfo {
    public sourceId: number;
    public name: string;
    public communities: C3peoCommunity[] = [];
}
export class C3PEOTreeCommunityInfoDto {
    name: string;
    community: C3peoCommunityDto;
    constructor( obj: C3PEOTreeCommunityInfo) {
        this.name = obj.name;
        this.community = new C3peoCommunityDto(obj.communities[0]);
    }
}

export class C3peoCommunity {
    sourceId: number;
    communityId?: number;
    originId?: number;
    communityMappingId?: number;
    communityCode: string;
    name: string;
    longName: string;
    address: C3peoAddress;
    altTypeCode: string;
    yrEnd: number;
    inactive: boolean;
    areas: C3peoArea[] = [];
}


export class C3peoCommunityDto {
    communityCode: string;
    name: string;
    address: C3peoAddressDto;
    areas: C3peoAreaDto[] = [];
    constructor(obj: C3peoCommunity) {
        this.communityCode = obj.communityCode;
        this.name = obj.name;
        this.address = new C3peoAddressDto(obj.address);
        if (obj.areas != null) {
        obj.areas.forEach( c => {
            const areayDto  = new C3peoAreaDto(c);
            this.areas.push(areayDto);
           });
        }
    }
}

export class C3peoArea {
    sourceId: number;
    areaCode: string;
    name: string;
    lockboxId: string;
    inactive: boolean;
    units: C3peoUnit[] = [];
}

export class C3peoAreaDto {
    areaCode: string;
    name: string;
    units: C3peoUnitDto[] = [];
    constructor(obj: C3peoArea) {
        this.name = obj.name;
        this.areaCode = obj.areaCode;
        if (obj.units) {
        obj.units.forEach( c => {
            const unitDto  = new C3peoUnitDto(c);
            this.units.push(unitDto);
           });
        }
    }
}

export class C3peoUnit {
    sourceId: number;
    areaCode: string;
    unitCode: string;
    address: C3peoAddress;
    people: C3peoPeople[];
}

export class C3peoUnitDto {
    unitCode: string;
    areaCode: string;
    peoples: C3peoPeopleDto[] = [];
    constructor(obj: C3peoUnit) {
        this.unitCode = obj.unitCode;
        this.areaCode = obj.areaCode;
        if (obj.people) {
        obj.people.forEach( c => {
            const peopleDto  = new C3peoPeopleDto(c);
            this.peoples.push(peopleDto);
           });
        }
    }
}

export class C3peoAddressDto {
    street: string;
    st: string;
    zip: string;
    constructor(obj: C3peoAddress) {
        this.street =  obj.street.substring(0, 20);
        this.st = obj.st;
        this.zip = obj.zip;
    }
}



export class C3peoAddress {
    street: string;
    city: string;
    st: string;
    zip: string;
    country: string;
}

export class C3peoPeople {
    sourceId: number;
    areaCode: string;
    unitCode: string;
    personCode: string;
    appNo: number;
    resStatus: string;
    coResNo: number;
    coType?: any;
    fName: string;
    mi: string;
    lName: string;
    mainAddress: C3peoAddress;
    billAddress: C3peoAddress;
    legal: boolean;
    phone: string;
    workPhone: string;
    email: string;
    cellPhone: string;
    inDate: Date;
    outDate: Date;
    developCode: string;
    transactions?: any;
}

export class C3peoPeopleDto {
    name: string;
    constructor(obj: C3peoPeople) {
        this.name =  obj.personCode + '-' + obj.fName + ' ' +  obj.lName;
    }
}

export class C3peoPeopleSelected {
    areaCode: string;
    unitCode: string;
    personCode: string;
    appNo: number;
}
