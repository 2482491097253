import { Component, OnInit } from '@angular/core';
import { CommunitySettings } from 'src/app/model/community-settings';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { CommunityService } from 'src/app/services/community.service';
import { CommunitySettingsService } from 'src/app/services/community-settings.service';
import { forkJoin } from 'rxjs';
import { NotificationTypeService } from 'src/app/services/notification-type.service';
import { NotificationType } from 'src/app/model/Notification-type';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';

@Component({
  selector: 'app-settings-package',
  templateUrl: './settings-package.component.html',
  styleUrls: ['./settings-package.component.css']
})
export class SettingsPackageComponent implements OnInit {

  public model: CommunitySettings;
  public packageSettingsFormGroup: FormGroup;
  public loading: boolean;
  public CommunityName: string;
  public notificationTypes: NotificationType[];


  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private communityService: CommunityService,
    private notificationTypeService: NotificationTypeService,
    private communitySettingService: CommunitySettingsService
    ) {
      this.model = new CommunitySettings(this.fb);
      this.packageSettingsFormGroup = this.model.buildFormGroup();
    }

  ngOnInit() {
    this.loading = true;
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.model.communityId = comunityLocalStorage;   
      forkJoin(this.communitySettingService.getByCommunityId(this.model.communityId),
      this.communityService.getById(this.model.communityId)).subscribe(result => {
        if (result[0] ) {
          this.model.fromObject(result[0]);
        } else {
          this.model.communityId = result[1].communityId;
        }
        this.loading = false;
    });
  }
  onSubmit() {
    const dto = this.model.toDto();
    dto.communityId = this.model.communityId;
    if (this.model.communitySettingsId > 0) {
      this.communitySettingService.update(dto).subscribe();
    } else {
        this.communitySettingService.add(dto).subscribe();
    }
    this.goToPackageList();

  }
  cancel() {
    this.goToPackageList();
  }
  goToPackageList() {
    this.router.navigateByUrl('/app/packages');
  }

}
