import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Menu } from '../model/menu';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss']
})
export class MenuListItemComponent implements OnInit {

  @Input() item: Menu;
  @Input() class: string;
  @Input() index: number;
  @Input() collapse: boolean;
  @Input() menuItems: any;
  @Output() toggleMenu = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  showChildren(item: Menu, e) {
    if (item.isActive) {
      this.toggleMenu.emit();
      this.resetSelection(item);
      item.selected = true;
      if (item.children.length > 0) {
        e.preventDefault();
        item.opened = !item.opened;
      } else {
        if (item.click !== undefined) {
          item.click();
        }
      }
    }else {
      e.stopPropagation();
    }
    
  }

  resetSelection(selectedItem: any) {
    if (selectedItem.level === 0) {
      this.menuItems.forEach(menu => {
        menu.selected = false;
        menu.opened = selectedItem.id == menu.id ? menu.opened : false;
        menu.children.forEach(child => {
          this.resetSelection(child);
        });
      });
    }else {
      selectedItem.selected = false;
      if (this.menuItems.length === undefined) {
        this.menuItems.selected = false;
        this.menuItems.children.forEach(c => {
          if (selectedItem.id === c.id) {
            this.menuItems.selected = true;
            c.opened = selectedItem.id == c.id ? c.opened : false;
          }          
          c.selected = false;
        });
      }else {
        selectedItem.opened = false
        this.menuItems.forEach(menu => { 
          menu.selected = false;
        });
      }
      selectedItem.children.forEach(child => {
        this.resetSelection(child);
      });
    }
  }


  resetSelection2(selectedItem: any) {
    if (selectedItem.level === 0) {
      // this will reset all first level parent menus
      this.menuItems.forEach(menu => {
        menu.selected = false;
        menu.opened = selectedItem.id == menu.id ? menu.opened : false;
        menu.children.forEach(child => {
          this.resetSelection(child);
        });
      });
    }else {
      selectedItem.selected = false;
      this.menuItems.forEach(menu => { 
        menu.selected = false;
      });
     
      selectedItem.children.forEach(child => {
        this.resetSelection(child);
      });
    }
  }
}
