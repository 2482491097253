import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class Vehicle extends BaseForm {
    vehicleId = 0;

    @FormField('vehicleBrandId', [null])
    vehicleBrandId = 0;

    @FormField('model', [null])
    model = '';

    @FormField('year', [null])
    year = null;

    @FormField('colorId', [null])
    colorId = null;

    @FormField('tagNumber', [null])
    tagNumber = '';

    startDate = new Date();

    @FormField('parkingDecalNumber', [''])
    parkingDecalNumber = '';

    @FormField('decalExpirationDate', [null])
    decalExpirationDate = null;

    @FormField('notes', [''])
    notes = '';

    IsDeleted = false;

}
