import { Injectable, Inject } from '@angular/core';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { Transaction } from '../model/transaction';
import { AppConfig } from '../app-config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { APP_CONFIG } from '../app.config';
import { LevelFilter } from '../model/breadcrumb';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService extends AbstractDataProviderService<Transaction> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/transactions', http, appConfig, LevelFilter.Account);
  }


  getAllPaged(pageIndex: number, pageSize: number, accountId: number): Observable<Transaction[]> {
    this.setBreadcrumbParams();
    const route = '/allPageByBreadcrumb/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString();

    queryString = queryString + '/?accountId=' + accountId;

    return this.http.get<Transaction[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCounter(accountId: number): Observable<number> {
    const endpoint = '/countByAccountId/?';
    const queryString = endpoint + 'accountId=' + accountId;
    return this.http.get<number>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCount(filter?: string): Observable<number> {
    const endpoint = '/countByAccountId/?';
    const queryString = endpoint + 'accountId=' + filter;
    return this.http.get<number>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
  getCountFiltered(filter?: string, date?: Date, type?: string,
    typeCode?: string, description?: string): Observable<number> {
    const endpoint = '/getCountByParams/?';
    let queryString = endpoint + 'accountId=' + filter;
    if (date && date !== null && date !== undefined && date.getUTCFullYear() > 1970) {
      queryString += '&date=' + date.getFullYear() + '-' + (date.getUTCMonth() + 1).toString().padStart(2, '0')
        + '-' + date.getUTCDate().toString().padStart(2, '0');
    }

    if (type && type !== null && type !== undefined && type !== '') {
      queryString += '&type=' + type.toString();
    }

    if (description && description !== null && description !== undefined) {
      queryString += '&description=' + description.toString();
    }
    return this.http.get<number>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getAllFilteredPaged(pageIndex: number,
    pageSize: number,
    accountId: number,
    date?: Date, type?: string,
    typeCode?: string,
    description?: string,
    sortData?: string):
    Observable<Transaction[]> {
    this.setBreadcrumbParams();
    const route = '/allPageByBreadcrumb/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString();

    queryString = queryString + '/?accountId=' + accountId;

    if (date && date !== null && date !== undefined && date.getUTCFullYear() > 1970) {
      queryString += '&date=' + date.getFullYear() + '-' + (date.getUTCMonth() + 1).toString().padStart(2, '0')
        + '-' + date.getUTCDate().toString().padStart(2, '0');
    }

    if (type && type !== null && type !== undefined && type !== '') {
      queryString += '&transType=' + type.toString();
      queryString += '&typeCode=' + typeCode.toString();
    }

    if (description && description !== null && description !== undefined) {
      queryString += '&description=' + description.toString();
    }

    if (sortData) {
      const sortCol = sortData.split('|');
      if (sortCol[1] !== '') {
        queryString = queryString + '&sort=' + sortCol[0];
        queryString = (queryString + '&dir=') + (sortCol[1] !== '' ? sortCol[1] : 'asc');
      }
    }

    return this.http.get<Transaction[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

}


