import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../../src/app/material.module';
import { SearchRoutingModule } from './search-routing/search-routing.module';
import { FormsModule } from '@angular/forms';

import { SearchComponent } from './search/search.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    SearchRoutingModule,
    SharedModule
  ],
  declarations: [
    SearchComponent
  ]
})
export class SearchModule { }
