import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class MassCommunicationList extends BaseForm {
    massCommId = 0;

    /**
     * The type of the list static or dinamic.
     */
    @FormField('listType', ['', Validators.required])
    listType = 0;

    /**
     * The list itself.
     */
    @FormField('massCommList', ['', Validators.required])
    massCommList: number[] = [];
}

export class MassCommunicationListType {
    constructor(id: number, desc: string) {
        this.Id = id;
        this.Desc = desc;
    }

    Id: number;
    Desc: string;
}
