import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BundleRoutingModule } from './bundle-routing/bundle-routing.module';
import { FormsModule } from '@angular/forms';
import { BundleListComponent } from './bundle-list/bundle-list.component';
import { BundleAddEditComponent } from './bundle-add-edit/bundle-add-edit.component';
import { BundleAddDocumentDialogComponent } from './bundle-add-document-dialog/bundle-add-document-dialog.component';
import { SharedModule } from '../thorin-shared/shared.module';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    BundleRoutingModule,
    SharedModule
  ],
  declarations: [
    BundleListComponent,
    BundleAddEditComponent,
    BundleAddDocumentDialogComponent
  ],
  entryComponents: [
    BundleAddDocumentDialogComponent
]
})
export class BundleModule { }
