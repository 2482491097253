// tslint:disable-next-line:class-name
export class Community_Position {
    community_PositionId: number;
    communityId: number;
    communityName: string;
    positionId: number;
    positionName: string;
    firstLetterPositionName: string;
}

export class Position {
    community_PositionId: number;
    positionName: string;
    constructor(id: number, name: string) {
      this.community_PositionId = id;
      this.positionName = name;
    }
    toString() {
      return this.positionName;
    }
  }
