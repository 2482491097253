import { Component, OnInit } from '@angular/core';
import { StaffContact } from '../../../model/staff-contact';
import { StaffService } from '../../../services/staff.service';
@Component({
  selector: 'app-staff-contact',
  templateUrl: './staff-contact.component.html',
  styleUrls: ['./staff-contact.component.css']
})
export class StaffContactComponent implements OnInit {
  public contacts: StaffContact[];
  public loading: boolean;
  public breakpoint: number;
  constructor(private staffService: StaffService) { }

  ngOnInit() {
    this.loading = true;
    this.staffService.getStaffContacts().subscribe(x => {
      this.contacts = x;
      this.loading = false;
    });
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 3;
  }

  onResize(event) {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 3;
  }
}
