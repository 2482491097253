import { Package } from 'src/app/model/package';
import { AfterViewInit, Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatAutocomplete,
} from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { ResidentSearch } from 'src/app/model/resident-search';
import { fromEvent, Observable, forkJoin, Subject } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PeopleService } from 'src/app/services/people.service';
import { Carrier } from 'src/app/model/carrier';
import { CarrierService } from 'src/app/services/carrier.service';
import { PackagesService } from 'src/app/services/packages.service';
import { Router } from '@angular/router';
import { PackageTypeService } from 'src/app/services/package-type.service';
import { PackageType } from 'src/app/model/Package-type';
import { StorageService } from 'src/app/services/storage.service';
import { CommunitySettings } from 'src/app/model/community-settings';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { HotPackagesService } from '../../services/hot-packages.service';

@Component({
  selector: 'app-package-add',
  templateUrl: './package-add.component.html',
  styleUrls: ['./package-add.component.css']
})
export class PackageAddComponent implements OnInit, AfterViewInit {
  public carriers: Carrier [];
  private packageTypes: PackageType[];
  public storages: Storage[] = [];
  public model: Package;
  public currentPackage: Package;
  public packageFormGroup: FormGroup;
  public loading: boolean;
  @Input() communitySettings: CommunitySettings;
  @Input() showInPopup: boolean;
  @Input() resident: ResidentSearch;
  public addPackages = new Subject<Package>();
  public addAnotherP = new Subject<Package>();
  public cancel = new Subject<boolean>();
  public isEdit = false;
  isInit = false;


  /**
   * Initializes a new instance of TagAddEditComponent.
   *
   * @param fb A reference to an instance of FormBuilder.
   * @param snackBar A reference to an instance of MatSnackBar.
   * @param dialog .
   */

  constructor(
    private fb: FormBuilder,
    private carrierService: CarrierService,
    private packageTypeService: PackageTypeService,
    private storageService: StorageService,
    private router: Router,
    private packageService: PackagesService,
    private hotPackageService: HotPackagesService) {
      this.model = new Package(this.fb);
      this.packageFormGroup = this.model.buildFormGroup();
    }

    settingForm() {
      this.loading = true;
      if (this.communitySettings.showNote && this.communitySettings.requireNote ) {
        this.addRequiredValidatorToControl('notes');
      }
      if (this.communitySettings.showPackageType && this.communitySettings.requirePackageType ) {
        this.addRequiredValidatorToControl('packageTypeId');
      }
      if (this.communitySettings.showSender && this.communitySettings.requireSender ) {
        this.addRequiredValidatorToControl('sender');
      }
      if (this.communitySettings.showStorage && this.communitySettings.requireStorage ) {
        this.addRequiredValidatorToControl('storageId');
      }
      this.packageFormGroup.updateValueAndValidity();
      this.loading = false;
    }

    addRequiredValidatorToControl(control: string) {
      this.packageFormGroup.controls[control].setValidators([Validators.required]);
      this.packageFormGroup.controls[control].updateValueAndValidity();
    }

 /**
   * Sets up the component once it has been initialized by Angular.
   */
  ngOnInit() {
    if (this.communitySettings.communityId && !this.isInit) {
    this.isInit = true; 
    forkJoin(this.carrierService.getAll(),
            this.packageTypeService.getAll(),
            this.storageService.getByCommunityId(this.communitySettings.communityId)
    ).subscribe(result => {
      this.carriers = result[0];
      this.packageTypes = result[1];
      this.storages = result[2];   
      if (this.currentPackage) {
        this.model.fromObject(this.currentPackage);
        this.isEdit = true;
      }  
    });
  }
  }
  ngAfterViewInit() {
    this.settingForm();
  }

  detect(value) {
    this.carrierService.detect(value).subscribe (result => {
      if (result && result.length > 0) {
        this.packageFormGroup.controls['carrierId'].setValue(result[0].carrierId);
      }
    });
  }

  protected carrierChange(carrier: Carrier) {
    this.model.carrier = carrier.name;
  }

  protected  addPackage(): Observable<Package> {
    if (!this.packageFormGroup.valid) {
        SiteHelper.markFormGroupTouched(this.packageFormGroup);
        return;
    }
      const dto = this.model.toDto();
      this.addPackages.next(dto);
      return this.addPackages.asObservable();
  }
  protected addAnother() {
    if (!this.packageFormGroup.valid) {
      return;
    }
    const dto = this.model.toDto();
    this.addAnotherP.next(dto);
   }

  public onCancel() {
    if (!this.showInPopup) {
      this.router.navigateByUrl('/app/packages');
    } else {
      this.cancel.next(true);
    }
  }
  public watchCancel() {
    return this.cancel.asObservable();
  }
  public watchAddPackage() {
    return this.addPackages.asObservable();
  }
  public watchAddAnother() {
    return this.addAnotherP.asObservable();
  }

    /**
   * Event handler for the Ok/Submit button.
   */
  onSubmit(): void {
    this.loading = true;
    const dto = this.model.toDto();
    dto.peopleId = this.resident.peopleId;
    dto.unitId = this.resident.unitId;
    dto.storageId = dto.storageId === 0 && this.storages.length > 0 ? this.storages[0] : dto.storageId;
    if (this.model.hotPackageId == 0) {
    this.packageService.receive(dto).subscribe(_ => this.router.navigateByUrl('/app/packages'));
    } else {
      this.hotPackageService.update(dto).subscribe(_ => this.router.navigateByUrl('/app/packages'));
    }
  }

}
