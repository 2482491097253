export class RingEntityTable {
    name: string;
    description: string;
    ringId: number;
    ringTypeId: number;
    ringTypeName: string;
    entityId: number;
    entityName: string;
    entityTypeId: number;
    entityTypeName: string;
}
