import { Validators, FormGroup } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { Vehicle } from './vehicle';

export class VisitorsTimeLog extends BaseForm {
    visitorsTimeLogId = 0;

    @FormField('firstName', ['', Validators.required])
    firstName = '';

    @FormField('lastName', ['', Validators.required])
    lastName = '';

    visitorId = null;

    communityId = 0;

    @FormField('accessDenied', [false])
    accessDenied = false;

    pointInTime = new Date();

    fileId: number;

    pictureId = null;

    signatureId =  null;

    picture: string;

    signature: string;

    @FormField('companyId', [null])
    companyId = null;


    @FormField('visitorTypeId', [null])
    visitorTypeId = null;

    isDeleted = false;

    relationshipIdArray: number[] = [];

    communityName: string;

    vehicleGroup: FormGroup;

    hasVehicle = false;

    relationshipId: number;

    residentName: string;

    unitNumber: string;

    vehicleName: string;
}

export class VisitorsTimeLogFull extends Vehicle {
    visitorsTimeLogId = 0;

    firstName: string;

    lastName: string;

    visitorId = null;

    communityId = 0;

    accessDenied = false;

    pointInTime: Date;

    companyId = null;

    visitorTypeId = null;

    isDeleted = false;

    relationshipId: number;

    accessId: number;
}
