import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '../../../../src/app/app.config';
import { AbstractDataProviderService } from '../../../../src/app/services/abstract-data-provider.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CrudResponseModel } from '../../../../src/app/model/crud-response-model';
import * as AppConstants from '../../../../src/app/app-constants';
import { LocalStorageHelper } from '../../../../src/app/helpers/local-storage-helper';
import { PricePlanRelationshipTypeFilter } from '../model/price-plan-relationshipType-filter';
// import { PricePlanUnitFilter } from '../model/price-plan-unit-filter';
import { PricePlanRelationshipFilter } from '../model/price-plan-relationship-filter';
import { Community } from 'src/app/model/community';
import { PricePlanCommunityFilter } from '../model/price-plan-community-filter';
import { CountResultModel } from 'src/app/model/count-result-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends AbstractDataProviderService<any> {
  private userInfo = new BehaviorSubject(LocalStorageHelper.getUserInfo());
  currentUser = this.userInfo.asObservable();

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.Thorin, '/common', http, appConfig);
  }

  // getUnitByMCAndCommunityId(propertyManagementCompanyId: number, communityId: number): Observable<PricePlanUnitFilter[]> {
  //   const queryString = '/getUnitsByCompanyAndCommunity' + '?mcId=' + propertyManagementCompanyId + '&communityId=' + communityId;
  //   return this.http.get<PricePlanUnitFilter[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  // }

  getRelationshipType(): Observable<PricePlanRelationshipTypeFilter[]> {
    const queryString = '/relationshipType';
    return this.http.get<PricePlanRelationshipTypeFilter[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getRByMCAndCommunityId(propertyManagementCompanyId: number, communityId: number): Observable<PricePlanRelationshipFilter[]> {
    const queryString = '/relations' + '?mcId=' + propertyManagementCompanyId + '&communityId=' + communityId;
    return this.http.get<PricePlanRelationshipFilter[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCommunitybyMcId(propertyManagementCompanyId: number, needAll: boolean): Observable<PricePlanCommunityFilter[]> {
    const queryString = '/getCommunitiesByMcId' + '?mcId=' + propertyManagementCompanyId + '&needAll=' + needAll;
    return this.http.get<PricePlanCommunityFilter[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCountByBreadcumd(entName: string): Observable<number> {
    this.setBreadcrumbParams();
    const queryString = '/getCountByParams?mcId='
      + this.searchParamsBreadcrumb.propertyManagementCompanyId
      + '&entName=' + entName
      + '&communityId=' + this.searchParamsBreadcrumb.communityId;
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers: this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }
}
