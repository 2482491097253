import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { stringHelper } from 'src/app/helpers/stringHelper';

@Component({
  selector: 'app-photo-camera',
  templateUrl: './photo-camera.component.html',
  styleUrls: ['./photo-camera.component.css']
})
export class PhotoCameraComponent implements OnInit, AfterViewInit {

  public width = 320;
  public height = 200;
  public streaming = false;
  public takeFoto: boolean;
  public picture: any;
  public src = '';
  public readonly imageDefaul = 'assets/images/photo-placeholder@2x.png';

  @ViewChild('photo') photoElement: ElementRef;
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') canvasElement: ElementRef;
    arrayBuffer: string | ArrayBuffer;

  constructor() {
    this.setDefault();
  }

  public setDefault() {
    this.takeFoto = true;
    this.src = this.imageDefaul;
    this.picture = null;
  }

  ngAfterViewInit(): void {
  }
  ngOnInit() {
  }

    startup() {
        const canvas = this.canvasElement.nativeElement;
        const video = this.videoElement.nativeElement;
        const width = this.width;
        let height = this.height;
        let streaming = this.streaming;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
                video.srcObject = stream;
                video.play();
            }, function(err) {
                console.log('An error occurred: ' + err);
            });

        }

        video.addEventListener('canplay', function(ev) {
            if (!streaming) {
                height = video.videoHeight / (video.videoWidth / width);
                // Firefox currently has a bug where the this.height can't be read from
                // the video, so we will make assumptions if this happens.
                if (isNaN(height)) {
                    height = height / (4 / 3);
                }
                video.setAttribute('width', width);
                video.setAttribute('height', height);
                canvas.setAttribute('width', width);
                canvas.setAttribute('height', height);
                streaming = true;
            }

        }, false);
        this.streaming = streaming;
    }
    clearphoto() {
        const photo = this.photoElement.nativeElement;
        const canvas = this.canvasElement.nativeElement;
        const context = canvas.getContext('2d');
        context.fillStyle = '#AAA';
        context.fillRect(0, 0, canvas.width, canvas.height);
        const data = canvas.toDataURL('image/png');
        photo.setAttribute('src', data);
    }
    setPicture(src: any) {
        this.takeFoto = true;
        this.src = src;
        this.picture = stringHelper.dataURLtoBlob(src);
    }
    takePicture() {
        const photo = this.photoElement.nativeElement;
        const canvas = this.canvasElement.nativeElement;
        const video = this.videoElement.nativeElement;
        const context = canvas.getContext('2d');
        if (this.width && this.height) {
             canvas.width = this.width;
             canvas.height = this.height;
            canvas.getContext('2d').drawImage(video, 0, 0, this.width, this.height);
            const data = canvas.toDataURL('image/png');
            this.src = data;
            this.picture = stringHelper.dataURLtoBlob(data);
            this.takeFoto = true;
        } else {
            this.clearphoto();

        }
    }

  }
