import { PricePlan } from './price-plan';
import { Document } from './document';


export class DocumentPricePlan {
    document: Document;
    pricePlan: PricePlan;
    communityId: number;
    managementCompanyId: number;
}
