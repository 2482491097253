import { Component, OnInit, OnDestroy } from '@angular/core';

import { forkJoin, Observable, Subscription } from 'rxjs';

import { C3POService } from '../../services/c3po.service';
import { MatDialog } from '@angular/material';
import { PaymentDialogComponent } from 'src/app/app-dialogs/payment-dialog/payment-dialog.component';
import { PaymentConfirmationComponent } from 'src/app/app-dialogs/payment-confirmation/payment-confirmation.component';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AccountService } from 'src/app/services/account.service';
import { FinancialsAccount, BalanceAccount } from 'src/app/model/account';
import { C3POBalance } from 'src/app/model/c3-pobalance';
import { AccessType } from 'src/app/model/access-type-ring';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { Resident } from '../../model/resident';
import { SiteHelper } from '../../helpers/site-helper';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent extends BaseSecurity implements OnInit, OnDestroy {

  public accounts: number[] = [];

  public totalAmount = 0;

  public loading: boolean;

  public acC3poArray: C3POBalance[];

  public balances: number[] = [];

  private finantialsAccounts: FinancialsAccount[];

  private observableBalanceArray: Observable<BalanceAccount[]>;

  public breadcrumbChanges: Subscription;
  resident: Resident;

  constructor(
    private dialog: MatDialog,
    private accountService: AccountService,
  ) {
    super();
      this.populatePayments();
     this.breadcrumbChanges =  LocalStorageHelper.watchBreadcrumb().subscribe(e  => {
        const unitId = LocalStorageHelper.getUnitIdFromBreadcrumb();
        if (unitId > 0) {
            this.populatePayments();
        }
      });
      this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.securityEntity.rings.push({ringId : 7012, accessType: AccessType.Add });
    this.securityEntity.rings.push({ringId : 7013, accessType: AccessType.Execute });
    this.entityRings.push(this.securityEntity);
  }

  ngOnDestroy() {
    if (this.breadcrumbChanges) {
    this.breadcrumbChanges .unsubscribe();
    }
  }

  populatePayments() {
    this.loading = true;
    const paymentInfo = LocalStorageHelper.getPaymentInfo().filter(x => (x.accountExternalCode));

    const userInfo = LocalStorageHelper.getLoggedUserInfo(false);
    if (userInfo.isResident) {
      this.resident = userInfo.residents.filter(u => u.userId = userInfo.userId)[0];
    }
      if (!userInfo.isResident) {
        this.loading = false;
        return;
      }
      const unitId = this.resident.unitId;


    if (paymentInfo.length > 0 || unitId > 0) {
      this.accounts = paymentInfo.length > 0 ? paymentInfo.map(x => x.accountExternalCode) : [];

    // get payment info
    if (unitId > 0) {
      this.observableBalanceArray = this.accountService.getBalancesByUnitId(unitId);

    } else {
      this.observableBalanceArray = this.accountService.getBalances();
    }
      forkJoin(this.observableBalanceArray).subscribe(results => {
        if (results.length > 0 && results[0] && results[0].length > 0) {
        this.totalAmount = results[0].map(s => s.amount).reduce((acc, val) => {
          return acc + val;
        });
        this.accounts  = results[0].map(s => Number(s.accountCode));
      }
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  openPayment() {
    let data;
    data = {
      accounts: this.accounts
    };
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: data,
      panelClass:  SiteHelper.getDefaultTheme()
    });
    dialogRef.componentInstance.registerPaymentEvent.subscribe(result => {
      if (result) {
        this.populatePayments();
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const dialogConfirmation = this.dialog.open(PaymentConfirmationComponent, {
          data: result,
          panelClass:  SiteHelper.getDefaultTheme()
        });
        dialogConfirmation.afterClosed().subscribe(results => {
        });
      }
    });
  }

}
