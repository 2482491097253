import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Injector, AfterViewInit } from '@angular/core';
import { BaseList } from 'src/app/building-blocks/base-list';
import { Observable, fromEvent, Subject } from 'rxjs';
import { VisitorsTimeLogService } from 'src/app/services/visitors-time-log.service';
import { VisitorsTimeLog } from 'src/app/model/visitors-time-log';
import { map, debounceTime, switchAll } from 'rxjs/operators';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { SiteHelper } from '../../helpers/site-helper';
import { CommunitySettingsService } from '../../services/community-settings.service';
import { MatDialog } from 'material2/src/lib';


const visitorsColumns = [
 'visitor',
 'pointInTime',
 'visitorTypeName',
 'company',
 'vehicle',
 'actions',
];

@Component({
  selector: 'app-visitors-time-log-list',
  templateUrl: './visitors-time-log-list.component.html',
  styleUrls: ['./visitors-time-log-list.component.css']
})
export class VisitorsTimeLogListComponent extends BaseList<VisitorsTimeLog> implements  AfterViewInit  {
  @ViewChild('filter') filterInput: ElementRef;
  private filterObservable: Observable<any>;
  private filterStatus: Subject<boolean> = new Subject<boolean> ();
  sortCol = 'pointInTime';
  sortDir = 2;
  constructor( public injector: Injector,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private visitorTimeLogService: VisitorsTimeLogService, 
    private communitySettingService: CommunitySettingsService) {
       super(visitorsColumns, '/visitorsTimeLog', visitorTimeLogService, 'visitorsTimeLog', injector);
       this.filters = '';
      }

      ngAfterViewInit() {
        super.ngAfterViewInit();
        if (this.filterInput) {
        this.filterObservable = fromEvent(this.filterInput.nativeElement, 'keyup')
          .pipe(
            map((e: any) => {
              return e.target.value;
            }),
            debounceTime(1000),
            map((q: string) => {
              if (q.length >= 2) {
                this.applyFilter(q);
                this.filterStatus.next(true);
              } else {
                if (q.length === 0 && this.filterStatus) {
                  this.applyFilter('');
                  this.filterStatus.next(true);
                }
              }
            },
            switchAll()));
            this.filterObservable.subscribe();
            this.filterStatus.subscribe(f => {                  
              this.loading = f;
            });
      }
    }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<VisitorsTimeLog[]> {
    return this.visitorTimeLogService.getAllPaginatedSorted(pageIndex, pageSize, this.sortCol, this.sortDir, filter);
  }

  protected getCount(): Observable<number> {
    return this.visitorTimeLogService.getCount(this.dataSource.filter);
  }

  protected getById(id: number): Observable<VisitorsTimeLog> {
    return this.visitorTimeLogService.getById(id);
  }

  protected edit(id: number) {
    this.router.navigateByUrl('/app/visitors/edit/' + id);
  }
  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any {}

  newVisitor() {
    const commmunity = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if (commmunity === 0) {
      SiteHelper.openDialogAlert('Warning',
        'Please select a community on breadcrumb.',
        this.dialog);
    } else {
      this.communitySettingService.getByCommunityId(commmunity).subscribe(c => {
        if (c != null && c.visitorsEnabled) {
          this.router.navigateByUrl('/app/visitors/add');
        } else {
          SiteHelper.openDialogAlert('Warning',
            'you can not add new visitor for this community, please configure enable visitor for this community',
            this.dialog);
        }
      });
    }
  }

}
