import { Component, OnInit, Input } from '@angular/core';
import { SearchAddress } from 'src/app/model/search-address';
import { SearchPeople } from 'src/app/model/search-people';
import { SearchService } from 'src/app/services/search.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search-unit',
  templateUrl: './search-unit.component.html',
  styleUrls: ['./search-unit.component.css']
})
export class SearchUnitComponent implements OnInit {

  constructor(public searchService: SearchService) { }
  selectedAddresses: SearchAddress[] = [];
  resultSearch: SearchAddress[] = [];
  contacts: SearchPeople[] = [];
  notFound = false;
  @Input() showAddress: boolean;
  @Input() showSearch: boolean;
  public selectedAddress = new Subject<boolean>();
  public searchedAddress = new Subject<boolean>();
  ngOnInit() {
  }

  searchAddress(termSearch: string) {
    this.selectedAddresses = [];
    if (termSearch === '') {
      this.resultSearch = [];
    } else {
      if (termSearch.replace(/\d+/g, '').trim().length > 0) {
        this.searchService.findAddresses(termSearch).subscribe(results => {
          if (results && results.length > 0) {
            this.resultSearch = results; // .map(x => x.line1);
            this.showAddress = true;
            this.notFound = false;
          } else {
            this.notFound = true;
            this.showAddress = false;
          }
          this.searchedAddress.next(true);
        });
      }
    }
  }

  onSearchAddressCompleted() {
    return this.searchedAddress.asObservable();
  }

  clickCheck(address: SearchAddress, checked: boolean) {
    this.selectedAddresses = [];
    if (checked) {
      if (this.selectedAddresses.filter(_address => address === _address).length === 0) {
        this.selectedAddresses.push(address);
      }
    } else {
      this.selectedAddresses.splice(this.selectedAddresses.indexOf(address), 1);
    }
    this.selectedAddress.next(true);
  }

  public onSelectedAddress() {
    return this.selectedAddress.asObservable();
  }

}
