import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { CrudResponseModel } from '../model/crud-response-model';
import { Observable } from 'rxjs';

import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Role } from '../model/role';
import { RolesEntry } from '../model/roles-entry';
import { UserRoleCompany } from '../model/user-role-company';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends AbstractDataProviderService<Role> {
    constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.SecurityAdminService, '/roles', http, appConfig);
  }
  linkRoleToUser(roleId: number, userId: number): Observable<CrudResponseModel> {
    const body = {
      'roleId': roleId,
      'userId': userId
    };
    return this.http.post<CrudResponseModel>(this.baseUrl + '/addUser', body, { headers: this.buildPostHeaders() });
  }
  unlinkRoleFromUser(roleId: number, userId: number): Observable<CrudResponseModel> {
    return this.http.delete<CrudResponseModel>(this.baseUrl + '/user/' + roleId.toString() + '/' + userId.toString(),
     { headers: this.buildPostHeaders() });
  }
  getByUserId(userId: number): Observable<UserRoleCompany[]> {
    return this.http.get<UserRoleCompany[]>(this.baseUrl + '/byUserId/' + userId.toString(), { headers: this.buildGetHeaders() });
  }

  getByCompanyId(companyId: number): Observable<RolesEntry[]> {
    return this.http.get<RolesEntry[]>(this.baseUrl + '/byCompanyId/' + companyId.toString(), { headers: this.buildGetHeaders() });
  }

  getByApiKeyId(apiKeyId: number): Observable<Role[]> {
    return this.http.get<Role[]>(this.baseUrl + '/byApiKeyId/' + apiKeyId.toString(), { headers: this.buildGetHeaders() });
  }

  linkRoleToRing(roleId: number, ringId: number): Observable<CrudResponseModel> {
    const body = {
      'roleId': roleId,
      'ringId': ringId
    };
    return this.http.post<CrudResponseModel>(this.baseUrl + '/addRing', body, { headers: this.buildPostHeaders() });
  }
  unlinkRoleFromRing(roleId: number, ringId: number): Observable<CrudResponseModel> {
    return this.http.delete<CrudResponseModel>(this.baseUrl + '/ring/' + roleId.toString() + '/' + ringId.toString(),
     { headers: this.buildPostHeaders() });
  }
}
