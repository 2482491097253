import { Directive, ElementRef, HostListener,  ChangeDetectorRef } from '@angular/core';


@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) { }

  @HostListener('input',  ['$event.type', '$event.target.value'] ) oninput( event: string, value: string ) {
    this.cdRef.detectChanges();
    value = value.replace(/[^0-9]/g, '');
    this.el.nativeElement.value = value;
  }

}
