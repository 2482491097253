import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';

import { forkJoin } from 'rxjs';

/* Helpers */
import { SiteHelper } from '../helpers/site-helper';

/* Models */
import { AccountInfo } from '../model/account';
import { BankAccountType } from '../model/bank-account-type';
import { C3POTransaction } from '../model/c3po-transaction';
import { Community } from '../model/community';
import { CreditCardForm } from '../model/credit-card-form';
import { PaymentAnonymousWithBankAccount } from '../model/new-bank-account';
import { PaymentAnonymousWithCard } from '../model/newCreditCard';
import { PaymentMethodType } from '../model/payment-method-type';

/* Services */
import { AccountService } from '../services/account.service';
import { AddressService } from '../services/address.service';
import { C3POService } from '../services/c3po.service';
import { CommunityService } from '../services/community.service';
import { CompanyService } from '../services/company.service';
import { SmaugService } from '../services/smaug.service';
import { Transaction } from '../model/transaction';
import { TransactionItem } from '../model/transaction-item';


@Component({
  selector: 'app-anonymous-payment-find-people',
  templateUrl: './anonymous-payment-find-people.component.html',
  styleUrls: ['./anonymous-payment-find-people.component.css']
})
export class AnonymousPaymentFindPeopleComponent implements OnInit {
  private modelCard: CreditCardForm;
  public step: number;
  public loading: boolean;
  private success: boolean;
  private currentCommunity: Community;
  private currentAccount: AccountInfo;
  private currentPeopleId: number;
  private transactions: TransactionItem[];
  private CommunityName: string;
  private address: string;
  private totalAmount: number;
  private totalFee: number;
  private totalAmountWithFee: number;
  private currentDate: Date;
  private paymentMethodSelected: any;
  private paymentMethod: number;
  private paymentMethodString: string;
  private registerCard: FormGroup;
  private registerBank: FormGroup;
  private findAccountGroup: FormGroup;
  private routing: number;
  private bankAccountNumber: number;
  public companyLogo = 'assets/images/grid-squares-logo.svg';
  private bankAccountTypes: BankAccountType[] = [];
  private years: string[] = [];
  private months: string[] = [];
  private applicationId = 1;
  private providerId = 1;
  private paymentMethodArray: PaymentMethodType[] = [];

  /**
   * The confirmation number that is shown to the user if the payment succeeded.
   */
  private confirmationNumber: number;

  constructor(private router: Router, private communityService: CommunityService,
    private c3poService: C3POService,
    private accountService: AccountService,
    private companyService: CompanyService,
    private addressService: AddressService,
    private smaugService: SmaugService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar) {

      this.modelCard = new CreditCardForm(this.fb);
      this.registerCard = this.modelCard.buildFormGroup();
      this.registerCard.controls['cardName'].clearValidators();

      /// Authentication by Api Key
      this.smaugService.authApiKey = true;
      this.c3poService.authApiKey = true;
      this.accountService.authApiKey = true;
      this.companyService.authApiKey = true;
      this.addressService.authApiKey = true;
   }

  ngOnInit() {
    this.loading = true;
    this.success = false;
    this.step = 1;
    this.registerFormsGroup();
    const currentYear = new Date().getFullYear();
    this.modelCard.year = currentYear.toString().substring(2);
    this.registerCard.get('year').setValue(this.modelCard.year);
    for (let i = 1 ; i <= 12; i++) {
      this.months.push(i < 10 ? '0' + i : i.toString());
    }
    for (let i = currentYear ; i <= currentYear + 20; i++) {
      this.years.push(i.toString().substring(2, i.toString().length ));
    }
    this.registerCard.get('month').setValue(this.months[0]);

    // If it's a community, try to retrieve its information by the corresponding subdomain.
    if (SiteHelper.isCommunity()) {
      this.communityService.getBySubDomain(location.host.split('.')[0]).subscribe(c => {
        if (c) {
          this.currentCommunity = c;
          this.CommunityName = this.currentCommunity.name;
          this.loading = false;
          this.setCommunityInfo();
        } else {
        this.loading = false;
         SiteHelper.goToLogin();
        }
      });
    }
  }

  registerFormsGroup() {
    this.registerBank = this.fb.group({
      bankAccountType: ['' , [Validators.required]],
      routing: ['', [Validators.required]],
      bankAccount: ['', [Validators.required]],
    });
    this.findAccountGroup = this.fb.group({
      firstname : ['', [Validators.required]],
      lastname : ['', [Validators.required]],
      email : ['', [Validators.required]],
      account : ['', [Validators.required]],
    });
  }

  /**
   * Invokes the web service to look for an account containing the specified last name and account
   * number.
   *
   * @param lastName - The last name of the user trying to pay.
   * @param accountNumber - The account number of the user trying to pay.
   */
  findAccount(lastName: string, accountNumber: string) {

    if (this.findAccountGroup.status !== 'VALID') {
      return;
    }

    this.loading = true;
    this.accountService.findAccountByParams(this.currentCommunity.communityId, lastName.trim(), accountNumber.trim()).subscribe( acc => {
      if (acc.length > 0) {
        this.cdr.detectChanges();
        this.currentAccount =  acc[0];
        this.currentPeopleId = acc[0].peopleId;
        this.setStep2(this.currentAccount.accountCode);
      } else {
        this.step = 3;
        this.loading = false;
      }
    });
  }

  /**
   * Sets up the second payment step.
   *
   * @param AccountNumber - The account number of the user trying to pay.
   */
  setStep2(AccountNumber: string) {
    this.step = 2;
    this.loading = true;
    const observableArray = this.accountService.getAllTransactionsByUnit(this.currentAccount.unitId);
    const observableArrayAddress = this.addressService.getByRelationship(this.currentPeopleId);
    const observableBalanceArray = this.accountService.getBalancesByUnitId(this.currentAccount.unitId);

    forkJoin(observableArray, observableArrayAddress, observableBalanceArray).subscribe(result => {
      if (result.length > 0) {
        this.totalAmount = result[2].filter(balances => balances.accountCode === AccountNumber)[0].amount;
        this.totalAmountWithFee = this.totalAmount,
        this.transactions = result[0][0].transactions;
        if (result[1].length > 0) {
          this.address = result[1][0].line1 + ' | ' +
          result[1][0].zipCode.substring(0, 1) + '***' +  result[1][0].zipCode.substring(3, result[1][0].zipCode.length) +
          ' | ' + this.CommunityName;
        }

        const observableFee = this.smaugService.getFee(this.totalAmount, this.applicationId, this.providerId);
        forkJoin(observableFee).subscribe(resultFee => {
          if (resultFee.length > 0) {
            this.paymentMethodArray = resultFee[0];
          }
        });
      }

      this.loading = false;
    });

    this.smaugService.getBankAccountType(true).subscribe(x => {
      this.bankAccountTypes = x;
    });
  }

  makePayment() {
    this.step = 4;
    this.currentDate = new Date();
  }
  tooltipText(row: any): string {
    return  this.accountService.getTooltipForPending(row);
  }

  /**
   * Sets the payment method that the user selected.
   *
   * @param value - The data of the payment method that was selected by the user.
   */
  selectPaymentMethod(value) {
    this.paymentMethodSelected = value;
    this.paymentMethod = value.paymentMethodTypeId;
    this.totalFee =  value.fee;
    this.totalAmountWithFee = this.totalAmount + this.totalFee;
  }

  confirmPaymentMethod() {

    if ((this.registerBank.status === 'VALID' && this.paymentMethod === 0) ||
        (this.registerCard.status === 'VALID' && this.paymentMethod === 1))  {
      this.step = 5;
      this.paymentMethodString = this.paymentMethod === 1 ? 'Card ' + this.registerCard.get('cardNumber').value :
      'Bank ' + this.registerBank.get('bankAccount').value;
    } else {
      SiteHelper.markFormGroupTouched(this.registerBank);
      SiteHelper.markFormGroupTouched(this.registerCard);
    }

  }

  /**
   * Device what step should we return to, in case the user wants to go back.
   */
  goToPreviousStep() {
    if (this.step === 3 || this.step === 6 || this.step === 2) {
      this.step = 1;
    } else if (this.step === 4) {
      this.step = 2;
    } else if (this.step === 5) {
      this.step = 4;
    }
  }

  /**
   * Sends the payment request.
   */
  pay() {
    this.loading = true;

    if (this.paymentMethod === 1) {
      const entity = this.modelCard.toDto();
      const paymentAnonymousWithCard: PaymentAnonymousWithCard = {
        userSourceAccountNumber: this.currentAccount.accountCode,
        paypointSourceCommunityCode: this.currentAccount.communityExternalCode,
        applicationId: this.applicationId,
        cardName: '',
        cardNumber: entity.cardNumber,
        cardExpirationMMYY: entity.month  +  entity.year,
        cardCVV: entity.cardCVV,
        zip: entity.zip,
        amount: this.totalAmount,
        classificationItemId: 1,
        email: this.findAccountGroup.controls['email'].value,
        accountId: this.currentAccount.accountId
      };
      this.accountService.makePayment(paymentAnonymousWithCard).subscribe(u => {
        this.step = 6;
        this.loading = false;
      }, error => {
        this.snackbar.open(error, '', { duration: 5000 });
        this.loading = false;
      });

    } else if (this.paymentMethod === 0) {
      this.routing = this.registerBank.get('routing').value;
      this.bankAccountNumber = this.registerBank.get('bankAccount').value;

      const paymentAnonymousWithBankAccount: PaymentAnonymousWithBankAccount = {
        userSourceAccountNumber: this.currentAccount.accountCode,
        paypointSourceCommunityCode: this.currentAccount.communityExternalCode,
        applicationId: this.applicationId,
        bankAccountNumber: this.bankAccountNumber,
        bankAccountTypeId: this.registerBank.get('bankAccountType').value.bankAccountTypeId,
        bankABANumber: this.routing,
        amount: this.totalAmount,
        classificationItemId: 1,
        email: this.findAccountGroup.controls['email'].value,
        accountId: this.currentAccount.accountId
      };


      this.accountService.makePayment(paymentAnonymousWithBankAccount).subscribe(u => {
        this.step = 6;
        this.loading = false;
        this.confirmationNumber = u.provider_TransactionId;
      }, error => {
        this.snackbar.open(error, '', { duration: 5000 });
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  /**
   * Retrieves the community logo, if one is available.
   */
  setCommunityInfo(): void {
    this.companyService.currentCompanyLogo.subscribe(logo => {
      if (logo !== undefined && logo !== null && logo !== '') {
        this.companyLogo = logo;
      }
    });
  }

  /**
   * Redirects to the login screen.
   */
  goToLogin() {
    this.router.navigate(['login']);
  }

}
