
import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class TemplateRole extends BaseForm {
    templateRoleId = 0;

    @FormField('name', ['', Validators.required])
    name = '';

    @FormField('description', ['', Validators.required])
    description = '';

    @FormField('roleTypeId', ['', Validators.required])
    roleTypeId = 0;

}
