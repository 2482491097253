import { Component, OnInit, Inject } from '@angular/core';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { CompanyGuests } from 'src/app/model/company-guests';
import { FormGroup, FormBuilder } from '@angular/forms';

import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { CompanyGuestsService } from 'src/app/services/company-guests.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.css']
})
export class CompanyAddComponent extends BaseSecurity implements  OnInit {

  public model: CompanyGuests;
  public companyFormGroup: FormGroup;
  public loading = false;
  constructor(public dialogRef: MatDialogRef<CompanyAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private companyService: CompanyGuestsService) {
    super();

    this.model = new CompanyGuests(this.fb);
      this.companyFormGroup = this.model.buildFormGroup();
      this.loadSecurityRings();
  }

  protected loadSecurityRings() {
  }

  ngOnInit() {
  }

  onCancel(): void {
    if (this.companyFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else { this.dialogRef.close({ valid: false }); }
  }


  save() {
    this.loading = true;
    this.companyService.add(this.model.toDto()).subscribe(c => {
      this.loading = false;
      this.dialogRef.close({
        valid: true,
        companyId: c.id
      });
    }, error => {
      this.loading = false;
      this.snackBar.open('An error Ocurred.', '', {
        duration: 2000
      });
    });
  }

}
