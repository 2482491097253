import { DynamicMenu } from '../model/dynamic-menu';
import { Level } from '../model/breadcrumb';
import { PaymentInfo } from '../model/paymentInfo';
import { Observable, Subject } from 'rxjs';
import { UserImpersonated } from '../model/user-impersonated';
import { LoggedUser } from '../model/logged-user';
import { Permition } from '../model/permition';
import { Newsfeed } from '../model/newsfeed';

const USER_DATA_KEY = 'GridSystemsUserData';
const USER_DISPLAYNAME = 'GridSystemsUserDisplayName';
const USER_IMPERSONATED_DATA_KEY = 'GridSystemsUserImpersonatedData';
const USER_MENU_KEY = 'GridEnterpriseMenu-';
const SYSTEM_INFO = 'SystemInfo';
const BREADCRUMB_INFO = 'BCInfo';
const PAYMENT_INFO = 'PaypointInfo';
const LOGGED_USER = 'loggedUser';
const IMPERSONATED_LOGGED_USER = 'ImpersonatedLoggedUser';
const IMPERSONATED_FLAG = 'ImpersonatedFlag';
const BIG_FONT = 'bigFont';
const SELECTED_LOCATION = 'SelectedLocation';

const storageSub = new Subject<boolean>();
const storageSubPaymentInfo = new Subject<boolean>();
const storageSubChangeUser = new Subject<boolean>();
const selectedLocationBarNode = new Subject<any>();
const backToLink = new Subject<any>();

export namespace LocalStorageHelper {


  export function watchLoggetUserInfo(): Observable<any> {
    return storageSubChangeUser.asObservable();
  }

  export function setWatchLoggetUserInfo() {
    storageSubChangeUser.next(true);
  }

  export function setSelectedLocationBarNode(selectedLocation: string) {
    sessionStorage.setItem(SELECTED_LOCATION, selectedLocation);
    selectedLocationBarNode.next(selectedLocation);
  }

  export function getSelectedLocationBarNode() : Observable<any> {
    let selectedLocation = sessionStorage.getItem(SELECTED_LOCATION);
    selectedLocationBarNode.next(selectedLocation);
    return selectedLocationBarNode.asObservable();
  }
  
  export function setBackToButtonOnLocationBar(backToLinkValue: any) {
    backToLink.next(backToLinkValue);
  }

  export function getBackToButtonOnLocationBar(): Observable<any> {
    return backToLink.asObservable();
  }
  
    /**
     * Stores breadcrumb data in the local storage.
     *
     * @param userData - The user data to store in the local storage.
     */
    export function setBreadcrumbInfo(BCData: any) {
        const jsonData = JSON.stringify(BCData);
        sessionStorage.setItem(BREADCRUMB_INFO, jsonData);
        storageSub.next(true);
    }

    /**
   * Check changes in Breadcrumb selected
   * from the local storage.
   */
    export function watchBreadcrumb(): Observable<any> {
        return storageSub.asObservable();
    }

    /**
   * Retrieves BreadcrumbIn for current user
   * from the local storage.
   */
    export function getBreadcrumbInfo(): Level[] {
        let bcInfo: Level[] = [];

        const bcData = sessionStorage.getItem(BREADCRUMB_INFO);
        if (bcData !== null) {
            const parsedData = JSON.parse(bcData);
            bcInfo = parsedData;
        } else {
            bcInfo = null;
        }

        return bcInfo;
    }

    export function getManagementCompanyFromBreadcrumb(): number {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let mc = 0;
        if (bcInfo) {
            mc = bcInfo[1].type === 'ManagementCompany' ? bcInfo[1].id : 0;
        }
        return mc;
    }

    export function getCommunitiesFromBreadcrumb(): number {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let community = 0;
        if (bcInfo) {
            community = bcInfo[1].type === 'Community' ? bcInfo[1].id : bcInfo[2].id;
        }
        return community;
    }
    export function getCommunityNameFromBreadcrumb(): string {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let community = '';
        if (bcInfo) {
            community = bcInfo[1].type === 'Community' ? bcInfo[1].name : bcInfo[2].name;
        }
        return community;
    }

    export function getBuildingFromBreadcrumb(): number {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let building = 0;
        if (bcInfo) {
            building = bcInfo[3].type === 'Area' ? bcInfo[3].id : 0;
        }
        return building;
    }
    export function getBuildingNameFromBreadcrumb(): string {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let building = '';
        if (bcInfo) {
            building = bcInfo[3].type === 'Area' ? bcInfo[3].name : '';
        }
        return building;
    }

    export function getUnitFromBreadcrumb(): string {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let unit = '';
        if (bcInfo) {
            unit = bcInfo[2].type === 'Unit' ? bcInfo[2].name : '';
        }
        if (unit === '' && bcInfo && bcInfo[4]) {
            unit = bcInfo[4].type === 'Unit' ? bcInfo[4].name : '';
        }
        return unit;
    }
    export function getUnitIdFromBreadcrumb(): number {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let unit = 0;
        if (bcInfo) {
            unit = bcInfo[2].type === 'Unit' ? bcInfo[2].id : 0;
        }
        if (unit === 0 && bcInfo && bcInfo[4]) {
            unit = bcInfo[4].type === 'Unit' ? bcInfo[4].id : 0;
        }
        return unit;
    }


    export function getAccountIdFromBreadcrumb(): number {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let account = 0;

        if (bcInfo && bcInfo[3]) {
            account = bcInfo[3].type === 'Account' ? bcInfo[3].id : 0;
        }
        return account;
    }

    export function getBuildingChildsFromBreadcrumb(): Level[] {
        const bcInfo: Level[] = getBreadcrumbInfo();
        let building: Level[] = [];
        if (bcInfo) {
             building = bcInfo[1].type === 'Community' ? bcInfo[1].son : bcInfo[2].son;
        }
        return building;
    }

    /**
    * Stores the impersonated flag
    */
    export function setImpersonatedFlag(impersonatedFlag: boolean) {
        if (impersonatedFlag === null) {
            sessionStorage.removeItem(IMPERSONATED_FLAG);
        } else {
            const jsonData = JSON.stringify(impersonatedFlag);
            sessionStorage.setItem(IMPERSONATED_FLAG, jsonData);
            if (!impersonatedFlag) {
                sessionStorage.removeItem(IMPERSONATED_LOGGED_USER);
            }
        }
    }

    /**
    * Get the impersonated flag
    */
   export function getImpersonatedFlag() {
        const impersonatedFlagJson = sessionStorage.getItem(IMPERSONATED_FLAG);
        if (impersonatedFlagJson === null) {
            return false;
        }
        const impersonatedFlag: boolean = JSON.parse(impersonatedFlagJson);
        return impersonatedFlag;
   }

    /**
    * Stores the user claims in the session storage
     */
    export function setLoggedUserInfo(loggedUser: LoggedUser, impersonated: boolean) {
        const user = impersonated ? IMPERSONATED_LOGGED_USER : LOGGED_USER;
        if (loggedUser === null) {
            sessionStorage.removeItem(user);
        } else {
            const jsonData = JSON.stringify(loggedUser);
            sessionStorage.setItem(user, jsonData);
        }
    }

     /**
    * Stores the user claims in the session storage
     */
    export function getLoggedUserInfo(impersonated: boolean): LoggedUser {
        const user = getImpersonatedFlag() ? IMPERSONATED_LOGGED_USER : LOGGED_USER;
        const userInfo = sessionStorage.getItem(user);
        if (userInfo === null) {
            return null;
        }
        const loggedUser: LoggedUser = JSON.parse(userInfo);
        return loggedUser;
    }

    /**
    * Retrieves the user claims in the session storage
     */
    export function getUserPermitions(impersonated: boolean): Permition[] {
        const imp = impersonated ? IMPERSONATED_LOGGED_USER : LOGGED_USER;
        const user = sessionStorage.getItem(imp);
        if (user === null) {
            return null;
        }
        const loggedUser: LoggedUser = JSON.parse(user);
        return loggedUser.permitions != null ? loggedUser.permitions : null;
    }


    /**
     * Stores paypointData in the local storage.
     *
     * @param PaypointData - The PaypointData to store in the local storage.
     */
    export function setPaymentInfo(PaypointData: PaymentInfo[]) {
        const jsonData = JSON.stringify(PaypointData);
        localStorage.setItem(PAYMENT_INFO, jsonData);
        storageSubPaymentInfo.next(true);
    }

    export function watchPaymentInfo(): Observable<any> {
        return storageSubPaymentInfo.asObservable();
    }


    /**
     * Retrieves Payment data from the local storage.
     */
    export function getPaymentInfo(): PaymentInfo[] {
        const payment_Info = localStorage.getItem(PAYMENT_INFO);
        return (payment_Info !== null) ? JSON.parse(payment_Info) : null;
    }

    /**
     * Retrieves user authentication data from the local storage.
     */
    export function getAuthToken(): string {
        let token;

        const userData = localStorage.getItem(USER_DATA_KEY);
        if (userData !== null) {
            const parsedData = JSON.parse(userData);
            token = parsedData.token;
        } else {
            token = null;
        }

        return token;
    }

    /**
     * Stores user authentication data in the local storage.
     *
     * @param userData - The user data to store in the local storage.
     */
    export function setAuthToken(userData?: any) {

        if (userData !== null) {
            const jsonData = JSON.stringify(userData);
            localStorage.setItem(USER_DATA_KEY, jsonData);
        } else {
            console.error('Invalid user data provided.');
        }
    }

    /**
 * Retrieves user authentication data from the local storage.
 */
    export function getClientToken(): string {
        let token;

        const tokenData = localStorage.getItem('ClientToken');
        if (tokenData !== null) {
            const parsedData = JSON.parse(tokenData);
            token = parsedData;
        } else {
            token = null;
        }

        return token;
    }

    /**
     * Stores app token data in the local storage.
     *
     * @param userData - The token
     */
    export function setClientToken(token?: any) {

        if (token !== null) {
            const jsonData = JSON.stringify(token);
            localStorage.setItem('ClientToken', jsonData);
        } else {
            console.error('Invalid token data');
        }
    }

    /**
     * Stores user personal information (such as first and last names) in
     * the local storage.
     *
     * @param firstName The user's first name.
     * @param lastName The user's last name.
     */
    export function setUserInfo(firstName: string, lastName: string): void {

        const userData = localStorage.getItem(USER_DATA_KEY);
        if (userData !== null) {
            const parsedData = JSON.parse(userData);
            parsedData.firstName = firstName;
            parsedData.lastName = lastName;
            const jsonData = JSON.stringify(parsedData);
            localStorage.setItem(USER_DATA_KEY, jsonData);
        } else {
            console.error('Invalid user data provided.');
        }
    }

    /**
     * Retrieves user personal information (such as first and last names)
     * from the local storage.
     */
    export function getUserInfo(): string {
        let userInfo;

        const userData = localStorage.getItem(USER_DATA_KEY);
        if (userData !== null) {
            const parsedData = JSON.parse(userData);
            userInfo = parsedData.firstName + ' ' + parsedData.lastName;
        } else {
            userInfo = null;
        }

        return userInfo;
    }

    export function setUserDisplayName(displayName: string): void {
            const jsonData = JSON.stringify(displayName);
            localStorage.setItem(USER_DISPLAYNAME, jsonData);
    }

    /**
     * Retrieves user personal information (such as first and last names)
     * from the local storage.
     */
    export function getUserDisplayName(): string {
        let userInfo;

        const userData = localStorage.getItem(USER_DISPLAYNAME);
        if (userData !== null) {
            const parsedData = JSON.parse(userData);
            userInfo = parsedData;
        } else {
            userInfo = null;
        }

        return userInfo;
    }

    /**
     * Stores user impersonated  personal information (such as first and last names) in
     * the local storage.
     *
     * @param firstName The user's first name.
     * @param lastName The user's last name.
     */
    export function setUserImpersonatedInfo(usr: UserImpersonated): void {
        const jsonData = JSON.stringify(usr);
        localStorage.setItem(USER_IMPERSONATED_DATA_KEY, jsonData);
    }

    /**
     * Stores Big Font Flag
     *
     * @param flag The big font flag
     */
    export function setBigFontFlag(flag: boolean): void {
        const jsonData = JSON.stringify(flag);
        localStorage.setItem(BIG_FONT, jsonData);
    }

    /**
     * Retrieves Big Font Flag from the local storage.
     */
    export function getBigFontFlag(): boolean {
        let flag: boolean;

        const flagData = localStorage.getItem(BIG_FONT);
        if (flagData !== null) {
            const parsedData = JSON.parse(flagData);
            flag = parsedData;
        } else {
            flag = null;
        }

        return flag;
    }

    export function watchImpersonatedUser(): Observable<any> {
        return storageSubChangeUser.asObservable();
    }

  
    /**
    * Retrieves system information version from the local storage.
    */
    export function getVersionInfo(): string {
        let version: string;
        const systemInfo = localStorage.getItem(SYSTEM_INFO);
        if (systemInfo !== null) {
            const parsedData = JSON.parse(systemInfo);
            version = parsedData[0].version;
        } else {
            version = null;
        }

        return version;
    }

    /**
     * Stores system Info in the local storage.
     *
     * @param systemInfo - The system Info data to store in the local storage.
     */
    export function setSystemInfo(systemInfo?: any) {

        if (systemInfo !== null) {
            const jsonData = JSON.stringify(systemInfo);
            localStorage.setItem(SYSTEM_INFO, jsonData);
        } else {
            console.error('Invalid system info provided.');
        }
    }

    /**
     * Retrieves the username from the user data stored in the local storage.
     */
    export function getUsername(): string {

        let username;

        const userDataString = localStorage.getItem(USER_DATA_KEY);
        if (userDataString !== null) {
            const userData = JSON.parse(userDataString);
            username = userData.username;
        } else {
            console.error('Invalid user data stored.');
            username = '';
        }

        return username;
    }

    /**
     * Retrieves a user menu from the local storage.
     *
     * @param userId The ID of the user for whom the menu was generated.
     */
    export function getUserMenu(): DynamicMenu {

        let menuJson;
        menuJson = localStorage.getItem(USER_MENU_KEY + getUsername());

        return JSON.parse(menuJson);
    }

    /**
     * Stores a user menu into the local storage.
     *
     * @param userId The ID of the user for whom the menu was generated.
     * @param menu A reference to the menu instance to store.
     */
    export function setUserMenu(menu: DynamicMenu) {

        localStorage.setItem(USER_MENU_KEY + getUsername(), JSON.stringify(menu));
    }


    const POST_DETAIL = 'PostDetail';
    export function setPostDetail(post: Newsfeed): void {
        const jsonData = JSON.stringify(post);
        localStorage.setItem(POST_DETAIL, jsonData);
    }

    export function getPostDetail(): Newsfeed {
        let post;
        post = localStorage.getItem(POST_DETAIL);
        return JSON.parse(post);
    }
    export function removePostDetail(): void {
        localStorage.removeItem(POST_DETAIL);
    }
}
