import { Injectable, Inject } from '@angular/core';
import { Package } from '../model/package';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { CallbackPayload } from '../model/signature-pad';

@Injectable({
  providedIn: 'root'
})
export class PackagesService extends AbstractDataProviderService<Package> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/packages', http, appConfig);
  }

  receive(packages: Package): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/receive/' , packages,  { headers: this.buildGetHeaders() });
  }
  deliver(pack: CallbackPayload): Observable<any> {

    const payload = new FormData();
    payload.append('communityId', pack.communityId.toString());
    payload.append('communityName', pack.communityName);
    payload.append('residentId', pack.residentId.toString());
    payload.append('residentName', pack.residentName);
    payload.append('hotPackages', JSON.stringify(pack.hotPackages));
    payload.append('notes', pack.notes);
    payload.append('recipientName', pack.recipientName);
    payload.append('sameAsDestinatary', pack.sameAsDestinatary.toString());
    payload.append('temporaryFileId', pack.temporaryFileId.toString());

    return this.http.post<any>(this.baseUrl + '/deliver/full' , payload,  { headers: this.buildGetHeaders() });
  }
}
