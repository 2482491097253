import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseList } from '../../../../../src/app/building-blocks/base-list';
import { Bundle } from '../../model/bundle';
import { BundleService } from '../../services/bundle.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonService } from '../../services/common.service';

const bundleColumns = [
  'name',
  'documentsCount',
  'userName',
  'isActive',
  'actions'
];

@Component({
  selector: 'app-bundle-list',
  templateUrl: './bundle-list.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  styleUrls: ['./bundle-list.component.css']
})

export class BundleListComponent extends BaseList<Bundle> {
  public loading: boolean;

  constructor(
    public injector: Injector,
    private commonService: CommonService,
    private bundleService: BundleService) {
    super(bundleColumns, '/bundle', bundleService, 'bundle', injector);
  }


  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Bundle[]> {
    return this.bundleService.getAllPaged(pageIndex, pageSize);
  }

  protected getCount(): Observable<number> {
    return this.commonService.getCountByBreadcumd('Documents.Bundle');
  }

  protected getById(id: number): Observable<Bundle> {
    return this.bundleService.getById(id);
  }

  editBundle(bundleId: number): void {
    this.router.navigateByUrl('/app/docs/bundle/' + bundleId + '/edit');
  }

  addBundle(): void {
    this.router.navigateByUrl('/app/docs/bundle/' + 0 + '/edit');
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any { }

}

