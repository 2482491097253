import { Component, EventEmitter, Injector } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';

import { empty, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseList } from '../../building-blocks/base-list';
import { BaseListDialogData } from '../../building-blocks/base-list-dialog-data';

import { ApiKey } from '../../model/api-key';
import { CrudResponseModel } from '../../model/crud-response-model';
import { Role } from '../../model/role';
import { ApiKeyService } from '../../services/api-key.service';
import { ApiKeyAddEditDialogComponent } from '../api-key-add-edit-dialog/api-key-add-edit-dialog.component';
import { RoleService } from '../../services/role.service';
import { AccessType } from 'src/app/model/access-type-ring';
import { SiteHelper } from '../../helpers/site-helper';

const userColumns = [
  'apiKeyId',
  'apiKey',
  'description',
  'actions'
];

@Component({
  selector: 'app-api-key-list',
  templateUrl: './api-key-list.component.html',
  styleUrls: ['./api-key-list.component.css']
})
export class ApiKeyListComponent extends BaseList<ApiKey> {

  constructor(
    public injector: Injector,
    private apiKeyService: ApiKeyService,
    private dialog: MatDialog,
    private roleService: RoleService,
    private snackbar: MatSnackBar) {
      super(userColumns, '/security/apiKey', apiKeyService, 'apiKey', injector);
      this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.securityEntity.rings.push({ringId : 550, accessType: AccessType.Add });
    this.securityEntity.rings.push({ringId : 750, accessType: AccessType.Update });
    this.securityEntity.rings.push({ringId : 650, accessType: AccessType.Delete });
    this.entityRings.push(this.securityEntity);
  }
  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<ApiKey[]> {
    return this.apiKeyService.getAllPaginated(pageIndex, pageSize);
  }

  protected getCount(): Observable<number> {
    return this.apiKeyService.getCount();
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>): any {
    const dialogRef = this.dialog.open(ApiKeyAddEditDialogComponent, {
      width: '850px',
      data: new BaseListDialogData<ApiKey>(isUpdate, model, emitter),
      panelClass: [SiteHelper.getDefaultTheme()],
    });

    return dialogRef;
  }

  /** Overriden to implement further changes. */
  protected afterUpdate(entity: ApiKey, data: any, beforeResult: any, updateResult: any): Observable<any> {
    const roles: Role[] = data.roles;
    const rolesToWipe: Role[] = data.rolesToWipe;

    const linkUnlinkObservers = [];

    if (roles !== undefined) {
      roles.forEach(x => {
          linkUnlinkObservers.push(this.roleService.linkRoleToUser(x.roleId, entity.userId));
      });
    }
    rolesToWipe.forEach(x => {
      linkUnlinkObservers.push(this.roleService.unlinkRoleFromUser(x.roleId, entity.userId));
    });
    this.dataSource.reload();
    return forkJoin(linkUnlinkObservers);
  }

  /**
   * Method overriden to link roles to the API key after insertion.
   */
  protected afterInsert(entity: ApiKey, data: any, beforeResult: any, insertResult: any): Observable<any> {
    const roles: Role[] = data.roles;
    const roleLinkObservers = [];

    // Link the roles to the api keys
    roles.forEach(r => {
      roleLinkObservers.push(this.roleService.linkRoleToUser(r.roleId, insertResult.id));
    });

    return forkJoin(roleLinkObservers);
  }

  /**
   * Custom method to retrieve additional data to populate the add/edit dialog.
   */
  getDialogData(apiKeyId: number) {
    // A reference to this instance must be saved and passed to the
    // built function, so that it may be called from the dialog component.
    const closure = this;

    return () => {
      return forkJoin(
        closure.roleService.getByApiKeyId(apiKeyId),
        closure.apiKeyService.getById(apiKeyId))
      .pipe(map(results => {
        return {
          update: true,
          entity: results[1],
          roles: results[0]
        };
      }));
    };
  }
}

