import { Injectable, Inject } from '@angular/core';
import { PackageLog } from '../model/packageLog';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { LevelFilter } from '../model/breadcrumb';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PackageLogService extends AbstractDataProviderService<PackageLog> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/packages/log', http, appConfig, LevelFilter.Unit);
  }

  getAllPaged(pageIndex: number, pageSize: number, filterParamsFlag: boolean, trackingN: string, recipient: string, fromDate: string,
    toDate: string, status: number, carrier: number, sortByDesc: boolean = true): Observable<PackageLog[]> {

    this.setBreadcrumbParams();

    let url = '/allPaged/' + pageIndex + '/' + pageSize;

    if (this.searchParamsBreadcrumb.communityId !== 0 && filterParamsFlag) {
      url = '/allPagedByFiltersBreadcrumb/' + pageIndex + '/' + pageSize + '?propertyManagementCompanyId=' +
      this.searchParamsBreadcrumb.propertyManagementCompanyId + '&communityId=' + this.searchParamsBreadcrumb.communityId
      + '&areaId=' + this.searchParamsBreadcrumb.areaId + '&unitId=' + this.searchParamsBreadcrumb.unitId
      + '&fromDate=' + fromDate + '&toDate=' + toDate + '&status=' + status + '&carrier=' + carrier
      + '&recipient=' + recipient + '&tracking=' + trackingN  + '&sortByDesc=' + sortByDesc;
      return this.http.get<PackageLog[]>(this.baseUrl + url, { headers: this.buildGetHeaders() });
    }

    if (this.searchParamsBreadcrumb.communityId !== 0) {
      url = '/allPagedByBreadcrumb/' + pageIndex + '/' + pageSize + '?propertyManagementCompanyId=' +
       this.searchParamsBreadcrumb.propertyManagementCompanyId + '&communityId=' + this.searchParamsBreadcrumb.communityId
       + '&areaId=' + this.searchParamsBreadcrumb.areaId + '&unitId=' + this.searchParamsBreadcrumb.unitId + '&sortByDesc=' + sortByDesc;
       return this.http.get<PackageLog[]>(this.baseUrl + url, { headers: this.buildGetHeaders() });
    }

    if (filterParamsFlag) {
      url = '/allPagedByFilters/' + pageIndex + '/' + pageSize + '?fromDate=' + fromDate
      + '&toDate=' + toDate + '&status=' + status + '&carrier=' + carrier
      + '&recipient=' + recipient + '&tracking=' + trackingN + '&sortByDesc=' + sortByDesc;
      return this.http.get<PackageLog[]>(this.baseUrl + url, { headers: this.buildGetHeaders() });
    }

    return this.http.get<PackageLog[]>(this.baseUrl + url, { headers: this.buildGetHeaders() });
  }

  getCountForPackageLog(filterParamsFlag: boolean, trackingN: string, recipient: string, fromDate: string,
    toDate: string, status: number, carrier: number):  Observable<number> {

    let url = '/getCountForPackageLog';

    this.setBreadcrumbParams();

    if (this.searchParamsBreadcrumb.communityId !== 0 && filterParamsFlag) {
      url = '/getCountByFiltersBreadcrumb/' + '?propertyManagementCompanyId=' +
      this.searchParamsBreadcrumb.propertyManagementCompanyId + '&communityId=' + this.searchParamsBreadcrumb.communityId
      + '&areaId=' + this.searchParamsBreadcrumb.areaId + '&unitId=' + this.searchParamsBreadcrumb.unitId
      + '&fromDate=' + fromDate + '&toDate=' + toDate + '&status=' + status + '&carrier=' + carrier
      + '&recipient=' + recipient + '&tracking=' + trackingN;
      return this.http.get<CountResultModel>(this.baseUrl + url, { headers: this.buildGetHeaders() })
      .pipe(map(x => x.count));
    }

    if (this.searchParamsBreadcrumb.communityId !== 0) {
      url = '/getCountByBreadcrumb/' + '?propertyManagementCompanyId=' +
       this.searchParamsBreadcrumb.propertyManagementCompanyId + '&communityId=' + this.searchParamsBreadcrumb.communityId
       + '&areaId=' + this.searchParamsBreadcrumb.areaId + '&unitId=' + this.searchParamsBreadcrumb.unitId;
       return this.http.get<CountResultModel>(this.baseUrl + url, { headers: this.buildGetHeaders() })
       .pipe(map(x => x.count));
    }

    if (filterParamsFlag) {
      url = '/getCountByFilters/' + '?fromDate=' + fromDate
      + '&toDate=' + toDate + '&status=' + status + '&carrier=' + carrier
      + '&recipient=' + recipient + '&tracking=' + trackingN;
      return this.http.get<CountResultModel>(this.baseUrl + url, { headers: this.buildGetHeaders() })
      .pipe(map(x => x.count));
    }

    return this.http.get<CountResultModel>(this.baseUrl + url, { headers: this.buildGetHeaders() })
    .pipe(map(x => x.count));
  }

  getPackageLogDetail(id: string, ishot: string): Observable<PackageLog[]> {
    const queryString = '/getById/' + id + '/' + ishot;
    return this.http.get<PackageLog[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
}
