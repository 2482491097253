import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ResidentSearch } from 'src/app/model/resident-search';
import { PeopleService } from 'src/app/services/people.service';
import { FormBuilder } from '@angular/forms';
import { fromEvent, Observable, Subject, forkJoin, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { MatAutocomplete, MatDialog } from '@angular/material';
import { ResidentInfo } from '../../model/resident';
import { RelationshipService } from '../../services/relationship.service';

@Component({
  selector: 'app-search-resident-units',
  templateUrl: './search-resident-units.component.html',
  styleUrls: ['./search-resident-units.component.css']
})
export class SearchResidentUnitsComponent implements OnInit , AfterViewInit {
  public residentSearch: ResidentSearch[] = [];
  private loading = false;
  private isLoading = false;
  showResidents = false;
  public residents: ResidentInfo[] = [];
  @ViewChild('filter') input: ElementRef;
  @ViewChild('residentSelect') residentSelect: MatAutocomplete;
  public selectedPeople = new Subject<ResidentInfo>();
  relationshipId: any;
  constructor(private peopleService: PeopleService,
    private relationshipService: RelationshipService,
    private fb: FormBuilder,
    private dialog: MatDialog) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(200),
          tap(() => this.isLoading = true),
          distinctUntilChanged(),
          switchMap((e: any) => {
            let val = '';
            if (e) {
              val = e.target.value;
            }
            if (val && val.length > 2) {
              return this.applyFilter(val || '');
            } else {
              this.isLoading = false;
              this.residentSearch = [];
              return new Observable<ResidentSearch[]>();
            }
          })
        ).subscribe(result => {
          this.residentSearch = result;
          this.isLoading = false;
        });
    }
  }

  applyFilter(filterValue: string): Observable<ResidentSearch[]> {
    return this.peopleService.getResidentOrUnitByFilter(filterValue);
  }
  displayFn(people?: any): string {
    return people && people.peopleId ? people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
      people.lastName + (people.address ? ' - ' + people.address : '') + (' - ' + people.unitName) :
      people.unitName+ ' - ' +  people.areaName;
  }

  onResidentSelectionChange(people: ResidentInfo) {
    this.loading = true;
      this.input.nativeElement.value = this.displayFn(people);
      if (people.peopleId === null) {
      this.showResidents = true;
       this.populateResidents(people).subscribe();
      }
      else {    
        this.showResidents = false;
        this.populateResidents(people).subscribe();              
      }
  }

  populateResidents(p: any): Observable<any> {
    this.residents = [];
    return new Observable((obs: any) => {
      this.relationshipService.getResidentsByUnit(p.unitId).subscribe(r => {
        this.getResidentInfo(r);
        if (this.showResidents == false){
          this.selectedPeople.next(p);
        }  
      }); 
    }); 
  }

  


  getResidentInfo(residents: any[]) {
    const groups = Array.from(new Set(residents.map((item: any) => item.relationshipId)));
      groups.forEach(re => {
        const resident = new ResidentInfo();
        const people = residents.filter(p => p.relationshipId === re)[0];
          resident.peopleId = people.peopleId;
          resident.relationshipId = people.relationshipId;
          resident.firstName = people.firstName;
          resident.lastName = people.lastName;
          resident.areaId = people.areaId;
          resident.areaName = people.areaName;
          resident.unitName = people.unitName;
          if (resident.unitName && resident.unitName.includes('Unit:') === true) {
            resident.unitName =resident.unitName.replace('Unit:', '');
          }
          residents.filter(p => p.relationshipId === re).forEach(c => {
            const contact = { phoneNumber: c.phoneNumber, phoneType: c.phoneType, isPreferred: c.isPreferred };
            if (resident.contacts.filter(q => q.phoneNumber === c.phoneNumber).length === 0 ) {
              resident.contacts.push({ phoneNumber: c.phoneNumber, phoneType: c.phoneType, isPreferred: c.isPreferred });
            }
          });
            residents.filter(p => p.relationshipId === re).forEach(c => {
              const email = { email: c.email, emailType: c.emailType, isPreferred: c.isEmailPreferred };
              if (resident.emails.filter(q => q.email === c.email).length === 0 ) {
                resident.emails.push({ email: c.email, emailType: c.emailType, isPreferred: c.isEmailPreferred });
              }
            });
            this.residents.push(resident);
      }, this.loading = false);
  }

  public residentSelectionChangeEmit(): Observable<ResidentInfo> {
    return this.selectedPeople.asObservable();
  }

  selectResident(value) {
    const people = this.residents.filter(r => r.relationshipId === value)[0];
    this.selectedPeople.next(people);
  }

}

