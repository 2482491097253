import { Component, EventEmitter, Injector } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';

import { forkJoin, Observable } from 'rxjs';

import { BaseList } from '../../building-blocks/base-list';
import { BaseListDialogData } from '../../building-blocks/base-list-dialog-data';

import { Role } from '../../model/role';
import { CrudResponseModel } from '../../model/crud-response-model';
import { RoleService } from '../../services/role.service';
import { RoleAddEditDialogComponent } from '../role-add-edit-dialog/role-add-edit-dialog.component';
import { ExplicitSecurityService } from '../../services/explicit-security.service';
import { ExplicitSecurity } from '../../model/explicit-security';
import { EntityRing, AccessType } from 'src/app/model/access-type-ring';
import { SiteHelper } from '../../helpers/site-helper';

const roleColumns = [
  'name',
  'description',
  'actions'
];

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RoleListComponent extends BaseList<Role> {
  public rolepermisions = new EntityRing();

  constructor(
    public injector: Injector,
    private roleService: RoleService,
    private dialog: MatDialog,
    private explicitSecurityService: ExplicitSecurityService,
    private snackbar: MatSnackBar) {

      super(roleColumns, '/security/role', roleService, 'role', injector);
      this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.rolepermisions.rings.push({ringId: 556, accessType: AccessType.Add});
    this.rolepermisions.rings.push({ringId: 656, accessType: AccessType.Delete});
    this.rolepermisions.rings.push({ringId: 756, accessType: AccessType.Update});
    this.entityRings.push(this.rolepermisions);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Role[]> {
    return this.roleService.getAllPaginated(pageIndex, pageSize);
  }

  protected getCount(): Observable<number> {
    return this.roleService.getCount();
  }

  protected openEditForm(isUpdate: boolean, model: Role, emitter: EventEmitter<CrudResponseModel>): any {
    const dialogRef = this.dialog.open(RoleAddEditDialogComponent, {
      panelClass: [SiteHelper.getDefaultTheme() , 'dialog-panel'],
      data: new BaseListDialogData<Role>(isUpdate, model, emitter),
        height: '670px',
        disableClose: true
    });

    return dialogRef;
  }
  protected insert(data: any, emitter: EventEmitter<CrudResponseModel>) {
    let addObservables = [];
    const role = data.entity;
    if (role.roleId === 0 || !role.roleId) {
        const explicitSecurity: ExplicitSecurity[] = data.explicitSecurityToAdd;
        role.rings = data.ringsToAdd;
        this.roleService.add(role).subscribe(rol => {
           explicitSecurity.forEach(exp => {
            exp.roleId = rol.id;
            });
            addObservables.push(this.explicitSecurityService.addBulk(explicitSecurity));
            forkJoin(addObservables).subscribe();
            addObservables = [];
            this.reloadData('created');
          });
    }
  }


  protected update(data: any, emitter: EventEmitter<CrudResponseModel>) {
    const role: any = data.entity;
    role.rings = data.ringsToAdd;
    role.ringsToDelete =  data.ringsToDelete;

    this.roleService.update(role).subscribe(_ => this.updateExplicitSecurity(data));
    this.reloadData('updated');
  }

  updateExplicitSecurity(data: any, ) {
    const newValues = data.explicitSecurityToAdd;
    if (newValues.length > 0) {
        this.explicitSecurityService.addBulk(newValues).subscribe();
    }
    const deleteValues =  data.explicitSecurityToDelete;
    deleteValues.map(exp => this.explicitSecurityService.delete(exp.explicitSecurityId).subscribe());
  }

  reloadData(msg: string) {
    this.loading = false;
          this.dataSource.reload();
          this.snackBar.open('The record has been successfully ' + msg + ' .', '', {
            duration: 2000
          });
  }


  openAddEdit(id: number): void {
    this.loading = true;
    const isUpdate = (id > 0);

    // If the ID is a positive non-zero integer, then it's an update
    // and we first need to go for the data to the service.
    if (isUpdate) {
      forkJoin(
        this.roleService.getById(id)).subscribe(results => {
          forkJoin(
            this.explicitSecurityService.getByRoleId(results[0].roleId)).subscribe(expSecu => {
              const data = {
                update: true,
                explicitSecurity: expSecu[0],
                role: results[0],
              };
              this.openAddEditInternal(isUpdate, data);
              this.loading = false;
            });
          });
    } else {
      this.openAddEditInternal(isUpdate, null);
      this.loading = false;
    }
  }
}
