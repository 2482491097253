import { Injectable, Inject } from '@angular/core';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { MassCommunicationList } from '../model/mass-communication-list';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { MassCommmResident } from '../model/mass-comm-resident';
import { MassCommmCommunityStaff } from '../model/mass-comm-community-staff';
import { LevelFilter } from '../model/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class MassCommunicationService extends AbstractDataProviderService<MassCommunicationList> {
  constructor(protected http: HttpClient,
    @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/massCommunication', http, appConfig);
  }

  getList(pageIndex: number, pageSize: number, filter: string): Observable<MassCommunicationList[]> {
    return null;
  }

  getCount(): Observable<number> {
    return new Observable();
  }

  getMassCommResidentList(): Observable<MassCommmResident[]> {
    this.setBreadcrumbParams();
    const queryString = '/getResidentsContacts/' +
     this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' +
     this.searchParamsBreadcrumb.communityId + '/' +
     this.searchParamsBreadcrumb.areaId + '/' +
     this.searchParamsBreadcrumb.unitId;

    return this.http.get<MassCommmResident[]>(this.baseUrl + queryString,
      { headers: this.buildGetHeaders() });
  }

  getMassCommCStafffList(): Observable<MassCommmCommunityStaff[]> {
    this.setBreadcrumbParams();
    const queryString = '/getCommunityStaffContacts/' +
    this.searchParamsBreadcrumb.propertyManagementCompanyId + '/' +
    this.searchParamsBreadcrumb.communityId;

    return this.http.get<MassCommmCommunityStaff[]>(this.baseUrl + queryString,
      { headers: this.buildGetHeaders() });
  }

}
