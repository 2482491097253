export class SearchAddress {
    addressId: number;
    unitId: number;
    addressTypeId: number;
    number: number;
    line1: string;
    line2: string;
    apt: string;
    originId: number;
    zipCodeId: number;
    isDeleted: boolean;
    zipCode: string;
    stateId: number;
    stateName: string;
    cityId: number;
    cityName: string;
    relationshipId: number;
}
