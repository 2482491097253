
import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class ConfirmEmailForm extends BaseForm {

     /**
     * The username
     */
    @FormField('username', ['', Validators.required])
    username = '';

     /**
     * The vcode
     */
    @FormField('vcode', ['', Validators.required])
    vcode = '';

    token = '';
}
