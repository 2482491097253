import { Inject, Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { DynamicMenu, DynamicMenuItem } from '../model/dynamic-menu';
import { LocalStorageHelper } from '../helpers/local-storage-helper';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private baseUrl: string;
  public reloadMenu =  new Subject<boolean>();
  public sidePanelState: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) protected appConfig?: AppConfig) {

    if (this.appConfig !== null) {
      // Check whether the endpoint name exists in the endpoint dictionary.
      if (AppConstants.EndpointNames.MenuService in this.appConfig.endpoints) {
        const endpoint = this.appConfig.endpoints[AppConstants.EndpointNames.MenuService];
        this.baseUrl = endpoint + '/menus';
      } else {
        console.error('No endpoint specified for the menu service.');
      }
    } else {
      // If appConfig was not injected, log an error.
      console.error('A reference to an instance of AppConfig was not injected into this service.');
    }
  }


  /**
   * Loads all menus that are assigned to a specified application.
   *
   * @param appName The name of the application to which the menus are
   * associated.
   */
  public getMenusByAppName(appName: string): Observable<DynamicMenu[]> {
    const token = LocalStorageHelper.getAuthToken();

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'bearer ' + token);

    const url = this.baseUrl + '/byApp/' + appName;

    return this.http.get<DynamicMenu[]>(url, { headers: headers });
  }

  /**
   * Clear Cache Menu
   */
  public clearCache() {
    const token = LocalStorageHelper.getAuthToken();

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'bearer ' + token);

    const url = this.baseUrl + '/admin/clearCache/';

    return this.http.post(url, null, { headers: headers });
  }

  /**
   * Builds an Angular route object from a dynamic menu item.
   *
   * @param item The item containing information to build the route.
   * @param level The current level of recursion.
   */
  private getAngularRouteFromItem(item: DynamicMenuItem, level: number): any {

    const result = {};
    // Assignment of result.name should be changed when implementing i18n.
    result['id'] = item.id;
    result['name'] = item.defaultText;
    if (item.isActive) {
      result['url'] = item.link === undefined ? '' : item.link.trim();
    }else {
      result['url'] = '';
    }
    result['icon'] = item.icon;
    result['level'] = level;
    result['children'] = [];
    result['isActive'] = item.isActive

    if (item.link === '' && level > 0) {
      result['opened'] = false;
    }

    return result;
  }

  /**
   * Recursively maps a collection of dynamic menu items into a collection of Angular routes
   *
   * @param items A collection of dynamic menu items to map.
   * @param level The current level of recursion.
   */
  private mapItemsToAngularRoutes(items: DynamicMenuItem[], level: number): any[] {

    const result = [];

    for (const item of items) {
      const route = this.getAngularRouteFromItem(item, level);
      if (item.childItems !== undefined && item.childItems.length > 0) {
        route.children = this.mapItemsToAngularRoutes(item.childItems, level + 1);
      }

      result.push(route);
    }

    return result;
  }

  /**
   * Maps a dynamic menu object to a collection of a collection of Angular routes.
   *
   * @param menu A reference to the dynamic menu object to map.
   */
  public mapMenuToAngularRoutes(menu: DynamicMenu): any[] {
    return this.mapItemsToAngularRoutes(menu.menuItems, 0);
  }

/**
 * Get Node from menu links by menu id
 * @param links All Routes
 * @param id Id to find
 */
  getItemMenuById(links: any[], id): any {
    for (let i = 0; i < links.length ; i ++) {
        if (links[i].id === id ) {
          return  links[i];
        } else {
         const result = this.getItemMenuById(links[i].children, id);
         if (result != null) {
           return result;
         }
        }
    }
    return null;
  }

  /**
  * monitor state of side nav
  */
  setSidePanelState(state: boolean) {
      this.sidePanelState.emit(state);
  }
}
