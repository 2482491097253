export class ResidentSearch {
    communityId: number;
    communityName: string;
    peopleId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    relationshipId: number;
    address: string;
    phone: string;
    unitName: string;
    unit: string;
    unitId: number;
    areaId: number;
    areaName: string;
    positionName: string;
}


export class ResidentInfoPackage {

    peopleId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    relationshipId: number;
    unitName: string;
    unitId: number;
    areaId: number;
    areaName: string;
}
