import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../../app/app-constants';

import { LocalStorageHelper } from '../helpers/local-storage-helper';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  protected baseUrl: string;

  constructor(
    protected http: HttpClient,
    @Inject(APP_CONFIG) protected appConfig?: AppConfig) {

    this.baseUrl = this.appConfig.endpoints[AppConstants.EndpointNames.NotificationService];
  }

  /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  protected buildHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'bearer ' + token);
    headers = headers.set('Content-Type', 'application/json');

    return headers;
  }

  registerToken(token: string, application: string): Observable<any> {
    const payload = {
      'platform': 'web',
      'application': application,
      'deviceToken': token
    };

    return this.http.post(this.baseUrl + '/registerDeviceToken', payload, { headers: this.buildHeaders() });
  }
  deregisterToken(token: string, application: string): Observable<any> {
    const payload = {
      'platform': 'web',
      'application': application,
      'deviceToken': token
    };

    return this.http.post(this.baseUrl + '/deregisterDeviceToken', payload, { headers: this.buildHeaders() });
  }
}
