import { Validators, FormArray } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { AddressTableEntry } from './address-table-entry';
import { Email } from './email';
import { PhoneNumber } from './phone-number';
import { Address } from './address';
import { Community } from './community';
import { PropertyManagementCompany } from './property-management-company';
import { ValidatorHelper } from '../helpers/validator-helper';

export class Company extends BaseForm {
    companyId = 0;

    @FormField('legalName', ['', Validators.required])
    legalName = '';

    @FormField('nickName', ['', Validators.required])
    nickName = '';


    @FormField('einNumber', ['', Validators.required])
    einNumber = '';

    @FormField('webSite', ['', Validators.required])
    webSite = '';

    @FormField('description', ['', Validators.required])
    description = '';

    parentCompanyId = null;

    @FormField('companyType', ['', Validators.required])
    companyType = 1;

    @FormField('taxClassificationId', ['', Validators.required])
    taxClassificationId = 0;

   @FormField('address', [], AddressTableEntry,  true)
    address: FormArray;

    @FormField('emails', [], Email,  true)
    emails: FormArray;

    @FormField('phones', [], PhoneNumber,  true)
    phones: FormArray;

    address_list: Address[];
    email_list: Email[];
    phone_list: PhoneNumber[];
    picture: any;
    community: Community;
    pmc: PropertyManagementCompany;

    isDeleted = false;

       /**
     * The creation date of this record.
     */
    creationDate: Date = null;

    /**
     * The ID of the user that created this record.
     */
    creationUserId = 0;

    /**
     * The modification date of this record.
     */
    lastModification: Date = null;

    /**
     * The ID of the user that last modified this record.
     */
    modificationUserId = 0;

    parent: number = null;
}

export class CompanyEntry {
   companyId: number;
   legalName: string;
   typeCompany?: number;
   selected ? = false;
   parent: number = null;
   nickName: string;
}

export enum TypeCompany {
   'Community' = 1,
   'PropertyMAnagementCompany' = 2
}
