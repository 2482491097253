import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

import { CommunityRoutingModule } from './community-routing/community-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SharedCompanyModule } from '../companies/shared-company/shared-company.module';

import { CommunityConfigurationComponent } from './community-configuration/community-configuration.component';
import { CommunityListComponent } from './community-list/community-list.component';
import { CommunityAddEditComponent } from './community-add-edit/community-add-edit.component';

import { StaffListComponent } from './staff/staff-list/staff-list.component';
import { StaffEditComponent } from './staff/staff-edit/staff-edit.component';
import { StaffContactComponent } from './staff/staff-contact/staff-contact.component';
import { ContactUsModule } from '../contact-us/contact-us.module';
import { PairDeviceComponent } from '../pair-device/pair-device.component';
import { MatSelectModule, MatPaginatorModule, MatSortModule, MatTableModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ConfigDetailComponent } from './community-configuration/community-configuration-detail/config-detail.component';
import { CommunityOfficeInfoComponent } from './community-configuration/community-office-info/community-office-info.component';
import { CommunityOfficeInformationAddEditComponent } from './community-office-information-add-edit/community-office-information-add-edit.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MaskPhoneNumberPipe } from '../pipes/maskPhoneNumber.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CommunityRoutingModule,
    MaterialModule,
    SharedModule,
    SharedCompanyModule,
    ContactUsModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    AngularSvgIconModule
  ],
  exports: [
    CommunityRoutingModule,
    ConfigDetailComponent,
    CommunityOfficeInfoComponent
  ],
  declarations: [
    CommunityConfigurationComponent,
    CommunityListComponent,
    StaffListComponent,
    StaffEditComponent,
    StaffContactComponent,
    PairDeviceComponent,
    ConfigDetailComponent,
    CommunityOfficeInfoComponent,
    CommunityOfficeInformationAddEditComponent,
    MaskPhoneNumberPipe,
  ],
  bootstrap: [
    CommunityAddEditComponent,
    StaffEditComponent
  ],
})
export class CommunityModule { }
