import { Injectable, Inject } from '@angular/core';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { PackagesStatus } from '../model/packages-status';

@Injectable({
  providedIn: 'root'
})
export class PackageStatusService extends AbstractDataProviderService<PackagesStatus> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/packageStatus', http, appConfig);
  }
}
