import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { StaffContact } from '../../model/staff-contact';
import { Subscription, forkJoin } from '../../../../node_modules/rxjs';
import { StaffService } from '../../services/staff.service';
import { PictureService } from '../../services/picture.service';
import { stringHelper } from '../../helpers/stringHelper';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';

@Component({
  selector: 'app-contacts-widget',
  templateUrl: './contacts-widget.component.html',
  styleUrls: ['./contacts-widget.component.css']
})
export class ContactsWidgetComponent implements OnInit, OnDestroy {
  officeInformation: StaffContact[] = [];
  amenities: StaffContact[] = [];
  managementContacts: StaffContact[] = [];
  loading = false;
  imageSrc = 'assets/images/photo-placeholder@2x.png';
  communityId: any;
  noRecords = true;

  protected componentSubscriptions: Subscription[] = [];
  
  private communitySelected = 0;
  protected managementCompanyIdSelected = 0;
  protected areaSelected = 0;
  protected peopleId: number[] = [];
  constructor(private staffService: StaffService,
    private pictureService: PictureService,
    private cdr: ChangeDetectorRef) {
    this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.componentSubscriptions.push(LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      this.noRecords = true;
      const community = LocalStorageHelper.getCommunitiesFromBreadcrumb();
      if (community != this.communityId) {
        this.communityId = community;
        this.loadStaff();
      }
    }));

    this.componentSubscriptions.push(LocalStorageHelper.getSelectedLocationBarNode().subscribe(data => {
      this.noRecords = true;
      if (data.length > 0) {
        if (this.communityId > 0) {
          this.loadStaff();
        }
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.componentSubscriptions && this.componentSubscriptions.length > 0) {
      this.componentSubscriptions.forEach(item => {
        item.unsubscribe();
      })
    }
  }

  loadStaff() {
    this.loading = true;
    this.staffService.getStaffContacts().subscribe(x => {
      this.managementContacts = x;
      if (this.managementContacts && this.managementContacts.length > 0) {
        this.managementContacts = this.managementContacts.filter(p => p.showInContacts === true);
        this.peopleId = this.managementContacts.map(c => c.peopleId);
        this.noRecords = this.managementContacts.length === 0;
        this.pictureService.GetProfilePictureByPeopleIdArray(this.peopleId).subscribe(pictures => {
          pictures.forEach(p => {
            if (p.pictureData && p.pictureData !== null) {
              let src: string;
              const reader = new FileReader();
              const blob = new Blob([stringHelper.base64ToArrayBuffer(p.pictureData)], { type: 'image/jpeg' });
              if (blob.size > 0) {
                const file = new File([blob], 'pic', { type: 'image/jpeg', lastModified: Date.now() });
                reader.onload = (event: any) => {
                  src = event.target.result;
                  var contact = this.managementContacts.filter(m => m.peopleId == p.peopleId)[0];
                  contact.picture = src;
                };
                reader.readAsDataURL(file);
              }
            }
          });
        });
      }
      this.loading = false;
    });

  }

  ngOnInit() {
    if (this.communityId > 0) {
      this.loadStaff();
    }
  }
}
