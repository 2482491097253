import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MassCommListComponent } from './mass-comm-list/mass-comm-list.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { MassCommunicationRoutingModule } from './mass-communication-routing.module';
import { MassCommAddEditComponent } from './mass-comm-add-edit/mass-comm-add-edit.component';
import { MassCommEmailComponent } from './mass-comm-email/mass-comm-email.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    CommonModule,
    FormsModule,
    MassCommunicationRoutingModule,
    QuillModule
  ],
  exports: [
    MassCommunicationRoutingModule,
  ],
  declarations: [
    MassCommListComponent,
    MassCommAddEditComponent,
    MassCommEmailComponent
  ]
})
export class MassCommunicationModule { }
