import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatSnackBar,
} from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';

/* Components */
import { CompanyAddComponent } from '../company-add/company-add.component';

/* Services */
import { PropertyManagmentCompanyService } from '../../services/property-managment-company.service';

/* Models */
import { PropertyManagementCompany } from '../../model/property-management-company';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { SiteHelper } from 'src/app/helpers/site-helper';


@Component({
  selector: 'app-property-management-company-add-edit',
  templateUrl: './property-management-company-add-edit.component.html',
  styleUrls: ['./property-management-company-add-edit.component.css']
})
export class PropertyManagementCompanyAddEditComponent implements  OnInit {

  public formPropertyManagementCompany: FormGroup;
  public model: PropertyManagementCompany;
  public loading: boolean;
  @ViewChild('companyComponent') companyComponent: CompanyAddComponent;

  constructor(private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private managementCompanyyService: PropertyManagmentCompanyService,
    private breadcrumbService: BreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private propertyManagmentCompaniesService: PropertyManagmentCompanyService,
    private router: Router,
    private dialog: MatDialog
) {
  this.loading =  true;
  this.formPropertyManagementCompany = this.fb.group({
  });
 }

  ngOnInit() {
    this.model = new PropertyManagementCompany();
    this.activatedRoute.params.subscribe((params: Params) => {
      const parent = params['parent'] as number;
      const id = params['id'] as number;
      if (parent && parent > 0) {
          this.model.parent = parent;
          this.initCompany();
      } else if (id) {
        this.propertyManagmentCompaniesService.getById(id).subscribe(mc => {
          this.model = mc;
          this.companyComponent.companyId = this.model.companyId;
          this.initCompany();
        });
      } else {this.initCompany(); }
      this.loading = false;
    });
  }

  initCompany() {
    this.companyComponent.watchInit().subscribe(r => {
      if (r) {
      this.companyComponent.initCompany();
      }
  });
  }

  Save(): void {
    if (this.formPropertyManagementCompany.status !== 'VALID' || this.companyComponent.formCompany.status !== 'VALID') {
      SiteHelper.markFormGroupTouched(this.companyComponent.formCompany);
      return;
    }
    this.companyComponent.model.pmc = this.model;
    this.companyComponent.saveCompany('PropertyManagementCompany').subscribe( Company => {
      this.goToList();
  });
  }

  goToList(): void {
    this.breadcrumbService.watchUpdated(true).subscribe();
    this.router.navigate(['app/management-company/list']);
  }

  cancel(): void {
    this.goToList();
  }
}


