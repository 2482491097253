import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '../../../node_modules/@angular/common/http';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { environment } from '../../environments/environment';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { catchError } from '../../../node_modules/rxjs/operators';
import { ArticleType } from '../model/article-type';
import { Observable, throwError } from '../../../node_modules/rxjs';
import { NotificationGroup } from '../model/notification-group';
import { Newsfeed } from '../model/newsfeed';

@Injectable({
  providedIn: 'root'
})
export class ThorService {

  public baseUrl: string;
  public authApiKey = false;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    if (AppConstants.EndpointNames.Thor in appConfig.endpoints) {
      const endpoint = appConfig.endpoints[AppConstants.EndpointNames.Thor];
      this.baseUrl = endpoint;
    } else {
      console.error('Invalid endpoint name passed to data provider.');
    }
  }
   /**
   * Builds a set of HTTP headers to send whenever a POST, PUT or PATCH request is issued.
   */
  protected buildPostHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();
    let postHeaders = new HttpHeaders();   
    postHeaders = postHeaders.set('Authorization', 'bearer ' + token);
    postHeaders = postHeaders.set('Content-Type', 'application/json');
    return postHeaders;
  }


  addNewsFeed(newsFeed: Newsfeed): Observable<number[]> {
    const eventName = 'ActiveOwnersEvent';
    return this.http.post<number[]>(this.baseUrl + '/Events/triggerevent/' + eventName,  newsFeed, { headers: this.buildPostHeaders()});
  }
  
  getArticlesTypes(): Observable<ArticleType[]> {
    const requestUrl = this.baseUrl + '/Notifications/articleTypes';
    return this.http.get<ArticleType[]>(requestUrl )
    .pipe(
      catchError(this.handleError)
    );
  }

  getGroups(): Observable<NotificationGroup[]> {
    const requestUrl = this.baseUrl + '/groups';
    return this.http.get<NotificationGroup[]>(requestUrl )
    .pipe(
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}`);
    }

    return throwError(
      `Connection error with the service. Code ${error.status}. {error.error.errorMessage}`);
  }

}