import {
  Component, Injector, OnDestroy, ChangeDetectorRef, AfterViewInit, AfterViewChecked, OnInit,
  AfterContentChecked
} from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Observable, Subscription, of } from 'rxjs';
import { BaseList } from '../../../../../src/app/building-blocks/base-list';
import { DeliveryOption } from '../../model/delivery-option';
import { CrudResponseModel } from '../../../../../src/app/model/crud-response-model';
import { DeliveryOptionService } from '../../services/delivery-option.service';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { delay, map, tap } from 'rxjs/operators';
import { CommonService } from '../../services/common.service';


const deliveryColumns = [
  'name',
  'description',
  'isActive',
  'actions'
];


@Component({
  selector: 'app-delivery-option-list',
  templateUrl: './delivery-option-list.component.html',
  styleUrls: ['./delivery-option-list.component.css']
})
export class DeliveryOptionListComponent extends BaseList<DeliveryOption> {
  public loading: boolean;

  constructor(
    public injector: Injector,
    private commonService: CommonService,
    private deliveryOptionService: DeliveryOptionService) {
    super(deliveryColumns, '/docs/deliveryOption', deliveryOptionService, 'deliveryOption', injector);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<DeliveryOption[]> {
    return this.deliveryOptionService.getAllPaged(pageIndex, pageSize);
  }

  protected getCount(): Observable<number> {
    return this.commonService.getCountByBreadcumd('Documents.DeliveryOption');
  }

  protected getById(id: number): Observable<DeliveryOption> {
    return this.deliveryOptionService.getById(id);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any { }

  addDeliveryOption(): void {
    this.router.navigateByUrl('/app/docs/delivery/' + 0 + '/edit');
  }

  editDeliveryOption(deliveryOptionId: number): void {
    this.router.navigateByUrl('/app/docs/delivery/' + deliveryOptionId + '/edit');
  }

}
