import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { PictureCommunity } from '../model/picture-community';

@Injectable({
  providedIn: 'root'
})
export class PictureCommunityService extends AbstractDataProviderService<PictureCommunity> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/picture_community', http, appConfig);
    }
}
