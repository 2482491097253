
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export class FileNode {
    children: FileNode[];
    filename: string;
    parent: string;
    type: any;
  }

  /** Flat node with expandable and level information */
  export class FileFlatNode {
    filename: string;
    type: any;
    level: number;
    expandable: boolean;
    parent: string;
  }

@Injectable()
export class FileDatabase {
  dataChange: BehaviorSubject<FileNode[]> = new BehaviorSubject<FileNode[]>([]);

  get data(): FileNode[] { return this.dataChange.value; }

  constructor() {
    this.initialize();
  }

  initialize() {
  }

}
