import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { forkJoin, Subject, Observable } from 'rxjs';
import { ResidentInfo } from 'src/app/model/resident';
import { RelationshipService } from 'src/app/services/relationship.service';
import { VisitorService } from 'src/app/services/visitor.service';
import { VisitorFullModel } from 'src/app/model/visitor';
import { VisitorsTimeLogService } from 'src/app/services/visitors-time-log.service';
import { VisitorsTimeLog } from 'src/app/model/visitors-time-log';
import { SearchResidentUnitsComponent } from '../../shared/search-resident-units/search-resident-units.component';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';

@Component({
  selector: 'app-cards-visitors',
  templateUrl: './cards-visitors.component.html',
  styleUrls: ['./cards-visitors.component.css']
})
export class CardsVisitorsComponent implements OnInit {
  @Input()
  public residents: ResidentInfo[] = [];
  private searchResident: SearchResidentUnitsComponent
  public showResidentInfo = true;
  public staffInfo = '';
  public isStaff = false;
  @Input() public loading = false;
  public areaId = 0;
  @Input() public areaName = '';
  @Input() public unitName = '';
  public bigFont: boolean;

  @Input() public relationshipId = null;
  public visitors: VisitorFullModel[] = [];
  public isEdit = false;
  public selectedVisitor = new Subject<VisitorsTimeLog>();

  @ViewChild('searchResident') set contentSearch(content: SearchResidentUnitsComponent) {
    this.searchResident = content;
    if (this.searchResident) {
      this.searchResident.residentSelectionChangeEmit().subscribe(p => {
      this.populateVisitors();
      });
    }
  }
  constructor(
    private visitorsService: VisitorService,
    private cdr: ChangeDetectorRef,
    private visitorsTimeLogService: VisitorsTimeLogService) {
      this.setBigFontFromStorage();

    }

  ngOnInit() {
  }
  newVisitor() {
    this.selectedVisitor.next(null);
  }
  populateVisitors() {
    //this.loading = true;
    const groups = Array.from(new Set(this.residents.map((item: any) => item.relationshipId)));
    this.visitorsService.getByRelationshipIdArray(groups).subscribe(r => {
      this.visitors = r;
      this.loading = false;
    });
  }

  setBigFontFromStorage() {
    const aux = LocalStorageHelper.getBigFontFlag();
    if (aux !== null) {
      this.bigFont = aux;
    }
  }
  displayFn(visitor: any) {
    return visitor.firstName + ' ' + visitor.lastName;
  }

  
  onClick(visitor: VisitorFullModel, id: any) {
    if (visitor) {
      if (visitor.visitorsTimeLogId && visitor.visitorsTimeLogId !== 0) {
        this.loading = true;
        this.visitorsTimeLogService.getById(visitor.visitorsTimeLogId).subscribe(p => {
          this.selectedVisitor.next(p);
          this.loading = false;
          });
      } else if (visitor.visitorId && visitor.visitorId !== 0) {
        this.loading = true;
        const visitorTimeLog = {
          relationshipId: visitor.relationshipId,
          communityId: visitor.communityId,
          firstName: visitor.firstName,
          lastName: visitor.lastName,
          visitorId: visitor.visitorId,
          visitorTypeId: visitor.visitorTypeId
        } as VisitorsTimeLog;
          this.selectedVisitor.next(visitorTimeLog);
          this.loading = false;
      }
    }
  }

 
  public visitorSelectionChangeEmit(): Observable<VisitorsTimeLog> {
    return this.selectedVisitor.asObservable();
  }

}
