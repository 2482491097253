export class SignaturePad {
    clientToken: string;
    title: string;
    callback: string;
    callbackPayload: any;
}

export class CallbackPayload {
    temporaryFileId: number;
    communityId: number;
    communityName: string;
    residentId: number;
    residentName: string;
    hotPackages: number[];
    notes: string;
    recipientName: string;
    sameAsDestinatary: number;

}
