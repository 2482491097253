import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../app.config';
import * as AppConstants from '../app-constants';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { DynamicMenu } from '../model/dynamic-menu';

@Injectable({
  providedIn: 'root'
})
export class DynamicMenuService extends AbstractDataProviderService<DynamicMenu> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.MenuService, '/menus', http, appConfig);
  }

  getTest(): Observable<DynamicMenu> {
    return this.http.get<DynamicMenu>(this.baseUrl + '/test', { headers: this.buildGetHeaders() });
  }

  //
}
