import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MassCommListComponent } from './mass-comm-list/mass-comm-list.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { MassCommAddEditComponent } from './mass-comm-add-edit/mass-comm-add-edit.component';
import { MassCommEmailComponent } from './mass-comm-email/mass-comm-email.component';

const routes: Routes = [ {
  path: '',
  children: [
    {
      path: '',
      component: MassCommListComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'edit/:id',
      component: MassCommAddEditComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'email/:id',
      component: MassCommEmailComponent,
      canActivate: [AuthGuardService]
    }
  ]
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MassCommunicationRoutingModule {
}
