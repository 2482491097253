import { Validators, FormArray } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { ValidatorHelper } from '../helpers/validator-helper';

export class PhoneNumber extends BaseForm {

    @FormField('phoneNumberId', [])
    phoneNumberId = 0;

    @FormField('number', ['', [Validators.required, ValidatorHelper.phoneNumber]])
    number = '';

    @FormField('phoneTypeId', [1, Validators.required])
    phoneTypeId = 1;

    @FormField('isPreferred', [false, Validators.required])
    isPreferred = false;

    isDeleted = false;
    originId = 2;
    /* The creation date of this record.
     */
    creationDate: Date = null;

    /**
     * The ID of the user that created this record.
     */
    creationUserId = 0;

    /**
     * The modification date of this record.
     */
    modificationDate: Date = null;

    /**
     * The ID of the user that last modified this record.
     */
    modificationUserId = 0;

    relationshipId = null;
}
