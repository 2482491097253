import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

import { LoginService } from '../services/login.service';
import { PeopleService } from '../services/people.service';
import { UserService } from '../services/user.service';

import { AccountDetailsComponent } from '../account/account-details/account-details.component';
import { SelectUserDialogsComponent } from '../app-dialogs/select-user-dialogs/select-user-dialogs.component';
import { CompanyService } from '../services/company.service';
import { CommunityService } from '../services/community.service';
import { SiteHelper } from '../helpers/site-helper';
import { forkJoin, Observable } from 'rxjs';

import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { MenuService } from '../services/menu.service';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { UserImpersonated } from '../model/user-impersonated';
import { ThemeService } from '../services/theme.service';
import { Community } from '../model/community';

import { Newsfeed } from '../model/newsfeed';
import { NewsfeedService } from '../services/newsfeed.service';
import { map } from 'rxjs/operators';
import { LoggedUser } from '../model/logged-user';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public collapse: boolean;
  public userInfo: string;
  public userInfoImpersonate: string;
  public userPicture: string;
  public companyLogo: string;
  public companyInfo: string;
  public hasPhoto = false;
  public hasLogo = false;
  public impersonate = false;
  public title = 'Select user to impersonate';
  public titleButton = 'Impersonate';
  public isCommunity: boolean;
  public version: string;
  public allowImpersonate = false;

  @Input() sideNav: SideNavComponent;

  constructor(private _router: Router,
     private _loginService: LoginService,
     private peopleService: PeopleService,
     private companyService: CompanyService,
     private communityService: CommunityService,
     private userService: UserService,
     private menuService: MenuService,
     private themeService: ThemeService,
     private dialog: MatDialog) {
     this.collapse = false;
  }

  logout(): void {
    if (LocalStorageHelper.getImpersonatedFlag()) {
      this.userService.clearImpersonate().subscribe();
    }
    this._loginService.logout();
    this._router.navigateByUrl('/login');
  }

  account(): void {
    this.openDialog();
  }

  impersonateUser() {
    if (this.impersonate) {
      this.sideNav.loading = true;
      this.userService.clearImpersonate().subscribe( i => {
        LocalStorageHelper.setImpersonatedFlag(false); // Cleaning General Impersonated User Flag
        this.reloadUserInfo();
        this.impersonate = false;
     });
    } else {
      this.openDialogUsers();
    }
  }

  openDialogUsers(): void {
    const dialogRef = this.dialog.open(SelectUserDialogsComponent, {
      data: { title: this.title , titleButton: this.titleButton},
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.sideNav.loading = true;
        this.userService.impersonate(result.data.user).subscribe( u => {
           this.impersonate = u !== null && u.success;
           LocalStorageHelper.setImpersonatedFlag(this.impersonate); // Setting General Impersonated User Flag
           this.reloadUserInfo(u);
        });
      }
    });
  }

  reloadUserInfo(user?: UserImpersonated) {
    const userName = user ? user.username : LocalStorageHelper.getUsername();
    this.userInfoImpersonate = user ? user.firstName + ' ' + user.lastName : '';
    const menuObservable = this.menuService.getMenusByAppName('GEWEB');
    const paymentInfoObservable = this.peopleService.getPaymentInfo(userName);
    const loggerUserObservable = this.userService.getLoggedUserInfo();
    forkJoin(
      menuObservable,
      paymentInfoObservable,
      loggerUserObservable).subscribe(results => {
       LocalStorageHelper.setBreadcrumbInfo(null);
      LocalStorageHelper.setUserMenu(results[0][0]);
      this.sideNav.populateMenu(); // reload menu
      LocalStorageHelper.setPaymentInfo(results[1]);
      LocalStorageHelper.setLoggedUserInfo(results[2], true); // Setting ImpersonatedLoggedUserInfo
      LocalStorageHelper.setWatchLoggetUserInfo();
      this._router.navigate(['/']);  
      this.themeService.setCurrentTheme(SiteHelper.getDefaultTheme());
      this.sideNav.loading = false;  

    });

  }
  openDialog(): void {
   const dialogRef = this.dialog.open(AccountDetailsComponent, {
      width: '800px',
      data: {isImpersonated : this.impersonate},
      panelClass:  SiteHelper.getDefaultTheme()
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  fire(target) {
    target.dispatchEvent(new Event('collapse', { bubbles: true }));
  }

  ngOnInit() {
    const userInfo = LocalStorageHelper.getLoggedUserInfo(false);
    this.allowImpersonate = userInfo.isCommunityStaff || userInfo.isManagementCompanyStaff || userInfo.isGridStaff;
    this.peopleService.setUserPicture();
    this.setUserInfo();
    this.setCommunityInfo();
    this.menuService.sidePanelState.subscribe(d => {
      this.collapse = d;
    });
  }

  setUserInfo(): void {
    this.peopleService.getUserInfo().subscribe( userInfo => {
        this.userInfo = userInfo;
    });

    const userImpersonate = LocalStorageHelper.getImpersonatedFlag();
    if (userImpersonate) {
      this.impersonate = true;
      LocalStorageHelper.setImpersonatedFlag(this.impersonate); // Setting General Impersonated User Flag
      this.allowImpersonate = true;
      this.reloadUserInfo();
    }

    this.peopleService.currentUserPicture.subscribe( userPicture => {
      this.userPicture = userPicture;
      this.hasPhoto = (userPicture !== null && userPicture !== undefined && userPicture !== '');
    });
  }

  setCommunityInfo(): void {
    this.companyService.currentCompanyLogo.subscribe(logo => {
      this.companyLogo = logo;
      this.hasLogo = (logo !== null && logo !== undefined);
    });
    this.companyService.currentCompanyInfo.subscribe(info => {
      this.companyInfo = info;
    });
  }

  navigateToPreferences(tabName: string) {
    this._router.navigate(['app/profile' , tabName]);
  }
}

