import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ResidentSearch } from 'src/app/model/resident-search';
import { PeopleService } from 'src/app/services/people.service';
import { FormBuilder } from '@angular/forms';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { MatAutocomplete, MatDialog } from '@angular/material';

@Component({
  selector: 'app-search-resident',
  templateUrl: './search-resident.component.html',
  styleUrls: ['./search-resident.component.css']
})
export class SearchResidentComponent implements OnInit , AfterViewInit {
  public residentSearch: ResidentSearch[] = [];
  private loading = false;
  private isLoading = false;
  private isLoadingPackage = false;
  @ViewChild('filter') input: ElementRef;
  @ViewChild('residentSelect') residentSelect: MatAutocomplete;
  public selectedPeople = new Subject<ResidentSearch>();
  constructor(private peopleService: PeopleService,
    private fb: FormBuilder,
    private dialog: MatDialog) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(200),
          tap(() => this.isLoading = true),
          distinctUntilChanged(),
          switchMap((e: any) => {
            let val = '';
            if (e) {
              val = e.target.value;
            }
            if (val && val.length > 2) {
              return this.applyFilter(val || '');
            } else {
              this.isLoading = false;
              this.residentSearch = [];
              return new Observable<ResidentSearch[]>();
            }
          })
        ).subscribe(result => {
          this.residentSearch = result;
          this.isLoading = false;
        });
    }
  }

  applyFilter(filterValue: string): Observable<ResidentSearch[]> {
    return this.peopleService.getAllByFilter(filterValue);
  }
  displayFn(people?: ResidentSearch): string {
    return people && people.peopleId ? people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
      people.lastName + (people.address ? ' - ' + people.address : '') + (' - ' + people.unitName) : '';
  }

  onResidentSelectionChange(people: ResidentSearch) {
    this.loading = true;
      this.input.nativeElement.value = '';
      this.loading = false;
      this.selectedPeople.next(people);
  }

  public residentSelectionChangeEmit(): Observable<ResidentSearch> {
    return this.selectedPeople.asObservable();
  }

}
