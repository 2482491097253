import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from '../../../../src/app/app.config';
import { Bundle } from '../model/bundle';
import { AbstractDataProviderService } from '../../../../src/app/services/abstract-data-provider.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CrudResponseModel } from '../../../../src/app/model/crud-response-model';
import * as AppConstants from '../../../../src/app/app-constants';
import { LocalStorageHelper } from '../../../../src/app/helpers/local-storage-helper';
import { BundlePricePlan } from '../model/bundlePricePlan';
import { LevelFilter } from 'src/app/model/breadcrumb';

@Injectable({
  providedIn: 'root'
})

export class BundleService extends AbstractDataProviderService<Bundle> {

  private userInfo = new BehaviorSubject(LocalStorageHelper.getUserInfo());
  currentUser = this.userInfo.asObservable();

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.Thorin, '/bundle', http, appConfig, LevelFilter.Community);
  }

  // getBundlesPaginated(pageIndex: number, pageSize: number, filter?: string): Observable<Bundle[]> {
  //   const queryString = '/allBundles/' + pageIndex.toString() + '/' + pageSize.toString() + (filter ? '/' + filter : '');
  //   return this.http.get<Bundle[]>(this.baseUrl + queryString, { headers: this.buildPostHeaders() });
  // }

  getBundlesById(id: number): Observable<BundlePricePlan> {
    return this.http.get<BundlePricePlan>(this.baseUrl + '/getById/' + id.toString(), { headers: this.buildGetHeaders() });
  }

  addBundle(entity: BundlePricePlan): Observable<CrudResponseModel> {
    return this.http.post<CrudResponseModel>(this.baseUrl + '/add', entity, { headers: this.buildPostHeaders() });
  }

  updateBundle(entity: BundlePricePlan): Observable<null> {
    return this.http.put<null>(this.baseUrl + '/update', entity, { headers: this.buildPostHeaders() });
  }

  getAllPaged(pageIndex: number, pageSize: number): Observable<Bundle[]> {
    this.setBreadcrumbParams();
     const queryString = '/all/' + pageIndex.toString() + '/' + pageSize.toString() +
     '?mcId=' + this.searchParamsBreadcrumb.propertyManagementCompanyId + '&communityId=' + this.searchParamsBreadcrumb.communityId;
    return this.http.get<Bundle[]>(this.baseUrl + queryString ,  { headers: this.buildGetHeaders() });
  }

  // getBundlesByMCAndCommunityId(propertyManagementCompanyId: number, communityId: number): Observable<Bundle[]> {
  //   return this.http.get<Bundle[]>(this.baseUrl + '/getByCompanyAndCommunity/' +
  //   '?mcId=' + propertyManagementCompanyId + '&communityId=' + communityId, { headers: this.buildGetHeaders() });
  // }

}
