export class Address {
    addressId: number;
    addressTypeId: number;
    line1: string;
    line2: string;
    number: number;
    apt: string;
    zipCodeId: number;
    isDeleted: boolean;
    isMailing: boolean;
    creationDate: Date;
    creationUserId: number;
    modificationDate: Date;
    modificationUserId: number;
}
