import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { PropertyManagementCompany } from '../model/property-management-company';
import { Observable } from 'rxjs';
import { LevelFilter } from '../model/breadcrumb';
import { map } from 'rxjs/operators';
import { CountResultModel } from '../model/count-result-model';

@Injectable({
  providedIn: 'root'
})
export class PropertyManagmentCompanyService extends AbstractDataProviderService<PropertyManagementCompany> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/propertyManagementCompany', http, appConfig, LevelFilter.PropertyManagementCompany);
  }

  getAllTreePaginated(pageIndex: number, pageSize: number, filter? : string): Observable<PropertyManagementCompany[]> {
    this.setBreadcrumbParams();
    const endpoint = filter ? '/allPagedFiltered/' : '/allPaged/';
    let queryString = endpoint + pageIndex.toString() + '/' + pageSize.toString()
    + (filter ? '/' + filter : '');
    return this.http.get<PropertyManagementCompany[]>(this.baseUrl + queryString ,  { headers: this.buildGetHeaders() });
  }

  getCountByParams(filter?: string): Observable<number> {
    this.setBreadcrumbParams();
    const endpoint = filter ? '/getCountPMCFilter' : '/getCountPMC';
    let queryString = endpoint + (filter ? '/' + filter : '');
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }

  getAllFiltered(name?: string): Observable<PropertyManagementCompany[]> {
    let queryString = '';
    queryString = '/allByName/' + name ;
    return this.http.get<PropertyManagementCompany[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }
}

