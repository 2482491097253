import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

import { LoginService } from '../services/login.service';
import { ServiceError } from '../model/service-error';
import { ApiResponse } from '../model/api-response';
import { SiteHelper } from '../helpers/site-helper';

@Component({
  selector: 'app-confirm-forgot-password',
  templateUrl: './confirm-forgot-password.component.html',
  styleUrls: ['./confirm-forgot-password.component.css']
})
export class ConfirmForgotPasswordComponent implements OnInit {

  @Input() username: string;

  public resetPasswordFormGroup: FormGroup;
  public resetPasswordFailed: boolean;
  public resetPasswordErrorMessage: string;
  public isUserDisabled: boolean;
  public isDisabled: boolean;
  public responseCode: string;
  public loading: boolean;

  constructor(private loginService: LoginService,
    private fb: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute) {
    this.resetPasswordFormGroup = fb.group({
      username: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
    this.responseCode = this.route.snapshot.paramMap.get('responseCode');
    this.isUserDisabled = this.responseCode && this.responseCode === '1';
  }

  ngOnInit() {
    this.username = this.route.snapshot.paramMap.get('username');
    this.resetPasswordFormGroup.get('username').setValue(this.username);
    if (this.isUserDisabled) {
      this.resetPasswordFormGroup.addControl('oldPassword', new FormControl('', Validators.required));
    } else {
      this.resetPasswordFormGroup.addControl('vCode', new FormControl('', Validators.required));
    }
  }

  resetPassword() {
    if (this.resetPasswordFormGroup.status !== 'VALID') {
        SiteHelper.markFormGroupTouched(this.resetPasswordFormGroup);
        return;
    }
    this.isDisabled = false;
    this.resetPasswordErrorMessage = '';
    const un = this.resetPasswordFormGroup.get('username').value;
    const vc = !this.isUserDisabled ? this.resetPasswordFormGroup.get('vCode').value : '';
    const np = this.resetPasswordFormGroup.get('newPassword').value;
    const cp = this.resetPasswordFormGroup.get('confirmPassword').value;
    const op = this.isUserDisabled ? this.resetPasswordFormGroup.get('oldPassword').value : '';

    if (np === cp) {
      this.loginService.resetPassword(un, vc, np,  op).subscribe((result: ApiResponse) => {
        if (result.success) {
          this.snackbar.open('Your password was reset correctly, redirecting to login page.', '', { duration: 4000 });
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 4000);
          this.isDisabled = false;
        } else {
          this.isDisabled = false;
          this.resetPasswordFailed = true;
          this.resetPasswordErrorMessage = result.description;
        }
      }, (error: ServiceError) => {
        this.isDisabled = false;
        this.resetPasswordFailed = true;
        this.resetPasswordErrorMessage = error.errorInfo.description;
      });
    } else {
      this.isDisabled = false;
      this.resetPasswordFailed = true;
      this.resetPasswordErrorMessage = 'Passwords are not the same.';
    }
  }
}
