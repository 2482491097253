import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { PricePlanRelationshipTypeFilter } from '../model/price-plan-relationshipType-filter';
import { Observable, Subscription, forkJoin } from 'rxjs';
import {
  MatAutocomplete, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA,
  MatAutocompleteSelectedEvent, MatChipInputEvent
} from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CommonService } from '../services/common.service';
import { startWith, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { PricePlanCommunityFilter } from '../model/price-plan-community-filter';

@Component({
  selector: 'app-community-dialog',
  templateUrl: './community-dialog.component.html',
  styleUrls: ['./community-dialog.component.css']
})
export class CommunityDialogComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  relTypeCtrl = new FormControl();
  comCtrl = new FormControl();
  filteredCom: Observable<PricePlanCommunityFilter[]>;
  community: PricePlanCommunityFilter[] = [];
  allComm: PricePlanCommunityFilter[] = [];
  comIncluded: PricePlanCommunityFilter[] = [];
  comInPriceP: PricePlanCommunityFilter[] = [];
  newCommunity: PricePlanCommunityFilter[] = [];
  @ViewChild('comInput') comInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoC') matAutocomplete: MatAutocomplete;
  @ViewChild('chipListC') chipListC;

  public pricePlanId: number;
  public loading: boolean;
  private sub: Subscription;
  public editedBefore: number;
  public editedAfter: number;

  constructor(public dialogRef: MatDialogRef<CommunityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService) {
    this.filteredCom = this.comCtrl.valueChanges.pipe(
      startWith(null),
      map((c: string | null) => c ? this._filterCommunity(c) : this.allComm.slice()));
  }

  ngOnInit() {
    this.loading = true;
    this.getObservables();

  }

  getObservables() {
    this.sub = this.route.paramMap.subscribe(
      params => {
        const comObservable = this.commonService.getCommunitybyMcId(this.data.mcIdSelected, false);
        forkJoin(comObservable)
          .subscribe(results => {
            this.allComm = results[0];
            this.initDialog();
            this.loading = false;
          });
      });
  }

  initDialog() {
    if (this.data.update) {
      this.pricePlanId = this.data.pricePlanId;
      this.comIncluded = this.data.comInPriceP.slice();

      this.communitiesAdded();
    } else {
      if (this.data.comInPriceP) {
        this.communitiesAdded();
      }
    }
    if (this.data.details) {
      this.setMatChips();
    }

    // deletes matchips from dropdown if included
    this.comInPriceP.forEach((el) => {
      const idx = this.allComm.findIndex(c => c.communityId === el.communityId);
      if (idx > -1) {
        this.allComm.splice(idx, 1);
      }
    });

    if (this.community.length === 0) {
      this.chipListC.errorState = true;
    }
  }

  setMatChips() {
    // set chips on top just when opening on details model
    this.data.comDetails.forEach((el) => {
      this.addSelectedCommunity(null, el, el.name);
      this.removeDuplicate(el);

    });
  }

  communitiesAdded() {
    this.data.comInPriceP.forEach((el) => {
      this.comInPriceP.push(el);
    });
  }

  // addOnBlur(event: MatChipInputEvent): void {
  //   // Add only when MatAutocomplete is not open
  //   // To make sure this does not conflict with OptionSelected Event
  //   if (!this.matAutocomplete.isOpen) {
  //     const input = event.input;
  //     // Reset the input value
  //     if (input) {
  //       input.value = '';
  //     }
  //     this.comCtrl.setValue(null);
  //   }
  // }

  addSelectedCommunity(event: MatAutocompleteSelectedEvent, c: PricePlanCommunityFilter, name?: string): void {
    const passedValue = name ? name.trim() : event.option.viewValue.trim();
    this.comInput.nativeElement.value = '';

    this.allComm.forEach((el, index) => {
      if (el.name.trim() === passedValue) {
        this.allComm.splice(index, 1);
      }
    });

    this.community.push(c);
    this.newCommunity.push(c);
    if (this.community.length > 0) {
      this.chipListC.errorState = false;
    }
    this.updateCommunityDropdown();
    if (event != null) {
      event.option.value = '';
    }
    this.comCtrl.setValue(null);
  }

  private updateCommunityDropdown() {
    this.filteredCom = this.comCtrl.valueChanges.pipe(
      startWith(null),
      map((c: string | null) => c ? this._filterCommunity(c) : this.allComm.slice()));
  }

  removeCommunity(c: PricePlanCommunityFilter): void {
    const index = this.community.indexOf(c);

    if (index >= 0) {
      this.community.splice(index, 1);
      this.removeFromUInPP(c);
      this.allComm.push(c);
      this.removeNewComunity(c);
      if (this.community.length === 0) {
        this.chipListC.errorState = true;
      }
      this.updateCommunityDropdown();
    }
  }

  removeNewComunity(com: PricePlanCommunityFilter) {
    const index = this.newCommunity.findIndex(c => c.communityId === com.communityId);
    if (index > -1) {
      this.newCommunity.splice(index, 1);
    }
  }

  removeFromUInPP(u: PricePlanCommunityFilter): void {
    const idx = this.comInPriceP.findIndex(r => r.communityId === u.communityId);
    if (idx > -1) {
      this.comInPriceP.splice(idx, 1);
    }
  }

  private _filterCommunity(value: string): PricePlanCommunityFilter[] {
    if (typeof value === 'object') {
      value = value['name'];
    }
    let filterValue = value; // .toLowerCase();
    if (filterValue) { filterValue = filterValue.toLowerCase(); }
    return this.allComm.filter(u => u.name.toLowerCase().includes(filterValue));
  }

  removeDuplicate(c: PricePlanCommunityFilter): void {
    const index = this.comInPriceP.indexOf(c);

    if (index >= 0) {
      this.editedBefore = c.before;
      this.editedAfter = c.after;
      this.comInPriceP.splice(index, 1);
    }
  }

  addToModel(community) {
    community.forEach((c) => {
      let model = new PricePlanCommunityFilter();
      if (community) {
        model = {
          pricePlanCommunityFilterId: 0,
          pricePlanId: this.pricePlanId,
          communityId: c.communityId,
          name: c.name,
          before: this.data.details ? this.editedBefore : this.data.counter,
          after: this.data.details ? this.editedAfter : this.data.counter,
        };
        this.comInPriceP.push(model);
      }
    });
  }

  onSubmitFilters(): void {
    this.addToModel(this.newCommunity);
    this.dialogRef.close({
      dirty: true,
      update: true,
      comInPriceP: this.comInPriceP
    });
  }

  onCancelFilters(): void {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '750px'
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.data.comInPriceP = [];
        this.comInPriceP = [];
        this.dialogRef.close({
          dirty: false,
          comInPriceP: this.comIncluded
        }
        );
      }
    });
  }

}
