// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import * as AppConstants from '../app/app-constants';

export const environment = {
  production: false,
  defaultDomain: 'gridenterprisetest.com',
  endpoints: {
    'C3PO': 'https://c3po.gridenterprisetest.com/api',
    'gridVendor': 'https://gringotts.gridenterprisetest.com/api',
    'menuService': 'https://menu.gridenterprisetest.com/api',
    'sortingHat': 'https://sortinghat.gridenterprisetest.com/api',
    'security': 'https://sec.gridenterprisetest.com/api',
    'tardis': 'https://tardis.gridenterprisetest.com/api',
    'notification': 'https://notifications.gridenterprisetest.com/api/notifications',
    'smaug': 'https://smaug.gridenterprisetest.com/api',
    'search': 'https://searchservice.gridenterprisetest.com/api',
    'newsfeed': 'https://newsfeed.gridenterprisetest.com/api',
    'pensieve': 'https://pensieve.gridenterprisetest.com/api',
    'thor': 'https://thor.gridenterprisetest.com/api',
  },
  apiKeys: {
    Smaug : 'F7F85f9ACPlIhPjJ2+fXAao9EbzGMqsqjIJVXrJfSq4=',
    C3PO : 'ADmEpdg0zEeJ7VjeWSgDOp63qxxYo6l3LYltDRYCss0',
    Gingotts : 'ADmEpdg0zEeJ7VjeWSgDOp63qxxYo6l3LYltDRYCss0',
    Pensieve : 'gFs03YTa+jVOi+2MGpmiZBGAwinXFi4ZuwFmu9cQLU0='
   },
  firebaseConfig: {
    apiKey: "AIzaSyDk8GIWgkuq5V72sIX4o7E9v9zxwf4ZPSA",
    authDomain: "gridenterprisetest.firebaseapp.com",
    databaseURL: "https://gridenterprisetest.firebaseio.com",
    projectId: "gridenterprisetest",
    storageBucket: "gridenterprisetest.appspot.com",
    messagingSenderId: "155785512408"
  },
  apiKeyGoogleMaps: 'AIzaSyA3Jo3ZkCJUbyBdlnXmk9ZJVb9eYlHtGvc',
  messagingPublicKey: 'BN0NHthXWzIxBxHex6UVlweX7PIHriEqYVz_f5Dp5LX3I1DDC1gUISFxrEYca-M_p0qXPRTCpBUl1ELq5NNSHhk',
  snsApplicationName: 'GridEnterpriseTest',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
