import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subject, Observable, fromEvent } from 'rxjs';
import { People } from 'src/app/model/people';
import { PeopleService } from 'src/app/services/people.service';
import { LevelFilter } from 'src/app/model/breadcrumb';
import { PeopleEditComponent } from 'src/app/people/people-edit/people-edit.component';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { VisitorService } from 'src/app/services/visitor.service';
import { VisitorFullModel } from 'src/app/model/visitor';

@Component({
  selector: 'app-search-people',
  templateUrl: './search-people.component.html',
  styleUrls: ['./search-people.component.css']
})
export class SearchPeopleComponent implements OnInit {

  @Input() disabledInput: boolean;
  public selectedPeople = new Subject<People>();
  private isLoading = false;
  private peopleSearch: People[] = [];
  private peopleVisitorSeacrh: VisitorFullModel;
  private loading: boolean;
  @ViewChild('filter') input: ElementRef;
  public selectTedPeople = '';
  private people: People;

  constructor(private peopleService: PeopleService,
    private visitorService: VisitorService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.isLoading = false;
    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(200),
          tap(() => this.isLoading = true),
          distinctUntilChanged(),
          switchMap((e: any) => {
            let val = '';
            if (e) {
              val = e.target.value;
            }
            if (val && val.length > 2) {
              return this.applyFilter(val || '');
            } else {
              this.isLoading = false;
              this.peopleSearch = [];
              return new Observable<People[]>();
            }
          })
        ).subscribe(result => {
          this.peopleSearch = result;
          this.isLoading = false;
        });
    }
  }
  protected applyFilter(filterValue: string): Observable<People[]> {
    this.peopleService.filterLevel = LevelFilter.None;
    return this.peopleService.getAllPaginated(1, 100, filterValue);
  }
  public displayFn(people?: People): string {
    return people && people.peopleId ? people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
      people.lastName : '';
  }

  protected onPeopleSelectionChange(people: People) {
    this.selectTedPeople = this.displayFn(people);
    this.people = people;
    this.selectedPeople.next(people);
  }
  public peopleSelectionChangeEmit(): Observable<People> {
    return this.selectedPeople.asObservable();
  }

  newPeople() {
    this.onPeopleSelectionChange(null);
  }
  protected openAddForm() {
    const dialogRef = this.dialog.open(PeopleEditComponent, {
      width: '1150px',
      height: '600px',
      data: { isDialog: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined' && result.valid === true && result.peopleId !== 0) {
        this.loading = true;
        this.peopleService.getById(result.peopleId).subscribe(p => {
          this.onPeopleSelectionChange(p);
          this.loading = false;
        });
      }
    });
  }

}
