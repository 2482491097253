import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { LocationOffice } from 'src/app/model/locationOffice';
import { OfficeInformationWidgetComponent } from 'src/app/shared/office-information-widget/office-information-widget.component';

const enum OFFICE_STATUS {
    LOADING,
    HASOFFICE,
    NOOFFICE
}

@Component({
    selector: 'community-office-info',
    templateUrl: './community-office-info.component.html',
    styleUrls: ['./community-office-info.component.scss']
})

export class CommunityOfficeInfoComponent {
    @ViewChild('officeInformation') officeInformation: OfficeInformationWidgetComponent;
    offices: LocationOffice[] = [];
    hasOffices: number = OFFICE_STATUS.LOADING;

    constructor() { }

    ngOnInit() {
        this.officeInformation.$offices.subscribe(result => {
            if (typeof (result) == 'boolean') {
                if (!result) {
                    this.hasOffices = OFFICE_STATUS.NOOFFICE;
                }
            } else {
                this.offices = result;
                if (this.offices.length > 0) {
                    this.hasOffices = OFFICE_STATUS.HASOFFICE;
                } else {
                    this.hasOffices = OFFICE_STATUS.NOOFFICE;
                }
            }
        });
    }
}