import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { CommunitySettings } from '../../model/community-settings';
import { VisitorType } from '../../model/Visitor-type';
import { Subject, Observable, forkJoin } from '../../../../node_modules/rxjs';
import { PhotoCameraComponent } from '../../shared/photo-camera/photo-camera.component';
import { VisitorsTimeLog } from '../../model/visitors-time-log';
import { FormGroup, FormBuilder } from '../../../../node_modules/@angular/forms';
import { Vehicle } from '../../model/vehicle';
import { VehicleBrand } from '../../model/vehicle-brand';
import { CompanyGuests } from '../../model/company-guests';
import { Color } from '../../model/color';
import { VisitorTypeService } from '../../services/visitor-type.service';
import { ColorService } from '../../services/color.service';
import { VehicleBrandService } from '../../services/vehicle-brand.service';
import { CompanyGuestsService } from '../../services/company-guests.service';
import { TardisFileService } from '../../services/pensieve.service';
import { MatDialog } from 'material2/src/lib';
import { SiteHelper } from '../../helpers/site-helper';
import { SignaturePad } from '../../model/signature-pad';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { SignaturePadService } from '../../services/signature-pad.service';
import { FirebaseMessagingService } from '../../services/firebase-messaging.service';
import { CompanyAddComponent } from 'src/app/visitors/company-add/company-add.component';
import { RelationshipService } from '../../services/relationship.service';
import { MatCheckbox, MatSelect } from '@angular/material';
import { ResidentInfo } from '../../model/resident';
import { VisitorService } from '../../services/visitor.service';

@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.css']
})
export class GuestInfoComponent implements OnInit, AfterViewInit {

  @Input() public communitySettings: CommunitySettings = new CommunitySettings(null);
  public visitorTypes: VisitorType[] = [];
  public vehicleMarks: VehicleBrand[] = [];
  public years: number[] = [];
  public colors: Color[];
  public companies: CompanyGuests[] = [];
  public signature = '';
  public model: VisitorsTimeLog;
  public vehicle: Vehicle;
  @Input() public loading = false;
  public visitorFormGroup: FormGroup;
  public cameraInit = new Subject<boolean>();
  private camera: PhotoCameraComponent;
  temporaryFileId = 0;
  @Input() communityId: number;
  @Input() communityName: string;
  selectedVisitor: VisitorsTimeLog;
  showVisitorInfo = false;
  bigFont = true;
  @Input() areaId: any;
  @Input() areaName: any;
  @Input() unitName = '';
  @Input() relationshipId: any;

  @Input() isEdit: any;

  public parkingPass: MatCheckbox;
  public badge: MatCheckbox;
  @Input() public residents: ResidentInfo[] = [];
  public showResidentInfo = false;
  public isStaff = false;
  init = false;

  @ViewChild('camera') set content(content: PhotoCameraComponent) {
    this.camera = content;
    if (this.camera) {
      this.camera.startup();
      this.cameraInit.next(true);
    }
  }
  @ViewChild('parkinPass') set contentParkingPass(content: MatCheckbox) {
    this.parkingPass = content;
    if (this.parkingPass) {
      this.parkingPass.checked = this.communitySettings.requireVehicleParkingTicket;
      this.cdr.detectChanges();
    }
  }
  @ViewChild('badge') set contentBadge(content: MatCheckbox) {
    this.badge = content;
    if (this.badge) {
      this.badge.checked = this.communitySettings.requireVisitorsBadge;
      this.cdr.detectChanges();
    }
  }

  constructor(private fb: FormBuilder,
    private fbVehicle: FormBuilder,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private visitorTypeService: VisitorTypeService,
    private colorService: ColorService,
    private vehicleBrandService: VehicleBrandService,
    private companyGuestService: CompanyGuestsService,
    private tardisFileService: TardisFileService,
    private signaturePadService: SignaturePadService,
    private relationshipService: RelationshipService,
    private firebaseMessagingService: FirebaseMessagingService, ) {
    this.model = new VisitorsTimeLog(this.fb);
    this.visitorFormGroup = this.model.buildFormGroup();
    this.vehicle = new Vehicle(this.fbVehicle);
    this.visitorFormGroup.addControl('vehicleGroup', this.vehicle.buildFormGroup());
  }
  displayFn(visitor: any) {
    return visitor.firstName + ' ' + visitor.lastName;
  }


  populateResidents(p: any) {
    this.loading = true;
    this.residents = [];
    this.showResidentInfo = true;
    if (p.relationshipId != null) {
      this.relationshipId = p.relationshipId ;
      this.getRelationships(p.unitId);
     
    } 
  }

  
  getRelationships(unitId: number) {
    this.loading = true;
    forkJoin(this.relationshipService.getResidentsByUnit(unitId)).subscribe(result => {
      this.getResidentInfo(result[0]);
    });
  }

  getResidentInfo(residents: any[]) {
    this.areaId = residents[0].areaId;
    this.areaName = residents[0].areaName;
    this.unitName = residents[0].unitName;
    if (this.unitName && this.unitName.includes('Unit:') === true) {
      this.unitName = this.unitName.replace('Unit:', '');
    }
    this.loading = true;
    const groups = Array.from(new Set(residents.map((item: any) => item.relationshipId)));
      groups.forEach(re => {
        const resident = new ResidentInfo();
        const people = residents.filter(p => p.relationshipId === re)[0];
          resident.peopleId = people.peopleId;
          resident.relationshipId = people.relationshipId;
          resident.firstName = people.firstName;
          resident.lastName = people.lastName;
          this.residents.push(resident);
      }, this.loading = false);
  }

  
  ngAfterViewInit() {
  }

  onCameraInit(): Observable<boolean> {
    return this.cameraInit.asObservable();
  }

  
  ngOnInit() {
      this.setBigFontFromStorage();
  }

  onToggleChange() {
    this.bigFont = !this.bigFont;
    this.bigFont = this.bigFont;
    LocalStorageHelper.setBigFontFlag(this.bigFont);
  }

  initialize(visitorTimeLog?: any) {   
    if (!this.init) {
      this.init = true;
    if (this.communitySettings.vehicleYear) {
      const currentYear = new Date().getFullYear() + 1;
      for (let i = currentYear; i >= currentYear - 20; i--) {
        this.years.push(i);
      }
    }
    const observableArray: Observable<any>[] = [];
    if (this.communitySettings.visitorsType) {
      observableArray.push(this.visitorTypeService.getAll());
    }
    if (this.communitySettings.vehiclesEnabled && this.communitySettings.vehicleColor) {
      observableArray.push(this.colorService.getAll());
    }
    if (this.communitySettings.vehicleMaker) {
      observableArray.push(this.vehicleBrandService.getAll());
    }
    if (this.communitySettings.visitorsCompanyName) {
      observableArray.push(this.companyGuestService.getAll());
    }
    if (observableArray.length === 0) {
      this.loading = false;
    }
    if (observableArray.length === 0 && visitorTimeLog) {
      this.setModel(visitorTimeLog);
    }
    forkJoin(observableArray).subscribe(results => {
      let i = 0;
      if (this.communitySettings.visitorsType) {
        this.visitorTypes = results[i];
      }
      if (this.communitySettings.vehiclesEnabled && this.communitySettings.vehicleColor) {
        i++;
        this.colors = results[i];
      }
      if (this.communitySettings.vehicleMaker) {
        i++;
        this.vehicleMarks = results[i];
      }
      i++;
      this.companies = results[i];
      this.visitorFormGroup.controls['vehicleGroup'].updateValueAndValidity();
      this.visitorFormGroup.updateValueAndValidity();
      if (visitorTimeLog) {
        this.setModel(visitorTimeLog);
      } else {
        this.loading = false;
      }
    });
  }
  }
  setModel(p: any) {
    this.model.relationshipIdArray = [];
    this.model.fromObject(p);  
    if (!this.isEdit) {
      this.model.visitorsTimeLogId = 0;
      this.showResidentInfo = true;
    } else {
      this.isStaff = p.relationshipId === null;
      this.showResidentInfo  =  !this.isStaff;
    }
    if (p.relationshipId) {
      this.relationshipId = p.relationshipId; 
      this.model.relationshipIdArray.push(p.relationshipId);
      if (p.visitorsTimeLogId !== null) {
        this.relationshipService.getById(p.relationshipId).subscribe(u => {
          this.populateResidents(u);
          this.areaId = u.areaId;
          this.areaName = u.areaName;
          this.unitName = u.unitName;
        });       
      }
      this.loading = false;
    }
    //this.visitorTypes = this.visitorTypes.filter(v => v.isTiedToUnit === (p.relationshipId !== null));
   
    if (p.vehicleId) {
      this.vehicle.fromObject(p);
    }
    if (p.signature) {
      this.getFile(p.signature, 'signature');
    }
    if (p.picture) {
      this.getFile(p.picture, 'picture');
    }
    this.loading = false;
  }

  setImage(pic: any, type: string) {
    const reader = new FileReader();
    const blob = new Blob([new Uint8Array(pic)], { type: 'image/png' });
    if (blob.size > 0) {
      const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
      reader.onload = (event: any) => {
        if (type === 'signature') {
          this.signature = event.target.result;
          this.model.signature = this.signature;
          this.dialog.closeAll();
        } else if (type === 'picture') {
          if (this.camera !== undefined) {
            this.setPhotoCamera(event.target.result);
          }
          this.onCameraInit().subscribe(_ => this.setPhotoCamera(event.target.result));
        }
      };
      reader.readAsDataURL(file);
    }
  }
  setPhotoCamera(src: any) {
    this.camera.setPicture(src);
    this.model.picture = this.camera.src;
  }
  getFile(fileId: number, type: string) {
    if (fileId > 0) {
      this.loading = true;
      this.tardisFileService.getFile(fileId).subscribe(pic => {
        this.setImage(pic, type);
        this.loading = false;
      });
    }
  }

  requestSignature() {
    SiteHelper.openDialogAlert('Info', 'please wait while the signature is captured', this.dialog);
    this.loading = true;
    const signaturePad = new SignaturePad();
    signaturePad.clientToken = LocalStorageHelper.getClientToken();
    signaturePad.title = 'new visit';
    signaturePad.callback = 'sendNotification';
    signaturePad.callbackPayload = { Message: 'new request signature' };
    this.signaturePadService.signaturePad(signaturePad).subscribe(data => {
    },
      err => {
        this.dialog.closeAll();
        const error = err.status === 404 ? err.error.errorMessage : '';
        SiteHelper.openDialogAlert('Warning', 'An error ocurred on request signature. ' + error, this.dialog);
        this.loading = false;
      });
    this.firebaseMessagingService.onNewVisitSignatureSuccess().subscribe(data => {
      this.dialog.closeAll();
      if (data && data.TemporaryFileId !== undefined) {
        this.temporaryFileId = data.TemporaryFileId;
        this.getSignature(this.temporaryFileId);
      }
    });

  }

  getSignature(fileId: number) {
    this.tardisFileService.getFile(fileId).subscribe(pic => {
      const reader = new FileReader();
      const blob = new Blob([new Uint8Array(pic)], { type: 'image/png' });
      if (blob.size > 0) {
        const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
        reader.onload = (event: any) => {
          this.signature = event.target.result;
          this.loading = false;
        };
        reader.readAsDataURL(file);
      }
    });
  }

  validateVisitor(): boolean {
    if ((this.communitySettings.visitorsPicture && this.communitySettings.requireVisitorsPicture)
      && (this.camera.picture === null || this.camera.picture === undefined)) {
      SiteHelper.openDialogAlert('Warning', 'Please Take a Photo', this.dialog);
      return false;
    }
    if ((this.communitySettings.visitorsSignature && this.communitySettings.requireVisitorsSignature)
      && (this.signature === null || this.signature === '')) {
      SiteHelper.openDialogAlert('Warning', 'Please Request a Signature', this.dialog);
      return false;
    }
    if (!SiteHelper.isValid(this.visitorFormGroup)) {
      SiteHelper.markFormGroupTouched(this.visitorFormGroup);
      SiteHelper.openDialogAlert('Warning', 'Please check the required fields', this.dialog);
      return false;
    }
    return true;
  }

  getData(): FormData {
    const entity: any = this.model.toDto();
    entity.communityId = this.communityId;
    if (this.visitorFormGroup.controls['vehicleGroup'].valid && this.visitorFormGroup.controls['vehicleGroup'].dirty) {
      entity.hasVehicle = true;
      const entityVehicle = this.vehicle.toDto();
      entity.vehicle = entityVehicle;
    }
    if (entity.relationshipIdArray.length === 0 && this.relationshipId !== null) {
      entity.relationshipIdArray.push(this.relationshipId);
    }

    const form = this.model.toFormData(entity);

    if ((this.communitySettings.visitorsPicture) &&
      ((this.model.visitorsTimeLogId === null || this.model.visitorsTimeLogId === 0)
        || (this.model.visitorsTimeLogId > 0 && this.model.picture !== this.camera.src))) {
      form.append('picture', this.camera.picture);
    }
    if (entity.vehicle && entity.vehicle.decalExpirationDate) {
      const datestr = (new Date(entity.vehicle.decalExpirationDate)).toUTCString();
      form.delete('decalExpirationDate');
      form.append('decalExpirationDate', datestr);
    }

    if (entity.lastName === '' && entity.firstName === '') {
      form.set('firstName', this.visitorFormGroup.controls['firstName'].value.toString());
      form.set('lastName', this.visitorFormGroup.controls['lastName'].value.toString());
      const idType = this.visitorFormGroup.controls['visitorTypeId'].value;
      if (idType) {
        form.set('visitorTypeId', this.model.visitorTypeId.toString());
      }
    }

    if (this.communitySettings.visitorsSignature) {
      form.append('temporaryFileId', this.temporaryFileId.toString());
    }
    form.append('areaId', this.areaId.toString());
    form.append('areaName', this.areaName);
    form.append('unitName', this.unitName);
    form.append('communityName', this.communityName);
    form.append('PrintParkingPass', this.communitySettings.visitorsBadge ? this.parkingPass.checked.toString() : 'false');
    return form;
  }

  selectResident(value) {
    this.model.relationshipIdArray.push(value);
  }
  newVisitor() {
    this.visitorFormGroup.reset();
    this.visitorFormGroup.controls['vehicleGroup'].reset();
    this.cdr.detectChanges();
    this.camera.setDefault();
  }

  setBigFontFromStorage() {
    const aux = LocalStorageHelper.getBigFontFlag();
    if (aux !== null) {
      this.bigFont = aux;
    }
  }
  resetSignature() {
    this.signature = '';
  }
  addCompany() {
    this.openAddCompanyForm();
  }

  protected openAddCompanyForm() {
    const dialogRef = this.dialog.open(CompanyAddComponent, {
      width: '350px',
      height: '300px',
    });

    dialogRef.beforeClose().subscribe(result => {
      if (typeof result !== 'undefined' && result.valid === true) {
        this.loading = true;
        this.companyGuestService.getAll().subscribe(res => {
          this.companies = res;
          this.model.companyId = result.companyId;
          this.visitorFormGroup.controls['companyId'].setValue(result.companyId);
          this.loading = false;
        });
      }
    });
  }


}
