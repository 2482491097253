import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { StaffContact } from 'src/app/model/staff-contact';
import { StaffService } from 'src/app/services/staff.service';
import { Subscription } from 'rxjs';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';

@Component({
  selector: 'app-contact-us-dashboard',
  templateUrl: './contact-us-dashboard.component.html',
  styleUrls: ['./contact-us-dashboard.component.css']
})
export class ContactUsDashboardComponent implements OnInit, OnDestroy {
  officeInformation: StaffContact[] = [];
  amenities: StaffContact[] = [];

  managementContacts: StaffContact[] = [];
  loading = false;

  protected breadcrumbChanges: Subscription;
  private communitySelected = 0;
  protected managementCompanyIdSelected = 0;
  protected areaSelected = 0;

  constructor(
    private staffService: StaffService,
    private cdr: ChangeDetectorRef
  ) {
    this.checkParamns();
    this.breadcrumbChanges  = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      this.cdr.detectChanges();
      if (this.checkParamns()) {
        this.loading = true;
        this.loadStaff();
      }
    });
  }

  ngOnDestroy() {
    this.breadcrumbChanges.unsubscribe();
  }

  ngOnInit() {
    this.loadStaff();
  }

  loadStaff() {
    this.loading = true;
    this.staffService.getStaffContacts().subscribe(x => {
      this.managementContacts = x;
      if(this.managementContacts && this.managementContacts.length > 0)
      {
        this.managementContacts = this.managementContacts.filter(p=>p.showInContacts === true);
      }
      this.loading = false;
    });
    
  }

  checkParamns(): boolean {

    const mcLocalStorage = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    const areaLocalStorage = LocalStorageHelper.getBuildingFromBreadcrumb();

    if (this.managementCompanyIdSelected !== mcLocalStorage ||
      this.communitySelected !== comunityLocalStorage ||
      this.areaSelected !== areaLocalStorage) {
      this.managementCompanyIdSelected = mcLocalStorage;
      this.communitySelected = comunityLocalStorage;
      this.areaSelected = areaLocalStorage;
      return true;
    } else {
      return false;
    }
  }

}
