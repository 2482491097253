import { Component, EventEmitter, Injector, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatDialogConfig } from '@angular/material';

import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseList } from '../../building-blocks/base-list';
import { BaseListDialogData } from '../../building-blocks/base-list-dialog-data';

import { ResetPasswordDialogComponent } from '../../app-dialogs/reset-password-dialog/reset-password-dialog.component';
import { UsersAddEditDialogComponent } from '../users-add-edit-dialog/users-add-edit-dialog.component';

import { ApiResponse } from '../..//model/api-response';
import { CrudResponseModel } from '../../model/crud-response-model';
import { NewUser } from '../../model/new-user';
import { People } from '../../model/people';
import { ServiceError } from '../../model/service-error';
import { User } from '../../model/user';

import { LoginService } from '../../services/login.service';
import { RoleService } from '../../services/role.service';
import { UserService } from '../../services/user.service';
import { Community } from 'src/app/model/community';
import { EntityRing, AccessType } from 'src/app/model/access-type-ring';
import { UserRoleCompany } from 'src/app/model/user-role-company';
import { SiteHelper } from '../../helpers/site-helper';
import { FormControl } from '@angular/forms';


const userColumns = [
  'firstName',
  'lastName',
  'email',
  'actions'
];

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent extends BaseList<User> implements OnInit, AfterViewInit {
  public communities: Community[] = [];
  public userPermisions = new EntityRing();

  private cols = [
    {
      headerName: 'First Name',
      columnDef: 'firstName',
      cell: (user) => user.firstName,
      sortable: true
    },
    {
      headerName: 'Last Name',
      columnDef: 'lastName',
      cell: (user) => user.lastName,
      sortable: true
    },
    {
      headerName: 'User Name/Email',
      columnDef: 'email',
      cell: (user) => user.email,
      sortable: true
    }
  ];
  private rowActions = ['edit', 'delete', 'resetPassword'];
  private showFilter: boolean = false;
  private firstnameSearchControl: FormControl = new FormControl();
  private lastnameSearchControl: FormControl = new FormControl();
  private emailSearchControl: FormControl = new FormControl();

  constructor(
    public injector: Injector,
    private userService: UserService,
    private roleService: RoleService,
    private loginService: LoginService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef) {

    super(userColumns, '/security/user', userService, 'user', injector);
    this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.userPermisions.rings.push({ accessType: AccessType.Add, ringId: 559 });
    this.userPermisions.rings.push({ accessType: AccessType.Delete, ringId: 659 });
    this.userPermisions.rings.push({ accessType: AccessType.Update, ringId: 759 });
    this.entityRings.push(this.userPermisions);

  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<User[]> {
    return this.userService.getAllByParamsPaginated(pageIndex, pageSize, filter, 
      this.firstnameSearchControl.value, this.lastnameSearchControl.value, this.emailSearchControl.value, 
      this.dataSource.sortData);
  }

  protected getCount(): Observable<number> {
    return this.userService.getCountByParams(this.dataSource.filter, this.firstnameSearchControl.value, this.lastnameSearchControl.value, this.emailSearchControl.value);
  }

  protected openEditForm(isUpdate: boolean, model: User, emitter: EventEmitter<CrudResponseModel>): any {
    const dialogRef = this.dialog.open(UsersAddEditDialogComponent, {
      width: '900px',
      data: new BaseListDialogData<User>(isUpdate, model, emitter),
      disableClose: true,
      panelClass: [SiteHelper.getDefaultTheme()],
    });

    return dialogRef;
  }
  /** Overriden to implement further changes. */
  protected update(data: any, emitter: EventEmitter<CrudResponseModel>) {
    const entity = data.entity;
    this.aftefUpdate(entity, data).subscribe(_ => {
      this.loading = false;
      this.dataSource.reload();
      this.snackBar.open('The record has been successfully updated.', '', {
        duration: 2000
      });
    });
  }
  /** Overriden to implement further changes. */
  protected aftefUpdate(entity: User, data: any): Observable<any> {
    const roles: UserRoleCompany[] = data.roles;
    const people: People = data.people;
    const userRoleCompanies: UserRoleCompany[] = data.rolesCompanies;
    const linkObservables = [];
    people.userId = entity.userId;

    const rolesToAdd = userRoleCompanies.filter(c =>
      roles.filter(ro => ro.roleId === c.roleId && ro.companyId === c.companyId).length === 0);

    const rolesToWipe = roles.filter(c =>
      userRoleCompanies.filter(ro => ro.roleId === c.roleId && ro.companyId === c.companyId).length === 0);

    rolesToAdd.forEach(x => {
        linkObservables.push(this.userService.linkRoleToUser(x.roleId, entity.userId, x.companyId));
     });

    rolesToWipe.forEach(x => {
      linkObservables.push(this.userService.unlinkRoleFromUser(x.roleId, entity.userId, x.companyId));
    });
    this.reload();
    return forkJoin(linkObservables);
  }


  protected insert(data: any, emitter: EventEmitter<CrudResponseModel>) {

    const user: any = data.entity;
    const people: People = data.people;
    const roles: UserRoleCompany[] = data.rolesCompanies;
    const linkObservables = [];
    console.log(user);

    // 2019-03-05 - ppolcher: Users should not be created by using the registerUser method from
    // the user service. This endpoint is reserved for internal calls only. Use the addNewUser
    // method from the same service, instead.
    //
    // Additionally, refactor this code so that roles are passed to the backend and inserted as
    // part of the process. AVOID MULTIPLE CALLS TO THE BACKEND FOR OPERATIONS THAT SHOULD BE
    // ATOMIC.

   /* this.userService.addNewUser(new NewUser(user)).subscribe(insertResult => {
      roles.forEach(r => {
        linkObservables.push(this.userService.linkRoleToUser(r.roleId, insertResult.id, r.companyId));
      });
      forkJoin(linkObservables).subscribe();
    });*/
  }

  getDialogData(userId: number) {
    // A reference to this instance must be saved and passed to the
    // built function, so that it may be called from the dialog component.
    const closure = this;

    return () => {
      return forkJoin(
        closure.roleService.getByUserId(userId),
        closure.userService.getById(userId))
        .pipe(map(results => {
          return {
            update: true,
            user: results[1],
            roles: results[0]
          };
        }));
    };
  }

  resetPasswordDialog(userName: string): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { userName };

    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      user => {
        if (user != null) {
          this.loginService.resetPasswordByAdmin(user)
            .subscribe((result: ApiResponse) => {
              if (result.success) {
                this.snackbar.open(`Password reset for user ${user} initiated`, '', { duration: 2000 });
              } else {
                this.snackbar.open(`There was an error reseting the password for ${user}`, '', { duration: 2000 });
              }
            }, (error: ServiceError) => {
              console.log(error.errorInfo.description);
            });
        }
      }
    );
  }


  navigateUserAddEdit(id: number = 0) {
    if (id == 0) {
      this.router.navigate(['app/security/add']);
    } else {
      this.router.navigate(['app/security/edit', id]);
    }
  }


  searchStringParam: string;

  search(searchParameter: string) {
    this.searchStringParam = searchParameter;
    this.emailSearchControl.setValue(this.searchStringParam);
    this.applyFilter(this.searchStringParam);
  }

  applySort($event) {
    this.dataSource.sortData = $event.sortData;
    this.dataSource.reload();
  }

  applyAdvanceFilter() {
    this.searchStringParam = this.emailSearchControl.value;
    this.dataSource.reload();
  }

  clearFilters() {
    this.firstnameSearchControl.setValue('');
    this.lastnameSearchControl.setValue('');
    this.emailSearchControl.setValue('');
    this.searchStringParam = '';
    this.dataSource.filter = this.searchStringParam;
    this.dataSource.reload();
  }

  downloadPDFEvent(doc: any) {
    doc.save('grid-system-export.pdf');
  }

  downloadCSVEvent(directive: any, options: any) {
    directive.exportTable('csv', options);
  }
}

