export class PackageLog {
    packageLogId: number;
    since: string;
    packageStatus: string;
    isHot: boolean;
    people: string;
    community: string;
    unit: string;
    packageId: number;
    carrierName: string;
    relationshipId: number;
    receivedDate: string = null;
    trackNumber: string;
    statusChanged: string = null;
    notes: string;

    peopleId: number;
    unitId: number;
    recipientName = '';
    sender = '';
    fileId : number;
}
