import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Observable, of, Subject } from 'rxjs';
import { City } from '../model/city';

@Injectable({
  providedIn: 'root'
})
export class CityService extends AbstractDataProviderService<City> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/city', http, appConfig);
  }
  getByStateId(stateId: number): Observable<City[]> {
    return this.http.get<City[]>(this.baseUrl + '/byStateId/' + stateId, { headers: this.buildGetHeaders() });
  }

  getByPeopleId(peopleId: number): Observable<City[]> {
    return this.http.get<City[]>(this.baseUrl + '/byPeopleId/' + peopleId, { headers: this.buildGetHeaders() });
  }
}
