import { Package } from 'src/app/model/package';
import { AfterViewInit, Component, Inject, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatAutocomplete,
  MatSnackBarConfig,
} from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { ResidentSearch } from 'src/app/model/resident-search';
import { fromEvent, Observable, forkJoin, observable, Subject } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PeopleService } from 'src/app/services/people.service';
import { Carrier } from 'src/app/model/carrier';
import { CarrierService } from 'src/app/services/carrier.service';
import { PackagesService } from 'src/app/services/packages.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PackageAddDialogComponent } from '../com-package-add-dialog/package-add-dialog.component';
import { PackageAddComponent } from '../com-package-add/package-add.component';
import { SearchParamsBreadcrumb } from 'src/app/model/search-params-breadcrumb';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { HotPackagesService } from 'src/app/services/hot-packages.service';
import { CommunitySettingsService } from 'src/app/services/community-settings.service';
import { CommunitySettings } from 'src/app/model/community-settings';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { CustomSnackbarService } from 'src/app/services/custom-snackbar.service';
import { snackType } from '../../shared/custom-snackbar/custom-snackbar.component';
import { ResidentService } from '../../services/resident.service';

@Component({
  selector: 'app-receive-package',
  templateUrl: './receive-package.component.html',
  styleUrls: ['./receive-package.component.css']
})
export class ReceivePackageComponent implements AfterViewInit, OnInit, OnDestroy {

  public model: Package;
  public infoFormGroup: FormGroup;
  public selectTedResident = '';
  private resident: ResidentSearch;
  public loading = false;
  public isLoading = false;
  private relationshipId: number;
  public communitySettings: CommunitySettings = new CommunitySettings(null);
  private nameResident = '';
  private residentSearch: ResidentSearch [] = [];
  private carriers: Carrier [];
  public packageList: Package[] = [];
  private searchParamsBreadcrumb: SearchParamsBreadcrumb = new SearchParamsBreadcrumb();
  @ViewChild('filter') input: ElementRef;
  @ViewChild('residentSelect') residentSelect: MatAutocomplete;
  public searchComplete = false;
  private addPackageComp: PackageAddComponent;
  breadcrumbChanges: any;
  packagelogId: number;
  @ViewChild('addPackageComp') set content(content: PackageAddComponent) {
    this.addPackageComp = content;
    if (this.addPackageComp) {
      this.cdr.detectChanges();
      this.addPackageComp.communitySettings = this.communitySettings;
      if (this.packageList.length > 0) {
        this.addPackageComp.currentPackage = this.packageList[0];
      }
      this.addPackageComp.ngOnInit();
      this.addPackageComp.model.recipientName =  this.nameResident;
      this.addPackageComp.model.relationshipId = this.relationshipId;         
    this.addPackageComp.watchAddAnother().subscribe(result => {
      this.addAnother(result);
    });
    this.addPackageComp.watchAddPackage().subscribe(result => {
      this.addAnother(result);
      this.done();
    });
  }
  }

  /**
   * Initializes a new instance of TagAddEditComponent.
   *
   * @param fb A reference to an instance of FormBuilder.
   * @param snackBar A reference to an instance of MatSnackBar.
   * @param dialog .
   */

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private peopleService: PeopleService,
    private carrierService: CarrierService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private packageService: PackagesService,
    private hotPackageService: HotPackagesService,
    private communitySettingService: CommunitySettingsService,
    private residentService: PeopleService,
    private snackbar: CustomSnackbarService) {
      this.infoFormGroup = this.fb.group({
        addressUnit: [''],
        recipientName: [''],
        email: [''],
        phone: [''],
      });    
      this.resident = new ResidentSearch();      
      this.infoFormGroup.controls['recipientName'].disable();
      this.infoFormGroup.controls['addressUnit'].disable();
      this.infoFormGroup.controls['email'].disable();
      this.infoFormGroup.controls['phone'].disable();
    }


 /**
   * Sets up the component once it has been initialized by Angular.
   */
  ngOnInit() {
    forkJoin(this.carrierService.getAll()).subscribe(result => this.carriers = result[0]);
    this.checkParamns();
       this.breadcrumbChanges  = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
        this.checkParamns();
      });

      this.route.paramMap.subscribe(
        params => {
          this.packagelogId = +params.get('id');
          if (this.packagelogId && this.packagelogId > 0) {
            this.loading = true;
            this.hotPackageService.getById(this.packagelogId).subscribe(p => {
              this.packageList.push(p);           
              this.residentService.getByRelationshipId(p.relationshipId).subscribe(r => {
                const resident = r.filter(res => res.relationshipId == p.relationshipId)[0];
                this.onResidentSelectionChange(resident);
              })
            });
          }
        });
  }
  checkParamns(): any {
    this.searchParamsBreadcrumb.propertyManagementCompanyId = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    this.searchParamsBreadcrumb.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.searchParamsBreadcrumb.areaId = LocalStorageHelper.getBuildingFromBreadcrumb();
    this.searchParamsBreadcrumb.unitId =  LocalStorageHelper.getUnitIdFromBreadcrumb();
  }
  ngOnDestroy() {
    this.breadcrumbChanges.unsubscribe();
  }
  ngAfterViewInit() {
    this.isLoading = false;
    if (this.input) {
    fromEvent(this.input.nativeElement, 'keyup')
    .pipe(
      debounceTime(200),
      tap(() => this.isLoading = true),
      distinctUntilChanged(),
      switchMap((e: any)  => {
        this.searchComplete = false;
        let val = '';
        if (e) {
          val = e.target.value;
        }
        if (val && val.length > 2) {
        return this.applyFilter(val || '');
        } else {
          this.isLoading = false;
          this.residentSearch = [];
            return new Observable<ResidentSearch[]>();
        }
      })
    ).subscribe( result => {
      this.residentSearch = result;
      this.isLoading = false;
      this.searchComplete = true;
    });
  }
  }

  clearFilter() {
    this.selectTedResident = '';
    this.residentSearch = [];
    this.selectTedResident = '';
    this.resident = null;
  }

  applyFilter(filterValue: string): Observable<ResidentSearch[]>  {
    return this.peopleService.getAllByFilter(filterValue);
  }
  displayFn(people?: ResidentSearch): string {
    return people ?  people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
     people.lastName  : '';
  }

  displayFnPackage(people?: ResidentSearch): string {
    return people ?  people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
     people.lastName + ' ' +  people.unit  : '';
  }

  onResidentSelectionChange(people: ResidentSearch) {
    this.loading = true;
    this.getCommunitySetting(people.communityId).subscribe(result => {
      if (!result) {
        SiteHelper.openDialogAlert('Warning',
            'you can not receive a package for this community, please configure the reception of packages for this community',
             this.dialog);
             this.loading = false;
             this.residentSearch = [];
           return;
      }
      if (!this.communitySettings.packagesEnabled) {
        SiteHelper.openDialogAlert('Warning',
        'This community is not enabled to receive packages',
         this.dialog);
         this.residentSearch = [];
         this.loading = false;
         return;
       }
    this.selectTedResident = this.displayFn(people);
    if (this.input) {
      this.input.nativeElement.value = '';
    }
    this.resident = people;
    this.nameResident = people.firstName + (people.middleName ? ' ' + people.middleName + ', ' : ' ') +
    people.lastName;
    this.relationshipId = people.relationshipId;
    this.loading = false;
  });
  }
  getCommunitySetting(communityId: number): Observable<boolean> {
    const flag = new Subject<boolean>();
    forkJoin(this.communitySettingService.getByCommunityId(communityId))
      .subscribe(sett => {
        if (sett.length > 0 &&  sett[0]) {
          this.communitySettings = sett[0];
          flag.next(true);
        } else {
          flag.next(false);
        }
      });
      return flag.asObservable();
  }
   /**
   * Event handler for the Cancel button.
   */
  onCancel(): void {   
    this.router.navigateByUrl('/app/packages');
}

  addAnother(_package: Package) {
    this.loading = true;
    _package.peopleId = this.resident.peopleId;
    _package.unitId = this.resident.unitId;
    this.packageService.receive(_package).subscribe(x => {
      _package.hotPackageId = x.hotPackageId;
      this.snackbar.openSnackBar('Tracking Number: ' + _package.trackNumber,
      'Package Saved for ' + this.nameResident, snackType.Success);    
      this.addPackageComp.packageFormGroup.reset();
      this.loading = false;
    }, err => {
      this.snackbar.openSnackBar('Error:','An error occurred when receiving the package' , snackType.Error);    
      this.loading = false;
    });
  }

  remove(i: number) {
    this.loading = true;
    this.hotPackageService.delete(this.packageList[i].hotPackageId).subscribe(x => {
      this.packageList.splice(i, 1);
      this.loading = false;
    });
  }
  done() {
    this.loading = false;
    this.router.navigateByUrl('/app/packages');
  }
}
