import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { ThemeService } from '../services/theme.service';
import { Observable } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';
import { LoggedUser } from '../model/logged-user';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { SiteHelper } from '../helpers/site-helper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
selectedTheme = 'grid-light-theme';

  @ViewChild('sidenav') sidenav: SideNavComponent;
  constructor(private themeService: ThemeService,
    private overlayContainer: OverlayContainer) {
    this.selectedTheme =  SiteHelper.getDefaultTheme();

  }


  ngOnInit() {
    this.themeService.currTheme.subscribe(value => {
      this.selectedTheme = value;
      switch (value) {
        case 'grid-light-theme':
          this.overlayContainer.getContainerElement().classList.remove('grid-dark-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-teal-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-pink-theme');
          this.overlayContainer.getContainerElement().classList.add('grid-light-theme');
          break;
        case 'grid-dark-theme':
          this.overlayContainer.getContainerElement().classList.remove('grid-light-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-teal-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-pink-theme');
          this.overlayContainer.getContainerElement().classList.add('grid-dark-theme');
          break;
        case 'grid-teal-theme':
          this.overlayContainer.getContainerElement().classList.remove('grid-light-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-dark-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-pink-theme');
          this.overlayContainer.getContainerElement().classList.add('grid-teal-theme');
          break;
        case 'grid-pink-theme':
          this.overlayContainer.getContainerElement().classList.remove('grid-light-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-dark-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-teal-theme');
          this.overlayContainer.getContainerElement().classList.add('grid-pink-theme');
          break;
          case 'grid-castle-theme':
          this.overlayContainer.getContainerElement().classList.remove('grid-light-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-dark-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-teal-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-pink-theme');
          this.overlayContainer.getContainerElement().classList.add('grid-castle-theme');
          break;
        default:
          this.overlayContainer.getContainerElement().classList.remove('grid-dark-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-teal-theme');
          this.overlayContainer.getContainerElement().classList.remove('grid-pink-theme');
          this.overlayContainer.getContainerElement().classList.add('grid-light-theme');
      }
    });
  }

  @HostListener('collapse')
  click() {
    this.sidenav.toggle();
  }

}
