import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { ZipCode } from '../model/zip-code';

@Injectable({
  providedIn: 'root'
})
export class ZipCodeService extends AbstractDataProviderService<ZipCode> {

  private allZips: ZipCode[];

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/zipCode', http, appConfig);

    this.allZips = null;
    super.getAll().subscribe(x => {
      this.allZips = x;
    });
  }

  getByStateCode(stateCode: string): Observable<ZipCode[]> {
    return this.http.get<ZipCode[]>(this.baseUrl + '/byStateCode/' + stateCode, { headers: this.buildGetHeaders() });
  }
  getByZipCode(zipCode: string): Observable<ZipCode[]> {
    return this.http.get<ZipCode[]>(this.baseUrl + '/byZipCode/' + zipCode, { headers: this.buildGetHeaders() });
  }

  getAll(): Observable<ZipCode[]> {
    let result: Observable<ZipCode[]>;

    if (this.allZips === null) {
      result = super.getAll();
    } else {
      result = of(this.allZips);
    }

    return result;
  }
}

