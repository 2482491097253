import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { APP_CONFIG, AppConfig } from '../app.config';
import { TransactionTypes } from '../model/transaction-type';
import { LevelFilter } from '../model/breadcrumb';
import * as AppConstants from '../app-constants';

@Injectable({
    providedIn: 'root'
})

export class TransactionTypeService extends AbstractDataProviderService<TransactionTypes> {
    constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
        super(AppConstants.EndpointNames.GridVendor, '/transactionType', http, appConfig, LevelFilter.Account);
    }
}