import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { LoginService } from '../services/login.service';
import { ServiceError } from '../model/service-error';
import { ApiResponse } from '../model/api-response';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public forgotPasswordFormGroup: FormGroup;
  public forgotPasswordFailed: boolean;
  public forgotPasswordErrorMessage: string;

  constructor(private loginService: LoginService,
    private fb: FormBuilder,
    private router: Router) {
    this.forgotPasswordFormGroup = fb.group({
      username: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  forgotPassword() {
    const username = this.forgotPasswordFormGroup.get('username').value;
    this.loginService.forgotPassword(username)
      .subscribe((result: ApiResponse) => {
        if (result.success) {
          this.router.navigate(['/confirm-forgot-password', username]);
        } else {
          this.forgotPasswordFailed = true;
          this.forgotPasswordErrorMessage = result.description;
        }
      }, (error: ServiceError) => {
        this.forgotPasswordFailed = true;
        this.forgotPasswordErrorMessage = error.errorInfo.description;
      });
  }
}
