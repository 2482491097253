export class RoleType {
    roleTypeId: number;
    name: string;
}

export enum RoleTypes {
    Custom = 1,
    Owner = 2,
    Tenant = 3,
    CommunityOperator = 4,
    CommunityAdmin = 5,
    ManagementCompanyOperator = 6,
    ManagementCompanyAdmin = 7,
    GridOperator = 8,
    GridAdmin = 9,
    SystemAdmin = 10
}
