import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PictureService } from 'src/app/services/picture.service';
import { TardisFileService } from 'src/app/services/pensieve.service';


@Component({
  selector: 'app-visitor-badge',
  templateUrl: './visitor-badge.component.html',
  styleUrls: ['./visitor-badge.component.css']
})
export class VisitorBadgeComponent implements OnInit {

  protected fileId = 0;
  protected firstName = '';
  protected lastName = '';
  protected visitorType = '';
  protected company = '';
  protected building = '';
  protected unitNumber = '';
  protected imagePath: any;
  protected picture = null;
  protected completeName: string;
  protected date = new Date();

  constructor(private route: ActivatedRoute, private fileService: TardisFileService) { }

  ngOnInit() {
    window.onafterprint = function() {
      window.open('', '_self').close();
      //window.close();
    };
    this.route.queryParams.subscribe(
      params => {
        this.firstName =  params['firstName'];
        this.lastName = params['lastName'];
        this.visitorType = params['visitorType'];
        this.company = params['company'];
        this.building = params['building'];
        this.unitNumber = params['unitNumber'];
        this.fileId =  parseInt(params['fileId'], 10);
        this.completeName = this.firstName.trim() + ' ' + this.lastName.trim();

        if (this.completeName.length <= 15) {
          this.firstName = this.completeName;
          this.lastName = ' ';
        }

        if (this.fileId && this.fileId > 0) {
          this.fileService.getFile(this.fileId).subscribe( async x => {
          await this.setImage(x);
        });
        } else {
          this.picture = 'assets/images/photo-placeholder@2x.png';
        }
      });
  }

  async setImage(pic: any) {
    const reader = new FileReader();
    const blob = new Blob([new Uint8Array(pic)], { type: 'image/png' });
    if (blob.size > 0) {
      const file = new File([blob], 'QrCode', { type: 'image/png', lastModified: Date.now() });
      reader.onload = (event: any) => {
          this.picture = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  printBadge() {
    if (this.fileId && this.fileId > 0) {
      window.print();
      
    }
  }
}
