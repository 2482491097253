import { FormGroup, FormControl } from '@angular/forms';
import { AlertDialogComponent } from '../app-dialogs/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material';
import { environment } from '../../environments/environment';
import { LocalStorageHelper } from './local-storage-helper';

export namespace SiteHelper {

  /**
   * Checks whether the domain points to a community site, or to the generic GridEnterprise site.
   */
  export function isCommunity() {
    const currentSubdomain = location.host.split('.')[0];

    return (currentSubdomain !== undefined
      && currentSubdomain !== 'localhost'
      && currentSubdomain !== environment.defaultDomain.split('.')[0]);
  }

  export function markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  export function isDirty(formGroup: FormGroup): boolean {
    let Dirty = false;
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control['dirty'] && control instanceof FormControl) {
        Dirty = true;
      }
    });
    return Dirty;
  }

  export function isValid(formGroup: FormGroup): boolean {
    let Valid = true;
    (<any>Object).values(formGroup.controls).forEach(control => {
      if ((!control['valid'] && control instanceof FormControl)
        || (!control['valid'] && control['dirty'] && control instanceof FormGroup)) {
        Valid = false;
      }
    });
    return Valid;
  }
  export function showThemeforCastle(): boolean {
    const user = LocalStorageHelper.getLoggedUserInfo(false);
    if ((user.isManagementCompanyStaff || user.isCommunityStaff) && user.managementCompanies !== null
      && user.managementCompanies.length === 1 && user.managementCompanies[0] === 1) {
      return true;
    } else if (user.isResident && user.residents[0].managementCompanyId === 1) {
      return true;
    } else {
      return false;
    }
  }
  export function getDefaultTheme(): string {
    if (this.showThemeforCastle()) {
      return 'grid-castle-theme';
    } else {
      return 'grid-light-theme';
    }
  }


  export function goToLogin() {
    window.location.href = location.protocol + '//' +
      environment.defaultDomain + (location.port !== '' ? ':' + location.port : '') + '/login';
  }
  export function openDialogAlert(title: string, Message: string, dialog: MatDialog): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };

    const dialogRef = dialog.open(AlertDialogComponent, {
      width: '300px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => { });
  }
}
