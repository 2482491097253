import { Picture } from './picture';

export class PictureEntry extends Picture {
    picture_CommunityId: number;
    communityId: number;
    src = '';
    fileName: string;
    file: File;
    byteArray: Uint8Array[];
    orderShown: number;
    pictureData: Uint8Array[];
}
