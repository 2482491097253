import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { CommunityMapping } from '../model/community-mapping';

@Injectable({
  providedIn: 'root'
})
export class CommunityMappingService  extends AbstractDataProviderService<CommunityMapping> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/communityMapping', http, appConfig);
  }

}
