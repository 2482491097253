import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials'
})

export class InitialsPipe implements PipeTransform {
    transform(value: string) {
        if (!value) {
            console.log('err InitialsPipe, value empty');
        } else {
            return value.replace(/[a-z]/g, '').replace(' ', '');
        }
    }
}
