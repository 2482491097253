import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Area } from '../model/area';
import { Observable } from 'rxjs';
import { AreaTree } from '../model/area-tree';
import { LevelFilter } from '../model/breadcrumb';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends AbstractDataProviderService<Area> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/area', http, appConfig, LevelFilter.Unit);
  }

  getAllByCommunityId(communityId: number): Observable<AreaTree[]> {
    const queryString = '/allByCommunityId/' + communityId;
    return this.http.get<AreaTree[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getAllPaginated(pageIndex: number, pageSize: number, filter?: string): Observable<Area[]> {
    //this.setBreadcrumbParams();
    const route = this.filterLevel ? '/allPageByBreadcrumb/' : '/allPaged/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString()  + (filter ? '/' + filter : '');

    if (this.filterLevel && this.filterLevel >= LevelFilter.PropertyManagementCompany) {
      queryString = queryString + '?propertyManagementCompanyId=' +  this.searchParamsBreadcrumb.propertyManagementCompanyId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Community) {
      queryString = queryString + '&communityId=' +  this.searchParamsBreadcrumb.communityId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Area) {
      queryString = queryString + '&areaId=' +  this.searchParamsBreadcrumb.areaId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Unit) {
      queryString = queryString + '&unitId=' +  this.searchParamsBreadcrumb.unitId;
    }

    return this.http.get<Area[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  
  addBuilding(area: Area) : Observable<number> {
    const url = this.baseUrl + '/addBuilding';
    return this.http.post<number>(url, area, { headers: this.buildPostHeaders() });
  }
}
