import { Injectable } from '@angular/core';
import { Upload } from '../model/upload';

import * as firebase from 'firebase';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { }

  pushUpload(upload: Upload) {
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child('uploads/${upload.file.name}').put(upload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>  {
        // upload in progress
        upload.progress = (uploadTask.snapshot.bytesTransferred / uploadTask.snapshot.totalBytes) * 100;
      },
      (error) => {
        // upload failed
        console.log(error);
      },
      () => {
        // upload success
        upload.url = uploadTask.snapshot.downloadURL;
        upload.name = upload.file.name;
        this.saveFileData(upload);
      }
    );
  }



  // Writes the file details to the realtime db
  private saveFileData(upload: Upload) {
  }

}
