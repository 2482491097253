import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

import { CommunityConfigurationComponent } from '../community-configuration/community-configuration.component';
import { CommunityListComponent } from '../community-list/community-list.component';
import { CommunityAddEditComponent } from '../community-add-edit/community-add-edit.component';

import { StaffListComponent } from '../staff/staff-list/staff-list.component';
import { StaffEditComponent } from '../staff/staff-edit/staff-edit.component';
import { StaffContactComponent } from '../staff/staff-contact/staff-contact.component';


import { AuthGuardService } from '../../services/auth-guard.service';
import { UnderConstructionComponent } from '../../under-construction/under-construction.component';
import { SharedModule } from '../../shared/shared.module';
import { ContactUsDashboardComponent } from 'src/app/contact-us/contact-us-dashboard/contact-us-dashboard.component';
import { PairDeviceComponent } from 'src/app/pair-device/pair-device.component';
import { CommunityOfficeInformationAddEditComponent } from '../community-office-information-add-edit/community-office-information-add-edit.component';


const communityRoutes: Routes = [{
  path: '',
  children: [
    {
      path: 'add/:id/:type',
      component: CommunityAddEditComponent,
      data: { title: 'Add Communities' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'list',
      component: CommunityListComponent,
      data: { title: 'Edit Community' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'edit/:id',
      component: CommunityAddEditComponent,
      data: { title: 'Edit Community' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'staff',
      component: StaffListComponent,
      data: { title: 'List Staff' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'staff/edit/:id',
      component: StaffEditComponent,
      data: { title: 'Edit Staff' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'staff/contacts',
      component: ContactUsDashboardComponent,
      data: { title: 'Staff Contacts' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'setting/:id',
      component: CommunityConfigurationComponent,
      data: { title: 'Setting Community' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'contacts',
      component: UnderConstructionComponent,
      data: { title: 'contacts' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'mycommunity',
      component: UnderConstructionComponent,
      data: { title: 'my community' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'pictures',
      component: UnderConstructionComponent,
      data: { title: 'pictures' },
      canActivate: [AuthGuardService]
    } ,
    {
      path: 'settings',
      component: CommunityConfigurationComponent,
      data: { title: 'settings' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'pair-device',
      component: PairDeviceComponent,
      data: { title: 'Pair Device' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'office-information-add-edit/:id',
      component: CommunityOfficeInformationAddEditComponent,
      data: { title: 'Office Information Add/Edit' },
      canActivate: [AuthGuardService]
    }
  ]
}];


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(communityRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class CommunityRoutingModule { }
