import { EventEmitter, Output } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material';

import { Observable, Subject } from 'rxjs';

import { DataProviderInterface } from '../interfaces/data-provider-interface';

/**
 * Implements a data source that acts as a bridge between the entity service
 * and the list component.
 */
export class TableDataSource<T> extends DataSource<T> {

    private currentPageIndex: number;
    private pageSize: number;
    filter: string;
    public  sortData: string;
    public dataStream$: Subject<T[]> = new Subject<T[]>();
    public tableData: any;

    private _paginator: MatPaginator;
    private paginatorSubscription: any = null;
    public recordCount: number;

    public getLambda: (pi: number, ps: number, fi: string, sortData?: string) => Observable<T[]>;
    private getCount: () => Observable<number>;

    public noData: Subject<boolean> = new Subject<boolean>();

    @Output()
    public loadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    set paginator(p: MatPaginator) {

        if (p === undefined || p === null) {
            return;
        }

        // Get rid of any previously existing subscriptions to paginator events.
        if (this.paginatorSubscription !== null) {
            this.paginatorSubscription.unsubscribe();
            this.paginatorSubscription = null;
        }

        // Setup the paginator.
        this.currentPageIndex = p.pageIndex + 1;
        this.pageSize = p.pageSize;
        this._paginator = p;

        // Try to fetch the total record count for this table. Once the count is
        // obtained, set up the paginator and subscribe to paginator events.
        const that = this;
        this.getCount().subscribe(x => {
            that._paginator.length = x;

            that.paginatorSubscription = that._paginator.page.subscribe(y => {
                // Fetch new data and update paginator variables every time
                // a new page is selected.
                that.currentPageIndex = y.pageIndex + 1;
                that.pageSize = y.pageSize;
                that.loadingEvent.emit(true);
                that.getLambda(that.currentPageIndex, that.pageSize, that.filter)
                .subscribe(data => {
                    that.loadingEvent.emit(false);
                    that.tableData = data;
                    that.dataStream$.next(data);
                });
            });
        });

    }

    /**
     * Informs the data source that it should refresh the data it contains.
     */
    reload() {
        this.loadingEvent.emit(true);
        this.currentPageIndex = 1;
        this._paginator._pageIndex = 0;
        this.getCount()
        .subscribe(data => {
           this.recordCount = this._paginator.length = data;
        });

        this.getLambda(this.currentPageIndex, this.pageSize, this.filter, this.sortData)
        .subscribe(data => {
            this.loadingEvent.emit(false);
            this.dataStream$.next(data);
            if (data && data.length > 0) {
                this.tableData = data;
                this.noData.next(false);
            }else {
                this.noData.next(true);
            }
        });
    }

    constructor(
        private dataSource: DataProviderInterface<T>,
        getLambda: (pi: number, ps: number, fi: string, sortData?: string)  => Observable<T[]>,
        getCount: () => Observable<number>) {
        super();

        this.currentPageIndex = 1;
        this.pageSize = 10;
        if (getLambda !== null) {
            this.getLambda = getLambda;
            this.getCount = getCount;
        } else {
            this.getLambda = this.dataSource.getAllPaginated;
            this.getCount = this.dataSource.getCount;
        }
    }

    connect(): Observable<T[]> {
        this.getLambda(this.currentPageIndex, this.pageSize, this.filter, this.sortData)
        .subscribe(data => {
            this.loadingEvent.emit(false);
            this.dataStream$.next(data);
            if (data && data.length > 0) {
                this.tableData = data;
                this.noData.next(false);
            }else {
                this.noData.next(true);
            }
        },
        error => {
            this.loadingEvent.emit(false);
            this.noData.next(true);
        });
        return this.dataStream$.asObservable();
    }

    disconnect() {
        if (this.paginatorSubscription !== null) {
            this.paginatorSubscription.unsubscribe();
            this.paginatorSubscription = null;
        }
        if (this.noData !== null) {
            this.noData.unsubscribe();
        }
    }
}
