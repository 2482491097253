import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { EntityType } from '../model/entity-type';

@Injectable({
  providedIn: 'root'
})
export class EntityTypeService extends AbstractDataProviderService<EntityType> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.SecurityAdminService, '/entityType', http, appConfig);
  }
}