import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PackagesListComponent } from './com-packages-list/packages-list.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { PackagesEditComponent } from './com-packages-edit/packages-edit.component';
import { ReceivePackageComponent } from './receive-com-package/receive-package.component';
import { SettingsPackageComponent } from './settings-com-package/settings-package.component';
import { PackageLogComponent } from './com-package-log/package-log.component';
import { DeliverPackageComponent } from './deliver-com-package/deliver-package.component';
import { PackageLogDetailComponent } from './com-package-log/package-log-detail/package-log-detail.component';
import { UnderConstructionComponent } from '../under-construction/under-construction.component';
import { SharedModule } from 'ThorinFrontend/src/app/thorin-shared/shared.module';
import { CommonModule } from '../../../node_modules/@angular/common';

const routes: Routes = [ {
  path: '',
  children: [
    {
      path: '',
      component: PackageLogComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: ':id/edit',
      component: ReceivePackageComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'receive',
      component: ReceivePackageComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'deliver',
      component: DeliverPackageComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'deliver/:id',
      component: DeliverPackageComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'settings',
      component: SettingsPackageComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'log',
      component: PackageLogComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'reports',
      component: UnderConstructionComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'log/detail/:id/:ishot',
      component: PackageLogDetailComponent,
      canActivate: [AuthGuardService]
    }
  ]
}];
@NgModule({
  imports: [SharedModule,
  CommonModule,
  RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PackagesRoutingModule {
}
