import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstLetter'
})
export class FirstLetterPipe {
    transform(value: string, args: any[]) {
        if (value !== null || value !== undefined) {
            return value.charAt(0).toLowerCase();
        }
    }
}