import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsRoutingModule } from './contact-us-routing/contact-us-routing.module';
import { ContactUsDashboardComponent } from './contact-us-dashboard/contact-us-dashboard.component';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { InitialsPipe } from '../helpers/initials-pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ContactUsRoutingModule,
    SharedModule
  ],
  exports: [
    ContactUsRoutingModule
  ],
  declarations: [
    ContactUsDashboardComponent,
    InitialsPipe
    // SidebarAccountComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ContactUsModule { }
