import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
} from '@angular/material';

import { ConfirmDialogComponent } from '../app-dialogs/confirm-dialog/confirm-dialog.component';

import { Tag } from '../model/tag';
import { AccessType } from '../model/access-type-ring';
import { BaseSecurity } from '../building-blocks/base-security';

/**
 * A component used for adding/editing tags.
 */
@Component({
  selector: 'app-tag-add-edit',
  templateUrl: './tag-add-edit.component.html',
  styleUrls: ['./tag-add-edit.component.css']
})
export class TagAddEditComponent extends BaseSecurity implements AfterViewInit, OnInit {

  public model: Tag;
  public tagFormGroup: FormGroup;

  /**
   * Initializes a new instance of TagAddEditComponent.
   *
   * @param dialogRef A reference to an instance of MatDialogRef that allows
   * add/update dialogs to be opened.
   * @param data If a tag has been selected for update, the data of the tag
   * to update.
   * @param fb A reference to an instance of FormBuilder.
   * @param snackBar A reference to an instance of MatSnackBar.
   * @param dialog .
   */
  constructor(public dialogRef: MatDialogRef<TagAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {

    super();
      this.model = new Tag(this.fb);
      this.tagFormGroup = this.model.buildFormGroup();
      this.loadSecurityRings();
    }
    protected loadSecurityRings() {
      this.securityEntity.rings.push({ringId : 569, accessType: AccessType.Add });
      this.securityEntity.rings.push({ringId : 769, accessType: AccessType.Update });
      this.entityRings.push(this.securityEntity);
    }

  /**
   * Sets up the component once it has been initialized by Angular.
   */
  ngOnInit() {

    if (this.data.update === true) {
      this.model.fromObject(this.data.model.entity);
      // this.model = this.data.model.entity;
    } else {
      this.model.isActive = true;
    }
  }

  ngAfterViewInit() {
    /* if (this.data.update === true) {
      this.model = this.data.model.entity;
    } */
  }

  /**
   * Event handler for the Cancel button.
   */
  onCancel(): void {
    if (this.tagFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else { this.dialogRef.close({ valid: false }); }
  }

  /**
   * Event handler for the Ok/Submit button.
   */
  onSubmit(): void {
   this.dialogRef.close({
      valid: true,
      entity: this.model.toDto()
    });
  }
}
