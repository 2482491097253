export class R2D2 {
    FName: string;
    Mi: string;
    LName: string;
    Phone: string;
    WorkPhone: string;
    Email: string;
    CellPhone: string;
    MainAddress: MainAddress;
    BillAddress: BillAddress;
}

export class MainAddress{
    Street: string;
    City: string;
    St: string;
    Zip: string;
    Country: string;
}

export class BillAddress{
    Street: string;
    City: string;
    St: string;
    Zip: string;
    Country: string;
}