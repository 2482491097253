import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeduplicate'
})
export class RemoveDuplicatePipe implements PipeTransform {

    transform(collection: any, property: string): any {
        // Remove the duplicate elements
            const unique =  collection.filter((element, pos, arr) => {
                return arr.map(mo => mo[property]).indexOf(element[property]) === pos;
            });
        return unique;
    }
}
