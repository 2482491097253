import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { EmailAddressType } from '../model/email-address-type';

@Injectable({
  providedIn: 'root'
})
export class EmailAddressTypeService extends AbstractDataProviderService<EmailAddressType> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.GridVendor, '/emailAddressType', http, appConfig);
  }
}
