import { Component, EventEmitter, Injector, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { BaseList } from '../../building-blocks/base-list';
import { Community } from '../../model/community';
import { CommunityTree } from '../../model/community-tree';
import { CommunityService } from '../../services/community.service';
import { CrudResponseModel } from '../../model/crud-response-model';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { map} from 'rxjs/operators';
import { EntityRing, AccessType } from 'src/app/model/access-type-ring';
import { SecurityHelper } from 'src/app/helpers/security-helper';
import { trigger, state, style, transition, animate } from '@angular/animations';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CustomExporterService } from 'src/app/services/grid-table-custom-exporter-service';

const communityColumns = [
  'name',
  'actions',
];


@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed',
        animate('225ms ease-in-out')),
    ]),
  ]
})
export class CommunityListComponent extends BaseList<Community> implements OnDestroy {
  private cols = [
    {
      columnDef: 'name',
      headerName: 'Name',
      cell: (community) => community.name,
      sortable: true,
      parentRow: true
    }
  ];
  private showFilter: boolean = false
  private rowActions = ['add', 'edit', 'delete', 'configure'];

  applySort($event) {
    console.log('Error: feature not yet supported');
  }

  search(searchParameter: string) {
    this.applyFilter(searchParameter);
  }

  public collapseAll = true;
  protected communityIdSelected = 0;
  protected managementCompanyIdSelected = 0;
  public dataSourceAll: CommunityTree[] = [];
  public communityPermisions = new EntityRing();
  public settingPermisions = new EntityRing();
  constructor(
    public injector: Injector,
    private communityService: CommunityService,
    private breadcrumbService: BreadcrumbService,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private customExporter: CustomExporterService) {
    super(communityColumns, '/community', communityService, 'community', injector); { }
    this.loadSecurityRings();
  }

  protected loadSecurityRings() {
    this.communityPermisions.rings.push({ accessType: AccessType.Add, ringId: 508 });
    this.communityPermisions.rings.push({ accessType: AccessType.Delete, ringId: 608 });
    this.communityPermisions.rings.push({ accessType: AccessType.Update, ringId: 708 });
    this.entityRings.push(this.communityPermisions);

    this.settingPermisions.rings.push({ accessType: AccessType.Add, ringId: 514 });
    this.settingPermisions.rings.push({ accessType: AccessType.Update, ringId: 714 });
    this.entityRings.push(this.settingPermisions);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Community[]> {
    this.loading = true;
    return this.communityService.getAllPaginatedByPropertyManagementComp(pageIndex, pageSize).pipe(map(result => this.renderTree(result)));
  }

  protected renderTree(communitiesArray: Community[]): Community[] {
    this.loading = true;
    this.dataSourceAll = communitiesArray as CommunityTree[];
    this.loading = false;
    return communitiesArray;
  }
  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>): any { }

  protected opened(index: number, isOpen: boolean): boolean {
    if (this.collapseAll) {
      return this.dataSourceAll[index].openChilds === isOpen;
    } else {
      return !isOpen;
    }
  }

  protected clickOpenCollapse(community: CommunityTree, open: boolean, index: number): void {
    this.collapseAll = !open;
    this.dataSourceAll[index].openChilds = true; // open;
    this.dataSourceAll.forEach((co, i) => {
      if (co.parent === community.id) {
        co.state = open;
        this.clickOpenCollapse(co, open, i);
      }
    });
  }

  protected getCount(): Observable<number> {
    return this.communityService.getCountByBreadcumd();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public delete(id: number): void {
    if (confirm('Are you sure you want to delete this item?')) {
      this.loading = true;
      this.communityService.softDelete(id).subscribe(result => {
        this.breadcrumbService.watchUpdated(true).subscribe();
        this.dataSource.reload();
        this.loading = false;
      });
    }
  }

  public validateClaim(ringId: number): boolean {
    return SecurityHelper.ValidatePermition(ringId);
  }

  public add(row: CommunityTree) {
    this.router.navigate(['app/community/add/' + row.id + '/' + row.type]);
  }

  public edit(id: number): void {
    this.router.navigate(['app/community/edit/' + id]);
  }

  public configure(id: number): void {
    this.router.navigate(['app/community/setting/' + id]);
  }

  public downloadPDFEvent(doc: any) {
    let tableData = this.dataSource.tableData.map(d => {
      return {'name': d.name}; 
    });
    const docz = new jsPDF();
    docz.autoTable({
      body: tableData,
      columns: [ 
        {header: 'Name', dataKey: 'name'}
      ]
    });
    docz.save('grid-system-export.pdf');
  }
  
  public downloadCSVEvent(directive: any, options: any) {
    let tableData = this.dataSource.tableData.map(d => {
      return {'name': d.name}; 
    });
    this.customExporter.export(tableData, options);
  }
}
