import { Injectable, Inject } from '@angular/core';
import { Package } from '../model/package';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig } from '../app-config';
import { APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { SignaturePad } from '../model/signature-pad';

@Injectable({
  providedIn: 'root'
})
export class SignaturePadService extends AbstractDataProviderService<SignaturePad> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/signaturePad', http, appConfig);
  }
  signaturePad(signaturePad: SignaturePad): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/requestSignature', signaturePad,  { headers: this.buildGetHeaders() });
  }
  getQRCode(token: string, communityId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/pairingCode?clientToken=' + token + '&communityId='
     + communityId,  { headers: this.buildGetHeaders() , responseType: 'arraybuffer'});
  }
}
