import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDialogComponent } from '../../../../../src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { Subscription, forkJoin } from 'rxjs';
import { ConfirmDialogComponent } from '../../../../../src/app/app-dialogs/confirm-dialog/confirm-dialog.component';

// Services
import { DeliveryOptionService } from '../../services/delivery-option.service';
import { ServiceError } from '../../../../../src/app/model/service-error';

// Models
import { DeliveryOption } from '../../model/delivery-option';
import { DeliveryPricePlan } from '../../model/deliveryPricePlan';
import { PricePlan } from '../../model/price-plan';

@Component({
  selector: 'app-delivery-option-add-edit',
  templateUrl: './delivery-option-add-edit.component.html',
  styleUrls: ['./delivery-option-add-edit.component.css']
})
export class DeliveryOptionAddEditComponent implements OnInit, OnDestroy {

  public model: DeliveryPricePlan;
  public SAVED_MESSAGE = 'Your delivery option was suscessfully saved';
  public TITLE_INFO = 'Info';
  public formGroup: FormGroup;
  public loading: boolean;
  protected communityIdSelected = 0;
  protected managementCompanyIdSelected = 0;
  protected breadcrumbChanges: Subscription;
  private sub: Subscription;
  public saveFailed: boolean;
  public saveErrorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private deliveryOptionService: DeliveryOptionService,
    private dialog: MatDialog) {
     this.loading = true;

    this.checkParams();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
       // this.loading = true;
      if (this.checkParams()) {
        this.onCancel();
      }
    });

  }

  ngOnInit() {

    this.model = new DeliveryPricePlan();
    this.model.delivery = new DeliveryOption();
    this.model.pricePlan = new PricePlan();
    this.model.pricePlan.relationShipTypes = [];
    this.model.pricePlan.communities = [];
    this.validateDeliveryOptionFormGroup();

    this.sub = this.route.paramMap.subscribe(
      params => {
        this.model.delivery.deliveryOptionId = +params.get('id');

        if (this.model.delivery.deliveryOptionId) {
          this.initDeliveryOption();
          // this.loading = false;
        }
      });
    if (!this.model.delivery.deliveryOptionId) {
      this.loading = false;
      this.model.delivery.isActive = true;
    }

  }

  validateDeliveryOptionFormGroup(): void {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      deliveryTime: ['', [Validators.required, Validators.min(1), Validators.max(31)]],
      isActive: '',
      pricePlan: this.fb.group({
        before: ['', Validators.required],
        after: ['', Validators.required]
      })
    });
  }

  initDeliveryOption(): void {
    if (this.model.delivery.deliveryOptionId) {

      this.deliveryOptionService.getDeliveryById(this.model.delivery.deliveryOptionId).subscribe(dc => {
        this.model = dc;
        this.loading = false;
      });
    }
    this.loading = false;
  }

  checkParams(): boolean {
    const mcLocalStorage = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if ((this.managementCompanyIdSelected !== mcLocalStorage || this.communityIdSelected !== comunityLocalStorage)) {
      this.managementCompanyIdSelected = mcLocalStorage;
      this.communityIdSelected = comunityLocalStorage;
      return true;
    } else {
      return false;
    }
  }

  onSave() {
    if (this.formGroup.dirty && this.formGroup.valid) {
      this.loading = true;
      this.model.managementCompanyId = this.managementCompanyIdSelected;
      this.model.communityId = this.communityIdSelected;
      if (!this.model.delivery.deliveryOptionId) {
        this.addDeliveryOption();
      } else {
        this.editDeliveryOption();
      }
    } else {
      this.onCancel();
    }
  }

  addDeliveryOption() {
    this.model.delivery.isDeleted = false;
    this.deliveryOptionService.addDeliveryOption(this.model).subscribe(
      result => {
        this.onSaveComplete();
      },
      (error: ServiceError) => {
        this.onServerError(error);
      });
  }

  editDeliveryOption() {
    this.model.delivery.isDeleted = false;
    console.log(this.managementCompanyIdSelected);
    this.deliveryOptionService.updateDeliveryOption(this.model).subscribe(result => {
      this.onSaveComplete();

    },
      (error: ServiceError) => {
        this.onServerError(error);
      });
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '750px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.formGroup.reset();
      this.router.navigateByUrl('/app/docs/delivery');
    });
  }

  onServerError(data) {
    this.loading = false;
    this.saveFailed = true;
    this.saveErrorMessage = 'Sorry, there was an error while saving the record.';
    console.log(data.error.errorMessage);
  }


  onSaveComplete(): void {
    this.loading = false;
    this.openDialog(this.TITLE_INFO, this.SAVED_MESSAGE);
  }

  onCancel(): void {
    this.formGroup.reset();
    this.router.navigateByUrl('/app/docs/delivery');
  }

  ngOnDestroy() {
    if (this.breadcrumbChanges) {
      this.breadcrumbChanges.unsubscribe();
    }
  }

  // onCancel(): void {
  //   if (this.deliveryOptionFormGroup.dirty) {
  //     const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
  //       width: '750px'
  //     });

  //     confirmDialog.afterClosed().subscribe(result => {
  //       if (result === true) {
  //         this.deliveryOptionFormGroup.reset();
  //         this.dialogRef.close({ valid: false });
  //         this.router.navigateByUrl('/app/docs/delivery');
  //       }
  //     });
  //   } else { this.dialogRef.close({ valid: false }); }
  // }


}
