import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { SearchAddress } from 'src/app/model/search-address';
import { SearchPeople } from 'src/app/model/search-people';
import { SearchService } from 'src/app/services/search.service';
import { Subject } from 'rxjs';
import { PlaceGoogleMaps } from '../../model/place-google-maps';
import { environment } from '../../../environments/environment.dev';
declare var google: any;

@Component({
  selector: 'app-search-address-google-component',
  templateUrl: './search-address-google.component.html',
  styleUrls: ['./search-address-google.component.css']
})
export class SearchAddressGoogleComponent implements OnInit, AfterViewInit {


  constructor(public searchService: SearchService) { }
  selectedAddresses: SearchAddress = new SearchAddress;
  resultSearch: SearchAddress[] = [];
  contacts: SearchPeople[] = [];
  notFound = false;
  @Input() showAddress: boolean;
  @Input() showSearch: boolean;
  public selectedAddress = new Subject<boolean>();
  public searchedAddress = new Subject<boolean>();
  public placeSearch: any;
  @ViewChild('autocompleteInput') autocompleteInput: any;
  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.initAutocomplete();
  }


  initAutocomplete() {
    const input = this.autocompleteInput;
    const that = this;
    // Load google maps script after view init
    const apiKey = environment.apiKeyGoogleMaps;
    const DSLScript = document.createElement('script');
    DSLScript.src = 'https://maps.googleapis.com/maps/api/js?key='+ apiKey +'&libraries=places'; 
    DSLScript.type = 'text/javascript';
    DSLScript.async = true;
    DSLScript.defer = true;
    DSLScript.onload = function () {
      const autocomplete = new google.maps.places.Autocomplete(input.nativeElement,
        {
          componentRestrictions: { country: 'US' },
          types: ['geocode']  // 'establishment' / 'address' / 'geocode'
        });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        that.searchAddress(place);
      });
    };
 
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);   
  }

  getPlace(place: any): PlaceGoogleMaps {
    const placeGoogle = new PlaceGoogleMaps();
    placeGoogle.place_id = place.place_id;
    for (var i = 0, i_len = place.address_components.length; i < i_len; i++) {
      var addressType = place.address_components[i].types[0];

      switch (addressType) {
        case "route":
          placeGoogle.street = place.address_components[i]["short_name"];
          break;

        case "street_number":
          placeGoogle.streetNumber = place.address_components[i]["short_name"];
          break;
      }
    }
    return placeGoogle;
  }

  searchAddress(place: any) {
    this.searchService.getAddressesBySreetAndNumber(this.getPlace(place)).subscribe(results => {
      if (results && results.length > 0) {
        this.showAddress = true;
        this.notFound = false;
        this.selectedAddresses = results[0];
      } else {
        this.notFound = true;
        this.showAddress = false;
      }
      this.searchedAddress.next(true);
      this.selectedAddress.next(true);
    });
  }

  onSearchAddressCompleted() {
    return this.searchedAddress.asObservable();
  }


  public onSelectedAddress() {
    return this.selectedAddress.asObservable();
  }

}
