import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    private _appTheme = new Subject<string>();
    currTheme = this._appTheme.asObservable();

    setCurrentTheme(value: string) {
        this._appTheme.next(value);
    }
}