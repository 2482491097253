import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { LedgerRoutingModule } from './ledger-routing/ledger-routing.module';

import { PaymentComponent } from './payment/payment.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { Transactions2Component } from './transactions2/transactions2.component';
import { MatSelectModule, MatPaginatorModule, MatSortModule, MatTableModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LedgerRoutingModule,
    MaterialModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule
  ],
  exports: [
    LedgerRoutingModule,
    TransactionsComponent,
    Transactions2Component
  ],
  declarations: [
    PaymentComponent,
    TransactionsComponent,
    Transactions2Component,
  ]
})
export class LedgerModule { }
