import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { GuestInfoComponent } from '../guest-info/guest-info.component';
import { Subscription, Subject, Observable, forkJoin } from '../../../../node_modules/rxjs';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { SiteHelper } from '../../helpers/site-helper';
import { VisitorsTimeLog } from '../../model/visitors-time-log';
import { CommunitySettings } from '../../model/community-settings';
import { MatDialog } from 'material2/src/lib';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { VisitorsTimeLogService } from '../../services/visitors-time-log.service';
import { CommunitySettingsService } from '../../services/community-settings.service';
import { CommunityService } from '../../services/community.service';
import { SearchResidentUnitsComponent } from '../../shared/search-resident-units/search-resident-units.component';
import { CardsVisitorsComponent } from '../cards-visitors/cards-visitors.component';
import { ResidentInfo } from '../../model/resident';


@Component({
  selector: 'app-visitor-time-log-add-edit',
  templateUrl: './visitor-time-log-add-edit.component.html',
  styleUrls: ['./visitor-time-log-add-edit.component.css']
})
export class VisitorTimeLogAddEditComponent implements OnInit {

  public breadcrumbChanges: Subscription;
  communityId: number;
  communityName: string;
  loading: boolean;
  public communitySettings: CommunitySettings = new CommunitySettings(null);
  visitorsTimeLogId: number;
  private sub: Subscription;
  disabled: boolean;
  showGuestInfo: boolean;
  showResidentInfo: boolean;
  showSearchInfo: boolean;
  private searchResident: SearchResidentUnitsComponent;
  isEdit: boolean;
  public residents: ResidentInfo[] = [];
  guestInfoComponent: GuestInfoComponent;
  
  relationshipId: number;
  visitorTimeLog: VisitorsTimeLog;
  areaId: any;
  areaName: any;
  unitName ='';
  public bigFont: boolean;
  loadingGuest: boolean = false;
  loadingVisitor: boolean = false;
  @ViewChild('guestInfoComponent') set contentGuestInfo(content: GuestInfoComponent) {
    this.guestInfoComponent = content;
    if (this.guestInfoComponent) { 
      this.loadingGuest = true;  
      this.cdr.detectChanges();           
      this.guestInfoComponent.initialize(this.visitorTimeLog);       
    }
  }

  @ViewChild('searchResident') set contentSearch(content: SearchResidentUnitsComponent) {
    this.searchResident = content;
    if (this.searchResident) {
      this.searchResident.residentSelectionChangeEmit().subscribe(people => {
        this.relationshipId = people.relationshipId;
        this.showGuestInfo = true;
        this.showSearchInfo = false;
        this.residents = this.searchResident.residents;
       this.areaId = people.areaId;
       this.unitName = people.unitName;
       this.areaName = people.areaName;
      });
    }
  }
  selectedVisitorComponent: CardsVisitorsComponent;
  @ViewChild('guestCardsComponent') set contentGuest(content: CardsVisitorsComponent) {
    this.selectedVisitorComponent = content;
    if (this.selectedVisitorComponent) {   
      this.loadingVisitor = true;  
      this.cdr.detectChanges();   
      this.selectedVisitorComponent.populateVisitors();          
      this.selectedVisitorComponent.visitorSelectionChangeEmit().subscribe(p => {        
        this.showResidentInfo = true;
        this.showGuestInfo = false;
        this.visitorTimeLog = p;
      });
    }
  }
  constructor(private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private communitySettingService: CommunitySettingsService,
    private visitorTimeLogService: VisitorsTimeLogService,
    private communityService: CommunityService) {
  }

  setBigFontFromStorage() {
    const aux = LocalStorageHelper.getBigFontFlag();
    if (aux !== null) {
      this.bigFont = aux;
    }
  }
  configureVisitorSetting(visitor?: VisitorsTimeLog) {
    if (this.communityId === 0) {
      this.guestInfoComponent.model.communityId = this.communityId;
      SiteHelper.openDialogAlert('Warning',
        'Select a community on breadcrumd',
        this.dialog);
      return;
    }
    this.loading = true;
    this.getCommunitySetting(this.communityId).subscribe(result => {
      if (!result) {
        SiteHelper.openDialogAlert('Warning',
          'you can not add new visitor for this community, please setting visitors for this community',
          this.dialog);
        this.loading = false;
        return;
      }
      if (!this.communitySettings.visitorsEnabled) {
        SiteHelper.openDialogAlert('Warning',
          'This community is not enabled visitors',
          this.dialog);
        this.loading = false;
        return;
      }
      if (this.isEdit) {
        this.showResidentInfo = true;
        this.cdr.detectChanges();    
      } 
      this.loading = false;
      
    });
  }
  getCommunitySetting(communityId: number): Observable<boolean> {
    const flag = new Subject<boolean>();
    forkJoin(this.communitySettingService.getByCommunityId(communityId))
      .subscribe(sett => {
        if (sett.length > 0 && sett[0]) {
          this.communitySettings = sett[0];
          flag.next(true);
        } else {
          flag.next(false);
        }
      });
    return flag.asObservable();
  }
  ngOnInit() {  
    this.showGuestInfo = false;
    this.setBigFontFromStorage();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      const community = LocalStorageHelper.getCommunitiesFromBreadcrumb();
      if (this.communityId !== community) {
        this.communityId = community;
        this.communityName = LocalStorageHelper.getCommunityNameFromBreadcrumb();
        this.configureVisitorSetting();
      }
    });
    this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.sub = this.route.paramMap.subscribe(
      params => {
        this.visitorsTimeLogId = +params.get('id');
        if (this.visitorsTimeLogId > 0) {
          this.loading = true;
          this.showSearchInfo = false;
          this.visitorTimeLogService.getById(this.visitorsTimeLogId).subscribe(visitor => {
            this.visitorTimeLog = visitor;
            this.isEdit = true;            
            if (this.communityId === 0) {
              this.communityId = visitor.communityId;
              this.communityService.getById(this.communityId).subscribe(c => this.communityName = c.name);
              this.configureVisitorSetting(visitor);
            } else {
              this.communityName = LocalStorageHelper.getCommunityNameFromBreadcrumb();
              this.configureVisitorSetting(visitor);              
            } 
          });
        } else { 
          this.showResidentInfo = false; 
          this.showSearchInfo = true;
          this.configureVisitorSetting();
         }
      }); 
  }
  save() {
    this.cdr.detectChanges();
    this.disabled = true;
    if (this.guestInfoComponent.model.visitorsTimeLogId === 0 || this.guestInfoComponent.model.visitorsTimeLogId === null) {
      this.addVisitor();
    } else {
      this.editVisitor();
    }
  }

  addVisitor() {
    if (this.guestInfoComponent.validateVisitor()) {      
      this.loading = true;
      const form = this.guestInfoComponent.getData();

      this.visitorTimeLogService.visit(form).subscribe(v => {
        let visitor: VisitorsTimeLog;
        this.visitorTimeLogService.getById(v.id).subscribe(x => {
          visitor = x;
          if (this.guestInfoComponent.parkingPass && this.guestInfoComponent.parkingPass.checked) {
            this.printParkingPass(visitor);
          }
          if (this.guestInfoComponent.badge && this.guestInfoComponent.badge.checked) {
            this.printBadge(visitor);
          }

          /*if (!this.guestInfoComponent.badge.checked && !this.guestInfoComponent.parkingPass.checked) {*/
          this.onCancel();
          /*}*/
        });
      }, error => this.onCancel());
    } else {
      this.disabled = false;
    }
  }

  printParkingPass(v: VisitorsTimeLog) {
    const veh: any = v;
    const area = this.guestInfoComponent.areaName;
    const color = this.communitySettings.vehicleColor && veh.colorId != null ?
      this.guestInfoComponent.colors.filter(c => c.colorId === veh.colorId)[0].name : '';
    const expirationDate = veh.decalExpirationDate;
    const unit = this.guestInfoComponent.unitName;
    const vehicleLicencePlate = veh.tagNumber ?
      veh.tagNumber : '';
    const year = veh.year;
    const model = veh.model ? veh.model : '';
    const brand = this.communitySettings.vehicleMaker && veh.vehicleBrandId !== null ?
      this.guestInfoComponent.vehicleMarks.filter(c => c.vehicleBrandId === veh.vehicleBrandId)[0].name : '';

    const decalNumber = veh.parkingDecalNumber;
    var docP = window.open(`/parkingPass/?area=${area}
   &unit=${unit}
   &color=${color}
   &expirationDate=${expirationDate}
   &vehicleLicencePlate=${vehicleLicencePlate}
   &year=${year}
   &model=${model}
   &decalNumber=${decalNumber}
   &brand=${brand}`, '_blank');
    if (docP) {
      docP.onafterprint = function () {
        docP.close();
      };
    }
  }

  printBadge(v: VisitorsTimeLog) {
    const firstName = v.firstName;

    const lastName = v.lastName;

    const fileId = v.picture;

    const visitorType = v.visitorTypeId ? this.guestInfoComponent.visitorTypes.filter(
      c => c.visitorTypeId === v.visitorTypeId)[0].name : '';

    const company = v.companyId ? this.guestInfoComponent.companies.filter(
      c => c.companyId === v.companyId)[0].name : '';

    const building = this.guestInfoComponent.areaName;

    const unitNumber = this.guestInfoComponent.unitName;
    /*
        window.open(`/badge/?firstName=${firstName}
        &lastName=${lastName}
        &visitorType=${visitorType}
        &company=${company}
        &building=${building}
        &unitNumber=${unitNumber}
        &fileId=${fileId}`, '_blank');
    */

    var doc = window.open(`/badge/?firstName=${firstName}
    &lastName=${lastName}
    &visitorType=${visitorType}
    &company=${company}
    &building=${building}
    &unitNumber=${unitNumber}
    &fileId=${fileId}`, '_blank');
    if (doc) {
      doc.onafterprint = function () {
        doc.close();
      };
    }
  }

  editVisitor() {
    if (this.guestInfoComponent.validateVisitor()) {
      this.loading = true;
      const form = this.guestInfoComponent.getData();
      this.visitorTimeLogService.updateVisit(form).subscribe(_ => {
        let visitor: VisitorsTimeLog;
        this.visitorTimeLogService.getById(this.visitorsTimeLogId).subscribe(v => {
          visitor = v;
          if (this.guestInfoComponent.parkingPass && this.guestInfoComponent.parkingPass.checked) {
            this.printParkingPass(visitor);
          }
          if (this.guestInfoComponent.badge && this.guestInfoComponent.badge.checked) {
            this.printBadge(visitor);
          }
          this.onCancel();
        });
      }, error => this.onCancel());
    } else {
      this.disabled = false;
    }
  }
  reset() {
    this.guestInfoComponent.newVisitor();
  }
  onCancel() {
    this.router.navigateByUrl('/app/visitors/visitorlog');
  }
}
