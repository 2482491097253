import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { RingEntityTable } from '../model/ring-entity-table';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RingEntityService extends AbstractDataProviderService<RingEntityTable> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.SortingHat, '/sortingHat/templates', http, appConfig);
  }

  getAllRingEntities(): Observable<RingEntityTable[]> {
    const queryString = '/allRingEntities/';
    return this.http.get<RingEntityTable[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

}
