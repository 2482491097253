import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Services
import { AuthGuardService } from 'src/app/services/auth-guard.service';

// Components
import { DocumentAddEditComponent } from '../document-add-edit/document-add-edit.component';
import { DocumentListComponent } from '../document-list/document-list.component';


const documentRoutes: Routes = [{
  path: 'document',
  children: [
    {
      path: '',
      component: DocumentListComponent,
      data: { title: 'Documents' },
      canActivate: [AuthGuardService]
    },
    {
      path: ':id/edit',
      component: DocumentAddEditComponent,
      data: { title: 'Add Documents' },
      canActivate: [AuthGuardService]
    },

  ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(documentRoutes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class DocumentRoutingModule { }
