import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from 'src/app/app-constants';
import { Document } from '../model/document';
import { DocumentPricePlan } from '../model/documentPricePlan';
import { Observable, BehaviorSubject } from 'rxjs';
import { CrudResponseModel } from '../../../../src/app/model/crud-response-model';
import { PricePlan } from '../model/price-plan';
import { LevelFilter } from 'src/app/model/breadcrumb';
import { APP_CONFIG, AppConfig } from 'src/app/app.config';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { AbstractDataProviderService } from 'src/app/services/abstract-data-provider.service';
import { CountResultModel } from 'src/app/model/count-result-model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentService extends AbstractDataProviderService<any> {

  private userInfo = new BehaviorSubject(LocalStorageHelper.getUserInfo());
  currentUser = this.userInfo.asObservable();


  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.Thorin, '/document', http, appConfig, LevelFilter.Community);
  }

  getDocById(id: number): Observable<DocumentPricePlan> {
    const queryString = '/getById/' + id.toString();
    return this.http.get<DocumentPricePlan>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  addDocument(doc: DocumentPricePlan): Observable<CrudResponseModel> {
    return this.http.post<CrudResponseModel>(this.baseUrl + '/add', doc, { headers: this.buildPostHeaders() });
  }

  updateDocument(doc: DocumentPricePlan): Observable<null> {
    return this.http.put<null>(this.baseUrl + '/update', doc, { headers: this.buildPostHeaders() });
  }

  getDocumentsByMCAndCommunityId(propertyManagementCompanyId: number, communityId: number): Observable<Document[]> {
    return this.http.get<Document[]>(this.baseUrl + '/getByCompanyAndCommunity/' +
    '?mcId=' + propertyManagementCompanyId + '&communityId=' + communityId, { headers: this.buildGetHeaders() });
  }

  // getDocumentsForPricePlanByMCAndCommunityId(propertyManagementCompanyId: number, communityId: number): Observable<Document[]> {
  //   return this.http.get<Document[]>(this.baseUrl + '/getByCompanyAndCommunity/' +
  //   '?mcId=' + propertyManagementCompanyId + '&communityId=' + communityId, { headers: this.buildGetHeaders() });
  // }

  getAllPaged(pageIndex: number, pageSize: number): Observable<Document[]> {
     this.setBreadcrumbParams();
      const queryString  = '/all/' + pageIndex.toString() + '/' + pageSize.toString() +
        '?mcId=' + this.searchParamsBreadcrumb.propertyManagementCompanyId + '&communityId=' + this.searchParamsBreadcrumb.communityId;
    return this.http.get<Document[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

}
