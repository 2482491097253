export class PropertyManagementCompany {
    propertyManagementCompanyId = 0;
    parent = null;
    companyId: number;
    name: string;
    isDeleted = false;
/**
     * The creation date of this record.
     */
    creationDate: Date = null;

    /**
     * The ID of the user that created this record.
     */
    creationUserId = 0;

    /**
     * The modification date of this record.
     */
    lastModification: Date = null;

    /**
     * The ID of the user that last modified this record.
     */
    modificationUserId = 0;
}
