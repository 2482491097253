export class CreditCard {
    creditCardId: number;
    userDataId: number;
    token: number;
    lastDigits: number;
    name: string;
    creditCardTypeId: number;
    expDate: number;
    paypointId: number;
    sourceCommunityCode: string;
}
