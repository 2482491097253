import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends AbstractDataProviderService<Storage> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.TardisService, '/storage', http, appConfig);
  }
  getByCommunityId(communityId: number): Observable<Storage[]> {
    return this.http.get<Storage[]>(this.baseUrl + '/byCommunityId/' + communityId, { headers: this.buildGetHeaders() });
  }
}
