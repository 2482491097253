import { Address } from "./address";
import { PhoneNumber } from "./phone-number";
import { Email } from "./email";
import { TimeShift } from "./time-shifts";
import { FormField } from "../building-blocks/decorators/reactive-form-decorators";
import { Validators, FormArray } from "@angular/forms";
import { BaseForm } from "../building-blocks/base-form";
import { AddressTableEntry } from "./address-table-entry";
import { SafeUrl } from "../../../node_modules/@angular/platform-browser";

export interface LocationOffice {
  companyId:               number;
  name:                    string;
  locationTypeId:          number;
  fileId:                  number;
  iconId:                  number;
  showInOfficeInformation: boolean;
  locationId:              number;
  timeShifts:              TimeShift[];
  specialDates:            any[];
  addresses:               Address[];
  phoneNumbers:            PhoneNumber[];
  emails:                  Email[];
  picture:                 SafeUrl;
}

export class OfficeInformation extends BaseForm {
  companies: number[] = [];
  companyId: number = 0;
  locationTypeId: number = 0;
  fileId: number = 0;
  iconId: number;
  showInOfficeInformation: boolean = false;
  locationId: number = 0;
  locationStatusId: number = 0;
  timeShifts: TimeShift[] = [];
  specialDates: any[] = [];
  addresses: Address[] = [];
  phoneNumbers: PhoneNumber[] = [];
  emails: Email[] = [];
  locationType: string = '';
  // picture: string = '';

  @FormField('name', ['', Validators.required])
  name = '';

  @FormField('address', [], AddressTableEntry,  true)
  address: FormArray;

  @FormField('phones', [], PhoneNumber,  true)
  phone: FormArray;

  @FormField('emails', [], Email,  true)
  email: FormArray;

}



