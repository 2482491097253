import { Component, Injector, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { Package } from 'src/app/model/package';
import { BaseList } from 'src/app/building-blocks/base-list';
import { Observable, fromEvent, Subject } from 'rxjs';
import { HotPackagesService } from 'src/app/services/hot-packages.service';
import { map, debounceTime, switchAll } from 'rxjs/operators';
import { SecurityHelper } from 'src/app/helpers/security-helper';
import { MatIconRegistry } from 'material2/src/lib';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { PackageStatusService } from '../../services/package-status.service';
import { PackagesStatus } from '../../model/packages-status';
import { Router } from '../../../../node_modules/@angular/router';

const packageColumns = [
  'receivedDate',
  'unit',
  'recipient',
  'carrierName',
  'trackNumber',
  'actions',
];

@Component({
  selector: 'app-packages-list',
  templateUrl: './packages-list.component.html',
  styleUrls: ['./packages-list.component.css']
})
export class PackagesListComponent extends BaseList<Package> implements  AfterViewInit {
  @ViewChild('filter') filterInput: ElementRef;
  private filterObservable: Observable<any>;
  private filterStatus: Subject<boolean> = new Subject<boolean> ();
  protected packagesStatus: PackagesStatus[] = [];
  isStaff: boolean;
  constructor(
    public injector: Injector,
    private cdr: ChangeDetectorRef,
    private _route: Router,
    private packageService: HotPackagesService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private packageStatusService: PackageStatusService,) {
       super(packageColumns, '/hotpackages', packageService, 'hotPackages', injector);
       this.filters = '';
       this.isStaff = SecurityHelper.LoggedUserIsStaff();
       this.loading = true;  
       this.matIconRegistry.addSvgIcon(
        "deliver",
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/deliver.svg')
      );
      this.matIconRegistry.addSvgIcon(
        "package",
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/packages.svg')
      );
      this.matIconRegistry.addSvgIcon(
        "search",
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/search.svg')
      );
   }

   ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.filterInput) {
    this.filterObservable = fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((e: any) => {
          return e.target.value;
        }),
        debounceTime(1000),
        map((q: string) => {
          if (q.length >= 2) {
            this.applyFilter(q);
            this.filterStatus.next(true);
          } else {
            if (q.length === 0 && this.filterStatus) {
              this.applyFilter('');
              this.filterStatus.next(true);
            }
          }
        },
        switchAll()));
        this.filterObservable.subscribe();
        this.filterStatus.subscribe(f => {
          this.loading = f;
        });
      }

      this.packageStatusService.getAll().subscribe(est => {
        this.packagesStatus = est;
      });
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Package[]> {
    return this.packageService.getAllPaged(pageIndex, pageSize, filter);
  }

  protected getCount(): Observable<number> {
    return this.packageService.getCount(this.dataSource.filter);
  }

  protected getById(id: number): Observable<Package> {
    return this.packageService.getById(id);
  }
  public edit(row) {
    this._route.navigateByUrl('/app/packages/' + + row.hotPackageId + '/edit');
  }
  public deliver(row) {
    this._route.navigateByUrl('/app/packages/deliver/' + row.hotPackageId);
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any {}

}
