import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsFeedRoutingModule } from './news-feed-routing.module';
import { CreateEditPostComponent } from '../news-feed/create-edit-post/create-edit-post.component';
import { ViewDetailPostComponent } from '../news-feed/view-detail-post/view-detail-post.component';
import { FormsModule } from '../../../node_modules/@angular/forms';
import { MaterialModule } from '../material.module';
import { EnumKeyValueListPipe } from '../pipes/enum-list.pipe';

@NgModule({
  imports: [
    CommonModule,
    NewsFeedRoutingModule,
    FormsModule,
    MaterialModule
  ],
  exports: [
    NewsFeedRoutingModule
  ],
  declarations: [CreateEditPostComponent, ViewDetailPostComponent,  EnumKeyValueListPipe]
})
export class NewsFeedModule { }
