import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';

import { Tag } from '../model/tag';
import { CrudResponseModel } from '../model/crud-response-model';
import { Observable } from 'rxjs';
import { TagTableEntry } from '../model/tag-table-entry';

@Injectable({
  providedIn: 'root'
})
export class TagService extends AbstractDataProviderService<Tag> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/tag', http, appConfig);
  }

  bulk(tags: TagTableEntry[]): Observable<CrudResponseModel> {
    return this.http.post<CrudResponseModel>(this.baseUrl + '/bulk', tags, { headers: this.buildPostHeaders() });
  }

  linkTagToArea(tagId: number, areaId: number): Observable<CrudResponseModel> {
    const body = {
      'tagId': tagId,
      'areaId': areaId
    };

    return this.http.post<CrudResponseModel>(this.baseUrl + '/link/area', body, { headers: this.buildPostHeaders() });
  }

  unlinkTagFromArea(tag: TagTableEntry): Observable<CrudResponseModel> {
    return this.http.post<CrudResponseModel>(this.baseUrl + '/unlink/area', tag, { headers: this.buildPostHeaders() });
  }

  getByAreaId(areaId: number): Observable<TagTableEntry[]> {
    return this.http.get<TagTableEntry[]>(this.baseUrl + '/area/' + areaId.toString(), { headers: this.buildGetHeaders() });
  }

}
