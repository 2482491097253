import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { PropertyManagementCompany } from '../../model/property-management-company';

@Component({
  selector: 'app-managment-company-dialogs',
  templateUrl: './managment-company-dialogs.component.html',
  styleUrls: ['./managment-company-dialogs.component.css']
})
export class ManagmentCompanyDialogsComponent implements OnInit {
  public propertyManagmentCompanies: PropertyManagementCompany[];
  public selectManagementCompany: number;
  constructor(
    public dialogRef: MatDialogRef<ManagmentCompanyDialogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.propertyManagmentCompanies = this.data.managenetComanies;
    if (this.data.propertyManagementCompanyId) {
      this.selectManagementCompany = this.data.propertyManagementCompanyId;
    }
  }

  onChangeSelection(id: number) {
    this.selectManagementCompany = id;
  }
  ngSelect(): void {
    this.dialogRef.close({
      valid: true,
      data: {
        propertyManagementCompanyId: this.selectManagementCompany
      }
    });
  }

}

