
import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class Entity extends BaseForm {
    entityId = 0;

     /**
     * The entityTypeId of the Entity.
     */
    @FormField('entityTypeId', ['', Validators.required])
    entityTypeId = 0;

     /**
     * The entityKeyId of the Entity.
     */
    @FormField('entityKeyId', ['', Validators.required])
    entityKeyId = 0;
    /**
     * The name of the Entity.
     */
    @FormField('name', ['', Validators.required])
    name = '';

    /**
     * The description of the Entity.
     */
    @FormField('description', ['', Validators.required])
    description = '';
}
