import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseList } from '../../../../../src/app/building-blocks/base-list';
import { Document } from '../../model/document';
import { DocumentService } from '../../services/document.service';
import { DocumentPricePlan } from '../../model/documentPricePlan';
import { CommonService } from '../../services/common.service';


const documentColumns = [
  'name',
  'description',
  'isActive',
  'actions'
];

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent extends BaseList<Document> {
  public model: Document;
  constructor(
    public injector: Injector,
    private documentService: DocumentService,
    private commonService: CommonService) {
      super(documentColumns, '/docs/document', documentService, 'document', injector);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Document[]> {
    return this.documentService.getAllPaged(pageIndex, pageSize);
  }

  protected getCount(): Observable<number> {
     return this.commonService.getCountByBreadcumd('Documents.Document');
  }

  protected getById(id: number): Observable<DocumentPricePlan> {
    return this.documentService.getById(id);
  }

  editDocument(documentId: number): void {
    this.router.navigateByUrl('/app/docs/document/' + documentId + '/edit');
  }

  addDocument(): void {
    this.router.navigateByUrl('/app/docs/document/' + 0 + '/edit');
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: any): any {}

  // ngOnDestroy() {
  //   if (this.breadcrumbChanges) {
  //     this.breadcrumbChanges.unsubscribe();
  //   }
  // }

}
