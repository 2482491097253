
export namespace stringHelper  {

 export function base64ToArrayBuffer(base64: any) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
       const ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }

 export function  jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function initCap(string) {
    return string.toLowerCase().replace(/(?:^|\b)[a-z]/g, function (m) {
        return m.toUpperCase();
     });
}

export function dataURLtoBlob(dataurl) {
   const arr = dataurl.split(',');
   const mime = arr[0].match(/:(.*?);/)[1];
   const bstr = atob(arr[1]);
   let n = bstr.length;
   const  u8arr = new Uint8Array(n);
   while (n --) {
       u8arr[n] = bstr.charCodeAt(n);
   }
   return  new Blob([u8arr], {type: mime});
}
export async function createFile(path) {
    const response = await fetch(path);
    const data = await response.blob();
    const metadata = {
      type: 'image/svg'
    };
    const file = new File([data], 'icon.svg', metadata);
    return file;
  }
    export function convertTime12to24(time12h) {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }

}
