import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// tslint:disable-next-line:max-line-length
import { AnonymousPaymentFindPeopleComponent } from '../anonymous-payment-find-people/anonymous-payment-find-people.component';
import { MaterialModule } from '../material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
  ],
  declarations: [AnonymousPaymentFindPeopleComponent]
})
export class AnonymousPaymentModule { }
