export class Application {
    applicationId: number;
    name: string;
    isDeleted: boolean;
    creationDate: Date;
    creationUserId: number;
    lastModification: Date;
    lastModificationUserId: number;
}

export class ClassificationItem {
    classificationItemId: number;
    name: string;
    isDeleted: boolean;
    creationDate: Date;
    creationUserId: number;
    lastModification: Date;
    lastModificationUserId: number;
}

export class Provider {
    providerId: number;
    name: string;
    isDeleted: boolean;
    creationDate: Date;
    creationUserId: number;
    lastModification: Date;
    lastModificationUserId: number;
}

export class PayPoint {
    paypointId: number;
    paypointNumberId: number;
    paypointNumber: string;
    providerId: number;
    sourceCommunityCode: string;
}

