import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Observable } from 'rxjs';
import { C3peoCommunity } from '../model/c3peo-tree-community-info';

@Injectable({
  providedIn: 'root'
})
export class ImportDataFromC3POService extends AbstractDataProviderService<C3peoCommunity> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/c3poCommunity', http, appConfig);
  }

  importDataFromC3PO(communityC3PO: C3peoCommunity): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + '/import/' , communityC3PO, { headers: this.buildGetHeaders() });
  }

}
