import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { LocalStorageHelper } from '../helpers/local-storage-helper';

import { C3POTransaction } from '../model/c3po-transaction';
import { C3PEOTreeCommunityInfo } from '../model/c3peo-tree-community-info';
import { catchError } from 'rxjs/operators';
import { C3POBalance } from '../model/c3-pobalance';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class C3POService {

  public baseUrl: string;
  public authApiKey = false;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    if (AppConstants.EndpointNames.C3PO in appConfig.endpoints) {
      const endpoint = appConfig.endpoints[AppConstants.EndpointNames.C3PO];
      this.baseUrl = endpoint;
    } else {
      console.error('Invalid endpoint name passed to data provider.');
    }
  }

  /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  private buildGetHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();

    let getHeaders = new HttpHeaders();
    if (this.authApiKey) {
        getHeaders = getHeaders.set('Authorization', 'api-key ' + environment.apiKeys.C3PO);
    }  else {
        getHeaders = getHeaders.set('Authorization', 'bearer ' + token);
    }

    return getHeaders;
  }

  getBalance(sourceId: number, accountCode: string): Observable<C3POBalance> {

    const requestUrl = this.baseUrl
      + '/getBalance/?sourceId=' + sourceId
      + '&accountCode=' + accountCode;
      return this.http.get<C3POBalance>(requestUrl, { headers: this.buildGetHeaders() } );
  }

  getTransactions(sourceId: number, accountCode: string): Observable<C3POTransaction[]> {

    const requestUrl = this.baseUrl
      + '/getTransactions/?sourceId=' + sourceId
      + '&accountCode=' + accountCode;
    return this.http.get<C3POTransaction[]>(requestUrl, { headers: this.buildGetHeaders() } )
    .pipe(
      catchError(this.handleError)
    );
  }

  getsTreeByCommunityAndAreas(sourceId: number, communityCode: string, areaCodes: string): Observable<C3PEOTreeCommunityInfo> {
      let paramAreaCode = '';
      areaCodes.split(',').forEach(a => {
        paramAreaCode += '&areaCode=' + a.trim();
      });

      const requestUrl = this.baseUrl
      + '/getsTreeByCommunityAndAreas/?sourceId=' + sourceId
      + '&communityCode=' + communityCode + paramAreaCode;
    return this.http.get<C3PEOTreeCommunityInfo>(requestUrl, { headers: this.buildGetHeaders() } );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}`);
    }

    return throwError(
      `Connection error with the service. Code ${error.status}. {error.error.errorMessage}`);
  }

}
