import { AfterViewInit, Component, Inject, OnInit, ViewChild, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
} from '@angular/material';

import { ConfirmDialogComponent } from '../../app-dialogs/confirm-dialog/confirm-dialog.component';

import { RoleService } from '../../services/role.service';
import { Role } from '../../model/role';
import { ApiKeyService } from '../../services/api-key.service';
import { ApiKey } from '../../model/api-key';
import { RolesEntry } from '../../model/roles-entry';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { AccessType } from 'src/app/model/access-type-ring';

@Component({
  selector: 'app-api-keys-add-edit-dialog',
  templateUrl: './api-key-add-edit-dialog.component.html',
  styleUrls: ['./api-key-add-edit-dialog.component.css']
})
export class ApiKeyAddEditDialogComponent extends BaseSecurity implements  OnInit {

  public model: ApiKey;
  public roles: RolesEntry[];
  public rolesAll: Role[];
  public roleModel: RolesEntry;
  public apiKeyFormGroup: FormGroup;
  public rolesToWipe: Role[];
  public rolesToAdd: Role[];

  constructor(public dialogRef: MatDialogRef<ApiKeyAddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private apiKeyService: ApiKeyService,
    private roleService: RoleService) {
    super();
      this.model = new ApiKey(this.fb);
      this.apiKeyFormGroup = this.model.buildFormGroup();
      this.loadSecurityRings();
     }
     protected loadSecurityRings() {
      this.securityEntity.rings.push({ringId : 550, accessType: AccessType.Add });
      this.securityEntity.rings.push({ringId : 750, accessType: AccessType.Update });
      this.entityRings.push(this.securityEntity);
    }

  ngOnInit() {
    this.roleModel = new RolesEntry();
    this.roles = [];
    this.rolesToWipe = [];
    this.rolesToAdd = [];

    if (this.data.model !== undefined && this.data.model !== null && !this.data.model.update) {
      this.data.model.roles = [];
    }

    const update = (this.data.model) ? true : false;

    this.roleService.getAll().subscribe(x => {
      this.rolesAll = x;
      for (const rol of this.rolesAll) {
        const role = new RolesEntry();
        role.roleId = rol.roleId;
        role.name = rol.name;
        if (update && this.data.model.update) {
          role.selected = this.data.model.roles.find(r => r.roleId ===  rol.roleId) !== undefined;
        }
        role.persisted = true;
        this.roles.push(role);
      }
   });

    if (this.data.update === true) {
      this.model.fromObject(this.data.model.entity);
    }
  }

  onChangeRole(event, index, item): void {
    const role = this.roles.find(r => r.roleId === item.roleId);
    role.selected = !item.selected;
    if (!role.selected) {
      this.removeRole(item);
    } else {
      this.addRole(item);
    }
  }

  addRole(role: Role): void {
    const idx = this.rolesToWipe.findIndex(x => x.roleId === role.roleId);
    if (idx > -1) {
      this.rolesToWipe.splice(idx, 1);
    }
    if (this.data.update === false ||
      (this.data.update === true && this.data.model.roles.findIndex(r => r.roleId ===  role.roleId) === -1)) {
       this.rolesToAdd.push(role);
    }
  }

  removeRole(role: Role): void {
    const idx = this.rolesToAdd.findIndex(x => x.roleId === role.roleId);
    if (idx > -1) {
       this.rolesToAdd.splice(idx, 1);
    }
    if (this.data.update === true) {
      this.rolesToWipe.push(role);
    }
  }

  onGenerate(): void {
    this.apiKeyService.generateAPIKey().subscribe(x => {
      this.model.apiKey = x;
    });
  }

  onSubmit(): void {
    this.dialogRef.close({
       valid: true,
       roles: this.rolesToAdd,
       rolesToWipe: this.rolesToWipe,
       entity: this.model.toDto(),
     });
   }

  onCancel(): void {
    if (this.apiKeyFormGroup.dirty) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: '750px'
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) { this.dialogRef.close({ valid: false }); }
      });
    } else { this.dialogRef.close({ valid: false }); }
  }

}
