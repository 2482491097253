import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

import { RoleListComponent } from '../role-list/role-list.component';
import { RingListComponent } from '../ring-list/ring-list.component';
import { EntityListComponent } from '../entity-list/entity-list.component';
import { ApiKeyListComponent } from '../api-key-list/api-key-list.component';

import { UsersListComponent } from '../users-list/users-list.component';
import { AuthGuardService } from '../../services/auth-guard.service';
import { TemplateListComponent } from '../template-list/template-list.component';
import { TemplateAddEditComponent } from '../template-add-edit/template-add-edit.component';
import { UserAddEditComponent } from '../user-add-edit/user-add-edit.component';

const securityRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'role',
        component: RoleListComponent,
        data: { title: 'Roles' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'ring',
        component: RingListComponent,
        data: { title: 'Rings' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'entity',
        component: EntityListComponent,
        data: { title: 'Entities' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'apiKey',
        component: ApiKeyListComponent,
        data: { title: 'Api Key' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'users',
        component: UsersListComponent,
        data: { title: 'user' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'templates',
        component: TemplateListComponent,
        data: { title: 'Template' },
        canActivate: [AuthGuardService]
      },

      {
        path: 'templates/:id/edit',
        component: TemplateAddEditComponent,
        data: { origin: 'template' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'add',
        component: UserAddEditComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'edit/:id',
        component: UserAddEditComponent,
        canActivate: [AuthGuardService]
      },
    ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(securityRoutes),
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class SecurityRoutingModule { }
