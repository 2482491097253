import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractDataProviderService } from './abstract-data-provider.service';
import { AppConfig, APP_CONFIG } from '../app.config';
import * as AppConstants from '../app-constants';
import { Staff } from '../model/Staff';
import { LevelFilter } from '../model/breadcrumb';
import { Observable } from 'rxjs';
import { CountResultModel } from '../model/count-result-model';
import { map } from 'rxjs/operators';
import { query } from '@angular/core/src/render3/query';

@Injectable({
  providedIn: 'root'
})
export class PeopleCommunityPositionService extends AbstractDataProviderService<Staff> {

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
  super(AppConstants.EndpointNames.GridVendor, '/people_CommunityPosition', http, appConfig, LevelFilter.Community);
  }

  getAllByAccessPaginated(pageIndex: number, pageSize: number, accessId: string, filter?: string, sortData?: string): Observable<Staff[]> {
    this.setBreadcrumbParams();
    const route = this.filterLevel ? '/allPagedFiltered/' : '/allPaged/';
    let queryString = route + pageIndex.toString() + '/' + pageSize.toString()  + (filter ? '/' + filter : '');

    if (this.filterLevel && this.filterLevel >= LevelFilter.PropertyManagementCompany) {
      queryString = queryString + '?propertyManagementCompanyId=' +  this.searchParamsBreadcrumb.propertyManagementCompanyId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Community) {
      queryString = queryString + '&communityId=' +  this.searchParamsBreadcrumb.communityId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Area) {
      queryString = queryString + '&areaId=' +  this.searchParamsBreadcrumb.areaId;
    }
    if (this.filterLevel && this.filterLevel >= LevelFilter.Unit) {
      queryString = queryString + '&unitId=' +  this.searchParamsBreadcrumb.unitId;
    }
    queryString = queryString + '&accessId=' + accessId;

    if (sortData) {
      const sortCol = sortData.split('|');
      if (sortCol[1] !== '') {
        queryString = queryString + '&sort=' + sortCol[0];
        queryString = (queryString + '&dir=') + (sortCol[1] !== '' ? sortCol[1] : 'asc');
      }
    }
    
    return this.http.get<Staff[]>(this.baseUrl + queryString, { headers: this.buildGetHeaders() });
  }

  getCountByBreadcumdByAccessId(accessId: string, filter?: string): Observable<number> {
    const endpoint = filter ? '/getCountAccessIdByParamsFiltered/' : '/getCountByParamsFiltered/';
      const queryString = endpoint + this.searchParamsBreadcrumb.propertyManagementCompanyId + '/'
       + this.searchParamsBreadcrumb.communityId + 
       '/' + this.searchParamsBreadcrumb.areaId +
       '/' + this.searchParamsBreadcrumb.unitId +
       (filter ? '/' + filter : '') +
       '/' + accessId;
        
    return this.http.get<CountResultModel>(this.baseUrl + queryString, { headers : this.buildGetHeaders() })
      .pipe(map(x => x.count));
  }


}
