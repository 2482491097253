import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { forkJoin } from 'rxjs';

import { CompanyService } from '../services/company.service';
import { CommunityService } from '../services/community.service';
import { LoginService } from '../services/login.service';
import { MenuService } from '../services/menu.service';
import { PeopleService } from '../services/people.service';
import { ServiceError } from '../model/service-error';
import { SystemInfoService } from '../services/system-info.service';
import { UserService } from '../services/user.service';

import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { SiteHelper } from '../helpers/site-helper';
import { FirebaseMessagingService } from '../services/firebase-messaging.service';
import { Community } from '../model/community';
import { ThemeService } from '../services/theme.service';
import { LoggedUser } from '../model/logged-user';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
})
export class LoginComponent implements OnInit {

  public loginFormGroup: FormGroup;
  public loginFailed: boolean;
  public loginErrorMessage: string;
  public loading: boolean;
  public location: Location;
  public isCommunity = false;
  public companyLogo = 'assets/images/gridsystems_logo.png';
  public companyInfo: string;
  public version: string;
  selectedTheme = 'grid-light-theme';

  constructor(
    private loginService: LoginService,
    private menuService: MenuService,
    private peopleService: PeopleService,
    private fb: FormBuilder,
    private router: Router,
    private companyService: CompanyService,
    private communityService: CommunityService,
    private systemInfoService: SystemInfoService,
    private userService: UserService,
    private firebaseMessaging: FirebaseMessagingService,
    private themeService: ThemeService,
    location: Location, ) {
      this.location = location;
      this.loginFormGroup = fb.group({
      username: [ '', Validators.required ],
      password: [ '', Validators.required ]
    });
  }

  login(): void {
    let username = this.loginFormGroup.get('username').value;
    if (username !== undefined && username !== null && typeof username === 'string') {
      username = username.trim();
    } else {
      return;
    }

    this.loading = true;
    this.loginFailed = false;
    this.loginService.login(
      username,
      this.loginFormGroup.get('password').value)
      .subscribe(result => {
        if (result) {
          this.loading = false;
          this.initApp();
        } else {
          // TODO: Display invalid user/pass.
        }
      }, (error: ServiceError) => {
        this.loginFailed = true;

        if (error.errorInfo.statusCode === 401) {
          if (error.errorInfo.code === 4) {
            this.router.navigate(['/confirm-forgot-password', username, 0]);
          } else if (error.errorInfo.code === 6) {
            this.router.navigate(['/confirm-forgot-password', username, 1]);
          } else if (error.errorInfo.code === 12) {
            this.router.navigate(['/confirm-email', username, error.token]);
          } else {
            // logon denied because of error.error.description with code error.error.code
            this.loginErrorMessage = 'Unauthorized: ' + error.errorInfo.description;
          }
        } else if (error.errorInfo.statusCode === 500) {
          // logon denied because of server error. error.error.description and error.error.code contain info.
          this.loginErrorMessage = 'Server error: ' + error.errorInfo.description;
        } else {
          // unknown error.
          this.loginErrorMessage = 'An unexpected error occurred.';
        }
        this.loading = false;
      });
  }

  setCommunityInfo() {
    this.companyService.currentCompanyLogo.subscribe(logo => {
      if (logo !== undefined && logo !== null && logo !== '') {
      this.companyLogo = logo;
      }
    });

    this.companyService.currentCompanyInfo.subscribe(info => {
      this.companyInfo = info;
    });

    this.loading = false;
  }


  ngOnInit() {
    const currentDomain = location.host.split('.')[0];
    if (currentDomain !== undefined
      && currentDomain !== 'localhost'
      && currentDomain === 'castlegroupdrawbridge') {
      this.selectedTheme = 'grid-castle-theme';
      this.companyLogo = 'assets/images/logo-castle.png';
    }

    // Check whether we should be displaying a community site.
   /* if (SiteHelper.isCommunity()) {

      this.isCommunity = true;
      this.loading = true;

      // TODO: review behavior.
      this.communityService.getBySubDomain(location.host.split('.')[0]).subscribe(c => {
        if (c) {
          this.companyService.setCommunity(c);
          this.setCommunityInfo();
        } else {
          SiteHelper.goToLogin();
          this.isCommunity = false;
          this.loading = false;
          return;
        }
      });

      this.version = LocalStorageHelper.getVersionInfo();
      if (this.version == null) {
        this.systemInfoService.getServiceInfo().subscribe(s => {
          LocalStorageHelper.setSystemInfo(s);
          this.version =  s[0].version;
        });
      }
    }*/
  }

  initApp(): void {
    this.loading = true;
    LocalStorageHelper.setUserImpersonatedInfo(null);
    LocalStorageHelper.setImpersonatedFlag(null);
    this.peopleService.setUserPicture();

    const serviceInfoObservable = this.systemInfoService.getServiceInfo();
    const menuObservable = this.menuService.getMenusByAppName('GEWEB');
    const paymentInfoObservable = this.peopleService.getPaymentInfo(LocalStorageHelper.getUsername());
    const peopleObservable = this.peopleService.GetForCurrentUser();
    const loggerUserObservable = this.userService.getLoggedUserInfo();

    forkJoin(
      serviceInfoObservable,
      menuObservable,
      paymentInfoObservable,
      loggerUserObservable,
      peopleObservable).subscribe(results => {    
      LocalStorageHelper.setSystemInfo(results[0]);
      LocalStorageHelper.setUserMenu(results[1][0]);
      LocalStorageHelper.setPaymentInfo(results[2]);
      LocalStorageHelper.setLoggedUserInfo(results[3], false);
      this.peopleService.setUserInfo(results[4]);
      this.loginService.loginSuccessful();
      this.firebaseMessaging.registerDeviceToUser();
      this.loading = false;
      this.router.navigate(['/']);
    }, error => {
      this.loading = false;
      this.loginErrorMessage = 'An unexpected error occurred.';
    });
  }

  goToFindPeople() {
    this.router.navigate(['payment']);
  }
}
