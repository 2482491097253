import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
    transform(collection: any[], properties: string[]): any {
        // in case of no array
        if (!collection) {
            return null;
        }
        const groupedCollection = collection.reduce(function (prev, current) {
            const key = properties.map(_ => `${current[_]}`).join(' :: ');
            if (!prev[key]) {
                prev[key] = [current];
            } else {
                prev[key].push(current);
            }
            return prev;
        }, {});

        // return array of objects where each one has the desired group
        const result = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
        return result;
    }
}
