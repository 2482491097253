import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { CreateEditPostComponent } from './create-edit-post/create-edit-post.component';
import { ViewDetailPostComponent } from './view-detail-post/view-detail-post.component';
import { CommonModule } from '../../../node_modules/@angular/common';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'view-details',
        component: ViewDetailPostComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: ':id/edit',
        component: CreateEditPostComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'new-post',
        component: CreateEditPostComponent,
        canActivate: [AuthGuardService],       

      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
  CommonModule],
  exports: [RouterModule]
})
export class NewsFeedRoutingModule { }
