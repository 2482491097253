import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VisitorLogEntryComponent } from './visitor-log-entry/visitor-log-entry.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { SettingVisitorComponent } from './setting-visitor/setting-visitor.component';
import { VisitorsTimeLogListComponent } from './visitors-time-log-list/visitors-time-log-list.component';
import { VisitorsLogListComponent } from './visitors-log-list/visitors-log-list.component';
import { VisitorLogDetailComponent } from './visitor-log-detail/visitor-log-detail.component';
import { VisitorTimeLogAddEditComponent } from './visitor-time-log-add-edit/visitor-time-log-add-edit.component';
import { VisitorParkingPassComponent } from './visitor-parking-pass/visitor-parking-pass.component';
import { VisitorBadgeComponent } from './visitor-badge/visitor-badge.component';
import { UnderConstructionComponent } from '../under-construction/under-construction.component';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [ {
  path: '',
  children: [
    {
      path: '',
      component: VisitorsTimeLogListComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'add',
      component: VisitorTimeLogAddEditComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'visitorlog',
      component: VisitorsLogListComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'settings',
      component: SettingVisitorComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'edit/:id',
      component: VisitorTimeLogAddEditComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'detail/:id',
      component: VisitorLogDetailComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'parkingPass',
      component: VisitorParkingPassComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'badge',
      component: VisitorBadgeComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'reports',
      component: UnderConstructionComponent,
      canActivate: [AuthGuardService]
    },
  ],
},
];


@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VisitorsRoutingModule {

}
