import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../../services/auth-guard.service';

import { PaymentComponent } from '../payment/payment.component';
import { TransactionsComponent } from '../transactions/transactions.component';

const ledgerRoutes: Routes = [{
  path: '',
  children: [
    {
      path: 'payment',
      component: PaymentComponent,
      data: { title: 'Component' },
      canActivate: [AuthGuardService]
    },
    {
      path: 'transactions',
      component: TransactionsComponent,
      data: { title: 'Transactions' },
      canActivate: [AuthGuardService]
    },
  ]
}];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ledgerRoutes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class LedgerRoutingModule { }
