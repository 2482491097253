import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG } from '../app.config';
import { AppConfig } from '../app-config';
import * as AppConstants from '../app-constants';
import { Observable, throwError } from 'rxjs';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import { Newsfeed } from '../model/newsfeed';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsfeedService {
  getNewsfeedById(arg0: any): any {
    throw new Error("Method not implemented.");
  }

  public baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) appConfig: AppConfig
  ) {
    if (AppConstants.EndpointNames.Newsfeed in appConfig.endpoints) {
      const endpoint = appConfig.endpoints[AppConstants.EndpointNames.Newsfeed];
      this.baseUrl = endpoint;
    } else {
      console.error('Invalid endpoint name passed to data provider.');
    }
  }

    /**
   * Builds a set of HTTP headers to send whenever a GET or DELETE request is issued.
   */
  private buildGetHeaders(): HttpHeaders {
    const token = LocalStorageHelper.getAuthToken();

    let getHeaders = new HttpHeaders();
    getHeaders = getHeaders.set('Authorization', 'bearer ' + token);

    return getHeaders;
  }

  getNewsfeedByUserId(): Observable<Newsfeed[]> {
    return this.http.get<Newsfeed[]>(this.baseUrl + '/newsfeed' , { headers: this.buildGetHeaders() })
    .pipe(
      catchError(this.handleError)
    );
  }

  save(newsfeed: Newsfeed): Observable<any> {
    return this.http.post<any>(this.baseUrl, newsfeed, { headers: this.buildGetHeaders() })
    .pipe(
      catchError(this.handleError)
    );
  }

  update(newsfeed: Newsfeed): Observable<any> {
    return this.http.put<any>(this.baseUrl , newsfeed, { headers: this.buildGetHeaders() })
    .pipe(
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/newsfeed' + id, { headers: this.buildGetHeaders() })
    .pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}`);
    }

    return throwError(
      `Connection error with the service. Code ${error.status}`);
  }

  

}
