import { Component, Input, Output, EventEmitter, Inject, TemplateRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CommunityConfiguration } from 'src/app/model/community-configuration';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CommunityImportationFromC3poComponent } from '../../community-importation-from-c3po/community-importation-from-c3po.component';
import { SiteHelper } from 'src/app/helpers/site-helper';
import { Source } from 'src/app/model/source';
import { CommunityMapping } from 'src/app/model/community-mapping';
import { CommunityMappingService } from 'src/app/services/community-mapping.service';
import { AddressTableEntry } from 'src/app/model/address-table-entry';
import { PhoneNumber } from 'src/app/model/phone-number';

@Component({
  selector: 'config-detail',
  templateUrl: './config-detail.component.html',
  styleUrls: ['./config-detail.component.scss']
})

export class ConfigDetailComponent {
  @Input() headerTitle: string = '';
  @Input() actionLabel: string = '';
  @Input() actionIcon: string = '';
  @Input() model: any = [];
  @Input() pageTemplate: TemplateRef<any> = null;
  @Output() onEditEvent: EventEmitter<any> = new EventEmitter<any>();


  //this code for edit
  modelEdit: CommunityConfiguration;
  modelMapping: CommunityMapping;
  communityConfigurationFormGroup: FormGroup;
  isUpdate: boolean = false;
  selectedTab: any = null;
  sources: Source[] = [];
  communityNameCtrl: FormControl;


  //address form
  addressFormGroup: FormGroup;
  addressModel: AddressTableEntry;

  //phone form
  phoneFormGroup: FormGroup;
  phoneModel: PhoneNumber;
  headerTitleEdit: any;


  constructor(private dialog: MatDialog,
    private dialogRef: MatDialogRef<ConfigDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = null,
    private fb: FormBuilder,
    private communityMappingService: CommunityMappingService) {
    this.modelEdit = new CommunityConfiguration(this.fb);
    this.communityConfigurationFormGroup = this.modelEdit.buildFormGroup();
    if (data && data.formModel !== undefined) {
      this.isUpdate = data.isUpdate;
      this.model = data.htmlModelRef;
      this.modelEdit = data.formModel;
      this.communityConfigurationFormGroup = data.formGroup;
      this.selectedTab = data.selectedTab;
      this.headerTitle = data.communityConfigDetailTitle;
      this.headerTitleEdit = data.communityConfigDetailTitleEdit;
      this.actionLabel = data.communityConfigDetailEditTitle;
      this.modelMapping = data.modelMapping;
      this.sources = data.sources;
      this.pageTemplate = data.pageTemplate;
      this.communityNameCtrl = data.communityNameCtrl;
      //for address
      this.addressFormGroup = data.addressFormGroup;
      this.addressModel = data.addressModel;
      //for phone
      this.phoneFormGroup = data.phoneFormGroup;
      this.phoneModel = data.phoneModel;
    } else {
      this.modelEdit.disableControls();
    }
  }

  public onEditGeneralDetails() {
    this.onEditEvent.emit();
  }
  
  public openDialogTree(areas: string): void {
    let data;
    data = {};
    const model = this.modelEdit.toDto(this.communityConfigurationFormGroup);
    model.communityId = this.modelEdit.communityId;
    let dialogRef: MatDialogRef<CommunityImportationFromC3poComponent>;
    if (this.modelMapping.communityMappingId === 0) {
      this.modelMapping.externalCode = model.externalCode;
      this.modelMapping.sourceId = model.sourceId;
      this.modelMapping.communityId = model.communityId;
      this.communityMappingService.add(this.modelMapping).subscribe(cm => {
        this.modelEdit.communityMappingId = cm.id;
        model.communityMappingId = cm.id;
        this.modelMapping.communityMappingId = cm.id;
        dialogRef = this.openTree(model, areas);
      });
    } else {
      dialogRef = this.openTree(model, areas);
    }
  }

  public openTree(model: any, areas: string): MatDialogRef<CommunityImportationFromC3poComponent> {
    const host = this.sources.filter(s => s.sourceId === model.sourceId)[0].hostId;
    const dialogRef = this.dialog.open(CommunityImportationFromC3poComponent, {
      width: '800px',
      data: { model: model, areas: areas, host: host },
      panelClass: SiteHelper.getDefaultTheme()
    });
    return dialogRef;
  }

  public save() {
    switch (this.selectedTab.name) {
      case 'pmTheme': {
        break;
      }
      case 'communityTheme' : {
        break;
      }
      default: {
        this.modelEdit.name = this.communityNameCtrl.value;
        this.dialogRef.close({
          data: {
            model: this.modelEdit.toDto(this.communityConfigurationFormGroup),
            address: {
              addressModel: this.addressModel,
              addressFormGroup: this.addressFormGroup
            },
            phoneNumber: {
              phoneModel: this.phoneModel,
              phoneFormGroup: this.phoneFormGroup
            }
          }
        });
        break;
      }
    }
   
  }

  public cancel() {
    this.dialogRef.close();
  }
}