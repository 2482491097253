import { Component, EventEmitter, Injector, Input, OnInit } from '@angular/core';
import { MatButton, MatDialog, MatSnackBar } from '@angular/material';

import { Observable } from 'rxjs';

import { BaseList } from '../../building-blocks/base-list';
import { BaseListDialogData } from '../../building-blocks/base-list-dialog-data';

import { Ring } from '../../model/ring';
import { CrudResponseModel } from '../../model/crud-response-model';
import { RingService } from '../../services/ring.service';
import { RingAddEditDialogComponent } from '../ring-add-edit-dialog/ring-add-edit-dialog.component';
import { isUndefined } from 'util';
import { SiteHelper } from '../../helpers/site-helper';

const ringColumns = [
  'ringId',
  'ringTypeName',
  'permitionTypeName',
  'entityName',
  'name',
  'description',
  'actions'
];

@Component({
  selector: 'app-ring-list',
  templateUrl: './ring-list.component.html',
  styleUrls: ['./ring-list.component.css']
})
export class RingListComponent extends BaseList<Ring> {

  constructor(
    public injector: Injector,
    private ringService: RingService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar) {
      super(ringColumns, '/security/ring', ringService, 'ring', injector);
  }

  protected getList(pageIndex: number, pageSize: number, filter: string): Observable<Ring[]> {
    return this.ringService.getAllPaginated(pageIndex, pageSize);
  }

  protected getCount(): Observable<number> {
    return this.ringService.getCount();
  }

  protected openEditForm(isUpdate: boolean, model: any, emitter: EventEmitter<CrudResponseModel>): any {
    const dialogRef = this.dialog.open(RingAddEditDialogComponent, {
      panelClass: [SiteHelper.getDefaultTheme() , 'dialog-panel'],
      data: new BaseListDialogData<Ring>(isUpdate, model, emitter)
    });

    return dialogRef;
  }

  protected ShortenStringDescription(description: string): string {
    if (isUndefined(description)) {
      return '';
    } else if (description.length > 20) {
      return description.substring(0, 17) + '...';
    }
    return description;
  }
}

