import { Component, OnInit } from '@angular/core';

import { DynamicMenuService } from '../../../services/dynamic-menu.service';
import { DynamicMenu } from '../../../model/dynamic-menu';

@Component({
  selector: 'app-dynamic-menu',
  templateUrl: './dynamic-menu.component.html',
  styleUrls: ['./dynamic-menu.component.css']
})
export class DynamicMenuComponent implements OnInit {

  private menu: DynamicMenu;

  constructor(private menuService: DynamicMenuService) { }

  ngOnInit() {
    this.menuService.getTest().subscribe(x => {
      this.menu = x;
    });
  }

}
