import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../app.config';
import { HttpClient } from '@angular/common/http';
import * as AppConstants from '../app-constants';
import { AbstractDataProviderService } from './abstract-data-provider.service';
import { R2D2 } from '../model/r2d2';

@Injectable({
  providedIn: 'root'
})

export class R2D2Service extends AbstractDataProviderService<R2D2> {
  constructor(protected http: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig) {
    super(AppConstants.EndpointNames.GridVendor, '/R2D2', http, appConfig);
  }
  
 SaveIntoJenark(toSave: R2D2, userId: number ): any{
  return this.http.post<any>(this.baseUrl + '/SaveIntoJenark/'+userId, toSave, { headers: this.buildGetHeaders()});
 } 
}
