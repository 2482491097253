import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';
import { AddressTableEntry } from './address-table-entry';
import { PhoneNumber } from './phone-number';

export class CommunityConfiguration extends BaseForm {
    communityConfigurationId = 0;

    @FormField('communityId', [])
    communityId = 0;

    @FormField('url', [])
    url = '';

    @FormField('domain', ['', Validators.required])
    domain = '';

    @FormField('sourceId', [])
    sourceId = 0;

    @FormField('allowPayment', [false])
    allowPayment =  false;

    @FormField('canLegalStatusPay', [false])
    canLegalStatusPay = false;

    @FormField('paymentMessage', [])
    paymentMessage = '';

    @FormField('legalMessage', [])
    legalMessage = '';

    @FormField('enableMobileAccess', [false])
    enableMobileAccess = false;

    @FormField('externalCode', [])
    externalCode = '';

    @FormField('paypointNumber', [])
    paypointNumber = '';

    @FormField('workOrderEmail', [])
    workOrderEmail = '';

    @FormField('newResidentMessage', [])
    newResidentMessage = '';

    communityMappingId = 0;

    // community information
    name: string = '';

  
}
