import { LocalStorageHelper } from './local-storage-helper';

export namespace SecurityHelper {

    export function ValidatePermition(ringId: number): boolean {

        // With this flag the system knows if has to work with an impersonated user or a logged user.
        // If the flag is false the system works with the loggedUserInfo
        // If the flag is true the system works with the impersonatedLoggedUserInfo
        const impersonatedFlag = LocalStorageHelper.getImpersonatedFlag();


        const permitions = LocalStorageHelper.getUserPermitions(impersonatedFlag);
        let i: number;
        if (permitions == null) {
            return false;
        }
        for (i = 0; i < permitions.length; i++) {
            if (permitions[i].ringId === ringId) {
                return true;
            }
        }
        return false;
    }

    export function LoggedUserIsStaff() {

        // With this flag the system knows if has to work with an impersonated user or a logged user.
        // If the flag is false the system works with the loggedUserInfo
        // If the flag is true the system works with the impersonatedLoggedUserInfo
        const impersonatedFlag = LocalStorageHelper.getImpersonatedFlag();

        const loggedUser = LocalStorageHelper.getLoggedUserInfo(impersonatedFlag); // Logged User
            return loggedUser.isCommunityStaff || loggedUser.isGridStaff ||
                   loggedUser.isManagementCompanyStaff || loggedUser.isGod;
    }
}
