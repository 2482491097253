import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PaymentDialogComponent } from 'src/app/app-dialogs/payment-dialog/payment-dialog.component';
import { MatDialog } from '@angular/material';
import { AccountData } from 'src/app/model/account-data';
import { PaymentConfirmationComponent } from 'src/app/app-dialogs/payment-confirmation/payment-confirmation.component';
import { forkJoin, Subscription } from 'rxjs';
import { C3POService } from 'src/app/services/c3po.service';
import { AccountService } from 'src/app/services/account.service';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';
import { PaymentInfo } from 'src/app/model/paymentInfo';
import { BaseSecurity } from 'src/app/building-blocks/base-security';
import { EntityRing, AccessType } from 'src/app/model/access-type-ring';
import { TransactionItem } from '../../model/transaction-item';
import { TransactionsService } from '../../services/transactions.service';
import { Transaction } from '../../model/transaction';
import { Router } from '../../../../node_modules/@angular/router';
import { Resident } from '../../model/resident';
import { SiteHelper } from '../../helpers/site-helper';

@Component({
  selector: 'app-sidebar-account',
  templateUrl: './sidebar-account.component.html',
  styleUrls: ['./sidebar-account.component.css']
})
export class SidebarAccountComponent  extends BaseSecurity implements OnInit , OnDestroy {
  totalAmount = 0;
  lastTransactionAffected: Date;
  protected breadcrumbChanges: Subscription;
  protected breadcrumbChangesInfo: Subscription;
  protected showOtherUnits: boolean;
  public payPermisions = new EntityRing();

  @Input() unitSelected : number;

  selectedAccount: AccountData = {
    account: '',
    amount_due: 0,
    checked: true
  };

  private transactions: Transaction[] = [];
  public accounts: string[] = [];

  loading = true;

  unitAccounts: AccountData[] = [];
  public resident: Resident;

  constructor(
    private dialog: MatDialog,
    private accountService: AccountService,
    private transactionService: TransactionsService,
    private _router: Router,
  ) {
    super();
    this.loadSecurityRings();
    this.populateBalance();
    this.breadcrumbChanges  = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      this.populateBalance();
    });
  }

  protected loadSecurityRings() {
    this.payPermisions.rings.push({ringId: 7012, accessType: AccessType.Add});
    this.entityRings.push(this.payPermisions);
  }

  ngOnDestroy() {
    if (this.breadcrumbChanges) {
      this.breadcrumbChanges.unsubscribe();
    }
    if (this.breadcrumbChangesInfo) {
    this.breadcrumbChangesInfo .unsubscribe();
    }
  }

  ngOnInit() {
}

populateBalance() {
  this.totalAmount = 0;
  this.accounts = [];
  this.unitAccounts = [];
  const userInfo = LocalStorageHelper.getLoggedUserInfo(false);
  if (!userInfo.isResident) {
    return;
  }
  this.resident =  userInfo.residents.filter(u => u.userId === userInfo.userId)[0];
  this.unitSelected = this.resident.unitId;
  const observableBalanceArray = this.accountService.getBalancesByUnitId(this.unitSelected);     
    const accountId = this.resident.accountId;
    this.accounts.push(this.resident.externalAccountNumber);
    const observableArray = this.transactionService.getAllPaged(1, 5, accountId);
    forkJoin(observableBalanceArray,observableArray).subscribe(results => {
      for (let i = 0; i < results[0].length; i++) {
        const element = results[0][i];
        this.unitAccounts.push({
          account: element.accountCode,
          amount_due: element.amount,
          checked: true
        });
       }
      this.selectedAccount = this.unitAccounts[0];
      this.totalAmount = results[0].map(s => s.amount).reduce((acc, val) => {
          return acc + val;
      });
      this.lastTransactionAffected = results[0].map(s => s.lastTransactionAffected).reduce((acc, val) => {
        return val;
    });
    this.transactions = results[1];
      this.showOtherUnits = this.unitAccounts.length > 1;
      this.loading = false;
    });
  }

tooltipText(row: any): string {
  return  this.accountService.getTooltipForPending(row);
}

goToTransactions() {
  this._router.navigateByUrl('app/ledger/transactions');
}
  openPayment(value: string) {
    let accountsToPay: string[] = [];
    (Array.isArray(value)) ? accountsToPay = value : accountsToPay.push(value);
    const data = {
      accounts: accountsToPay
    };
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      data: data,
      panelClass:  SiteHelper.getDefaultTheme()
    });
    dialogRef.componentInstance.registerPaymentEvent.subscribe(result => {
      if (result) {
        this.populateBalance();
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const dialogConfirmation = this.dialog.open(PaymentConfirmationComponent, {
          data: result,
          panelClass:  SiteHelper.getDefaultTheme()
        });
        dialogConfirmation.afterClosed().subscribe(results => {
        });
      }
    });
  }

}
