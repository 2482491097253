import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { MatDialog, MatSelect, MatSlideToggleChange } from '@angular/material';
import { AlertDialogComponent } from '../../../../../src/app/app-dialogs/alert-dialog/alert-dialog.component';
import { LocalStorageHelper } from 'src/app/helpers/local-storage-helper';


// Services
import { DocumentService } from '../../services/document.service';
import { DocumentTypeService } from '../../services/document-type.service';
import { ServiceError } from '../../../../../src/app/model/service-error';
// import { ApiResponse } from '../../../../../src/app/model/api-response';

// Models
import { DocumentPricePlan } from '../../model/documentPricePlan';
import { Document } from '../../model/document';
import { DocumentType } from '../../model/document-type';
import { PricePlan } from '../../model/price-plan';

@Component({
  selector: 'app-document-add-edit',
  templateUrl: './document-add-edit.component.html',
  styleUrls: ['./document-add-edit.component.css']
})
export class DocumentAddEditComponent implements AfterViewInit, OnInit {
  public model: DocumentPricePlan;
  public formGroup: FormGroup;
  // public documentDropDwn: Document[];
  public documentTypes: DocumentType[];
  @ViewChild('documentTypeSelect') documentTypeSelect: MatSelect;
  @ViewChild('documentSelect') documentSelect: MatSelect;
  public loading: boolean;
  public saveFailed: boolean;
  public saveErrorMessage: string;
  public SAVED_MESSAGE = 'Your document was suscessfully saved';
  public DELETE_MESSAGE = 'Are you sure you want to delete?';
  public TITLE_INFO = 'Info';
  private sub: Subscription;
  protected communityIdSelected = 0;
  protected managementCompanyIdSelected = 0;
  protected breadcrumbChanges: Subscription;


  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private documentService: DocumentService,
    private documentTypeService: DocumentTypeService,
    private dialog: MatDialog) {
    this.loading = true;
    this.checkParams();
    this.breadcrumbChanges = LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      if (this.checkParams()) {
        this.onCancel();
      }
    });
  }

  ngOnInit() {
    // Instantiate the model object.
    this.model = new DocumentPricePlan();
    this.model.document = new Document();
    this.model.pricePlan = new PricePlan();
    this.model.pricePlan.relationShipTypes = [];
    this.model.pricePlan.communities = [];

    this.validateDocFormGroup();

    this.sub = this.route.paramMap.subscribe(
      params => {
        this.model.document.documentId = +params.get('id');

        const docTypesObservable = this.documentTypeService.getAll();
        // const docObservable = this.documentService.getAll();
        forkJoin(docTypesObservable)
        .subscribe(results => {
          this.documentTypes = results[0];
          // this.documentDropDwn = results[1];
          this.initDocument();
          this.loading = false;
        });
      });

    if (!this.model.document.documentId) {
      this.model.document.isActive = true;
      // this.documentFormGroup.get('document').disable();
      this.loading = false;
    }
  }

  validateDocFormGroup(): void {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      documentType: ['', Validators.required],
      description: ['', Validators.required],
      isActive: '',
      mandatoryPayUpFront: '',
      pricePlan: this.fb.group({
        before: ['', Validators.required],
        after: ['', Validators.required]
      })
    });
  }

  initDocument(): void {
    if (this.model.document.documentId) {
      this.documentService.getDocById(this.model.document.documentId).subscribe(dc => {
        this.model = dc;
        });
    }
  }

  onSave() {
    if (this.formGroup.dirty && this.formGroup.valid) {
      this.model.document.isDeleted = false;
      this.model.managementCompanyId = this.managementCompanyIdSelected;
      this.model.communityId = this.communityIdSelected;
      this.loading = true;
      if (!this.model.document.documentId) {
        this.addDocument();
      } else {
        this.editDocument();
      }
    }
  }

  addDocument() {
    this.documentService.addDocument(this.model).subscribe(
      result => {
        this.onSaveComplete();
      },
      (error: ServiceError) => {
        this.onServerError(error);
      });
  }

  editDocument() {
    this.documentService.updateDocument(this.model).subscribe(result => {
      this.onSaveComplete();
    },
      (error: ServiceError) => {
        this.onServerError(error);
      });
  }

  openDialog(title: string, Message: string): void {
    let data;
    data = {
      Title: title,
      Message: Message
    };

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '750px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.formGroup.reset();
      this.router.navigateByUrl('/app/docs/document');
    });
  }

  onServerError(data) {
    this.loading = false;
    this.saveFailed = true;
    this.saveErrorMessage = 'Sorry, there was an error while saving the record.';
    console.log(data.error.errorMessage);
  }

  onSaveComplete(): void {
    this.loading = false;
    this.openDialog(this.TITLE_INFO, this.SAVED_MESSAGE);
  }

  ngAfterViewInit() {
  }

  onCancel(): void {
    this.formGroup.reset();
    this.router.navigateByUrl('/app/docs/document');

  }

  checkParams(): boolean {
    const mcLocalStorage = LocalStorageHelper.getManagementCompanyFromBreadcrumb();
    const comunityLocalStorage = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    if ((this.managementCompanyIdSelected !== mcLocalStorage || this.communityIdSelected !== comunityLocalStorage)) {
      this.managementCompanyIdSelected = mcLocalStorage;
      this.communityIdSelected = comunityLocalStorage;
      return true;
    } else {
      return false;
    }
  }

}
