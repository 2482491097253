import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AccountData } from 'src/app/model/account-data';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.css']
})
export class PaymentConfirmationComponent implements OnInit {
  totalAmount = 0;
  confirmationNumber = 0;
  accounts: AccountData[] = [];
  operationDate: number = Date.now();
  pay = false;
  fee = 0;
  responseMessage = '';

  constructor(
    public dialogRef: MatDialogRef<PaymentConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.accounts = this.data.accounts;
    this.pay = this.data.response.isApproved;
    this.confirmationNumber = this.data.response.provider_TransactionId;
    this.totalAmount = this.data.response.amount;
    this.responseMessage = this.data.response.provider_ResponseText;
    this.fee = this.data.response.fee;
  }

  close() {
    this.dialogRef.close();
  }

}
