import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { PricePlanRelationshipTypeFilter } from '../model/price-plan-relationshipType-filter';
import { Observable, Subscription, forkJoin } from 'rxjs';
import {
  MatAutocomplete, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA,
  MatAutocompleteSelectedEvent, MatChipInputEvent
} from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { CommonService } from '../services/common.service';
import { startWith, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/app-dialogs/confirm-dialog/confirm-dialog.component';
import { PricePlanCommunityFilter } from '../model/price-plan-community-filter';

@Component({
  selector: 'app-community-relation-type-dialog',
  templateUrl: './community-relation-type-dialog.component.html',
  styleUrls: ['./community-relation-type-dialog.component.css']
})
export class CommunityRelationTypeDialogComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  relTypeCtrl = new FormControl();
  filteredRelTypes: Observable<PricePlanRelationshipTypeFilter[]>;
  relType: PricePlanRelationshipTypeFilter[] = [];
  allrelType: PricePlanRelationshipTypeFilter[] = [];
  relTypeIncluded: PricePlanRelationshipTypeFilter[] = [];
  relTypeInPriceP: PricePlanRelationshipTypeFilter[] = [];
  newrelType: PricePlanRelationshipTypeFilter[] = [];
  relTypeDetails: PricePlanRelationshipTypeFilter[] = [];
  @ViewChild('relInput') relInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoRT') matAutocomplete: MatAutocomplete;
  comCtrl = new FormControl();
  filteredCom: Observable<PricePlanCommunityFilter[]>;
  community: PricePlanCommunityFilter[] = [];
  allComm: PricePlanCommunityFilter[] = [];
  copyAllComm: PricePlanCommunityFilter[];
  newCommunity: PricePlanCommunityFilter[] = [];
  @ViewChild('comInput') comInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoC') matAutocompleteC: MatAutocomplete;
  @ViewChild('chipList') chipList;
  @ViewChild('chipListC') chipListC;

  public pricePlanId: number;
  public loading: boolean;
  private sub: Subscription;
  public editedBefore: number;
  public editedAfter: number;

  constructor(public dialogRef: MatDialogRef<CommunityRelationTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService) {
    this.filteredRelTypes = this.relTypeCtrl.valueChanges.pipe(
      startWith(null),
      map((rel: string | null) => rel ? this._filterRelationType(rel) : this.allrelType.slice()));

    this.filteredCom = this.comCtrl.valueChanges.pipe(
      startWith(null),
      map((c: string | null) => c ? this._filterCommunity(c) : this.allComm.slice()));
  }

  ngOnInit() {
    this.loading = true;
    this.getObservables();

  }

  getObservables() {
    this.allComm = [];
    this.copyAllComm = [];
    this.sub = this.route.paramMap.subscribe(
      params => {
        const relObservable = this.commonService.getRelationshipType();
        console.log(this.data.mcIdSelected);
        const comObservable = this.commonService.getCommunitybyMcId(this.data.mcIdSelected, true);
        forkJoin(relObservable, comObservable)
          .subscribe(results => {
            this.allrelType = results[0];
            this.allComm = results[1];
            console.log(this.allrelType);
            console.log(this.allComm);
            this.copyAllComm = results[1];
            this.initDialog();
            this.loading = false;
          });
      });
  }

  initDialog() {
    if (this.data.update) {
      this.pricePlanId = this.data.pricePlanId;
      this.relTypeIncluded = this.data.relTypeInPriceP.slice();

      this.relationsAdded();

    } else {
      if (this.data.relTypeInPriceP) {
        this.relationsAdded();
      }
    }

    if (this.data.details) {
      this.setMatChips();
    }
    if (this.community.length === 0) {
      this.chipListC.errorState = true;
    }

    if (this.relType.length === 0) {
      this.chipList.errorState = true;
      this.chipList.focus();
    }
  }

  relationsAdded() {
    this.data.relTypeInPriceP.forEach((el) => {
      this.relTypeInPriceP.push(el);
    });
  }

  setMatChips() {
    // set chips on top just when opening on details model
    this.data.relTypeDetails.forEach((el) => {
      this.addSelected(null, el, el.name);

      // map to community
      this.setToCommunityModel(el);

      this.removeDuplicate(el);

    });

  }

  setToCommunityModel(community) {
    let model = new PricePlanCommunityFilter();
    if (community) {
      model = {
        pricePlanCommunityFilterId: community.pricePlanCommunityFilterId,
        pricePlanId: community.pricePlanId,
        communityId: community.communityId,
        name: community.communityName,
        before: community.before,
        after: community.after,
      };
      this.addSelectedCommunity(null, model, model.name);
    }
  }

  addSelected(event: MatAutocompleteSelectedEvent, r: PricePlanRelationshipTypeFilter, name?: string): void {
    const passedValue = name ? name.trim() : event.option.viewValue.trim();
    this.relInput.nativeElement.value = '';

    this.allrelType.forEach((el, index) => {
      if (el.name.trim() === passedValue) {
        this.relType.push(r);
        this.allrelType.splice(index, 1);
      }
    });
    if (this.newrelType.findIndex(x => x.relationshipTypeId === r.relationshipTypeId) === -1) {
      this.newrelType.push(r);
    }
    if (this.relType.length > 0) {
      this.chipList.errorState = false;
    }

    this.updateDropdown();
    if (event != null) {
      event.option.value = '';
    }
    this.relTypeCtrl.setValue(null);
  }

  addSelectedCommunity(event: MatAutocompleteSelectedEvent, c: PricePlanCommunityFilter, name?: string): void {
    const passedValue = name ? name.trim() : event.option.viewValue.trim();
    this.comInput.nativeElement.value = '';

    this.allComm.forEach((el, index) => {
      if (el.name.trim() === passedValue && el.communityId === -1) {
        this.community = [];
        this.allComm = [];
      }
      if (el.name.trim() === passedValue) {
        this.community.push(c);
        this.allComm.splice(index, 1);
      }
    });

    if ((this.newCommunity.findIndex(x => x.communityId === c.communityId)) === -1) {
      if (c.communityId === -1) {
        this.newCommunity = [];
    }
      this.newCommunity.push(c);
    }

    if (this.community.length > 0) {
      this.chipListC.errorState = false;
    }

    this.updateCommunityDropdown();
    if (event != null) {
      event.option.value = '';
    }
    this.comCtrl.setValue(null);
  }

  private updateDropdown() {
    this.filteredRelTypes = this.relTypeCtrl.valueChanges.pipe(
      startWith(null),
      map((rel: string | null) => rel ? this._filterRelationType(rel) : this.allrelType.slice()));
  }

  private updateCommunityDropdown() {
    this.filteredCom = this.comCtrl.valueChanges.pipe(
      startWith(null),
      map((c: string | null) => c ? this._filterCommunity(c) : this.allComm.slice()));
  }

  remove(rel: PricePlanRelationshipTypeFilter): void {
    const index = this.relType.indexOf(rel);

    if (index >= 0) {
      this.relType.splice(index, 1);
      this.allrelType.push(rel);
      this.removeNewRelations(rel);
      if (this.relType.length === 0) {
        this.chipList.errorState = true;
      }
      this.updateDropdown();
    }
  }

  removeNewRelations(rel) {
    const index = this.newrelType.findIndex(r => r.relationshipTypeId === rel.relationshipTypeId);
    if (index > -1) {
      this.newrelType.splice(index, 1);
    }
  }

  removeNewComunity(com: PricePlanCommunityFilter) {
    const index = this.newCommunity.findIndex(c => c.communityId === com.communityId);
    if (index > -1) {
      this.newCommunity.splice(index, 1);
    }
  }

  removeCommunity(u: PricePlanCommunityFilter): void {
    const index = this.community.indexOf(u);

    if (index >= 0) {
      this.community.splice(index, 1);
      this.allComm.push(u);
      this.removeNewComunity(u);
      if (this.community.length === 0) {
        this.chipListC.errorState = true;
      }
      if (u.communityId === -1) {
        this.allComm = this.copyAllComm;
      }
      this.updateCommunityDropdown();
    }
  }

  private _filterRelationType(value: string): PricePlanRelationshipTypeFilter[] {
    if (typeof value === 'object') {
      value = value['name'];
    }
    let filterValue = value; // .toLowerCase();
    if (filterValue) { filterValue = filterValue.toLowerCase(); }
    return this.allrelType.filter(rel => rel.name.toLowerCase().includes(filterValue));
  }

  private _filterCommunity(value: string): PricePlanCommunityFilter[] {
    if (typeof value === 'object') {
      value = value['name'];
    }
    let filterValue = value; // .toLowerCase();
    if (filterValue) { filterValue = filterValue.toLowerCase(); }
    return this.allComm.filter(u => u.name.toLowerCase().includes(filterValue));
  }

  addToModel(relationType, community) {
    relationType.forEach((el) => {
      community.forEach(c => {

        const model: PricePlanRelationshipTypeFilter = {
          pricePlanRelationshipTypeFilterId: 0,
          pricePlanId: this.pricePlanId,
          communityId: c.communityId,
          communityName: c.name,
          relationshipTypeId: el.relationshipTypeId,
          name: el.name,
          before: this.data.details ? this.editedBefore : this.data.counter,
          after: this.data.details ? this.editedAfter : this.data.counter,
        };
        this.relTypeInPriceP.push(model);
      });
    });

  }

  onSubmitFilters(): void {
    this.addToModel(this.newrelType, this.newCommunity);
    this.dialogRef.close({
      dirty: true,
      update: true,
      relTypeInPriceP: this.relTypeInPriceP
    });
  }

  onCancelFilters(): void {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '750px'
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.data.relTypeInPriceP = [];
        this.relTypeInPriceP = [];
        this.dialogRef.close({
          dirty: false,
          relTypeInPriceP: this.relTypeIncluded
        }
        );
      }
    });
  }

  removeDuplicate(rel: PricePlanRelationshipTypeFilter): void {
    const index = this.relTypeInPriceP.indexOf(rel);

    if (index >= 0) {
      this.editedBefore = rel.before;
      this.editedAfter = rel.after;
      this.relTypeInPriceP.splice(index, 1);
    }
  }

}
