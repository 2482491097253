export class Menu {
    id: number;
    name: string;
    icon: string;
    url: string;
    children: Menu[];
    opened: Boolean;
    level: number;
    click: () => void;
    selected: boolean;
    isActive: boolean;
}
