import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {

    if (items === undefined) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    let result;
    if (items.hasOwnProperty('name')) {
      result = items.filter(it => {
        return it.name.toLowerCase().includes(searchText);
      });
    } else if (items.hasOwnProperty('nickName')) {
      // TODO: Write better code to filter companies.
      result = items.filter(it => {
        return it.nickName.toLowerCase().includes(searchText);
      });
    } else if (items.hasOwnProperty('legalName')) {
      // TODO: Write better code to filter companies.
      result = items.filter(it => {
        return it.legalName.toLowerCase().includes(searchText);
      });
    }

    return result;
   }
}

