import { Directive, Input, ElementRef } from "@angular/core";

@Directive({
    selector: '[badgeDirective]'
})
export class BadgeDirectiveComponent {
    @Input() matBadgeIcon: string;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        const badge = this.el.nativeElement.querySelector('.mat-badge-content');
        badge.innerHTML = `<i class="material-icons" style="font-size: 20px">${this.matBadgeIcon}</i>`;
    }
}