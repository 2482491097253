export class RingTableEntry {
    select = false;
    ringId: number;
    ringTypeId: number;
    ringTypeName: string;
    entityId: number;
    entityName: string;
    name: string;
    description: string;
    persisted: boolean;
}
