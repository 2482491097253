import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { PictureService } from '../../services/picture.service';
import { LocalStorageHelper } from '../../helpers/local-storage-helper';
import { LocationService } from '../../services/location.service';
import { LocationOffice } from '../../model/locationOffice';
import { TardisFileService } from '../../services/pensieve.service';
import { CommunityService } from '../../services/community.service';
import { AddressTableEntry } from '../../model/address-table-entry';
import { stringHelper } from '../../helpers/stringHelper';
import { Router } from '@angular/router';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';

@Component({
  selector: 'app-office-information-widget',
  templateUrl: './office-information-widget.component.html',
  styleUrls: ['./office-information-widget.component.css']
})
export class OfficeInformationWidgetComponent implements OnInit, OnDestroy {
  @Input() performAction: boolean = false;
  offices: LocationOffice[] = [];
  protected componentSubscriptions: Subscription[] = [];
  imageSrc = 'assets/images/photo-placeholder@2x.png';
  loading: boolean;
  communityId: number;
  noRecords = true;
  $offices: Subject<any> = new Subject<any>();
  
  constructor(private officeService: LocationService,
    private pictureService: PictureService,
    private cdr: ChangeDetectorRef,
    private communityService: CommunityService,
    private tardisFileService: TardisFileService,
    private router: Router,
    private domSanitizer: DomSanitizer) {
    this.communityId = LocalStorageHelper.getCommunitiesFromBreadcrumb();
    this.componentSubscriptions.push(LocalStorageHelper.watchBreadcrumb().subscribe(changes => {
      this.noRecords = true;
      const community = LocalStorageHelper.getCommunitiesFromBreadcrumb();
      if (community != this.communityId) {
        this.communityId = community;
        this.loadOfficeInformation();
      }
    }));
    this.componentSubscriptions.push(LocalStorageHelper.getSelectedLocationBarNode().subscribe(data => {
      this.noRecords = true;
      if (data && data.length > 0) {
        if (this.communityId > 0) {
          this.loadOfficeInformation();
        }
      }
    }));
  }
  ngOnDestroy(): void {
    if (this.componentSubscriptions && this.componentSubscriptions.length > 0) {
      this.componentSubscriptions.forEach(item => {
        item.unsubscribe();
      })
    }
  }

  loadOfficeInformation() {
    this.offices = [];
    this.communityService.getById(this.communityId).subscribe(com => {
      const companyId = com.companyId;
      this.officeService.getLocationByCompany(companyId).subscribe(x => {
        this.offices = x.filter(off => off.showInOfficeInformation);
        if (this.offices.length > 0) {
          this.$offices.next(this.offices);
          this.noRecords = false;
          this.offices.forEach((office, index) => {
            if (office.iconId != null) {
              this.getFile(office.iconId, index);
            }
          });
        }else {
          this.$offices.next(false);
        }
      });
    });
  }


  getAddress(address: AddressTableEntry[]) {
    if (address.length > 0) {
      return address[0].line1 + (address[0].line2 ? ' ' + address[0].line2 : '');
    }
  }

  getAddressLine2(address: AddressTableEntry[]) {
    if (address.length > 0) {
      return stringHelper.initCap(address[0].cityName) + ', ' + address[0].stateName + ' ' + address[0].zipCode;
    }
  }


  setImage(pic: any, i: number) {
    const reader = new FileReader();
    const blob = new Blob([new Uint8Array(pic)], { type: 'image/svg+xml' });
    if (blob.size > 0) {
      const file = new File([blob], 'QrCode', { type: 'image/svg+xml', lastModified: Date.now() });
      reader.onload = (event: any) => {
        this.offices[i].picture = this.domSanitizer.bypassSecurityTrustUrl(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  getFile(fileId: number, i: number) {
    if (fileId > 0) {
      this.tardisFileService.getFile(fileId).subscribe(pic => {
        if (pic) {
          this.setImage(pic, i);
        }
      });
    }
  }

  ngOnInit() {
    if (this.communityId > 0) {
      this.loadOfficeInformation();
    }
   
  }

  ngAfterViewInit() {
    
  }

  convertTime12to24(time) {
    return stringHelper.convertTime12to24(time);
  }
  
  edit(office: LocationOffice) {
    this.setBackToOfficeInformationLink();
    this.router.navigate(['app/community/office-information-add-edit/' + office.locationId]);
  }

  delete(locationId: number) {
    this.officeService.softDelete(locationId).subscribe(result =>  {
      this.loadOfficeInformation();
    }, error => {
      console.log(error);
    });
  }

  public setBackToOfficeInformationLink() {
    //add parameter on the link to select office information tab
    LocalStorageHelper.setBackToButtonOnLocationBar({
      label: 'Back to Office Information',
      link: 'community/settings',
      selectedTabQueryParam: 'officeInformation'
    });
  }


}