import { Component, OnInit } from '@angular/core';
import { CommunityService } from '../services/community.service';
import { CompanyService } from '../services/company.service';
import { Observable } from 'rxjs';
import { PictureService } from '../services/picture.service';
import { Router } from '@angular/router';
import { stringHelper } from '../helpers/stringHelper';
import { SiteHelper } from '../helpers/site-helper';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-landing-community',
  templateUrl: './landing-community.component.html',
  styleUrls: ['./landing-community.component.css']
})
export class LandingCommunityComponent implements OnInit {

  public sliderArray: any;
  public selectedIndex: number;
  public loading: boolean;
  public success: boolean;
  transform: number;

  constructor(private companyService: CompanyService,
     private pictureService: PictureService,
     private _router: Router,
     private communityService: CommunityService) {
    this.selectedIndex = 0;
    this.transform = 100;
  }

  ngOnInit() {
    this.loading = true;
    this.success = false;

    this.sliderArray = [];

    // If it's a community, try to retrieve its information by the corresponding subdomain.
    if (SiteHelper.isCommunity()) {
      this.communityService.getBySubDomain(location.host.split('.')[0]).subscribe(c => {

        if (c) {
          this.loadCarousel(c[0].communityId);
          this.companyService.setCommunity(c[0]);
        } else {
          SiteHelper.goToLogin();
        }
      });
    }
  }

  loadCarousel(id: number) {
    const classe = 'carousel-item ';
    this.pictureService.GetCommunityPictureGallery(id).subscribe(pic => {

      // No pictures, nothing to do.
      if (pic == null) {
        console.error('No pictures available for this community. Configure some before trying again.');
        this.loading = false;
        return;
      }

      pic.forEach((p, index) => {
        let src: string;
        const reader =  new FileReader();
        const blob = new Blob([stringHelper.base64ToArrayBuffer(p.byteArray)], {type: 'image/jpeg' });
        if (blob.size > 0) {
          const file = new File([blob], 'pic', {type: 'image/jpeg', lastModified: Date.now()});
          reader.onload = (event: any) => {
            src = event.target.result;
            this.sliderArray.push({img: src, alt: '', text: '', class: index === 0 ? classe  + 'active' : classe, classBullet: 'active' });
          };
          reader.readAsDataURL(file);
        }
      });

      this.loading = false;
      this.success = true;
      this.timeout();
    });
  }

  nextImage(i) {
    this.unselected();
    this.selectedIndex = i;
    this.selected();
  }

  timeout() {
    setTimeout(() => {
        this.next();
        this.timeout();
    }, 3000);
}

  next() {
    this.unselected();
    this.selectedIndex  = (this.selectedIndex + 1) < this.sliderArray.length - 1  ? this.selectedIndex + 1 : 0;
    this.selected();
  }

  prev() {
    this.unselected();
    this.selectedIndex  = (this.selectedIndex - 1) >= 0 ? this.selectedIndex - 1 : this.sliderArray.length - 1;
    this.selected();
  }

  unselected() {
    this.sliderArray.forEach((element, index) => {
      element.class = 'carousel-item';
      element.classBullet = 'active';
    });
  }

  selected() {
    this.sliderArray[this.selectedIndex].class = 'carousel-item active';
    this.sliderArray[this.selectedIndex].classBullet = 'carousel-indicators-active active';
   }

}
