import { Validators } from '@angular/forms';
import { BaseForm } from '../building-blocks/base-form';

import { FormField } from '../building-blocks/decorators/reactive-form-decorators';

export class Role extends BaseForm {
        roleId = 0;

    /**
     * The name of the Role.
     */
    @FormField('name', ['', Validators.required])
    name = '';

    /**
     * The description of the Role.
     */
    @FormField('description', ['', Validators.required])
    description = '';

    /**
     * The roleTypeId of the Role.
     */
    @FormField('roleTypeId', ['', Validators.required])
    roleTypeId = 0;

    groupId = 0;
    companies: number[];
    rings: number[];



}
