import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

import {
  PropertyManagementCompanyListComponent
} from '../property-management-company-list/property-management-company-list.component';
import {
  PropertyManagementCompanyAddEditComponent
} from '../property-management-company-add-edit/property-management-company-add-edit.component';

import { AuthGuardService } from '../../services/auth-guard.service';
import { UnderConstructionComponent } from '../../under-construction/under-construction.component';
import { SharedModule } from '../../shared/shared.module';

const companiesRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'add/:parent',
        component: PropertyManagementCompanyAddEditComponent,
        data: { title: 'Add Management Companies' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'edit/:id',
        component: PropertyManagementCompanyAddEditComponent,
        data: { title: 'Edit Management Company' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'list',
        component: PropertyManagementCompanyListComponent,
        data: { title: 'List Management Companies' },
        canActivate: [AuthGuardService]
      },
      {
        path: 'settings',
        component: UnderConstructionComponent,
        data: { title: 'Settings' },
        canActivate: [AuthGuardService]
      } ,
      {
        path: 'staff',
        component: UnderConstructionComponent,
        data: { title: 'Staff' },
        canActivate: [AuthGuardService]
      }
      ,
      {
        path: 'my-management-company',
        component: UnderConstructionComponent,
        data: { title: 'My management company' },
        canActivate: [AuthGuardService]
      }
    ]
}];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(companiesRoutes),
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class CompaniesRoutingModule { }
